import React, { useEffect, useState } from "react";

import styles from "./TeamComponent.module.scss";
import FilterComponent from "../../../../../../../components/FilterComponent/FilterComponent";
import FilterComponentInput from "../../../../../../../components/FilterComponentInput/FilterComponentInput";
import ButtonColor from "../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import TeamItemComponent from "./components/TeamItemComponent/TeamItemComponent";
import AddTeamMemberPopup from "./components/AddTeamMemberPopup/AddTeamMemberPopup";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  makeSelectIsEmptyTeamMembersData,
  makeSelectFiltersEmail,
  makeSelectFiltersName,
  makeSelectListOfTeamMembers,
  makeSelectSortedListOfTeamMembers,
  makeSelectSortedFiltersName,
} from "../../../../../../../state/selectors/team";
import WelcomeTeamComponent from "./components/WelcomeTeamComponent/WelcomeTeamComponent";
import AddTeamMemberButton from "./components/AddTeamMemberButton/AddTeamMemberButton";
import { dispatch } from "../../../../../../../state/store";
import { setListOfTeamMembers } from "../../../../../../../state/ducks/team";

const mapStateToProps = createStructuredSelector({
  isEmptyTeamMembersData: makeSelectIsEmptyTeamMembersData(),
  listOfTeamMembers: makeSelectListOfTeamMembers(),
  sortedListOfMembers: makeSelectSortedListOfTeamMembers(),
  filtersName: makeSelectFiltersName(),
  sortedFiltersName: makeSelectSortedFiltersName(),
  filtersEmail: makeSelectFiltersEmail(),
});

const TeamComponent = (props: any) => {
  const {
    listOfTeamMembers,
    filtersName,
    filtersEmail,
    sortedListOfMembers,
    isEmptyTeamMembersData,
    sortedFiltersName,
  } = props;

  const [filterValueName, setFilterValueName] = useState({ name: "", id: -1 });
  const [filterValueEmail, setFilterValueEmail] = useState({
    name: "All",
    id: -1,
  });
  const [isVisibleAddMemberPopup, setIsVisibleAddMemberPopup] = useState(false);

  useEffect(() => {
    filterTeamMembers();
  }, [filterValueName, filterValueEmail]);

  const onNameClick = (name: any, id: number) => {
    setFilterValueName({ name: name, id: id });
  };

  const onNameInputChange = (input: string) => {
    setFilterValueName({ ...filterValueName, name: input });
  };

  const onEmailClick = (name: any, id: number) => {
    setFilterValueEmail({ name: name, id: id });
  };

  const onResetFiltersClick = () => {
    setFilterValueName({ name: "", id: -1 });
    setFilterValueEmail({ name: "All", id: -1 });
  };

  const onOpenAddTeamMemberPopup = () => {
    setIsVisibleAddMemberPopup(true);
  };

  const filterTeamMembers = () => {
    const isSelectName =
      filterValueName.name !== "" && filterValueName.name !== "All users";
    const isSelectEmail = filterValueEmail.name !== "All";
    const filteredByNameEmail = isSelectName && isSelectEmail;

    const filteredMembersList = sortedListOfMembers.filter(
      (memberItem: any) => {
        const learnerName = memberItem.name;
        const learnerEmail = memberItem.email;

        if (filteredByNameEmail) {
          return (
            learnerName.includes(filterValueName.name) &&
            learnerEmail.includes(filterValueEmail.name)
          );
        }

        if (isSelectName) {
          return learnerName
            .toLowerCase()
            .includes(filterValueName.name.toLowerCase().trim());
        }

        if (isSelectEmail) {
          return learnerEmail.includes(filterValueEmail.name);
        }
        return memberItem;
      }
    );

    dispatch(setListOfTeamMembers(filteredMembersList));
  };

  return (
    <>
      {isEmptyTeamMembersData && <WelcomeTeamComponent />}
      <div className={styles.ContainerTeam}>
        {!isEmptyTeamMembersData && (
          <div>
            <div className={styles.WrapperFiltersAndAddBtn}>
              <div className={styles.WrapperFilters}>
                <FilterComponentInput
                  filterValue={filterValueName}
                  classNameContainer={styles.FilterContainerItem}
                  classNameDropDown={styles.DropDownContainer}
                  classNameDropDownMenu={styles.DropDownMenuContainer}
                  classNameInput={styles.Input}
                  textTitle="Name"
                  classNameTitle={styles.Title}
                  listOptions={filtersName}
                  onOptionFilterClick={onNameClick}
                  onNameInputChange={onNameInputChange}
                  sortedListOptions={sortedFiltersName}
                  team={true}
                />
                <FilterComponent
                  filterValue={filterValueEmail}
                  classNameDropDown={styles.DropDownContainer}
                  classNameDropDownMenu={styles.DropDownMenuContainer}
                  classNameContainer={styles.FilterContainerItem}
                  textTitle="Email"
                  classNameTitle={styles.Title}
                  onOptionFilterClick={onEmailClick}
                  listOptions={filtersEmail}
                />
                <ButtonColor
                  className={styles.ResetFilters}
                  onClick={onResetFiltersClick}
                >
                  Reset filters
                </ButtonColor>
              </div>
              <AddTeamMemberButton
                onOpenAddTeamMemberPopup={onOpenAddTeamMemberPopup}
              />
            </div>
            {listOfTeamMembers.length ? (
              <TeamItemComponent setFilterValueName={setFilterValueName} />
            ) : (
              <div className={styles.NoData}>No data found</div>
            )}
          </div>
        )}
        {isVisibleAddMemberPopup && (
          <AddTeamMemberPopup
            setIsVisibleAddMemberPopup={setIsVisibleAddMemberPopup}
          />
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps)(TeamComponent);

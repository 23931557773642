import React, {useEffect, useState} from 'react';
import FilterComponent from '../../../../../../../../components/FilterComponent/FilterComponent';
import styles from './RoleComponent.module.scss';

import rolesList from './roleslist';
import InputAuthField from '../../../../../../../../components/Input/InputAuthField/InputAuthField';
import classNames from 'classnames';

type roleType = {
    name: string;
    id: number;
};

type RoleComponentProps = {
    role: roleType;
    setRole: Function;
    inputRole: string;
    setInputRole: Function;
}

const RoleComponent:React.FC<RoleComponentProps> = ({role, setRole, inputRole, setInputRole}) => {
    const [isVisibleInputRole, setIsVisibleInputRole] = useState(false);

    useEffect(() => {
        role.id === 9 ? setIsVisibleInputRole (true) : setIsVisibleInputRole (false);
    }, [role]);

    const onRoleClick = (name: string, id: number) => {
        setRole({name: name, id: id})
    };

    const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        setInputRole(event.currentTarget.value);
    };

    return (
        <>
            <FilterComponent
                filterValue={role}
                classNameOptions={styles.Options}
                classNameDropDown={classNames(styles.DropDownContainer, isVisibleInputRole && styles.DropDownContainerInput)}
                classNameDropDownMenu={classNames(styles.DropDownMenuContainer, isVisibleInputRole && styles.DropDownContainerMenuInput)}
                classNameContainer={styles.FilterContainerItem}
                onOptionFilterClick={onRoleClick}
                classNameTitle={styles.Title}
                textTitle='Role'
                listOptions={rolesList}
                signUp={true}
            />
            {isVisibleInputRole &&
                <InputAuthField
                    type='text'
                    value={inputRole}
                    placeholderInput='Your role'
                    classNameInput={styles.DropDownContainer}
                    onChange={onInputChange}
                />
            }
        </>
    );
};

export default RoleComponent;

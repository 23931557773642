import React, { useEffect, useState } from "react";

import Input from "../../../../../../../../../../../../../components/Input/Input";
import styles from "./ConfigureLearnerItem.module.scss";
import { createStructuredSelector } from "reselect";
import { makeSelectLearnersListByPipedrive } from "../../../../../../../../../../../../../state/selectors/integrations";
import { dataForPipedrive } from "../../../../../../../../../../../../../types/integrationType/dataForPipedrive";
import { connect } from "react-redux";
import { dispatch } from "../../../../../../../../../../../../../state/store";
import { setLearnersListByPipedrive } from "../../../../../../../../../../../../../state/ducks/integrations";
import integrationsService from "../../../../../../../../../../../../../services/server/integrationsService/integrationsService";

const mapStateToProps = () =>
  createStructuredSelector({
    learnersListByPipedrive: makeSelectLearnersListByPipedrive(),
  });

type ConfigureLearnerItemProps = {
  learnersListByPipedrive: Array<dataForPipedrive>;
  isLearnersDataEmpty: boolean;
};

const ConfigureLearnerItem: React.FC<ConfigureLearnerItemProps> = ({
  learnersListByPipedrive,
  isLearnersDataEmpty,
}) => {
  const learnerSelectedChange = (learnerId: number) => {
    const updatedLearnersList = learnersListByPipedrive.filter(
      (learner: any) => {
        if (learner.learnerId === learnerId) {
          learner.submitted = !learner.submitted;
        }
        return learner;
      }
    );

    dispatch(setLearnersListByPipedrive(updatedLearnersList));
  };

  return (
    <div className={styles.Container}>
      {!isLearnersDataEmpty && (
        <div className={styles.SubContainer}>
          {learnersListByPipedrive.map((item) => {
            const learnerId = item.learnerId;
            const pipedriveId = item.pipedriveId;
            const name = item.name;
            const isSubmitted = item.submitted;

            return (
              <label className={styles.WrapperInput} key={learnerId}>
                <div className={styles.InputAndLearner}>
                  <Input
                    className={styles.Input}
                    type="checkbox"
                    onChange={() => learnerSelectedChange(learnerId)}
                    checked={isSubmitted}
                  />
                  <span className={styles.Learner}>{name}</span>
                </div>
              </label>
            );
          })}
        </div>
      )}
      {isLearnersDataEmpty && (
        <div className={styles.ContainerEmptyData}>
          <div className={styles.FirstItemEmpty}>There are no users yet</div>
          <div className={styles.SecondItemEmpty}>
            But they will appear here once you invite somebody to the training.
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ConfigureLearnerItem);

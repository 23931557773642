import React from 'react';

import styles from './ModalContentComponent.module.scss';
import ContentFieldsList from './components/ContentFieldsList/ContentFieldsList';
import MoreFieldsList from './components/MoreFieldsList/MoreFieldsList';

const ModalContentComponent = (props:any) => {
    const {reference, hideNewContentModal, openYouTubePopup, openTaskPopup, openQuizPopup, openDelayPopup,
        setRecorder, isRecording} = props;
    
    return (
        <div className={styles.MainModalContainer} ref={reference}>
            <div className={styles.ContentContainer}>
                <span className={styles.Title}>CONTENT</span>
                <ContentFieldsList
                    classNameContainerList={styles.ContentBlockList}
                    hideNewContentModal={hideNewContentModal}
                    openYouTubePopup={openYouTubePopup}
                    setRecorder={setRecorder}
                    isRecording={isRecording}
                />
            </div>
            <div className={styles.Line} />
            <div className={styles.MoreContainer}>
                <span className={styles.Title}>MORE</span>
                <MoreFieldsList
                    classNameContainerList={styles.ContentBlockList}
                    hideNewContentModal={hideNewContentModal}
                    openTaskPopup={openTaskPopup}
                    openQuizPopup={openQuizPopup}
                    openDelayPopup={openDelayPopup}
                />
            </div>
        </div>
    );
};

export default ModalContentComponent;
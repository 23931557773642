import React, {useEffect} from 'react';

import CoursesListComponent from './components/CoursesListComponent/CoursesListComponent';
import TemplatesComponent from './components/TemplatesComponent/TemplatesComponent';
import {dispatch} from '../../../../../../../state/store';
import {connect} from 'react-redux';
import {setDraftCourse} from '../../../../../../../state/ducks/courseConstructor';
import {setCurrentCourseList, setIsVisibleCourseList, setIsVisibleCreateTemplates, setIsVisibleCreateCourseComponent} from '../../../../../../../state/ducks/admin';
import {createStructuredSelector} from 'reselect';
import {
    makeSelectIsVisibleCourseList,
    makeSelectIsVisibleCreateCourseComponent,
    makeSelectIsVisibleCreateTemplates
} from '../../../../../../../state/selectors/admin';
import courseService from '../../../../../../../services/server/courseService/courseService';
import {CourseMetaData} from '../../../../../../../types/CourseMetaData';

const mapStateToProps = createStructuredSelector({
    isVisibleCourseList: makeSelectIsVisibleCourseList(),
    isVisibleCreateTemplates: makeSelectIsVisibleCreateTemplates(),
    isVisibleCreateCourseComponent: makeSelectIsVisibleCreateCourseComponent()
});

const CoursesComponent = (props:any) => {
    const {isVisibleCourseList, isVisibleCreateTemplates, isVisibleCreateCourseComponent} = props;

    const updateCourseList = async () => {
        const allCourses:CourseMetaData[] | undefined = await courseService.getAllCourses();
        await dispatch(setCurrentCourseList(allCourses));
    };

    const viewCreateCourseComponent = (isVisible:boolean) => {
        dispatch(setIsVisibleCourseList(false));
        dispatch(setIsVisibleCreateTemplates(true));
        dispatch(setIsVisibleCreateCourseComponent(isVisible));
    };

    const onCreateNewCourseClick = () => {
        dispatch(setDraftCourse({}));
        viewCreateCourseComponent(true);
    };

    const onOpenTemplateComponent = async () => {
        dispatch(setIsVisibleCreateTemplates(true));
        dispatch(setIsVisibleCourseList(false));
    };

    const hideCreateNewCourseComponent = () => {
       setIsVisibleCreateCourseComponent(false);
       dispatch(setIsVisibleCourseList(true));
       dispatch(setIsVisibleCreateTemplates(false));
       dispatch(setIsVisibleCreateCourseComponent(false));
    };

    useEffect(() => {
        updateCourseList();
    }, [isVisibleCourseList, isVisibleCreateCourseComponent]);

    return (
        <div>
            {isVisibleCourseList && (
                <CoursesListComponent
                    viewCreateCourseComponent={viewCreateCourseComponent}
                    onOpenTemplateComponent={onOpenTemplateComponent}
                />
            )}
            {isVisibleCreateTemplates && (
                <TemplatesComponent
                    isVisibleCreateCourseComponent={isVisibleCreateCourseComponent}
                    hideCreateNewCourseComponent={hideCreateNewCourseComponent}
                    onCreateNewCourseClick={onCreateNewCourseClick}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(CoursesComponent);
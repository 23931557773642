import helpCenterImg from '../../../../../../assets/images/icon/help-center-account-icon.svg';
import activeHelpCenterImg from '../../../../../../assets/images/icon/active-help-center-account-icon.svg';
import settingsImg from '../../../../../../assets/images/icon/settings-account-icon.svg';
import activeSettingsImg from '../../../../../../assets/images/icon/active-settings-account-icon.svg';
import {SettingsMenuTab} from "../../../../../types/tab/Tab";

export const availableSettingsList : SettingsMenuTab[] = [
    {
        id: 8,
        name: 'Help Center',
        icon: helpCenterImg,
        activeIcon: activeHelpCenterImg
    },
    {
        id: 9,
        name: 'Settings',
        icon: settingsImg,
        activeIcon: activeSettingsImg
    }
];
import React, { useState } from "react";

import waveImg from "../../../../../../../../../../assets/images/emoji/waving-hand.png";
import Image from "../../../../../../../../../components/Image/Image";
import styles from "./WelcomeTeamComponent.module.scss";
import AddTeamMemberPopup from "../AddTeamMemberPopup/AddTeamMemberPopup";
import ButtonColor from "../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import { ReactComponent as AddTeamMemberImg } from "../../../../../../../../../../assets/images/icon/invite-member.svg";
import Svg from "../../../../../../../../../components/SvgComponent/SvgComponent";

const WelcomeTeamComponent = () => {
  const [isVisibleAddMemberPopup, setIsVisibleAddMemberPopup] = useState(false);

  const onOpenAddTeamMemberPopup = () => {
    setIsVisibleAddMemberPopup(true);
  };

  return (
    <div className={styles.WrapperWelcomeTeamComponent}>
      <div className={styles.TitleWrapper}>
        <span className={styles.Title}>Hey</span>
        <Image src={waveImg} className={styles.Image} />
      </div>
      <div className={styles.Subtitle}>you don't have any members yet</div>
      <div className={styles.WrapperButton}>
        <ButtonColor
          className={styles.Button}
          onClick={onOpenAddTeamMemberPopup}
        >
          <div className={styles.ButtonContainer}>
            <Svg className={styles.ImageAdd}>
              <AddTeamMemberImg />
            </Svg>
            <span>Add admin</span>
          </div>
        </ButtonColor>
      </div>
      {isVisibleAddMemberPopup && (
        <AddTeamMemberPopup
          setIsVisibleAddMemberPopup={setIsVisibleAddMemberPopup}
        />
      )}
    </div>
  );
};

export default WelcomeTeamComponent;

import React from 'react';
import {Switch} from 'react-router-dom';

import AppRoute from './AppRoute';
import * as routePaths from '../../consts/routes/routePaths';
import SignUpPage from '../SignUpPage/SignUpPage';
import Root from '../Root/Root';
import AdminAccountPage from '../ConsumerAccountPage/AdminAccountPage/AdminAccountPage';
import CourseConstructorPage from '../CourseConstructorPage/CourseConstructorPage';
import SignInPage from '../SignInPage/SignInPage';
import HomeAccountPage from '../ConsumerAccountPage/HomeAccountPage/HomeAccountPage';
import SignUpTeamPage from '../SignUpTeamPage/SignUpTeamPage';
import ForgotPasswordPage from '../ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage';
import {ExternalAuthComponent} from '../ExternalAuthComponent/ExternalAuthComponent';
import {PipedriveValidateComponent} from "../Integration/PipedriveValidateComponent/PipedriveValidateComponent";
import VerifyComponent from '../VerifyComponent/VerifyComponent';

const Routes = () => {

    return (
        <Switch>
            <AppRoute exact path={routePaths.ROOT} component={Root} />
            <AppRoute path={routePaths.ADMIN_SIGN_UP} component={SignUpPage} />
            <AppRoute path={routePaths.ADMIN_SIGN_IN} component={SignInPage} />
            <AppRoute path={routePaths.TEAM_SIGN_UP} component={SignUpTeamPage} />
            <AppRoute path={routePaths.PARTNERS_PAGE} component={HomeAccountPage}/>
            <AppRoute path={routePaths.RESET_PASSWORD} component={ResetPasswordPage} />
            <AppRoute path={routePaths.VERIFY_EMAIL} component={VerifyComponent} />
            <AppRoute path={routePaths.FORGOT_PASSWORD} component={ForgotPasswordPage} />
            <AppRoute path={routePaths.ADMIN_ACCOUNT_PAGE} component={AdminAccountPage} />
            <AppRoute path={routePaths.EXTERNAL_AUTH} component={ExternalAuthComponent} />
            <AppRoute path={routePaths.COURSE_CONSTRUCTOR} component={CourseConstructorPage} />
            <AppRoute path={routePaths.PIPE_DRIVE_VALIDATION} component={PipedriveValidateComponent}/>
        </Switch>
    );
};

export default Routes;
import React, {useState} from 'react';
import { AnimateKeyframes }  from 'react-simple-animate';
import {useMediaQuery} from 'react-responsive';
import ReactTooltip from 'react-tooltip';

import styles from './ProgressBar.module.scss';
import trophyImg from '../../../../../../../../../../../../../../assets/images/emoji/trophy.png';
import warningImg from '../../../../../../../../../../../../../../assets/images/emoji/warning.png';
import Image from '../../../../../../../../../../../../../components/Image/Image';
import classNames from 'classnames';

type ProgressBarProps = {
    mainColor:string
    personalResult:number
    bestValue:number
    comparisonResult?:number
    worstResult?:number
    betterPerformers?:Array<string>
    isPercentage?:boolean
}

const ProgressBar:React.FC<ProgressBarProps> = ({mainColor,
                                                    personalResult,
                                                    bestValue,
                                                    comparisonResult,
                                                    worstResult,
                                                    betterPerformers,
                                                    isPercentage}) => {
    const isHdScreen = useMediaQuery({ query: '(min-width: 1280px)' });
    const isHdAfterScreen = useMediaQuery({ query: '(min-width: 1400px)' });
    const isWxgaScreen = useMediaQuery({ query: '(min-width: 1600px)' });
    const isFullScreen = useMediaQuery({ query: '(min-width: 1920px)' });
    const isNotEmptyDataBetterPerformance = betterPerformers  && betterPerformers.length > 0;

    const widthWrapperProgressBar = isFullScreen ? 363 : isWxgaScreen ? 310 : isHdAfterScreen ? 263 : isHdScreen ? 213 : 0;

    let progressbarWidth:any, ratio;
    if (comparisonResult !== undefined) {
        if (personalResult > comparisonResult) {
            progressbarWidth = widthWrapperProgressBar;
        } else {
            ratio = comparisonResult == 0 ? 0 : personalResult / comparisonResult
            progressbarWidth = ratio * widthWrapperProgressBar;
        }
    } else if (bestValue !== undefined) {
        ratio = bestValue == 0 ? 0 : personalResult / bestValue
        progressbarWidth = ratio * widthWrapperProgressBar;
    }

    const fillerStyles = {
        width: `${progressbarWidth === 0 ? 2.5 : progressbarWidth}px`,
        maxWidth: '100%',
        backgroundColor: mainColor,
        borderRadius: `${progressbarWidth === 0 ? '6px 0 0 6px' : '6px'}`,
        height: '7px'
    }

    return (
        <div className={styles.Container}>
            <div className={classNames(styles.ProgressBar, isNotEmptyDataBetterPerformance && styles.BetterPerformance)}
                 data-tip={isNotEmptyDataBetterPerformance ? `Behind ${betterPerformers?.join()}`:''}>
                    <div style={fillerStyles}> </div>
                    <ReactTooltip
                        effect='solid'
                        className={styles.Tooltip}
                    />
            </div>
            <div className={styles.WrapperImageResult} >
                <div>{ isPercentage ? `${personalResult} %` : personalResult }</div>
                {(personalResult === bestValue && personalResult !== worstResult) ?
                    <div className={styles.WrapperImageBest} data-tip='Team best'>
                        <Image src={trophyImg}  className={styles.Image}/>
                        <ReactTooltip
                            effect='solid'
                            className={styles.Tooltip}
                        />
                    </div>
                    :
                    (personalResult === worstResult && personalResult !== bestValue) ? <Image src={warningImg} className={styles.Image}/> : ''
                }
            </div>
        </div>
    );
};

export default ProgressBar;

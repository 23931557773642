import React from 'react';
import { InlineWidget } from "react-calendly";
import { useMediaQuery } from 'react-responsive';

import styles from './CalendarComponent.module.scss';
import ButtonText from '../../../../components/Button/components/ButtonText/ButtonText';

const CalendarComponent = (props:any) => {
    const {onHideCalendarClick, email, name} = props;

    const isFull = useMediaQuery({ query: '(min-width: 1920px)' });
    const isWxga = useMediaQuery({ query: '(min-width: 1600px)' });
    const isHd = useMediaQuery({ query: '(min-width: 1280px)' });

    const heightCalendar = isFull ? 550 : isWxga ? 510 : isHd? 500 : 500;
    const maxWightCalendar = isFull ? 450 : isWxga ? 400 : isHd? 400 : 400;

    return (
        <div className={styles.WrapperCalendar}>
            <div  className={styles.TitleCalendar}>
                <div>Want to know how to use Gradual efficiently?</div>
                <div>Book a free training session.</div>
            </div>
            <div className={styles.Calendar}>
                <InlineWidget
                    styles={{height: `${heightCalendar}px`, maxWidth: `${maxWightCalendar}px`, position: 'relative'}}
                    url="https://calendly.com/gradual/20min?hide_event_type_details=1&hide_gdpr_banner=1"
                    prefill={{
                        email: email,
                        name: name
                    }}
                />
            </div>
            <ButtonText onClick={onHideCalendarClick} className={styles.SkipText}>Skip</ButtonText>
        </div>
    );
};

export default CalendarComponent;
import { dispatch } from "../../state/store";
import appLoadingService from "../../services/server/appLoadingService/appLoadingService";
import {
  setIsAppLoader,
  setIsTabLoaderAnalytics,
  setIsTabLoaderCourses,
  setIsTabLoaderCustomBranding,
  setIsTabLoaderIntegrations,
  setIsTabLoaderLearners,
  setIsTabLoaderReports,
  setIsTabLoaderSubscription,
  setIsTabLoaderTeam,
} from "../../state/ducks/global";
import { setUserDetails } from "../../state/ducks/userDetails";
import localStorageService from "../../services/storage/localStorageService";

const appInit = async () => {
  localStorageService.getCurrentGeoData().catch(console.log);

  try {
    const userDetails = await appLoadingService.userDetailsLoading();
    if (userDetails) {
      dispatch(setUserDetails(userDetails));

      if (!userDetails.isIntroNeeded) {
        const info = await appLoadingService.infoLoading();
        (window as any).civchat = info;
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://gradual.user.com/widget.js";
        script.setAttribute("data-cfasync", "false");
        document.body.appendChild(script);
      }
    }
  } catch (error) {
    console.log(error);
  }

  appLoadingService
    .subscriptionDataLoading()
    .then(() => {
      dispatch(setIsTabLoaderSubscription(false));
    })
    .finally(() => {
      dispatch(setIsAppLoader(false));
    });

  appLoadingService
    .coursesLoading()
    .then(() => {
      dispatch(setIsTabLoaderCourses(false));
    })
    .catch((error) => {
      console.log(error);
    });

  appLoadingService
    .reportsLoading()
    .then(() => {
      dispatch(setIsTabLoaderReports(false));
    })
    .catch((error) => {
      console.log(error);
    });

  appLoadingService
    .analyticsLoading()
    .then(() => {
      dispatch(setIsTabLoaderAnalytics(false));
    })
    .catch((error) => {
      console.log(error);
    });

  appLoadingService
    .learnersLoading()
    .then(() => {
      dispatch(setIsTabLoaderLearners(false));
    })
    .catch((error) => {
      console.log(error);
    });

  appLoadingService
    .integrationLoading()
    .then(() => {
      dispatch(setIsTabLoaderIntegrations(false));
    })
    .catch((error) => {
      console.log(error);
    });

  appLoadingService
    .customBrandingLoading()
    .then(() => {
      dispatch(setIsTabLoaderCustomBranding(false));
    })
    .catch((error) => {
      console.log(error);
    });

  appLoadingService
    .teamDataLoading()
    .then(() => {
      dispatch(setIsTabLoaderTeam(false));
    })
    .catch((error) => {
      console.log(error);
    });
};

export default {
  appInit,
};

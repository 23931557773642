import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';

const TextEditor = (props:any) => {
    const {className, value, onChange, placeholder} = props;
    const toolbarOptions = [
        ['bold', 'italic', 'underline','strike', 'blockquote', 'code-block'],
        [{'align': 'center'}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'header': 1}, {'header': 2}],
        ['link'],
        [{'color' : ['#000000', '#e60000', '#ff9900' ,'#ffff00' , '#008a00','#0066cc', '#9933ff',
                '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff']}],
        [{'background': ['#ffffff' , '#facccc', '#ffebcc', '#fffdcc', '#cce8cc','#cce0f5', '#ebd6ff',
                '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff']}]
    ];
    const clipBoardOptions = {matchVisual: false};

    return (
        <div data-text-editor="name" className={"notranslate"}>
            <ReactQuill
                theme="bubble"
                value={value}
                modules={{toolbar: toolbarOptions, clipboard: clipBoardOptions}}
                className={className}
                onChange={onChange}
                placeholder={placeholder}
                bounds={`[data-text-editor="name"]`}
            />
        </div>
    );
};

export default TextEditor;
import adminService from "../adminService/adminService";
import config from "../../../config/config";
import stringUtilsService from "../../utils/stringUtilsService";
import { dispatch } from "../../../state/store";
import {
  setPublicInviteLink,
  setPublicUserInviteLink,
} from "../../../state/ducks/admin";
const { data } = config;
const { defaultApiLink, externalLinks } = data;
const {
  emailInvitationToCoursePath,
  phoneInvitationToCoursePath,
  emailInvitationToTeamPath,
  publicInviteLinkPath,
  publicInviteLinkUserPath,
  emailInvitationUserPath,
} = externalLinks;

const sendPhoneInvitationToCourse = async (
  courseId: number,
  phones: string[]
) => {
  const formattedUrl = stringUtilsService.formatString(
    defaultApiLink,
    phoneInvitationToCoursePath
  );
  const updatedUrl = `${formattedUrl}/${courseId}`;

  await adminService.sendPhoneInvitations(updatedUrl, phones);
};

const sendEmailInvitationToCourse = async (
  courseId: number,
  emails: string[]
) => {
  const formattedUrl = stringUtilsService.formatString(
    defaultApiLink,
    emailInvitationToCoursePath
  );

  const updatedUrl = `${formattedUrl}/${courseId}`;

  await adminService.sendEmailInvitations(updatedUrl, emails);
};

const sendEmailInvitationToTeam = async (
  companyId: number,
  emails: string[]
) => {
  const formattedUrl = stringUtilsService.formatString(
    defaultApiLink,
    emailInvitationToTeamPath
  );

  await adminService.sendEmailInvitationsTeam(formattedUrl, {
    companyId: companyId,
    emails: emails,
  });
};

const getPublicInviteLink = async (courseId: number) => {
  const formattedUrl = stringUtilsService.formatString(
    defaultApiLink,
    publicInviteLinkPath
  );

  const updatedUrl = `${formattedUrl}/${courseId}`;

  const link = await adminService.getPublicInviteLink(updatedUrl);
  dispatch(setPublicInviteLink(link));
};

const getInviteLinkForUser = async () => {
  const formattedUrl = stringUtilsService.formatString(
    defaultApiLink,
    publicInviteLinkUserPath
  );
  const link = await adminService.getInviteLinkForUser(formattedUrl);

  dispatch(setPublicUserInviteLink(link));
};

const invitationForUserByEmail = async (emails: string[]) => {
  const formattedUrl = stringUtilsService.formatString(
    defaultApiLink,
    emailInvitationUserPath
  );
  await adminService.sendEmailInvitations(formattedUrl, emails);
};

export default {
  sendEmailInvitationToCourse,
  sendPhoneInvitationToCourse,
  sendEmailInvitationToTeam,
  getPublicInviteLink,
  getInviteLinkForUser,
  invitationForUserByEmail,
};

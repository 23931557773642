const backgroundList = [
    { id: 1, color: '#f94144'},
    { id: 2, color: '#f3722c'},
    { id: 3, color: '#d81159'},
    { id: 4, color: '#f9844a'},
    { id: 5, color: '#f9c74f'},
    { id: 6, color: '#90be6d'},
    { id: 7, color: '#43aa8b'},
    { id: 8, color: '#4d908e'},
    { id: 9, color: '#73d2de'},
    { id: 10, color: '#b5c99a'},
    { id: 11, color: '#e5989b'},
    { id: 12, color: '#000000'},
];

export default backgroundList;



import React from 'react';

import styles from './MetricsItemOverview.module.scss';
import classNames from 'classnames';
import {
    OverviewData,
    OverviewType
} from "../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceOverview";
import {
    TEAM
} from "../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceMetricType";

import ReactTooltip from 'react-tooltip';

interface MetricsItemOverviewType {
    metricName: string
    metricPayload: OverviewData
    reportPeriod: string
}

const MetricsItemOverview: React.FC<MetricsItemOverviewType> = ({metricName, metricPayload, reportPeriod}) => {
    const {current, previous, coefficient, type} = metricPayload;
    const isZero = coefficient === 0;
    const isPositiveImprovement = coefficient >= 0;

    const colorStyle = Math.abs(coefficient) <= 1 ? styles.ColorPercentageYellow : coefficient < 0 ? styles.ColorPercentageRed : styles.ColorPercentageGreen
    const isPercentageMetric = metricPayload.sign === OverviewType.PERCENTAGE;

    let currentPercentage = current,
        prevPercentage = previous;

    if (!isPercentageMetric) {
        if (currentPercentage > prevPercentage) {
            currentPercentage = 100;
            prevPercentage = previous/current * 100;
        } else {
            prevPercentage = 100;
            currentPercentage = current/previous  * 100;
        }
    }

    return (
        <div className={styles.Container}>
           <div className={styles.WrapperHeader}>
               <div className={styles.Header}>
                   <div className={styles.MetricName} data-tip={metricName}>{metricName}</div>
                   <div className={classNames(styles.Percentage, colorStyle)}>
                       {isZero ? '' : isPositiveImprovement ? '+' : '' }{coefficient}{isPercentageMetric ? '%' : ''}
                   </div>
                   <ReactTooltip
                       effect='solid'
                       className={styles.Tooltip}
                   />
               </div>
               <div className={styles.TeamPersonalText}>
                   {type === TEAM ? TEAM + ' AVERAGE' : 'AVERAGE ON PERSONAL BASIS'}
               </div>
           </div>
            <div className={styles.ContainerProgressBar}>
                <span>{reportPeriod}</span>
                <div className={styles.WrapperProgressBar}>
                    <div
                        className={styles.ProgressBarPeriod}
                        style={{width: currentPercentage === 0 ? '2.5%' : currentPercentage + '%', borderRadius: currentPercentage === 0 ? '6px 0 0 6px' : '6px'}}>
                    </div>
                </div>
                <span className={styles.Result}>{current}{isPercentageMetric ? '%' : ''}</span>
            </div>
            <div className={styles.ContainerProgressBar}>
                <span>Week before</span>
                <div className={styles.WrapperProgressBar}>
                    <div
                        className={styles.ProgressBarDate}
                        style={{width: prevPercentage === 0 ? '2.5%': prevPercentage+'%' , borderRadius: prevPercentage === 0 ? '6px 0 0 6px' : '6px'}}>
                    </div>
                </div>
                <span className={styles.Result}>{previous}{isPercentageMetric ? '%' : ''}</span>
            </div>
        </div>
    );
};

export default MetricsItemOverview;

import React, { useEffect, useState } from "react";

import styles from "./LearnersComponent.module.scss";
import { ReactComponent as DownloadImg } from "../../../../../../../../assets/images/icon/download-icon.svg";
import FilterComponent from "../../../../../../../components/FilterComponent/FilterComponent";
import FilterComponentInput from "../../../../../../../components/FilterComponentInput/FilterComponentInput";
import Svg from "../../../../../../../components/SvgComponent/SvgComponent";
import LearnersItem from "./components/LearnersItem/LearnersItem";
import { connect } from "react-redux";
import { dispatch } from "../../../../../../../state/store";
import { setLearnersList } from "../../../../../../../state/ducks/admin";
import { createStructuredSelector } from "reselect";
import {
  makeSelectFilterCourseLearner,
  makeSelectFilterNameLearner,
  makeSelectIsEmptyLearnersData,
  makeSelectLearnersList,
  makeSelectSortedLearnerList,
  makeSelectSortedLearnerName,
} from "../../../../../../../state/selectors/admin";
import learnerEmoji from "../../../../../../../../assets/images/emoji/graduation-cap_1f393.png";
import learnersService from "../../../../../../../services/server/learnersService/learnersService";
import {
  makeSelectIsLegacyUser,
  makeSelectPaymentInfo,
  makeSelectSeatsInfo,
} from "../../../../../../../state/selectors/subscription";
import ButtonColor from "../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import { setIsVisibleSubscriptionPopup } from "../../../../../../../state/ducks/global";
import Restricted from "../../../../../../../components/Restricted/Restricted";
import { Role } from "../../../../../../../types/UserDetails";
import { makeSelectUserDetails } from "../../../../../../../state/selectors/userDetails";
import CrmAnalytics from "./components/CrmAnalytics/CrmAnalytics";
import classNames from "classnames";
import inviteIcon from "../../../../../../../../assets/images/icon/team-invite-icon.svg";
import Image from "../../../../../../../components/Image/Image";
import InviteLearnersPopup from "../../../../../../CourseConstructorPage/components/CourseConstructorComponent/DraftModuleComponent/componentns/InviteLearnersPopup/InviteLearnersPopup";

const mapStateToProps = createStructuredSelector({
  isEmptyLearnersData: makeSelectIsEmptyLearnersData(),
  learnersList: makeSelectLearnersList(),
  sortedLearnerList: makeSelectSortedLearnerList(),
  filterLearnerCourse: makeSelectFilterCourseLearner(),
  filterLearnerName: makeSelectFilterNameLearner(),
  seatsInfo: makeSelectSeatsInfo(),
  isLegacyUser: makeSelectIsLegacyUser(),
  paymentInfo: makeSelectPaymentInfo(),
  userDetails: makeSelectUserDetails(),
  sortedLearnerName: makeSelectSortedLearnerName(),
});

const LearnersComponent = (props: any) => {
  const {
    learnersList,
    filterLearnerCourse,
    filterLearnerName,
    sortedLearnerList,
    isEmptyLearnersData,
    seatsInfo,
    isLegacyUser,
    paymentInfo,
    userDetails,
    sortedLearnerName,
  } = props;

  const [filterName, setFilterName] = useState({ name: "", id: -1 });
  const [filterCourse, setFilterCourse] = useState({
    name: "All training",
    id: -1,
  });
  const [urlCsv, setCsv] = useState("");
  const [currentPageForDashboard, setCurrentPageForDashboard] = useState<
    number | null
  >(null);
  const [isVisibleDashboardComponent, setIsVisibleDashboardComponent] =
    useState<boolean>(false);
  const [learnerName, setLearnerName] = useState<string>("");
  const [learnerId, setLearnerId] = useState<number>(0);
  const [learnerPipedriveId, setLearnerPipedriveId] = useState<number>(0);
  const [invitePopup, setInvitePopup] = useState(false);

  useEffect(() => {
    filterLearners();
    setCurrentPageForDashboard(0);
  }, [filterName, filterCourse]);

  const onNameClick = (name: any, id: number) => {
    setFilterName({ name: name, id: id });
  };

  const onNameInputChange = (input: string) => {
    setFilterName({ ...filterName, name: input });
  };

  const onCourseClick = (course: any, id: number) => {
    setFilterCourse({ name: course, id: id });
  };

  const onClickToAddSeat = async () => {
    dispatch(setIsVisibleSubscriptionPopup(true));
  };

  const onDownloadCsv = async () => {
    const data = await learnersService.getCsvLearners();
    const csvUrl = URL.createObjectURL(new Blob([data]));
    setCsv(csvUrl);
  };

  const onSetLearnerForDashboard = (
    name: string,
    pipedriveId: number,
    id: number
  ) => {
    setLearnerName(name);
    setLearnerId(id);
    setLearnerPipedriveId(pipedriveId);
  };

  const filterLearners = () => {
    const isSelectName =
      filterName.name !== "" && filterName.name !== "All users";
    const isSelectCourse = filterCourse.name !== "All training";
    const filteredByNameCourse = isSelectName && isSelectCourse;

    const filteredLearnersList = sortedLearnerList.filter(
      (learnerItem: any) => {
        const learnerName = learnerItem.name;
        const learnerCourses = learnerItem.courses;
        const listOfCourse: any = learnerCourses.map((item: any) => {
          return item.name;
        });

        if (filteredByNameCourse) {
          return (
            learnerName.includes(filterName.name) &&
            listOfCourse.includes(filterCourse.name)
          );
        }

        if (isSelectName) {
          return learnerName
            .toLowerCase()
            .includes(filterName.name.toLowerCase().trim());
        }

        if (isSelectCourse) {
          return listOfCourse.includes(filterCourse.name);
        }
        return learnerItem;
      }
    );
    dispatch(setLearnersList(filteredLearnersList));
  };

  const onResetFiltersClick = () => {
    setFilterName({ name: "", id: -1 });
    setFilterCourse({ name: "All training", id: -1 });
    setCurrentPageForDashboard(0);
  };

  return (
    <div
      className={classNames(
        styles.MainOnboardeesContainer,
        isVisibleDashboardComponent && styles.PerformanceContainer
      )}
    >
      {!isEmptyLearnersData && !isVisibleDashboardComponent && (
        <>
          {!isLegacyUser && (
            <Restricted
              userRoles={userDetails.roles}
              allowedTo={[Role.CREATOR]}
            >
              <div className={styles.WrapperDetailsSeats}>
                <div>
                  <div className={styles.NumbersSeats}>
                    {seatsInfo?.usedSeatsNumber}
                  </div>
                  <div className={styles.TextSeats}>your users</div>
                </div>
                <div>
                  <div className={styles.NumbersSeats}>
                    {seatsInfo?.availableSeatsNumber}
                  </div>
                  <div className={styles.TextSeats}>
                    <div>available seats</div>
                    <div
                      className={styles.UpdateSeats}
                      onClick={onClickToAddSeat}
                    >
                      Upgrade
                    </div>
                  </div>
                </div>
              </div>
            </Restricted>
          )}
          <div className={styles.WrapperForFilters}>
            <div className={styles.FilterContainer}>
              <FilterComponentInput
                classNameContainer={styles.FilterContainerItem}
                classNameDropDown={styles.DropDownContainer}
                classNameDropDownMenu={styles.DropDownMenuContainer}
                classNameInput={styles.Input}
                filterValue={filterName}
                textTitle="User"
                classNameTitle={styles.Title}
                listOptions={filterLearnerName}
                onOptionFilterClick={onNameClick}
                onNameInputChange={onNameInputChange}
                sortedListOptions={sortedLearnerName}
                learners={true}
              />
              <FilterComponent
                classNameDropDown={styles.DropDownContainer}
                classNameDropDownMenu={styles.DropDownMenuContainer}
                classNameContainer={styles.FilterContainerItem}
                filterValue={filterCourse}
                textTitle="Training"
                classNameTitle={styles.Title}
                listOptions={filterLearnerCourse}
                onOptionFilterClick={onCourseClick}
              />
              <ButtonColor
                className={styles.ResetFilters}
                onClick={onResetFiltersClick}
              >
                Reset filters
              </ButtonColor>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                href={urlCsv}
                target="_blank"
                rel="noopener noreferrer"
                download={"users.csv"}
                onClick={onDownloadCsv}
                className={styles.MainContainer}
              >
                <div className={styles.Text}>Download CSV</div>
              </a>
              <ButtonColor
                className={styles.ButtonInvite}
                onClick={() => setInvitePopup(true)}
              >
                <Image src={inviteIcon} className={styles.ImageInvite} />
                <span>Invite new user</span>
              </ButtonColor>
            </div>
          </div>
          {learnersList.length ? (
            <>
              <div className={styles.HeaderTitleContainer}>
                <span>User</span>
                <span>Email</span>
                <span>Training names</span>
                <span>Last activity</span>
              </div>
              <div className={styles.ItemListContainer}>
                <LearnersItem
                  setFilterName={setFilterName}
                  filterName={filterName}
                  filterCourse={filterCourse}
                  setIsVisibleDashboardComponent={
                    setIsVisibleDashboardComponent
                  }
                  onSetLearnerForDashboard={onSetLearnerForDashboard}
                  setCurrentPageForDashboard={setCurrentPageForDashboard}
                  currentPageForDashboard={currentPageForDashboard}
                />
              </div>
            </>
          ) : isEmptyLearnersData ? (
            <div className={styles.LearnerNoneFilter}>
              <div>No users yet</div>
            </div>
          ) : (
            <div className={styles.NoData}>No data found</div>
          )}
        </>
      )}
      {isEmptyLearnersData && (
        <div className={styles.LearnerNone}>
          <div className={styles.LearnerNoneTitle}>
            Invite your first users to the training!
          </div>
          <img src={learnerEmoji} alt="user_emoji" style={{ height: "25px" }} />
        </div>
      )}
      {isVisibleDashboardComponent && (
        <CrmAnalytics
          learnerName={learnerName}
          learnerId={learnerId}
          learnerPipedriveId={learnerPipedriveId}
          setIsVisibleDashboardComponent={setIsVisibleDashboardComponent}
        />
      )}
      {invitePopup && (
        <InviteLearnersPopup
          users
          hideInviteLearnersPopup={() => setInvitePopup(false)}
        ></InviteLearnersPopup>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(LearnersComponent);

import React from 'react';

import TabItemComponent from '../TabItemComponent/TabItemComponent';
import {availableTabList, TabListIndex} from './menuTabList';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsLegacyUser} from '../../../../../state/selectors/subscription';
import {connect} from 'react-redux';
import Restricted from '../../../../../components/Restricted/Restricted';
import {Role} from '../../../../../types/UserDetails';
import {makeSelectUserDetails} from '../../../../../state/selectors/userDetails';

const mapStateToProps = createStructuredSelector({
    isLegacyUser: makeSelectIsLegacyUser(),
    userDetails: makeSelectUserDetails()
});

const MenuListComponent = (props:any) => {
    const {onTabButtonClick, activeTab, isLegacyUser, userDetails} = props;

    return (
        <>
            {availableTabList.map((item) => {
                const isCustomBranding = item.id === TabListIndex.CustomBranding;
                const isSubscription = item.id === TabListIndex.Subscription;
                const isTeam = item.id === TabListIndex.Team;
                const isPerformanceReports = item.id === TabListIndex.PerformanceReports;

                const isRestrictedView = isCustomBranding || isSubscription || isTeam;
                const isAllowed = !(isLegacyUser && isSubscription);

                return (
                    <>
                        {isAllowed && !(isPerformanceReports && !userDetails.isPipedriveConnected) &&
                            <Restricted
                                userRoles={userDetails?.roles}
                                allowedTo={isRestrictedView ? [Role.CREATOR] : [Role.CREATOR, Role.CONTRIBUTOR]}
                            >
                                <TabItemComponent
                                    item={item}
                                    onTabButtonClick={onTabButtonClick}
                                    activeTab={activeTab}
                                />
                            </Restricted>
                        }
                    </>
                );
            })}
        </>
    );
};

export default connect(mapStateToProps)(MenuListComponent);
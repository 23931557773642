import React, { useCallback } from "react";
import FilterComponentInput from "../../../../../../../../../../../../../../../../../../components/FilterComponentInput/FilterComponentInput";
import styles from "./FiltersGapsComponent.module.scss";
import FilterMetricComponent from "./components/FilterMetricsComponent/FilterMetricComponent";
import ButtonColor from "../../../../../../../../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import { Filter } from "../../../../../../../../../../../../../../../../../../types/filter/Filter";

type FiltersGapsComponentProps = {
  learnerFilterValue: Filter;
  matchingLearnerFilters: Filter[];
  allLearnerFilters: Filter[];
  setMatchingLearnerFilters: Function;

  chosenMetricFilters: Filter[];
  allMetricFilters: Filter[];
  setChosenMetricFilters: Function;

  onOptionClickLearner: Function;
  onLearnerInputChange: Function;
};

const FiltersGapsComponent = ({
  learnerFilterValue,
  matchingLearnerFilters,
  allLearnerFilters,
  setMatchingLearnerFilters,
  onOptionClickLearner,
  onLearnerInputChange,
  chosenMetricFilters,
  allMetricFilters,
  setChosenMetricFilters,
}: FiltersGapsComponentProps) => {
  const handleResetFiltersClick = useCallback(() => {
    setChosenMetricFilters([]);
    setMatchingLearnerFilters(allLearnerFilters);
    onOptionClickLearner("", -1);
  }, [
    allLearnerFilters,
    setChosenMetricFilters,
    onOptionClickLearner,
    setMatchingLearnerFilters,
  ]);

  return (
    <div className={styles.Container}>
      <FilterComponentInput
        classNameInputContainer={styles.InputContainer}
        classNameContainer={styles.FilterContainerItem}
        classNameDropDown={styles.DropDownContainer}
        classNameDropDownMenu={styles.DropDownMenuContainer}
        classNameInput={styles.Input}
        filterValue={learnerFilterValue}
        textTitle="User"
        classNameTitle={styles.Title}
        listOptions={matchingLearnerFilters}
        sortedListOptions={allLearnerFilters}
        onOptionFilterClick={onOptionClickLearner}
        onNameInputChange={onLearnerInputChange}
        isPipedriveReports={true}
        setMatchingLearnerFilters={setMatchingLearnerFilters}
      />
      <FilterMetricComponent
        classNameContainer={styles.FilterContainerItem}
        textTitle="Metric"
        classNameDropDown={styles.DropDownContainer}
        classNameDropDownMenu={styles.DropDownMenuContainer}
        listOptions={chosenMetricFilters}
        setListOptions={setChosenMetricFilters}
        sortedListOptions={allMetricFilters}
      />
      <ButtonColor
        className={styles.ResetBtn}
        onClick={handleResetFiltersClick}
      >
        Reset filters
      </ButtonColor>
    </div>
  );
};

export default FiltersGapsComponent;

import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {createPortal} from 'react-dom';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import styles from './QuestionsListComponent.module.scss';
import {ReactComponent as DeleteImg} from '../../../../../../../../../../assets/images/icon/delete-content-icon.svg';
import {ReactComponent as DragAngDropImg} from '../../../../../../../../../../assets/images/icon/drag-drop-quiz-questions-icon.svg';
import Svg from "../../../../../../../../../components/SvgComponent/SvgComponent";
import {dispatch} from '../../../../../../../../../state/store';
import {setDraftModuleQuiz} from '../../../../../../../../../state/ducks/courseConstructor';
import moduleService from "../../../../../../../../../services/server/moduleService/moduleService";

const QuestionsListComponent = (props:any) => {
    const {draftModuleQuiz, activeQuestion, onQuestionItemClick, deleteQuestionButtonClick} = props;
    const classNameActionButton = classNames(styles.VisibleActionButton, draftModuleQuiz?.questions?.length < 2 && styles.HideActionButton);
    const _dragEl = document.getElementById('draggable')!;
    const [questionList, setQuestionList] = useState(draftModuleQuiz?.questions);

    useEffect(() => {
        setQuestionList([...draftModuleQuiz?.questions.sort((a:any, b:any) => a.sequenceId > b.sequenceId ? 1 : -1)]);
    }, [draftModuleQuiz]);

    const createNewPortal = (draggable:any, element:any) => {
        if(draggable) {
            return createPortal(element, _dragEl);
        }
        return element;
    };

    const onDragEnd = async (result:any) => {
        const {destination, source, reason} = result;

        if(!destination || reason === 'CANCEL') {
            return;
        }

        if(destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const questions = [...questionList];
        const droppedQuestions = questionList[source.index];

        questions.splice(source.index, 1);
        questions.splice(destination.index, 0, droppedQuestions);

        const questionSequences = questions.map((item:any, index:number) => {
            return {
                questionId: item.id,
                sequenceId: questionList[index].sequenceId
            };
        });

        const mutatedQuestionListBySequence = questions.map((item:any, index:number) => {
            item.sequenceId = questionSequences[index].sequenceId;
            return item;
        });

        dispatch(setDraftModuleQuiz({...draftModuleQuiz, questions : mutatedQuestionListBySequence}));
        moduleService.updateQuestionSequence(questionSequences);
    };

    return (
        <div className={styles.QuestionsListContainer} >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='id4'>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {questionList.map((item:any, index:any) => {
                                const questionId = item?.id;
                                const questionTitle = item?.multipleChoiceQuestion?.title || item?.essayQuestion?.title ||
                                    'Type a question';
                                const isActiveQuestion = questionId === activeQuestion?.id;

                                return (
                                    <Draggable key={index} draggableId={index + ''} index={index}>
                                        {(provided:any,snapshot:any) => (
                                            createNewPortal(snapshot.isDragging, (
                                                <div ref={provided.innerRef}
                                                     {...provided.draggableProps}
                                                     {...provided.dragHandleProps}
                                                >
                                                    <div className={styles.QuestionItem} key={questionId}
                                                        style={{fontFamily: snapshot.isDragging ? 'Lato-Medium' : ''}}
                                                    >
                                                        <Svg className={classNameActionButton}>
                                                            <DragAngDropImg/>
                                                        </Svg>
                                                        <div
                                                            className={`${styles.QuestionNumber} ${isActiveQuestion && styles.ActiveQuestionNumber}`}
                                                            style={{border: snapshot.isDragging && isActiveQuestion ? '1px solid #f85a40' :
                                                                    snapshot.isDragging && !isActiveQuestion ? '1px solid #cbc3c1' : '',
                                                                backgroundColor: snapshot.isDragging && isActiveQuestion ? '#f85a40' : '',
                                                                color: snapshot.isDragging && isActiveQuestion ? '#ffffff' :
                                                                    snapshot.isDragging && !isActiveQuestion ? '#000000' : ''
                                                            }}
                                                            onClick={() => onQuestionItemClick(questionId)}
                                                        >
                                                            {index+1}
                                                        </div>
                                                        <span
                                                            className={`${styles.QuestionTitle} ${isActiveQuestion && styles.ActiveQuestionTitle}`}
                                                            onClick={() => onQuestionItemClick(questionId)}
                                                            style={{color: snapshot.isDragging && isActiveQuestion ? '#f85a40' :
                                                                    snapshot.isDragging && !isActiveQuestion ? '#cbc3c1' : ''
                                                            }}
                                                        >
                                                        {questionTitle}
                                                        </span>
                                                        <Svg className={classNameActionButton}
                                                             onClick={() => deleteQuestionButtonClick(questionId)}>
                                                            <DeleteImg />
                                                        </Svg>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default QuestionsListComponent;
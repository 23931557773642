import {DRAFT_COURSE_ID} from '../../consts/storage/sessionStorageVariables';

const setDraftCourseId = (draftCourseId:number) => {
    sessionStorage.setItem(DRAFT_COURSE_ID, draftCourseId.toString());
};

const getDraftCourseId = () => {
    return sessionStorage.getItem(DRAFT_COURSE_ID);
}

export default {
    setDraftCourseId,
    getDraftCourseId
}
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import styles from './PerformanceGapsComponent.module.scss';
import FiltersGapsComponent from './components/FiltersGapsComponent/FiltersGapsComponent';
import GapsHeader from './components/FiltersGapsComponent/components/GapsHeader/GapsHeader';
import {
    PerformanceGapData
} from "../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceGapData";
import {copyObject} from "../../../../../../../../../../../../../../../../services/utils/objectCopy";
import {Filter} from "../../../../../../../../../../../../../../../../types/filter/Filter";
import {EmptyPerformanceDataComponent} from "../EmptyPerformanceDataComponent/EmptyPerformanceDataComponent";

interface PerformanceGapsType {
    performanceGapsData: PerformanceGapData | undefined
}

const extractFilters = (performanceGapsData: PerformanceGapData) : {learnerFilters: Filter[], metricFilters:Filter[]} => {
    const learnerFiltersMeta = new Set<string>();
    const metricFiltersMeta  = new Set<string>();

    performanceGapsData.allGaps.map(learnerGaps => {
        learnerFiltersMeta.add(learnerGaps.name);
        Object.keys(learnerGaps.personalGaps).forEach(key => metricFiltersMeta.add(key));
    });

    const learnerFilters = Array.from(learnerFiltersMeta)
        .map((learnerName, idx) => {
            return {
                name: learnerName,
                id: idx + 1
            };
        });

    const metricFilters = Array.from(metricFiltersMeta)
        .map((metricName, idx) => {
            return {
                name: metricName,
                id: idx + 1
            };
        });

    return {learnerFilters, metricFilters};
};

const PerformanceGapsComponent = ({performanceGapsData}: PerformanceGapsType) => {
    // performance gaps will remain unchanged per one modal opening
    // these values are used
    const {learnerFilters, metricFilters} = useMemo(() => extractFilters(performanceGapsData!), [performanceGapsData]);

    const [filteredPG, setFilteredPG] = useState(performanceGapsData);
    const [allLearnerFilters, ] = useState<Filter[]>([{name: 'All learners', id: -1}, ...learnerFilters]);
    const [allMetricFilters, ] = useState<Filter[]>(metricFilters); // -1 is reserved for All

    const [learnerFilter, setLearnerFilter] = useState<Filter>({name: '', id: -1});
    const [chosenMetricFilters, setChosenMetricFilters] = useState<Filter[]>([]); // empty array state == All metrics
    const [matchingLearnerNames, setMatchingLearnerNames] = useState(allLearnerFilters);

    const filterPerformanceGaps = useCallback(() =>  {
        const newGaps = copyObject(performanceGapsData!).allGaps
            .filter(learnerGaps =>
                matchingLearnerNames
                    .map(learnerName => learnerName.name)
                    .includes(learnerGaps.name)
            )
            .filter(learnerGaps => {
                Object.keys(learnerGaps.personalGaps)
                    .forEach(metricName => {
                        // if metric list is not empty and metric is not in this list - it is removed
                        if (!!chosenMetricFilters.length && !chosenMetricFilters.map(metricFilter => metricFilter.name).includes(metricName)) {
                            delete learnerGaps.personalGaps[metricName];
                        }
                    });

                if (!!Object.keys(learnerGaps.personalGaps).length) {
                    return learnerGaps;
                }
            });

        if (newGaps.length) { // prevent from empty list to be applied - might be handled by empty data message
            setFilteredPG({...performanceGapsData, allGaps: newGaps});
        }
    }, [chosenMetricFilters, matchingLearnerNames]);

    useEffect(filterPerformanceGaps, [chosenMetricFilters, matchingLearnerNames]);

    const onOptionClickLearner = (name:string, id: number) => {
        setLearnerFilter({name: name, id: id});
    };

    const onLearnerInputChange = (input:string) => {
        setLearnerFilter({...learnerFilter, name: input});
    };

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>Performance gaps</div>
            {!!performanceGapsData?.allGaps.length ?
                <>
                    <FiltersGapsComponent
                        learnerFilterValue={learnerFilter}
                        matchingLearnerFilters={matchingLearnerNames}
                        allLearnerFilters={allLearnerFilters}
                        setMatchingLearnerFilters={setMatchingLearnerNames}

                        chosenMetricFilters={chosenMetricFilters}
                        allMetricFilters={allMetricFilters}
                        setChosenMetricFilters={setChosenMetricFilters}

                        onOptionClickLearner={onOptionClickLearner}
                        onLearnerInputChange={onLearnerInputChange}
                    />
                    <GapsHeader performanceGapsData={filteredPG} />
                </> :
                <EmptyPerformanceDataComponent waringMessage={'Gaps were not identified'} />
            }
        </div>
    );
};

export default PerformanceGapsComponent;

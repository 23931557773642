import React from 'react';
import classNames from 'classnames';

import styles from './TypeDetailsTaskPopup.module.scss';
import closePopupImg from '../../../../../../../../../../assets/images/icon/close-popup-icon.svg';
import clipImg from '../../../../../../../../../../assets/images/icon/clip.icon.svg';
import Modal from '../../../../../../../../ServiceContainers/Modal/Modal';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import Image from '../../../../../../../../../components/Image/Image';
import ButtonColor from '../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectTaskDetails} from '../../../../../../../../../state/selectors/reports';
import routerService from '../../../../../../../../../services/route/routerService';
import checkedImg from '../../../../../../../../../../assets/images/icon/check-mark-white.svg';
import Svg from '../../../../../../../../../components/SvgComponent/SvgComponent';
import {ReactComponent as BellImgComponent} from '../../../../../../../../../../assets/images/icon/bell-icon.svg';
import {ReportStatus} from '../../../../../../../../../types/ReportStatus';

const mapStateToProps = createStructuredSelector({
    taskDetails: makeSelectTaskDetails(),
});

const TypeDetailsQuizPopup = (props:any) => {
    const {hideTaskPopup, taskDetails, onCheckedReport, onUncheckedReport, statusReport} = props;

    const onAttachFileClick = (attachLink:any) => {
        routerService.blankLinkOpen(attachLink);
    };

    return (
        <Modal>
            <PopupFullPage
                className={styles.HiddenBackground}
                isSupportOutsidePopupClick
                closePopup={hideTaskPopup}
            >
                <div className={styles.MainPopupContainer}>
                    <div className={styles.CloseContainer}>
                        <Image src={closePopupImg} onClick={hideTaskPopup} className={styles.CloseButton} />
                    </div>
                    <div className={styles.MainContainer}>
                        <div className={styles.WrapperContent}>
                        <span className={styles.TitleText}>{taskDetails?.moduleName}</span>
                        <div>
                            <div className={classNames(styles.SubtitleTask, styles.Subtitle)}>Task</div>
                            <div className={styles.TaskName}>{taskDetails?.taskName}</div>
                            {taskDetails?.taskDescription && <div className={styles.TaskDescription}>{taskDetails.taskDescription}</div>}
                        </div>
                        <div>
                            <div className={classNames(styles.SubtitleAnswer, styles.Subtitle)}>Answer</div>
                            {taskDetails?.answer && <div className={styles.Answer}>{taskDetails.answer}</div>}
                            {taskDetails?.attachFiles?.map((item:any, index:any) => {
                                const attachName = item.name;
                                const attachLink = item?.link;

                                return (
                                    <div className={styles.AttachName} key={index.toString()} onClick={() => onAttachFileClick(attachLink)}>
                                        <Image
                                            src={clipImg}
                                            className={styles.ClipImg}
                                        />
                                        <span>{attachName}</span>
                                    </div>
                                )
                            })}
                        </div>
                        </div>
                        {statusReport === ReportStatus.UNCHECKED.Value &&
                            <ButtonColor
                                className={classNames(styles.Button, styles.BtnOk)}
                                onClick={onCheckedReport}
                            >
                                <Image src={checkedImg} className={styles.ImageChecked}/>
                                <span>Mark as checked</span>
                            </ButtonColor>
                        }
                        {statusReport === ReportStatus.CHECKED.Value &&
                            <ButtonColor
                                className={classNames(styles.Button, styles.BtnOk, styles.ButtonUnchecked)}
                                onClick={onUncheckedReport}
                            >
                                <Svg className={styles.ImageChecked}>
                                    <BellImgComponent/>
                                </Svg>
                                <span>Mark as unchecked</span>
                            </ButtonColor>
                        }
                    </div>
                </div>
            </PopupFullPage>
        </Modal>
    );
}

export default connect(mapStateToProps)(TypeDetailsQuizPopup);
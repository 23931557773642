import React, {useState} from 'react';

import styles from './MetricsDetailsComponent.module.scss';
import {metricsList} from './metricsList';
import {ReactComponent as DiamondImg} from '../../../../../../../../../../../../assets/images/icon/diamond.svg';
import {ReactComponent as LightBulbImg} from '../../../../../../../../../../../../assets/images/icon/light-bulb-icon.svg';
import Svg from '../../../../../../../../../../../components/SvgComponent/SvgComponent';

const MetricsDetailsComponent = () => {
    const [isVisibleHintForConversions, setIsVisibleHintForConversions] = useState(false);

    const onShowHint = () => {
        setIsVisibleHintForConversions(true);
    };

    const onHideHint = () => {
        setIsVisibleHintForConversions(false);
    };

    return (
        <div className={styles.Container}>
            <div className={styles.Arrow}> </div>
            <div className={styles.WrapperImgTitle}>
                <div className={styles.WrapperImg}>
                    <Svg className={styles.LightImg} >
                        <LightBulbImg />
                    </Svg>
                </div>
                <div className={styles.Title}>Sales training essentials</div>
            </div>
            <div className={styles.Subtitle}>Connect Gradual and Pipedrive and get instant training suggestions. Select among  11 sales performance metrics.</div>
            <div className={styles.WrapperMetrics}>
                {metricsList.map((item) => {
                    const isConversionsMetric = item.id === 2;

                    return (
                        <div key={item.id} className={styles.Item}>
                            <div className={styles.TitleItem}>{item.name}</div>
                            <div className={styles.WrapperNameDiamondImage}>
                                <div className={styles.Description}>{item.description}</div>
                                {isConversionsMetric &&
                                    <div className={styles.WrapperDiamondImage} onMouseEnter={onShowHint} onMouseLeave={onHideHint}>
                                        <Svg className={styles.DiamondImage} >
                                            <DiamondImg />
                                        </Svg>
                                        {isVisibleHintForConversions &&
                                            <div className={styles.ContainerHint}>
                                                <div className={styles.ArrowWhite}> </div>
                                                <div className={styles.TextHint}>Connect Pipedrive to select your pipelines and stages</div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.WrapperConnectButton}>
                <a
                   className={styles.ConnectButton}
                   href='https://oauth.pipedrive.com/oauth/authorize?client_id=a64acc63df60263a&redirect_uri=https://app.gradual.io/pipedrive/validate'
                >
                    Connect Pipedrive
                </a>
            </div>
        </div>
    );
};

export default MetricsDetailsComponent;

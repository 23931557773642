import stringUtilsService from "../../utils/stringUtilsService";

import config from '../../../config/config';
import adminService from "../adminService/adminService";
import {dispatch} from '../../../state/store';
import {setCustomersList, setPartnerLink} from '../../../state/ducks/home';
const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {getPartnerInfoPath, getListCustomersPath} = externalLinks;

const getPartnerInfo = async (partnerId:number) => {
    const formattedUrl = stringUtilsService.formatString(defaultApiLink, getPartnerInfoPath);
    const updatedUrl = stringUtilsService.formatString(formattedUrl, partnerId);

    const partnerInfo = await adminService.getPartnerInfo(updatedUrl);

    dispatch(setPartnerLink(partnerInfo));
};

const getListOfCustomers = async (partnerId:number) => {
    const formattedUrl = stringUtilsService.formatString(defaultApiLink, getListCustomersPath);
    const updatedUrl = stringUtilsService.formatString(formattedUrl, partnerId)

    const listOfCustomers =  await adminService.getListOfCustomers(updatedUrl);
    
    dispatch(setCustomersList(listOfCustomers?.reverse()));
};

export default  {
    getPartnerInfo,
    getListOfCustomers
}
import React from 'react';
import { AnimateKeyframes }  from 'react-simple-animate';
import {useMediaQuery} from 'react-responsive';

import styles from './AverageProgressBar.module.scss';
import classNames from 'classnames';

type AverageProgressBar = {
    bestValue: number
    averageValue?: number
    backgroundColor: string
    comparisonResult?: number
    personalResult: number
    isPercentage?: boolean
}

const AverageProgressBar:React.FC<AverageProgressBar> = ({averageValue,
                                                             backgroundColor,
                                                             bestValue,
                                                             comparisonResult,
                                                             personalResult,
                                                             isPercentage}) => {
    const isHdScreen = useMediaQuery({ query: '(min-width: 1280px)' });
    const isHdAfterScreen = useMediaQuery({ query: '(min-width: 1400px)' });
    const isWxgaScreen = useMediaQuery({ query: '(min-width: 1600px)' });
    const isFullScreen = useMediaQuery({ query: '(min-width: 1920px)' });

    const widthWrapperProgressBar = isFullScreen ? 363 : isWxgaScreen ? 310 : isHdAfterScreen ? 263 : isHdScreen ? 213 : 0;

    let progressbarWidth, ratio;
    if(comparisonResult != undefined) {
        if (personalResult < comparisonResult) {
            progressbarWidth = widthWrapperProgressBar;
        } else {
            ratio = comparisonResult == 0 ? 0 : comparisonResult / personalResult;
            progressbarWidth = ratio * widthWrapperProgressBar;
        }
    } else if (averageValue != undefined) {
        ratio = bestValue == 0 ? 0 : (averageValue / bestValue)
        progressbarWidth =  ratio * widthWrapperProgressBar;
    }

    const stylesProgress = {
        width: `${progressbarWidth === 0 ? 2.5 : progressbarWidth}px`,
        backgroundColor: backgroundColor,
        height: '7px',
        borderRadius: `${progressbarWidth === 0 ? '6px 0 0 6px' : '6px'}`,
    };

    return (
        <div className={styles.Container}>
            <div className={classNames(styles.WrapperProgressBar)}>
                <div style={stylesProgress}> </div>
            </div>
            <div>{isPercentage ? `${averageValue} %` : comparisonResult !== undefined ? comparisonResult : averageValue}</div>
        </div>
    );
};

export default AverageProgressBar;

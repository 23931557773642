import React, {useState} from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import styles from './AddSeatsPopup.module.scss';
import checkMarkImg from '../../../../assets/images/icon/check-mark-red.svg';
import crossImg from '../../../../assets/images/icon/cross-icon-black.svg';
import equalsImg from '../../../../assets/images/icon/equals-icon.svg';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import Image from '../../../components/Image/Image';
import Input from '../../../components/Input/Input';
import ButtonColor from '../../../components/Button/components/ButtonColor/ButtonColor';
import firstBenefitsList from '../UpgradeComponent/firstBenefitsList';
import secondBenefitsList from '../UpgradeComponent/secondBenefitsList';
import {createStructuredSelector} from 'reselect';
import {makeSelectSeatsInfo} from '../../../state/selectors/subscription';
import {connect} from 'react-redux';
import subscriptionService from '../../../services/server/subscriptionService/subscriptionService';
import {dispatch} from '../../../state/store';
import {setSeatsInfo} from '../../../state/ducks/subscription';
import Loader from '../../../components/Loader/Loader';

const mapStateToProps = createStructuredSelector({
    seatsInfo: makeSelectSeatsInfo()
});

const AddSeatsPopup = (props:any) => {
    const {setIsVisibleAddSeatsPopup, paymentInfo, seatsInfo} = props;

    const [inputValue, setInputValue] = useState(seatsInfo.paySeatsNumber);
    const [loader, setLoader] = useState(false);
    const price:number = paymentInfo?.priceNumber/100;
    const result:number = Math.ceil(price * Number(inputValue));

    const hidePopup = () => {
        setIsVisibleAddSeatsPopup(false);
    };

    const onClickBtn = async () => {
        setLoader(true);
        if(inputValue > seatsInfo.paySeatsNumber) {
            await subscriptionService.setSeats(inputValue);
            const newSeatsInfo = await subscriptionService.getSeats();

            dispatch(setSeatsInfo(newSeatsInfo));
        }
        setLoader(false);
        hidePopup();
    };

    const onChangeInput = async (event:any) => {
        setInputValue(event.target.value);
    };

    return (
        <PopupFullPage
            classNameMainContainer={classNames(styles.MainPopupContainer, loader && styles.MainBackground)}
            isSupportOutsidePopupClick
            closePopup={hidePopup}
        >
            <>
                {loader ?
                    <div>
                        <Loader
                            classNameMainContainer={styles.MainContainer}
                        />
                    </div> :
                    <div>
                        <div className={styles.Title}>
                            Please, <span className={styles.Upgrade}>upgrade</span> your plan to continue:
                        </div>
                        <div className={styles.WrapperSeat}>
                            <div className={styles.WrapperPrice}>
                                <div className={styles.Price}>${price}</div>
                                <div>
                                    <div className={styles.Details}>per seat</div>
                                    <div className={styles.Details}>per month</div>
                                </div>
                            </div>
                            <div className={styles.WrapperBenefits}>
                                <div>
                                    {firstBenefitsList.map((item: any) => {
                                        return (
                                            <div key={item.id} className={styles.ItemBenefits}>
                                                <Image src={checkMarkImg} className={styles.Icon}/>
                                                <span>{item.name}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div>
                                    {secondBenefitsList.map((item: any) => {
                                        return (
                                            <div key={item.id} className={styles.ItemBenefits}>
                                                <Image src={checkMarkImg} className={styles.Icon}/>
                                                <span>{item.name}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={styles.CalculateWrapper}>
                            <span className={styles.CalculateTitle}>Calculate:</span>
                            <div>
                                <div className={styles.PriceCalculate}>{price}</div>
                                <div
                                    className={classNames(styles.SubtitleCalculatesPerSeat, styles.SubtitleCalculates)}>Per
                                    seat, $
                                </div>
                            </div>
                            <Image src={crossImg} className={styles.CrossImg}/>
                            <div>
                                <Input
                                    autoFocus
                                    className={styles.Input}
                                    value={inputValue}
                                    onChange={onChangeInput}
                                    type='number'
                                    placeholder={seatsInfo?.paySeatsNumber}
                                />
                                <div className={styles.WrapperSubtitleCalculates}>
                                    <div className={classNames(styles.SubtitleSeats, styles.SubtitleCalculates)}>Seats
                                    </div>
                                </div>
                            </div>
                            <Image src={equalsImg} className={styles.EqualsImg}/>
                            <div>
                                <div data-tip={`$${result}`} className={styles.PricePerMonth}>{`$${result}`}</div>
                                <div className={classNames(styles.PerMonth, styles.SubtitleCalculates)}>Per month</div>
                            </div>
                            <ReactTooltip
                                effect='solid'
                            />
                        </div>
                        <ButtonColor
                            onClick={onClickBtn}
                            className={classNames(styles.Btn, seatsInfo.paySeatsNumber >= inputValue && styles.DisabledBtn)}
                            disabled={seatsInfo.paySeatsNumber >= inputValue && true}
                        >
                            Upgrade
                        </ButtonColor>
                    </div>
                }
            </>
        </PopupFullPage>
    );
};

export default connect(mapStateToProps)(AddSeatsPopup);
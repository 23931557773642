import React, {useMemo, useState} from 'react';
import PopupFullPage from '../../../../../../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import styles
    from './ReportDetails.module.scss';
import OverviewComponent from './components/OverviewComponent/OverviewComponent';
import PerformanceGapsComponent from './components/PerformanceGapsComponent/PerformanceGapsComponent';
import PerformanceGridComponent from './components/PerformanceGridComponent/PerformanceGridComponent';
import {
    PerformanceDetails
} from "../../../../../../../../../../../../../../types/performanceReportsType/PerformanceDetails";
import Loader from "../../../../../../../../../../../../../../components/Loader/Loader";
import dateModify from "../../../../../../../../../../../../../../services/utils/dateModify/dateModify";

type ReportDetailsProps = {
    performanceDetailData: PerformanceDetails | undefined
    setIsVisibleReportDetails: Function
    isLoading: boolean
    creationTime: number
};

const ReportDetails = ({setIsVisibleReportDetails, performanceDetailData, isLoading, creationTime}: ReportDetailsProps) => {

    const reportPeriod: string = useMemo(() => dateModify.setPerformanceReportDate(creationTime), [creationTime]);

    const onClosePopup = () => {
        setIsVisibleReportDetails(false);
    };

    return (
        <PopupFullPage
            classNameMainContainer={styles.MainContainer}
            closePopup={onClosePopup}
            isSupportOutsidePopupClick
        >
            {isLoading && <Loader />}
            {!isLoading &&
                <div className={styles.SubWrapper}>
                    <div className={styles.Wrapper}>
                        <div className={styles.Title}>
                            <span>Performance report</span>
                            <span>{reportPeriod}</span>
                        </div>
                        <OverviewComponent overviewData={performanceDetailData?.overviewData} reportPeriod={reportPeriod}/>
                        <PerformanceGapsComponent performanceGapsData={performanceDetailData?.personalGapData}/>
                        <PerformanceGridComponent performanceGrid={performanceDetailData?.heatMapData}/>
                    </div>
                </div>
            }
        </PopupFullPage>
    );
};

export default ReportDetails;

import homeIcon from '../../../../../../assets/images/icon/home-icon.svg';
import homeActiveIcon from '../../../../../../assets/images/icon/home-active-icon.svg';

export enum PartnerTabListIndex {
    HOME
};

export enum PartnerTabListName {
    HOME = "Home"
};

export const availableTabListPartners = [
    {
        id: PartnerTabListIndex.HOME,
        name: PartnerTabListName.HOME,
        icon: homeIcon,
        activeIcon: homeActiveIcon
    },
];
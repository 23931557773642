import React from 'react';
import HomeComponent
    from '../../../AdminAccountPage/components/ContentComponent/components/HomeComponent/HomeComponent';
import Loader from '../../../../../components/Loader/Loader';
import styles from './ContentPartnersComponent.module.scss';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsTabLoaderHome} from '../../../../../state/selectors/global';
import {connect} from 'react-redux';

const mapStateToProps = createStructuredSelector({
    isTabLoaderHome: makeSelectIsTabLoaderHome(),
})

const ContentPartnersComponent = (props:any) => {
    const {activeTab} = props;

    return (
        <div className={styles.ContentContainer}>
            {activeTab.id === 1 && (
                <HomeComponent />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(ContentPartnersComponent);
import stringUtils from '../../../services/utils/stringUtilsService';
import adminService from '../../../services/server/adminService/adminService';
import config from '../../../config/config';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {connectPipedrivePath, submitAiAssistantAgreement} = externalLinks;

const validateOauthCode = async (code:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, connectPipedrivePath);

    return await adminService.setPipedriveIntegration(formattedUrl, code);
}

const setAiAssistantSetting = async (submitted:boolean) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, submitAiAssistantAgreement);
    return await adminService.setAiAssistantSetting(formattedUrl, submitted);
};


export default {
    validateOauthCode,
    setAiAssistantSetting
}
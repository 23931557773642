import classNames from 'classnames';
import React from 'react';

import styles from './Loader.module.scss';
import Image from '../Image/Image'
import appLoaderSvg from '../../../assets/images/icon/loader-icon.svg';

const Loader = (props:any) => {
    const {classNameMainContainer, classNameLogoContainer, classNameLogo} = props;
    const classMainContainer = classNames(styles.MainContainer, classNameMainContainer);
    const classLogoContainer = classNames(styles.LogoContainer, classNameLogoContainer);
    const classLogo = classNames(styles.Logo, classNameLogo);

    return (
        <div className={classMainContainer}>
            <div className={classLogoContainer}>
                <Image
                    src={appLoaderSvg}
                    alt={'Loading...'}
                    className={classLogo}
                />
            </div>
        </div>
    );
};

export default Loader;
import UpgradeComponent from './UpgradeComponent/UpgradeComponent';
import PaymentComponent from './PaymentComponent/PaymentComponent';
import ProcessingPopupComponent from './ProcessingPopupComponent/ProcessingPopupComponent';
import React, {useState} from 'react';
import {createStructuredSelector} from 'reselect';
import {makeSelectPaymentInfo} from '../../state/selectors/subscription';
import {connect} from 'react-redux';
import {makeSelectIsVisibleAddSeatsProPopUp, makeSelectIsVisibleUpgradeProPopUp} from '../../state/selectors/global';
import {dispatch} from '../../state/store';
import {setIsVisibleUpgradeProPopUp, setIsVisibleAddSeatsPopUp} from '../../state/ducks/global';
import AddSeatsPopup from './AddSeatsPopup/AddSeatsPopup';

const mapStateToProps = () => createStructuredSelector({
    paymentInfo: makeSelectPaymentInfo(),
    isVisibleUpgradeProPopUp: makeSelectIsVisibleUpgradeProPopUp(),
    isVisibleAddSeatsPopUp: makeSelectIsVisibleAddSeatsProPopUp()
});

const SubscriptionPopupContainer = (props:any) => {
    const {paymentInfo, isVisibleUpgradeProPopUp, isVisibleAddSeatsPopUp} = props;

    const [processing, setProcessing] = useState<boolean>(false);
    const [result, setResult] = useState<number>(0);
    const [seats, setSeats] = useState<number>(5);
    const [errorPayment, setErrorPayment] = useState<string>();

    const [isVisiblePaymentPopup, setIsVisiblePaymentPopup] = useState(false);
    const [isVisibleProcessingPopup, setIsVisibleProcessingPopup] = useState(false);

    const subscribeClickBtn = () => {
        setProcessing(true);
        setIsVisibleProcessingPopup(true);
    };

    const onErrorSubscription = (detailsError:any) => {
        setIsVisiblePaymentPopup(false);
        setIsVisibleProcessingPopup(true);
        setErrorPayment(detailsError);
    };

    const selectSeats = (numberSeats:number, result:number) => {
        setSeats(numberSeats);
        setResult(result);
    };

    const setIsVisibleUpgradePopup = (isVisible:boolean) => {
        dispatch(setIsVisibleUpgradeProPopUp(isVisible));
    };

    const _setIsVisibleAddSeatsPopup = (isVisible:boolean) => {
        dispatch(setIsVisibleAddSeatsPopUp(isVisible));
    };

    return (
        <>
            {isVisibleUpgradeProPopUp &&
                <UpgradeComponent
                    paymentInfo={paymentInfo}
                    setIsVisibleUpgradePopup={setIsVisibleUpgradePopup}
                    setIsVisiblePaymentPopup={setIsVisiblePaymentPopup}
                    selectSeats={selectSeats}
                />
            }
            {isVisiblePaymentPopup &&
                <PaymentComponent
                    paymentInfo={paymentInfo}
                    seats={seats}
                    setIsVisiblePaymentPopup={setIsVisiblePaymentPopup}
                    result={result}
                    subscribeClickBtn={subscribeClickBtn}
                    onErrorSubscription={onErrorSubscription}
                    setProcessing={setProcessing}
                />
            }
            {isVisibleProcessingPopup &&
                <ProcessingPopupComponent
                    setIsVisibleProcessingPopup={setIsVisibleProcessingPopup}
                    processing={processing}
                    setIsVisiblePaymentPopup={setIsVisiblePaymentPopup}
                    errorPayment={errorPayment}
                    setErrorPayment={setErrorPayment}
                />
            }
            {isVisibleAddSeatsPopUp &&
                <AddSeatsPopup
                    setIsVisibleAddSeatsPopup={_setIsVisibleAddSeatsPopup}
                    paymentInfo={paymentInfo}
                />
            }
        </>
    );
};

export default connect(mapStateToProps)(SubscriptionPopupContainer);
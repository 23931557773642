const optionsForPeriod = [
    {id: 1, name: 'This week'},
    {id: 2, name: 'Last week'},
    {id: 3, name: 'This month'},
    {id: 4, name: 'Last month'},
    {id: 5, name: 'This year'},
    {id: 6, name: 'Last year'}
]

export {
    optionsForPeriod
}
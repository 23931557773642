import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';

import styles from './StatusFilterComponent.module.scss'
import topDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg';
import bottomDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg';
import checkedStatusImg from '../../../../../../../../../../assets/images/icon/checked-mark-green-icon.svg';
import newStatusImg from '../../../../../../../../../../assets/images/icon/new-mark-tomato-icon.svg';
import Image from '../../../../../../../../../components/Image/Image';
import OptionsComponent
    from '../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent';
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect';
import {makeSelectFilterStatus} from '../../../../../../../../../state/selectors/reports';
import reportsService from '../../../../../../../../../services/server/reportsService/reportsService';
import {useOutsideListener} from "../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
    filterStatusList: makeSelectFilterStatus()
});

const StatusFilterComponent = (props:any) => {
    const {classNameContainer, classNameDropDown, classNameDropDownMenu, onStatusClick,
        statusFilter, filterStatusList} = props;

    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [dropDownValue, setDropDownValue] = useState(statusFilter.name);
    const [loader, setLoader] = useState(false);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectButtonRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setDropDownValue(statusFilter.name);
    },[statusFilter]);

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    const openDropDownList = async () => {
        setIsOpenDropDown(!isOpenDropDown);
        await reportsService.getFilterReportsStatus();
        setLoader(true);
    };


    const onStatusButtonClick = (statusName:string, statusId:number) => {
        setDropDownValue(statusName)
        onStatusClick(statusName, statusId);
        closeDropDownList();
    };

    return (
        <div className={classNameContainer}>
            <span className={styles.TitleStatus}>Status</span>
            <div className={isOpenDropDown ? classNames(styles.ActiveDropDown, classNameDropDown) :
                classNameDropDown} onClick={openDropDownList} ref={selectButtonRef}>
                {isOpenDropDown ? 'Select' : dropDownValue}
                <Image src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
                   className={classNames(styles.DropDownIcon, isOpenDropDown && styles.isActiveDropDownIcon)}
                />
            </div>
            {isOpenDropDown && (
                <div className={classNameDropDownMenu} ref={dropDownRef}>
                        {loader ?
                            filterStatusList?.map((item:any) => {
                            const statusName = item.name;
                            const statusId = item.id;
                            const isActiveOptions = item.id === statusFilter.id;
                            const imgOptions = statusName === 'Checked' ? checkedStatusImg :
                                statusName === 'New' ? newStatusImg : styles.AllReports;
                            const classNameStatus = statusName === 'All updates' ? '' :
                                styles.ReportsStatus;

                            return (
                                <OptionsComponent
                                    className={classNames(classNameStatus, styles.OptionsText)}
                                    classNameWraperOptions={styles.OptionsWrapper}
                                    isActiveOptions={isActiveOptions}
                                    reference={optionsRef}
                                    key={statusId}
                                    optionText={statusName}
                                    optionImg={imgOptions}
                                    onClick={() => onStatusButtonClick(statusName, statusId)}
                                />
                            )
                        }):
                            <div className={styles.WrapperForLoader}>
                                <Loader
                                    type="TailSpin"
                                    color="#F85A40"
                                    height={25}
                                    width={25}
                                />
                            </div>
                        }
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps)(StatusFilterComponent);
import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.home;

const makeSelectPartnerLink = () => createSelector(selectGlobal, (globalState:any) => globalState.partnerLink);

const makeSelectCustomersList = () => createSelector(selectGlobal, (globalState:any) => globalState.customersList);

const makeSelectCurrentPageDta = () => createSelector(selectGlobal, (globalState:any) => globalState.currentPageData);

export {
    makeSelectPartnerLink,
    makeSelectCustomersList,
    makeSelectCurrentPageDta
}
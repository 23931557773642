import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect/lib/index';

import * as commonLayoutSizes from '../../../consts/layout/commonLayoutSizes';
import * as commonLayoutTypes from '../../../consts/layout/commonLayoutTypes';
import {setLayout} from '../../../state/ducks/global';
import {makeSelectLayoutType} from '../../../state/selectors/global';

const mapStateToProps = createStructuredSelector({
    layoutType: makeSelectLayoutType(),
});

const WindowResizeChecker = (props:any) => {
    const {layoutType, dispatch} = props;

    const getSize = () => ({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [windowSize, setWindowSize] = useState(getSize());

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getSize());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const currentLayout = getLayout(windowSize.width);

    useEffect(() => {
        if (layoutType !== currentLayout) {
            dispatch(setLayout(currentLayout));
        }
    }, [layoutType, currentLayout]);

    return null;
};

const getLayout = (deviceWidth:number) => {
    if (deviceWidth <= commonLayoutSizes.MOBILE) {
        return commonLayoutTypes.MOBILE;
    } else if (deviceWidth >= commonLayoutSizes.DESKTOP) {
        return commonLayoutTypes.DESKTOP;
    } else {
        return commonLayoutTypes.TABLET;
    }
};

export default connect(mapStateToProps)(WindowResizeChecker);

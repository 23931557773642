import React, {useState} from 'react';

import styles from './CreateNewCourseComponent.module.scss';
import closeImg from '../../../../../../../../../../assets/images/icon/close-popup-icon.svg';
import CourseNameComponent from './components/CourseNameComponent/CourseNameComponent';
import CourseGoalComponent from "./components/CourseGoalComponent/CourseGoalComponent";
import CourseModulesComponent from './components/CourseModulesComponent/CourseModulesComponent';
import Image from '../../../../../../../../../components/Image/Image';
import {dispatch} from '../../../../../../../../../state/store';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectDraftCourse} from '../../../../../../../../../state/selectors/courseConstructor';
import {setDraftCourse} from '../../../../../../../../../state/ducks/courseConstructor';
import backgroundList from '../CoursesListComponent/backgroundList';
import courseService from '../../../../../../../../../services/server/courseService/courseService';

const mapStateToProps = createStructuredSelector({
    draftCourse: makeSelectDraftCourse()
});

const CreateNewCourseComponent = (props:any) => {
    const {hideCreateNewCourseComponent, draftCourse} = props;

    const [isNameIntroduced, setIsNameIntroduced] = useState(!!draftCourse.name || false);
    const [isGoalIntroduced, setIsGoalIntroduced] = useState(!!draftCourse.details || false);
    const [courseName, setCourseName] = useState(draftCourse.name || '');
    const [courseGoal, setCourseGoal] = useState(draftCourse.details || '');
    const randomBackground = backgroundList[Math.floor(Math.random() * backgroundList.length)].color;

    const onNameNextStepButtonClick = async (isValidCourseName: boolean) => {
        if (isValidCourseName) {
            const { id } = await courseService.createNewCourse(courseName, randomBackground);
            const updatedNewCourse = await courseService.getCourseById(id);

            dispatch(setDraftCourse(updatedNewCourse));
            setIsNameIntroduced(true);
        }
    };
    
    const onGoalNextStepButtonClick = async (isValidCourseGoal: boolean) => {
        if (isValidCourseGoal) {
            setIsGoalIntroduced(true);
            await courseService.setCourseGoal(draftCourse.id, courseGoal);
        }
    };

    const onNameChange = (courseName: string) => {
        setCourseName(courseName);
    };
    
    const onGoalChange = (courseGoal: string) => {
        setCourseGoal(courseGoal);
    };

    return (
        <div className={styles.NewCourseContainer}>
            <div className={styles.CloseButtonContainer}>
                <Image src={closeImg} className={styles.CloseImg} onClick={hideCreateNewCourseComponent} />
            </div>
            <span className={styles.StartCreateCourseText}>Let’s start creating your course!</span>
            <CourseNameComponent
                courseName={courseName}
                isNameIntroduced={isNameIntroduced}
                onNextStepButtonClick={onNameNextStepButtonClick}
                onNameChange={onNameChange}
                stepIconClassName={styles.StepIcon}
                classNameLabelText={styles.LabelText}
            />
            {isNameIntroduced && (
                <CourseGoalComponent
                    courseGoal={courseGoal}
                    isGoalIntroduced={isGoalIntroduced}
                    onNextStepButtonClick={onGoalNextStepButtonClick}
                    onGoalChange={onGoalChange}
                    stepIconClassName={styles.StepIcon}
                    classNameLabelText={styles.LabelText}
                />
            )}
            {isGoalIntroduced && (
                <CourseModulesComponent
                    stepIconClassName={styles.StepIcon}
                    classNameLabelText={styles.LabelText}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(CreateNewCourseComponent);
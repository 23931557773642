export const ROOT = "/";
export const ADMIN_SIGN_UP = "/admin-sign-up";
export const ADMIN_SIGN_IN = "/admin-sign-in";
export const ADMIN_ACCOUNT_PAGE = "/account";
export const NEW_COURSE_PAGE = "/new-course";
export const COURSE_CONSTRUCTOR = "/course-constructor";
export const PARTNERS_PAGE = "/partners";
export const EXTERNAL_AUTH = "/autologin";
export const TEAM_SIGN_UP = "/team-sign-up";
export const FORGOT_PASSWORD = "/forgot-password";
export const PIPE_DRIVE_VALIDATION = "/pipedrive/validate";
export const RESET_PASSWORD = "/reset-password/:verifyToken";
export const VERIFY_EMAIL = "/verify";

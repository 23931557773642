import {UserDetailsStorage} from '../interfaces/userDetailsInterface';
import {Role, UserDetails} from '../../types/UserDetails';

const SET_USER_DETAILS = 'app/userDetails/SET_USER_DETAILS';
const SET_USER_ROLES = 'app/userDetails/SET_USER_ROLES';
const SET_USER_ID = 'app/userDetails/SET_USER_ID';

const initialState:UserDetailsStorage = {
    id: null,
    roles: []
};

export default function reducer (state = initialState, action:any) {
    switch (action.type) {
        case SET_USER_DETAILS: {
            return {...action.payload};
        }
        case SET_USER_ROLES: {
            return {...state, roles: action.payload};
        }
        case SET_USER_ID: {
            return {...state, id: action.payload};
        }
        default: return state;
    }
};

export const setUserRoles = (value: Role[]) => ({type: SET_USER_ROLES, payload: value});

export const setUserId = (value: number) => ({type: SET_USER_ID, payload: value});

export const setUserDetails = (value: UserDetails | undefined) => ({type: SET_USER_DETAILS, payload: value});
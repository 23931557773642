import React from 'react';
import styles from '../LearnersItem.module.scss';
import Svg from '../../../../../../../../../../components/SvgComponent/SvgComponent';
import {ReactComponent as DashboardImg} from '../../../../../../../../../../../assets/images/icon/dashboard-icon.svg';
import {ReactComponent as RenameImg} from '../../../../../../../../../../../assets/images/icon/rename-course-icon.svg';
import {ReactComponent as DeleteImage} from '../../../../../../../../../../../assets/images/icon/delete-course-icon.svg';

type ModalContainerProps = {
    openRenamePopup:Function
    openDeletePopup:Function
    openDashboardPopup: Function
    name: string
    id: number
    pipedriveId: number
}

const ModalComponent:React.FC<ModalContainerProps> = ({openRenamePopup, openDeletePopup, openDashboardPopup, name, id, pipedriveId}) => {
    return (
        <>
            {!!pipedriveId &&
                <div className={styles.WrapperModalItem} onClick={(event) => openDashboardPopup(event, name, id, pipedriveId)}>
                    <Svg className={styles.ImageModal}>
                        <DashboardImg/>
                    </Svg>
                    <span>Dashboard</span>
                </div>
            }
            <div className={styles.WrapperModalItem} onClick={(event) => openRenamePopup(event)}>
                <Svg className={styles.ImageModal}>
                    <RenameImg/>
                </Svg>
                <span>Rename</span>
            </div>
            <div className={styles.WrapperModalItem} onClick={(event) => openDeletePopup(event)}>
                <Svg className={styles.ImageModal}>
                    <DeleteImage/>
                </Svg>
                <span>Delete</span>
            </div>
        </>
    );
};

export default ModalComponent;

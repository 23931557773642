import React, {useState} from 'react';

import styles from './AddTeamMemberPopup.module.scss';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import InviteMemberContainer from './components/InviteMemberComponent/InviteMemberContainer';
import FinishedInvitation from './components/FinishedInvitation/FinishedInvitation';

const AddTeamMemberPopup = (props:any) => {
    const {setIsVisibleAddMemberPopup} = props;

    const [isInvitationFinished, setIsInvitationFinished] = useState(false);
    const [invitationCount, setInvitationCount] = useState(0);

    const onFinishInvitation = (numberOfInvitations:number) => {
        setIsInvitationFinished(true);
        setInvitationCount(numberOfInvitations);
    };

    const onCloseInvitationFinished = () => {
        setIsInvitationFinished(false);
        hidePopup();
    };

    const hidePopup = () => {
        setIsVisibleAddMemberPopup(false);
    };

    return (
        <PopupFullPage
            classNameMainContainer={styles.MainPopupContainer}
            isSupportOutsidePopupClick
            closePopup={hidePopup}
        >
            {isInvitationFinished && (
                <FinishedInvitation
                    invitationCount={invitationCount}
                    hideInviteLearnersPopup={onCloseInvitationFinished}
                />
            )}
            {!isInvitationFinished && (
                <div className={styles.WrapperInviteInput}>
                    <InviteMemberContainer
                        onFinishInvitation={onFinishInvitation}
                    />
                </div>
            )}
        </PopupFullPage>
    );
};

export default AddTeamMemberPopup;

import arrangeImg from '../../../../../../../../../../../../../../assets/images/icon/action-arrange-icon.svg';
import arrangeTeamImg from '../../../../../../../../../../../../../../assets/images/icon/action-arrange-team-icon.svg';
import connectImg from '../../../../../../../../../../../../../../assets/images/icon/connect-icon.svg';
import libraryImg from '../../../../../../../../../../../../../../assets/images/icon/library-icon.svg';

export const actionsList = [
    {
        id: 1,
        name: 'Arrange 1-1',
        icon: arrangeImg,
    },
    {
        id: 2,
        name: 'Arrange a team meeting',
        icon: arrangeTeamImg,
    },
    {
        id: 3,
        name: 'Connect with expert',
        icon: connectImg,
    },
    {
        id: 4,
        name: 'Open library',
        icon: libraryImg,
    },
]
import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import classNames from 'classnames';

import styles from './CompanyDetails.module.scss';
import Image from '../../../../components/Image/Image';
import backgroundImg from '../../../../../assets/images/icon/background-login.png';
import StepSignUpComponent from './components/StepSignUpComponent/StepSignUpComponent';
import CompanyInputsComponent from './components/CompanyInputsComponent/CompanyInputsComponent';
import {ReactComponent as ArrowImg} from '../../../../../assets/images/icon/arrow-left-dashboard.svg';
import Svg from '../../../../components/SvgComponent/SvgComponent';
import Logo from '../../../../components/Logo/Logo';

type CompanyDetailsProps = {
    email: string;
    setIsIntroNeeded: Function;
    onSignOutClick: Function;
}

const CompanyDetails:React.FC<CompanyDetailsProps> = ({email, setIsIntroNeeded, onSignOutClick}) => {
    const [isVisibleCompanyInputs, setIsVisibleCompanyInputs] = useState(true);
    const [isOpen3Step, setIsOpen3Step] = useState(false);
    const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);
    const [isVisibleNeedsCompany, setIsVisibleNeedsCompany] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    useEffect(() => {
        scrollTop();
    },[isOpen3Step, isVisibleNeedsCompany]);

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const handleGoBack = () => {
        if (!isVisibleNeedsCompany && !isVisibleCalendar) {
            onSignOutClick();
        } else if (isVisibleNeedsCompany) {
            setIsOpen3Step(false);
            setIsVisibleNeedsCompany(false);
            setIsVisibleCompanyInputs(true);
        } else if (isVisibleCalendar) {
            setIsVisibleCalendar(false);
            setIsVisibleNeedsCompany(true);
        }
    };

    return (
        <div
            className={ classNames(isTabletOrMobile ? styles.MainContainerMobile : styles.MainContainerDesktop, (isVisibleCalendar && !isTabletOrMobile) && styles.MainContainerCalendar)}>
            {isTabletOrMobile && <Logo className={styles.LogoImage}/>}
            {!isTabletOrMobile && <Image src={backgroundImg} className={styles.BackgroundImage} />}
            {isTabletOrMobile &&
                <div className={styles.WrapperGoBack} onClick={handleGoBack}>
                    <Svg className={styles.ImageMenu}>
                        <ArrowImg/>
                    </Svg>
                    <span className={styles.TextGoBack}>Go back</span>
                </div>
            }
            <div className={classNames(styles.Wrapper, isVisibleCalendar && styles.WrapperCalendar)}>
                {(!isTabletOrMobile && !isVisibleCalendar) &&
                    <StepSignUpComponent
                        handleGoBack={handleGoBack}
                        isOpen3Step={isOpen3Step}
                    />
                }
                <CompanyInputsComponent
                    setIsOpen3Step={setIsOpen3Step}
                    isOpen3Step={isOpen3Step}
                    email={email}
                    isVisibleCalendar={isVisibleCalendar}
                    setIsVisibleCalendar={setIsVisibleCalendar}
                    setIsIntroNeeded={setIsIntroNeeded}
                    isVisibleNeedsCompany={isVisibleNeedsCompany}
                    setIsVisibleNeedsCompany={setIsVisibleNeedsCompany}
                    isVisibleCompanyInputs={isVisibleCompanyInputs}
                    setIsVisibleCompanyInputs={setIsVisibleCompanyInputs}
                    // handleGoBack={handleGoBack}
                />
            </div>
        </div>
    );
};

export default CompanyDetails;

import './assets/scss/typography.scss';
import './assets/scss/styles.scss';
import './assets/css/variables.css';
import './assets/css/index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';

import {configureStore} from './app/state/store';
import App from './app/containers/App/App';
import Routes from './app/containers/Routes/Routes';
import historyService from './app/services/history/historyService';

import {loadStripe} from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";

// const stripePromise = loadStripe('pk_test_51JLDkUFYIzagxKGuROmnwiE2v1YCw8eBondedyHHrjKl1MGsNtCHoFuRYGXF8mmbOFumeoU8KoDLTxNpwaa2pPCB0025uc8nWT');

const stripePromise = loadStripe('pk_live_51JLDkUFYIzagxKGu1Z3Rh2HcVPxc7rrHJzMVOvUqRAXTIvVnV76osI0lk6lOpUPTaC1NfshnJHTM3NUkUa84QmYm00rSwoirrf');

const render = async () => {
    const store = configureStore();

    const history = historyService.getHistory();

    ReactDOM.render(
        <Provider store={store}>
            <Elements stripe={stripePromise}>
                <App>
                    <Router history={history}>
                        <Routes />
                    </Router>
                </App>
            </Elements>
        </Provider>,
        document.getElementById('root')
    );
}

render();

import stringUtilsService from "../../utils/stringUtilsService";

import config from "../../../config/config";
import adminService from "../adminService/adminService";
import { UserDetails } from "../../../types/UserDetails";
const { data } = config;
const { defaultApiLink, externalLinks } = data;
const { getUserInfoPath, getInfoPath } = externalLinks;

const getUserInfo = async (): Promise<UserDetails | undefined> => {
  const url: string = stringUtilsService.formatString(
    defaultApiLink,
    getUserInfoPath
  );

  return await adminService.getUserInfo(url);
};

const getInfo = async () => {
  const url = stringUtilsService.formatString(defaultApiLink, getInfoPath);

  return await adminService.getInfo(url);
};

export default {
  getUserInfo,
  getInfo,
};

const current = new Date();

const first = current.getDate() - current.getDay(); // First day is the day of the month - the day of the week
const last = first + 6; // last day is the first day + 6

const firstDayThisWeek = () => {
    return new Date(new Date(current).setDate(first + 1));
};

const lastDayThisWeek = () => {
    return new Date(new Date(current).setDate(last + 1));
};

const lastMondayPreviousWeek = () => {
    const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
    const diffToMonday = beforeOneWeek.getDate() - beforeOneWeek.getDay() + (beforeOneWeek.getDay() === 0 ? -6 : 1);

    return new Date(beforeOneWeek.setDate(diffToMonday));
};

const lastSundayPreviousWeek = () => {
    const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
    const beforeOneWeek2 = new Date(beforeOneWeek);
    const diffToMonday = beforeOneWeek.getDate() - beforeOneWeek.getDay() + (beforeOneWeek.getDay() === 0 ? -6 : 1);

    return new Date(beforeOneWeek2.setDate(diffToMonday + 6));
};

const previousMonthFirstDay = () => {
    return new Date(current.getFullYear(), current.getMonth() - 1, 1);
};

const previousMonthLastDay = () => {
    return new Date(current.getFullYear(), current.getMonth(), 0);
};

const thisMonthFirstDay = () => {
    return new Date(current.getFullYear(), current.getMonth(), 1);
};

const thisMonthLastDay = () => {
    return new Date(current.getFullYear(), current.getMonth() + 1, 0);
};

const thisYearFirstDay = () => {
    return new Date(new Date().getFullYear(), 0, 1);
};

const thisYearLastDay = () => {
    return new Date(new Date().getFullYear(), 11, 31);
};

const previousYearFirstDay = () => {
    return new Date(new Date().getFullYear()-1, 0, 1);
};

const previousYearLastDay = () => {
    return new Date(new Date().getFullYear()-1, 11, 31);
};


export default {
    firstDayThisWeek,
    lastDayThisWeek,
    lastMondayPreviousWeek,
    lastSundayPreviousWeek,
    previousMonthFirstDay,
    previousMonthLastDay,
    thisMonthFirstDay,
    thisMonthLastDay,
    thisYearFirstDay,
    thisYearLastDay,
    previousYearFirstDay,
    previousYearLastDay
}
import React, {useState} from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import styles from './UpgradeComponent.module.scss';
import checkMarkImg from '../../../../assets/images/icon/check-mark-red.svg';
import crossImg from '../../../../assets/images/icon/cross-icon-black.svg';
import equalsImg from '../../../../assets/images/icon/equals-icon.svg';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import Image from '../../../components/Image/Image';
import Input from '../../../components/Input/Input';
import ButtonColor from '../../../components/Button/components/ButtonColor/ButtonColor';
import {firstBenefitsList} from './firstBenefitsList';
import {secondBenefitsList} from './secondBenefitsList';

const UpgradeComponent = (props:any) => {
    const {setIsVisibleUpgradePopup, setIsVisiblePaymentPopup, paymentInfo, selectSeats} = props;
    const [inputValue, setInputValue] = useState(5);
    const price:number = paymentInfo?.priceNumber/100;
    const result:number = Math.ceil(price * Number(inputValue));

    const onChangeInput = (event:any) => {
        setInputValue(event.target.value);
    };

    const onClickBtn = () => {
        if(inputValue >= 5) {
            const setsToNumber = Number(inputValue);
            selectSeats(setsToNumber, result);

            setIsVisiblePaymentPopup(true);
            setIsVisibleUpgradePopup(false);
        }
    };

    const hidePopup = () => {
        setIsVisibleUpgradePopup(false);
    };

    return (
        <PopupFullPage
            classNameMainContainer={styles.MainPopupContainer}
            isSupportOutsidePopupClick
            closePopup={hidePopup}
        >
            <div>
                <div className={styles.Title}>Please, <span className={styles.Upgrade}>upgrade</span> your plan to continue:</div>
                <div className={styles.WrapperSeat}>
                    <div className={styles.WrapperPrice}>
                        <div className={styles.Price}>${price}</div>
                        <div>
                            <div className={styles.Details}>per seat</div>
                            <div className={styles.Details}>per month</div>
                        </div>
                    </div>
                    <div className={styles.WrapperBenefits}>
                        <div>
                            {firstBenefitsList.map((item:any) => {
                                return (
                                    <div key={item.id} className={styles.ItemBenefits}>
                                        <Image src={checkMarkImg} className={styles.Icon}/>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            {secondBenefitsList.map((item:any) => {
                                return (
                                    <div key={item.id} className={styles.ItemBenefits}>
                                        <Image src={checkMarkImg} className={styles.Icon}/>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.CalculateWrapper}>
                    <span className={styles.CalculateTitle}>Calculate:</span>
                    <div>
                        <div className={styles.PriceCalculate}>{price}</div>
                        <div className={classNames(styles.SubtitleCalculatesPerSeat, styles.SubtitleCalculates)}>Per seat, $</div>
                    </div>
                    <Image src={crossImg} className={styles.CrossImg}/>
                    <div>
                        <Input
                            autoFocus
                            className={styles.Input}
                            value={inputValue}
                            onChange={onChangeInput}
                            type='number'
                            placeholder='5'
                        />
                        <div className={styles.WrapperSubtitleCalculates}>
                            <div className={styles.SubtitleCalculates}>Seats</div>
                            <div className={styles.SubtitleCalculates}>5 seats min.</div>
                        </div>
                    </div>
                    <Image src={equalsImg} className={styles.EqualsImg}/>
                    <div>
                        <div data-tip={`$${result}`} className={styles.PricePerMonth}>{`$${result}`}</div>
                        <div className={classNames(styles.PerMonth, styles.SubtitleCalculates)}>Per month</div>
                    </div>
                    <ReactTooltip
                        effect='solid'
                    />
                </div>
                <ButtonColor
                    onClick={onClickBtn}
                    className={classNames(styles.Btn, inputValue < 5 && styles.DisabledBtn)}
                    disabled={inputValue < 5 && true}
                >
                    Upgrade
                </ButtonColor>
            </div>
        </PopupFullPage>
    );
};

export default UpgradeComponent;
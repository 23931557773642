import React, {useState} from 'react';

import styles from './RenameCoursePopup.module.scss';
import PopupFullPage from '../../../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import Input from '../../../../../../../../../../../components/Input/Input';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import {dispatch} from '../../../../../../../../../../../state/store';
import {connect} from 'react-redux';
import {makeSelectCourseList} from '../../../../../../../../../../../state/selectors/admin';
import {setCurrentCourseList} from '../../../../../../../../../../../state/ducks/admin';
import {createStructuredSelector} from 'reselect';
import courseService from '../../../../../../../../../../../services/server/courseService/courseService';

const mapStateToProps = createStructuredSelector({
    currentCourseList: makeSelectCourseList()
});

const RenameCoursePopup = (props:any) => {
    const {hideRenamePopup, courseName, courseId, currentCourseList} = props;

    const [currentCourseName, setCurrentCourseName] = useState(courseName);
    const [count, setCount] = useState(courseName.length);

    const onCourseNameChange = (event: React.FormEvent<HTMLInputElement>) => {
        setCurrentCourseName(event.currentTarget.value);
        setCount(event.currentTarget.value.length);
    };

    const onRenameButtonClick = async () => {
        const newCourseList = currentCourseList.map((course:any) => {
            if(course.id === courseId) {
                course.name = currentCourseName;
            };

            return course;
        });
        dispatch(setCurrentCourseList(newCourseList));

        await courseService.renameCourse(courseId, currentCourseName);
        hideRenamePopup();
    };

    return (
        <PopupFullPage
            classNameMainContainer={styles.MainPopupContainer}
            closePopup={hideRenamePopup}
            isSupportOutsidePopupClick
        >
            <div className={styles.MainContainer}>
                <span className={styles.TitleText}>Rename this course</span>
                <Input
                    className={styles.InputField}
                    onChange={onCourseNameChange}
                    value={currentCourseName}
                    type='text'
                    maxLength={60}
                />
                <div className={styles.CharactersCount}>{count}/60</div>
                <ButtonColor className={styles.Button} onClick={onRenameButtonClick}>
                    Save
                </ButtonColor>
            </div>
        </PopupFullPage>
    )
};

export default connect(mapStateToProps)(RenameCoursePopup);
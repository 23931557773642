import React from 'react';

import {DeletePopup} from "../../../../../../../../../../../components/DeletePopup/DeletePopup";
import {connect} from 'react-redux';
import {dispatch} from '../../../../../../../../../../../state/store';
import {createStructuredSelector} from 'reselect';
import {makeSelectCourseList} from '../../../../../../../../../../../state/selectors/admin';
import courseService from '../../../../../../../../../../../services/server/courseService/courseService';
import {setCurrentCourseList} from '../../../../../../../../../../../state/ducks/admin';

const mapStateToProps = createStructuredSelector({
    currentCourseList: makeSelectCourseList(),
});

const DeleteCoursePopup = (props:any) => {
    const {hideDeletePopup, courseId, currentCourseList, courseName} = props;

    const deleteCourseButtonClick = async () => {
        const newCourseList = currentCourseList.filter((course:any) => course.id !== courseId);
        dispatch(setCurrentCourseList(newCourseList));

        await courseService.deleteCourse(courseId);
        hideDeletePopup();
    };

    return (
        <DeletePopup
            onHide={hideDeletePopup}
            onDelete={deleteCourseButtonClick}
            textData={{
                title:'Delete this course?',
                header: {
                    general:'You are about to delete',
                    specific:courseName,
                    note:'It\'ll be gone forever and we won\'t be able to recover it.'
                }
            }}
        />
    );
};

export default connect(mapStateToProps)(DeleteCoursePopup);
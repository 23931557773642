import React, {useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import styles from './SignUpTeamPage.module.scss';
import Image from '../../components/Image/Image';
import Line from '../../components/Line/Line';
import InputAuthField from '../../components/Input/InputAuthField/InputAuthField';
import AuthErrorModal from '../../components/Error/AuthErrorComponent/AuthErrorComponent';
import ButtonColor from '../../components/Button/components/ButtonColor/ButtonColor';
import ButtonText from '../../components/Button/components/ButtonText/ButtonText';
import logo from '../../../assets/images/logo/gradual.svg';
import smileImg from '../../../assets/images/icon/smile-icon.svg';
import validationService from '../../services/server/validationService/validationService';
import appRouterService from '../../services/route/appRouterService';
import backgroundImg from '../../../assets/images/icon/background-login.png';
import urlUtils from '../../services/utils/urlUtils';
import adminAuthService from '../../services/server/adminService/adminAuthService';
import {BAD_REQUEST, FORBIDDEN} from '../../consts/httpStatus/httpStatus';
import {COMPANY_NOT_FOUND, NOT_SUBSCRIBED, USER_ALREADY_REGISTERED} from '../../consts/error/errorTypesMessage';
import {usePresentSession} from '../../hooks/usePresentSession';

const SignUpTeamPage = () => {
    usePresentSession();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    const [email, companyId] : (string | number | null)[] = urlUtils.getUrlParams(['e','c_i']);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorOccurred, setIsErrorOccurred] = useState(false);

    const [isInputError, setIsInputError] = useState({
        isFirstName: false,
        isLastName: false,
        isEmailError: false,
        isPasswordError: false,
    });

    const textFieldForFirstNameRef = useRef<HTMLInputElement>(null)!;
    const textFieldForLastNameRef = useRef<HTMLInputElement>(null)!;
    const textFieldForEmailRef = useRef<HTMLInputElement>(null)!;
    const textFieldForPasswordRef = useRef<HTMLInputElement>(null)!;

    const onFirstNameChange = (event: React.FormEvent<HTMLInputElement>) => {
        setFirstName(event.currentTarget.value);
    };

    const onLastNameChange = (event: React.FormEvent<HTMLInputElement>) => {
        setLastName(event.currentTarget.value);
    };

    const onPasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
    };

    const onEnterKeyPressFirstName = (event:any) => {
        const isValidFirstName = validationService.isValidName(event.target.value);

        if(event.code === 'Enter' && isValidFirstName) {
            textFieldForLastNameRef!.current!.focus();
        } else {
            setIsInputError({...isInputError, isFirstName: !isValidFirstName});
        }
    };

    const onEnterKeyPressLastName = (event:any) => {
        const isValidLastName = validationService.isValidName(event.target.value);

        if(event.code === 'Enter' && isValidLastName) {
            textFieldForEmailRef!.current!.focus();
        } else {
            setIsInputError({...isInputError, isLastName: !isValidLastName});
        }
    };

    const onEnterKeyPressEmail = (event: any) => {
        const isValidEmail = validationService.isValidEmail(event.target.value);

        if (event.code === "Enter" && isValidEmail) {
            textFieldForPasswordRef!.current!.focus();
        } else {
            setIsInputError({...isInputError, isEmailError: !isValidEmail});
        }
    };

    const onEnterKeyPressPassword = (event: any) => {
        if(event.code === "Enter") {
            onSignUpButtonClick();
        }
    };

    const onLoginButtonClick = () => {
        appRouterService.forwardToAdminLoginPage();
    };

    const onSignUpButtonClick = () => {
        if (email) {
            const zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

            adminAuthService.signUpContributor(email, password, firstName, lastName, Number(companyId), zoneId)
                .then((response) => {
                    const modifiedEmail = email.toLowerCase();
                    adminAuthService.loginUser(modifiedEmail, password);
                })
                .catch((error) => {
                    const {status, data} = error;

                    if (status === BAD_REQUEST) {
                        setIsErrorOccurred(true);

                        if (data.includes(USER_ALREADY_REGISTERED)) {
                            setErrorMessage('This user is already registered!');
                        }

                        if (data.includes(COMPANY_NOT_FOUND)) {
                            setErrorMessage('There is no such company registered!');
                        }
                    }

                    if (status === FORBIDDEN && data.includes(NOT_SUBSCRIBED)) {
                        setIsErrorOccurred(true);
                        setErrorMessage('Subscription is not purchased');
                    }
                });
        }
    };

    return (
        <div className={styles.MainContainer}>
            {!isTabletOrMobile &&
            <>
                <Image src={backgroundImg} className={styles.BackgroundImage} />
                <div className={styles.SignInContainer}>
                    <div className={styles.TitleContainer}>
                        <span className={styles.TitleText}>Welcome to <span>Gradual</span></span>
                    </div>
                    <div className={styles.TitleDescriptionContainerHeader}>
                        <Line className={styles.Line} />
                        <span>Sign up with email to <span className={styles.CompanyField}>your company</span> account</span>
                        <Line className={styles.Line} />
                    </div>
                    <div className={styles.InputContainer}>
                        <InputAuthField
                            type='text'
                            labelText='First Name'
                            placeholderInput='Jon'
                            classNameInput={styles.InputField}
                            classNameLabel={styles.LabelInputText}
                            value={firstName}
                            onChange={onFirstNameChange}
                            onKeyPress={onEnterKeyPressFirstName}
                            reference={textFieldForFirstNameRef}
                            isInputError={isInputError.isFirstName}
                        />
                        <InputAuthField
                            type='text'
                            labelText='Second Name'
                            placeholderInput='Snow'
                            classNameInput={styles.InputField}
                            classNameLabel={styles.LabelInputText}
                            value={lastName}
                            onChange={onLastNameChange}
                            onKeyPress={onEnterKeyPressLastName}
                            reference={textFieldForLastNameRef}
                            isInputError={isInputError.isLastName}
                        />
                        <InputAuthField
                            type='text'
                            labelText='Email'
                            classNameInput={styles.InputField}
                            classNameLabel={styles.LabelInputText}
                            value={email}
                            placeholderInput='tyrion@lannister.com'
                            onKeyPress={onEnterKeyPressEmail}
                            reference={textFieldForEmailRef}
                            isInputError={isInputError.isEmailError}
                            isDisabledInput={true}
                        />
                        <InputAuthField
                            labelText='Password'
                            classNameInput={styles.InputField}
                            classNameLabel={styles.LabelInputText}
                            value={password}
                            placeholderInput='**********'
                            onChange={onPasswordChange}
                            onKeyPress={onEnterKeyPressPassword}
                            isInputError={isInputError.isPasswordError}
                            reference={textFieldForPasswordRef}
                            isPasswordViewButton
                        />
                        {isErrorOccurred &&
                            <AuthErrorModal
                                errorMessage={errorMessage}
                                onClick={onSignUpButtonClick}
                            />
                        }
                        <ButtonColor className={styles.LoginButton} onClick={onSignUpButtonClick}>
                            Sign up
                        </ButtonColor>
                        <div className={styles.TitleDescriptionContainer}>
                            <Line className={styles.Line} />
                            <span>Already have an account?</span>
                            <Line className={styles.Line} />
                        </div>
                        <ButtonText className={styles.SignUpButton} onClick={onLoginButtonClick}>
                            Log in
                        </ButtonText>
                    </div>
                </div>
            </>
            }
            {isTabletOrMobile &&
                <div className={styles.NotAdaptiveWrapper}>
                    <Image className={styles.LogoImage} src={logo}/>
                    <div className={styles.NotAdaptive}>
                        <span className={styles.TitleNotAdaptive}>Please switch to full screen mode or use Gradual with desktop for better experience</span>
                        <Image src={smileImg} className={styles.SmileImg}/>
                    </div>
                </div>
            }
        </div>
    );
};

export default SignUpTeamPage;

import {CustomBrandStorage} from '../interfaces/customBrandingInterface';

export const SET_BRAND_COLOR = 'app/admin/SET_BRAND_COLOR';
export const SET_BRAND_LOGO = 'app/admin/SET_BRAND_LOGO';
export const SET_IS_PHONE_INVITATION = 'app/admin/SET_IS_PHONE_INVITATION';

const initialState:CustomBrandStorage = {
    brandColor: '',
    brandLogo: '',
    isPhoneInvitation: false
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_IS_PHONE_INVITATION: {
            return {...state, isPhoneInvitation: action.payload};
        }
        case SET_BRAND_COLOR: {
            return {...state, brandColor: action.payload};
        }
        case SET_BRAND_LOGO: {
            return {...state, brandLogo: action.payload};
        }
        default: return state;
    }
}

export const setBrandColor = (value:string|undefined) => ({type: SET_BRAND_COLOR, payload: value});

export const setBrandLogo = (value:string|undefined) => ({type: SET_BRAND_LOGO, payload: value});

export const setIsPhoneInvitation = (value:boolean|undefined) => ({type: SET_IS_PHONE_INVITATION, payload: value});

import classNames from 'classnames';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import styles from './TextArea.module.scss';

const TextArea = (props:any) => {
    const {placeholder, className, onChange, onBlur, value, isDisableInput, autoFocus, maxLength, onKeyPress} = props;
    const classNameTextArea = classNames(styles.TextArea, className);

    return (
        <TextareaAutosize
            autoFocus={autoFocus}
            disabled={isDisableInput}
            placeholder={placeholder}
            className={classNameTextArea}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            maxLength={maxLength}
            onKeyPress={onKeyPress}
        />
    );
};

export default TextArea;
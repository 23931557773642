import React, {useEffect} from 'react';

import styles from './IntegrationsComponent.module.scss';
import TelegramIntegrationComponent from './components/TelegramIntegrationComponent/TelegramIntegrationComponent';
import SlackIntegrationComponent from './components/SlackIntegrationComponent/SlackIntegrationComponent';
import PipedriveComponent from './components/PipedriveComponent/PipedriveComponent';
import integrationsService from '../../../../../../../services/server/integrationsService/integrationsService';
import {UserDetails} from "../../../../../../../types/UserDetails";

interface PropType {
    userDetails: UserDetails
}

const IntegrationsComponent:React.FunctionComponent<PropType> = ({userDetails}) => {

    const isPipedriveAllowed = userDetails.isPipedriveAllowed;

    const getIntegrationsBots = async () => {
        await integrationsService.getIntegrationsBots();
    };

    useEffect(() => {
        getIntegrationsBots();
    }, []);

    return (
        <div className={styles.MainIntegrationsContainer}>
            {isPipedriveAllowed &&
                <>
                    <div className={styles.TitleText}>
                        Performance
                    </div>
                    <PipedriveComponent
                    classNameContainer={styles.IntegrationsContainer}
                    classNameHeaderContainer={styles.HeaderContainer}
                    classNameImage={styles.Image}
                    classNameCompleteImage={styles.CompleteImage}
                    classNameWrapperInputContainer={styles.WrapperInputContainer}
                    classNameInput={styles.Input}
                    classnameInputError={styles.InputError}
                    classNameInputLabelContainer={styles.InputLabelContainer}
                    classNameConnectButton={styles.ConnectButton}
                    сlassNameDisabledButton={styles.DisabledButton}
                    classNameHeader={styles.IntegrationsHeader}
                    classNameIntagration={styles.Intagrations}
                    classNameWrapperCheckBox={styles.WrapperCheckBox}
                    classNameTextConfirm={styles.TextConfirm}
                    classNameStatusContainer={styles.IntegrationsStatus}
                    classNameInvalid={styles.Invalid}
                    classNameValid={styles.Valid}
                    />
                </>
            }
            <span className={styles.TitleText}>
                Notifications
            </span>
            <TelegramIntegrationComponent
                classNameContainer={styles.IntegrationsContainer}
                classNameHeaderContainer={styles.HeaderContainer}
                classNameImage={styles.Image}
                classNameCompleteImage={styles.CompleteImage}
                classNameWrapperInputContainer={styles.WrapperInputContainer}
                classNameInput={styles.Input}
                classnameInputError={styles.InputError}
                classNameInputLabelContainer={styles.InputLabelContainer}
                classNameConnectButton={styles.ConnectButton}
                сlassNameDisabledButton={styles.DisabledButton}
                classNameHeader={styles.IntegrationsHeader}
                classNameIntagration={styles.Intagrations}
                classNameWrapperCheckBox={styles.WrapperCheckBox}
                classNameTextConfirm={styles.TextConfirm}
                classNameStatusContainer={styles.IntegrationsStatus}
                classNameInvalid={styles.Invalid}
                classNameValid={styles.Valid}
            />
            <SlackIntegrationComponent
                classNameContainer={styles.IntegrationsContainer}
                classNameHeaderContainer={styles.HeaderContainer}
                classNameImage={styles.Image}
                classNameCompleteImage={styles.CompleteImage}
                classNameWrapperInputContainer={styles.WrapperInputContainer}
                classNameInput={styles.Input}
                classnameInputError={styles.InputError}
                classNameInputLabelContainer={styles.InputLabelContainer}
                classNameConnectButton={styles.ConnectButton}
                сlassNameDisabledButton={styles.DisabledButton}
                classNameHeader={styles.IntegrationsHeader}
                classNameIntagration={styles.Intagrations}
                classNameWrapperCheckBox={styles.WrapperCheckBox}
                classNameTextConfirm={styles.TextConfirm}
                classNameStatusContainer={styles.IntegrationsStatus}
                classNameInvalid={styles.Invalid}
                classNameValid={styles.Valid}
            />
        </div>
    );
};

export default IntegrationsComponent;
import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import styles from './ModuleNameCellComponent.module.scss';
import {ReactComponent as DragAndDrop } from '../../../../../../../assets/images/icon/drag-drop-quiz-questions-icon.svg';
import {ReactComponent as DeleteImg} from '../../../../../../../assets/images/icon/delete-module-icon.svg';
import Svg from '../../../../../../components/SvgComponent/SvgComponent';
import Input from "../../../../../../components/Input/Input";
import {DeletePopup} from '../../../../../../components/DeletePopup/DeletePopup';

const ModuleNameCellComponent = (props:any) => {
    const {isActiveModule, moduleName, onDeleteModuleClick, onClick, onModuleNameChange, sortedModules} = props;

    const [moduleName_, setModuleName_] = useState(moduleName);
    const [isVisibleDeletePopup, setIsVisibleDeletePopup] = useState(false);
    const classNameModuleContainer = classNames(styles.ModuleItemContainer, isActiveModule && styles.ActiveModule);
    const classNameActionButton = classNames(styles.VisibleActionButton);
    const [toggle, setToggle] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    const useOutsideAlerter = (popupRef:any) => {
        useEffect(() => {
            function handleClickOutside(event:any) {
                if (inputRef.current && !inputRef.current.contains(event.target)) {
                    setToggle(true);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [popupRef]);
    };

    useOutsideAlerter(inputRef);

    const onChange = async (event:any) => {
        const updatedModuleName = event.target.value;
        setModuleName_(updatedModuleName);
        await onModuleNameChange(updatedModuleName);
    };

    const onDoubleClick = async (event:any) => {
        setToggle(false);
    };

    const onKeyPress = async (event:any) => {
        if (event.code === 'Enter') {
            setToggle(true);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const onOpenDeletePopup = () => {
        setIsVisibleDeletePopup(true);
    };

    const onCloseDeletePopup = () => {
        setIsVisibleDeletePopup(false);
    };

    return (
        <>
            <div className={classNameModuleContainer} onClick={onClick}>
                <Svg className={classNames(classNameActionButton, styles.DragAndDrop)}>
                    <DragAndDrop />
                </Svg>
                <div
                    className={styles.ModuleName}
                >
                    {toggle ?
                        (<div className={styles.TextModuleName} onDoubleClick={onDoubleClick}
                            >{moduleName_ ? moduleName_ : 'Give a name...'}
                        </div>)
                        : (<Input
                            placeholder={'Give a name...'}
                            value={moduleName_}
                            onChange={onChange}
                            className={styles.Input}
                            reference={inputRef}
                            onKeyPress={onKeyPress}
                            autoFocus
                        />)
                    }
                </div>
                <Svg
                    onClick={onOpenDeletePopup}
                    className={classNames(classNameActionButton, styles.DeleteButton , sortedModules?.length < 2 && styles.DisabledBtn)}
                >
                    <DeleteImg />
                </Svg>
            </div>
            {isVisibleDeletePopup &&
                <DeletePopup
                    onHide={onCloseDeletePopup}
                    onDelete={onDeleteModuleClick}
                    textData={{
                        title:'Are you sure?',
                        header:{
                            general:'You are about to remove the module!',
                            specific: '',
                            note:'It\'ll be gone forever and we won\'t be able to recover it.'
                        }
                    }}
                />
            }
        </>
    );
};

export default ModuleNameCellComponent;
import ReactPaginate from 'react-paginate';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import assert from 'assert';

import styles from './PaginationComponent.module.scss';

interface PaginationComponentProps<T> {
    list: T[] // full list of data used for pagination
    elementsPerPage: number
    handleWorkingListUpdate: Function
}

/**
 * List with 9 elements
 *
 * elements_per_page = 2 => n_pages = ceil( length /  elements_per_page) = ceil(4.5) = 5
 * page_count needs to start from 0 in {0, 1, 2, 3, 4} = PAGES
 *
 * offset needs to properly map to array indexing
 * [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ]
 *  |p0 |,|p1 |,|p2 |,|p3 |,|p4 |
 *
 * p0 -> [0], [1]
 * p1 -> [2], [3]
 * p2 -> [4], [5]
 * p3 -> [6], [7]
 * p4 -> [8]
 * pn -> [ elements_per_page * (n), elements_per_page * (n) + perPage )
 *
 * NOTE: the component may also perform a work on the list,
 * like element marching and working-list updating (strictly via callback function)
 * **/

const PaginationComponent = <T extends Object>({list, elementsPerPage, handleWorkingListUpdate}: PaginationComponentProps<T>) => {
    // assert(list.length !== 0, "Array must consist at least one element.\nNOTE: Consider managing it at the higher component level.")
    // number of pages required to display all the elements
    const [_currentPage, _setCurrentPage] = useState(0);
    const _pageCount = useMemo(() => Math.ceil(list.length / elementsPerPage), [list, elementsPerPage]);

    // offset of element in array from which the page starts
    const _leftOffSet:number = _currentPage * elementsPerPage;
    const _rightOffSet:number = _currentPage * elementsPerPage + elementsPerPage;

    const handlePageCountUpdate = useCallback((pageData : {selected:number }) => {
        _setCurrentPage(pageData.selected);
    },[]);

    useEffect(() => {
        handleWorkingListUpdate(
            list.slice(_leftOffSet, _rightOffSet)
        );
    }, [_currentPage, list]);

    useEffect(() => _setCurrentPage(0), [list]);

    return (
        <div className={styles.PaginationContainer}>
            <div className={styles.TextNumber}>
                {_pageCount > 1 && _rightOffSet <= list.length ?
                    `Showing ${_leftOffSet + 1} - ${_rightOffSet} of ${list.length}` :
                    `Showing ${_leftOffSet + 1} - ${list.length} of ${list.length}`
                }
            </div>
            <ReactPaginate
                pageCount={_pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                breakClassName={styles.ListPagination}
                pageClassName={styles.ListPagination}
                activeLinkClassName={styles.ActiveBtnPaginate}
                containerClassName={styles.ContainerPaginate}
                previousLabel={'<'}
                nextLabel = {'>'}
                previousClassName={styles.Previous}
                nextClassName={styles.Next}
                onPageChange={handlePageCountUpdate}
                forcePage={_currentPage}
            />
        </div>
    );
};

export default React.memo(PaginationComponent);


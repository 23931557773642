import {availableTabList} from '../../containers/ConsumerAccountPage/AdminAccountPage/components/MenuListComponent/menuTabList';
import {AdminMenuTab} from '../../types/tab/Tab';

export const SET_IS_ACTIVE_TAB = 'app/global/SET_IS_ACTIVE_TAB';
export const SET_IS_APP_LOADER = 'app/global/SET_IS_APP_LOADER';
export const SET_IS_VISIBLE_UPGRADE_PRO_POPUP = 'app/global/SET_VISIBLE_UPGRADE_PRO_POPUP';
export const SET_IS_VISIBLE_ADD_SEATS_POPUP = 'app/global/SET_IS_VISIBLE_ADD_SEATS_POPUP';
export const SET_IS_NETWORK_ONLINE = 'app/global/SET_IS_NETWORK_ONLINE';
export const SET_LAYOUT = 'app/global/SET_LAYOUT';
export const SET_CONTENT_LOADER = 'app/global/SET_CONTENT_LOADER';
export const SET_IS_TAB_LOADER = 'app/global/SET_IS_TAB_LOADER';
export const SET_IS_TAB_LOADER_COURSES = 'app/global/SET_IS_TAB_LOADER_COURSES';
export const SET_IS_TAB_LOADER_HOME = 'app/global/SET_IS_TAB_LOADER_HOME';
export const SET_IS_TAB_LOADER_REPORTS = 'app/global/SET_IS_TAB_LOADER_REPORTS';
export const SET_IS_TAB_LOADER_PERFORMANCE_REPORTS = 'app/global/SET_IS_TAB_LOADER_PERFORMANCE_REPORTS';
export const SET_IS_TAB_LOADER_ANALYTICS = 'app/global/SET_IS_TAB_LOADER_ANALYTICS';
export const SET_IS_TAB_LOADER_LEARNERS = 'app/global/SET_IS_TAB_LOADER_LEARNERS';
export const SET_IS_TAB_LOADER_INTEGRATIONS = 'app/global/SET_IS_TAB_LOADER_INTEGRATIONS';
export const SET_IS_TAB_LOADER_CUSTOM_BRANDING = 'app/global/SET_IS_TAB_LOADER_CUSTOM_BRANDING';
export const SET_IS_TAB_LOADER_SUBSCRIPTION = 'app/global/SET_IS_TAB_LOADER_SUBSCRIPTION';
export const SET_IS_TAB_LOADER_TEAM = 'app/global/SET_IS_TAB_LOADER_TEAM';
export const SET_IS_VISIBLE_SUBSCRIPTION_POPUP = 'app/global/SET_IS_VISIBLE_SUBSCRIPTION_POPUP';

const initialState = {
    activeTab: availableTabList[0],
    isAppLoader: false,
    isVisibleUpgradeProPopUp: false,
    isVisibleAddSeatsPopUp: false,
    isContentLoader : false,
    isTabLoaderCourses: true,
    isTabLoaderHome: true,
    isTabLoaderReports: true,
    isTabLoaderPerformanceReports: true,
    isTabLoaderAnalytics: true,
    isTabLoaderLearners: true,
    isTabLoaderIntegrations: true,
    isTabLoaderCustomBranding: true,
    isTabLoaderSubscription: true,
    isTabLoaderTeam: true,
    isVisibleSubscriptionPopup: false
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_IS_ACTIVE_TAB: {
            return {...state, activeTab: action.payload};
        }
        case SET_IS_APP_LOADER: {
            return {...state, isAppLoader: action.payload};
        }
        case SET_IS_VISIBLE_UPGRADE_PRO_POPUP: {
            return {...state, isVisibleUpgradeProPopUp: action.payload};
        }
        case SET_IS_VISIBLE_ADD_SEATS_POPUP: {
            return {...state, isVisibleAddSeatsPopUp: action.payload};
        }
        case SET_IS_NETWORK_ONLINE: {
            return {...state, isNetworkOnline: action.payload};
        }
        case SET_LAYOUT: {
            return {...state, layout: action.payload};
        }
        case SET_CONTENT_LOADER: {
            return {...state, isContentLoader: action.payload};
        }
        case SET_IS_TAB_LOADER: {
            return {...state, isTabLoader: action.payload};
        }
        case SET_IS_TAB_LOADER_COURSES: {
            return {...state, isTabLoaderCourses: action.payload};
        }
        case SET_IS_TAB_LOADER_HOME: {
            return {...state, isTabLoaderHome: action.payload};
        }
        case SET_IS_TAB_LOADER_REPORTS: {
            return {...state, isTabLoaderReports: action.payload};
        }
        case SET_IS_TAB_LOADER_PERFORMANCE_REPORTS: {
            return {...state, isTabLoaderPerformanceReports: action.payload};
        }
        case SET_IS_TAB_LOADER_ANALYTICS: {
            return {...state, isTabLoaderAnalytics: action.payload};
        }
        case SET_IS_TAB_LOADER_LEARNERS: {
            return {...state, isTabLoaderLearners: action.payload};
        }
        case SET_IS_TAB_LOADER_INTEGRATIONS: {
            return {...state, isTabLoaderIntegrations: action.payload};
        }
        case SET_IS_TAB_LOADER_CUSTOM_BRANDING: {
            return {...state, isTabLoaderCustomBranding: action.payload};
        }
        case SET_IS_TAB_LOADER_SUBSCRIPTION: {
            return {...state, isTabLoaderSubscription: action.payload};
        }
        case SET_IS_TAB_LOADER_TEAM: {
            return {...state, isTabLoaderTeam: action.payload};
        }
        case SET_IS_VISIBLE_SUBSCRIPTION_POPUP: {
            return {...state, isVisibleSubscriptionPopup: action.payload};
        }
        default: return state;
    }
}

export const setActiveTab = (value:AdminMenuTab) => ({type: SET_IS_ACTIVE_TAB, payload: value});

export const setIsAppLoader = (value:boolean) => ({type: SET_IS_APP_LOADER, payload: value});

export const setIsNetworkOnline = (value:boolean) => ({type: SET_IS_NETWORK_ONLINE, payload: value});

export const setLayout = (layout:number) => ({type: SET_LAYOUT, payload: layout});

export const setIsContentLoader = (value:boolean) => ({type: SET_CONTENT_LOADER, payload: value});

export const setIsTabLoader = (value:boolean) => ({type: SET_IS_TAB_LOADER, payload: value});

export const setIsTabLoaderCourses = (value:boolean) => ({type: SET_IS_TAB_LOADER_COURSES, payload: value});

export const setIsTabLoaderHome = (value:boolean) => ({type: SET_IS_TAB_LOADER_HOME, payload: value});

export const setIsTabLoaderReports = (value:boolean) => ({type: SET_IS_TAB_LOADER_REPORTS, payload: value});

export const setIsTabLoaderPerformanceReports = (value:boolean) => ({type: SET_IS_TAB_LOADER_PERFORMANCE_REPORTS, payload: value});

export const setIsTabLoaderAnalytics = (value:boolean) => ({type: SET_IS_TAB_LOADER_ANALYTICS, payload: value});

export const setIsTabLoaderLearners = (value:boolean) => ({type: SET_IS_TAB_LOADER_LEARNERS, payload: value});

export const setIsTabLoaderIntegrations = (value:boolean) => ({type: SET_IS_TAB_LOADER_INTEGRATIONS, payload: value});

export const setIsTabLoaderCustomBranding = (value:boolean) => ({type: SET_IS_TAB_LOADER_CUSTOM_BRANDING, payload: value});

export const setIsTabLoaderSubscription = (value:boolean) => ({type: SET_IS_TAB_LOADER_SUBSCRIPTION, payload: value});

export const setIsVisibleUpgradeProPopUp = (value:boolean) => ({type: SET_IS_VISIBLE_UPGRADE_PRO_POPUP, payload: value});

export const setIsVisibleAddSeatsPopUp = (value:boolean) => ({type: SET_IS_VISIBLE_ADD_SEATS_POPUP, payload: value});

export const setIsTabLoaderTeam = (value: boolean) => ({type: SET_IS_TAB_LOADER_TEAM, payload: value});

export const setIsVisibleSubscriptionPopup = (value: boolean) => ({type: SET_IS_VISIBLE_SUBSCRIPTION_POPUP, payload: value});
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import dynamicMiddlewares from 'redux-dynamic-middlewares-2';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import global from '../state/ducks/global';
import admin from '../state/ducks/admin';
import integrations from '../state/ducks/integrations';
import customBranding from '../state/ducks/customBranding';
import reports from '../state/ducks/reports';
import analytics from '../state/ducks/analytics';
import courseConstructor from '../state/ducks/courseConstructor';
import subscription from '../state/ducks/subscription';
import home from '../state/ducks/home';
import userDetails from '../state/ducks/userDetails';
import team from '../state/ducks/team';
import performance from '../state/ducks/performance';
import performanceReports from '../state/ducks/performanceReports';

let dispatch:any;
let getState:any;

const configureStore = (initialState = {}) => {
    const appReducer = combineReducers({global, admin, integrations, customBranding, reports,
        courseConstructor, analytics, subscription, home, userDetails, team, performance, performanceReports});

    const rootReducer = (state:any, action:any) => {
        return appReducer(state, action);
    };
    const middlewares = [promise, thunk];

    const middleware = applyMiddleware(...middlewares, dynamicMiddlewares);
    const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const store = createStore(rootReducer, initialState, composeEnhancers(middleware));
    dispatch = store.dispatch;
    getState = store.getState;

    return store;
};

export {configureStore, dispatch, getState};

import React from 'react';

import styles from './YourPlanFreeDetailsComponent.module.scss';
import checkMarkImg from '../../../../../../../../../../../assets/images/icon/check-mark-grey.svg';
import streamlineList from '../streamlineList';
import Image from '../../../../../../../../../../components/Image/Image';

type YourPlanFreeDetailsComponentProps = {
    freeSeatsNumber: number;
};

const YourPlanFreeDetailsComponent:React.FC<YourPlanFreeDetailsComponentProps> = ({freeSeatsNumber}) => {
    return (
        <div className={styles.WrapperFree}>
            <div className={styles.CurrentPlanWrapper}>
                <div className={styles.PlanTitle}>Your plan:</div>
                <div className={styles.Plan}>Free</div>
                <div className={styles.Seats}>{freeSeatsNumber} available seats</div>
            </div>
            <div>
                <div className={styles.Subtitle}>COMPANY ACCOUNT</div>
                <div className={styles.CompanyBranding}>
                    <Image className={styles.Image} src={checkMarkImg}/>
                    <div>Company Branding</div>
                </div>
                <div className={styles.Subtitle}>STREAMLINE TRAINING:</div>
                {streamlineList.map((item:any, id:number) => {
                    return (
                        <div className={styles.TrainingItem} key={id}>
                            <Image className={styles.Image} src={checkMarkImg}/>
                            <div>{item.name}</div>
                        </div>
                    )})}
            </div>
        </div>
    );
};

export default YourPlanFreeDetailsComponent;

import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.reports;

const makeSelectIsEmptyReportsData = () => createSelector(selectGlobal, (globalState:any) => globalState.isEmptyReportsData);

const makeSelectReportsList = () => createSelector(selectGlobal, (globalState:any) => globalState.reportsList);

const makeSelectFiltersType = () => createSelector(selectGlobal, (globalState:any) => globalState.filterTypeList);

const makeSelectFiltersLearners = () => createSelector(selectGlobal, (globalState:any) => globalState.filterLearnersList);

const makeSelectSortedLearners = () => createSelector(selectGlobal, (globalState:any) => globalState.sortedLearnersList);

const makeSelectFilterModule = () => createSelector(selectGlobal, (globalState:any) => globalState.filterModuleList);

const makeSelectFilterCourse = () => createSelector(selectGlobal, (globalState:any) => globalState.filterCourseList);

const makeSelectFilterStatus = () => createSelector(selectGlobal, (globalState:any) => globalState.filterStatusList);

const makeSelectTotalReportPages = () => createSelector(selectGlobal, (globalState:any) => globalState.totalReportPages);

const makeSelectQuizDetails = () => createSelector(selectGlobal, (globalState:any) => globalState.quizDetails);

const makeSelectTaskDetails = () => createSelector(selectGlobal, (globalState:any) => globalState.taskDetails);

const makeSelectFilteredQuizDetails = () => createSelector(selectGlobal, (globalState:any) => globalState.filteredQuizDetails);

export {
    makeSelectIsEmptyReportsData,
    makeSelectReportsList,
    makeSelectFiltersType,
    makeSelectFiltersLearners,
    makeSelectSortedLearners,
    makeSelectFilterModule,
    makeSelectFilterCourse,
    makeSelectFilterStatus,
    makeSelectTotalReportPages,
    makeSelectQuizDetails,
    makeSelectTaskDetails,
    makeSelectFilteredQuizDetails
};

import React, { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./TaskPopupComponent.module.scss";
import PopupFullPage from "../../../../../../../components/Popup/PopupFullPage/PopupFullPage";
import TextArea from "../../../../../../../components/TextArea/TextArea";
import ButtonColor from "../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import { dispatch } from "../../../../../../../state/store";
import { setDraftModule } from "../../../../../../../state/ducks/courseConstructor";
import { updateCourseModuleList } from "../../../draftCourseService";
import moduleService from "../../../../../../../services/server/moduleService/moduleService";

const TaskPopupComponent = (props: any) => {
  const { hideTaskPopup, task, draftModule } = props;

  const [taskName, setTaskName] = useState(task.name);
  const [taskDescription, setTaskDescription] = useState(task.description);

  const updateDraftModule = (updatedModule: any) => {
    dispatch(setDraftModule(updatedModule));
    updateCourseModuleList(updatedModule);
  };

  const onTaskNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTaskName(event.currentTarget.value);
    updateDraftModule({
      ...draftModule,
      task: { ...task, name: event.currentTarget.value },
    });
  };

  const onTaskDescriptionChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setTaskDescription(event.currentTarget.value);
    updateDraftModule({
      ...draftModule,
      task: { ...task, description: event.currentTarget.value },
    });
  };

  useEffect(() => {
    updateTask();
  }, [taskName, taskDescription]);

  const updateTask = async () => {
    await moduleService.updateTask(task.id, taskName, taskDescription);
  };

  const onSaveButtonClick = () => {
    hideTaskPopup();
  };

  return (
    <PopupFullPage
      classNameMainContainer={styles.MainPopupContainer}
      closePopup={hideTaskPopup}
      isSupportOutsidePopupClick
    >
      <span className={styles.TitleText}>Add a task</span>
      <div className={styles.InputContainer}>
        <span className={styles.InputLabel}>Give it a name</span>
        <TextArea
          value={taskName}
          className={classNames(styles.InputNameField, styles.TextArea)}
          onChange={onTaskNameChange}
          placeholder="Present the strategy for the battle of Winterfell"
        />
        <span className={styles.InputLabel}>Description</span>
        <TextArea
          value={taskDescription}
          className={styles.TextArea}
          onChange={onTaskDescriptionChange}
          placeholder="You can take dragons and Dothraki as your striking force"
        />
        <span className={styles.InformationText}>
          Users will be able to attach files or links
        </span>
      </div>
      <ButtonColor className={styles.SaveButton} onClick={onSaveButtonClick}>
        Done
      </ButtonColor>
    </PopupFullPage>
  );
};

export default TaskPopupComponent;


const rescaleImage = (url:string, fileType:string, fun:Function) => {

    let img = new Image();
    img.onload = () => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        let resizingParam = img.naturalHeight;        // assume the height is the lowest parameter
        if (img.naturalHeight > img.naturalWidth) {   // than check what is actually the lowest parameter
            resizingParam = img.naturalWidth;
        }

        const x = Math.ceil((resizingParam - img.naturalWidth)*0.5),
            y = Math.ceil((resizingParam - img.naturalHeight)*0.5);

        canvas.height = canvas.width = resizingParam;

        //@ts-ignore
        ctx.drawImage(img, x, y);

        try {
            fun(canvas.toDataURL(fileType));
        } catch (e) {
            fun(url);
        }
    };
    img.src = url;
}

export default  {
    rescaleImage
}
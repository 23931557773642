import React, {useEffect, useRef, useState} from 'react';

import styles from './ConfigureMetrics.module.scss';
import {ReactComponent as GearBoxImg} from '../../../../../../../../../../../../../../assets/images/icon/gearbox-icon.svg';
import Input from '../../../../../../../../../../../../../components/Input/Input';
import Svg from '../../../../../../../../../../../../../components/SvgComponent/SvgComponent';
import MenuComponent from './components/MenuComponent/MenuComponent';
import {createStructuredSelector} from 'reselect';
import {makeSelectMetricsListByPipedrive} from '../../../../../../../../../../../../../state/selectors/integrations';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {dispatch} from '../../../../../../../../../../../../../state/store';
import {
    setMetricsListByPipedrive
} from '../../../../../../../../../../../../../state/ducks/integrations';
import {
    metricsListByPipedrive,
    stageConversionsType
} from '../../../../../../../../../../../../../types/integrationType/dataForPipedrive';
import SubMenuComponent from './components/MenuComponent/components/SubMenuComponent/SubMenuComponent';
import {useOutsideListener} from "../../../../../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = () => createStructuredSelector({
    metricsListByPipedrive: makeSelectMetricsListByPipedrive()
});

type ConfigureMetricsProps = {
    metricsListByPipedrive: Array<metricsListByPipedrive>
};

const ConfigureMetrics:React.FC<ConfigureMetricsProps> = ({metricsListByPipedrive}) => {
    const [isShowPipeLineMenu, setIsShowPipeLineMenu] = useState(false);
    const [isShowStagesConversions, setIsShowStagesConversions] = useState(false);
    const [pipelineId, setPipelineId] = useState<number>();
    const [stageConversions, setStageConversions] = useState<Array<stageConversionsType>>([]);
    const [isSelectedConversionRates, setIsSelectedConversionRates] = useState();

    const menuRef = useRef<HTMLDivElement>(null);
    const gearBoxRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        metricsListByPipedrive.filter((metric:any) => {
            if(metric.metricType.keyWord === 'CONVERSION_RATES') {
                setIsSelectedConversionRates(metric.isSelected);
            }
        });

    }, [metricsListByPipedrive]);

    useEffect(() => {
        if (!isSelectedConversionRates) {
            setIsShowPipeLineMenu(false);
            setIsShowStagesConversions(false);
        }
    },[isSelectedConversionRates]);

    const metricSelectedChange = (metricName:number) => {
        const updatedMetricsList = metricsListByPipedrive.filter((metric:any) => {
            if(metric.metricType.keyWord === metricName) {
                metric.isSelected = !metric.isSelected;

                if(metric.metricType.keyWord === 'CONVERSION_RATES') {
                    setIsShowPipeLineMenu(!isShowPipeLineMenu);
                    setIsSelectedConversionRates(metric.isSelected);
                }

            }
            return metric;
        });

        dispatch(setMetricsListByPipedrive(updatedMetricsList));
    };

    const closeDropDownList = () => {
        setIsShowPipeLineMenu(false);
        setIsShowStagesConversions(false);
    };

    useOutsideListener(menuRef, gearBoxRef, closeDropDownList);

    const onClickShowSubMenu = (id:number, stageConversions: Array<stageConversionsType>) => {
        setIsShowStagesConversions(true);
        setIsShowPipeLineMenu(false);
        setStageConversions(stageConversions);
        setPipelineId(id);
    };

    return (
        <div className={styles.Container}>
            <div className={classNames(styles.SubContainer, isShowPipeLineMenu && styles.BlockScroll)}>
                {metricsListByPipedrive.map((item) => {
                    const isSelected = item.isSelected;
                    const metricType:any = item.metricType;
                    const pipelines = item.pipelines;
                    const name = metricType.name;
                    const description = metricType.description;
                    const keyWord = metricType.keyWord;

                    return (
                        <div className={styles.WrapperMetricItem} key={name}>
                            <div className={styles.WrapperInput}>
                                <div className={classNames(styles.WrapperMetricImg, pipelines && styles.WrapperConversionRates)}>
                                    <div className={styles.InputAndMetrics}>
                                        <Input
                                            className={styles.Input}
                                            type='checkbox'
                                            checked={isSelected}
                                            onChange={() => metricSelectedChange(keyWord)}
                                        />
                                        <span className={styles.Metric}>{name}</span>
                                    </div>
                                </div>
                                <div className={styles.Description}>{description}</div>
                            </div>
                            {pipelines &&
                                <>
                                    <div className={styles.WrapperImage}>
                                        {isSelectedConversionRates &&
                                            <div ref={gearBoxRef}>
                                                <Svg className={styles.Image} onClick={() => setIsShowPipeLineMenu(!isShowPipeLineMenu)}>
                                                    <GearBoxImg/>
                                                </Svg>
                                            </div>
                                        }
                                        {isShowPipeLineMenu &&
                                            <div ref={menuRef}>
                                                <MenuComponent
                                                    pipelines={pipelines}
                                                    pipelineId={pipelineId}
                                                    isShowStagesConversions={isShowStagesConversions}
                                                    onClickShowSubMenu={onClickShowSubMenu}
                                                />
                                            </div>
                                        }
                                        {isShowStagesConversions &&
                                            <div ref={menuRef}>
                                                <SubMenuComponent
                                                    setIsShowStagesConversions={setIsShowStagesConversions}
                                                    setIsShowPipeLineMenu={setIsShowPipeLineMenu}
                                                    stageConversions={stageConversions}
                                                    pipelines={pipelines}
                                                    pipelineId={pipelineId}
                                                />
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(ConfigureMetrics);

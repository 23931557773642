export enum Role {
  CREATOR = "CREATOR",
  CONTRIBUTOR = "CONTRIBUTOR",
}

export type UserDetails = {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: Role[];
  companyId?: number;
  companyName?: string;
  isPipedriveConnected: boolean;
  isPipedriveAllowed: boolean;
  isIntroNeeded: boolean;
  isNlp: undefined | boolean;
  isPerformanceReportConfigured: boolean;
};

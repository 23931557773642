import React, {useState} from 'react';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';
import {withCookies} from 'react-cookie';

import styles from './VerifyBlockComponent.module.scss';
import ButtonColor from '../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import verifyService from '../../../../../../../services/server/verifyService/verifyService';
import {createStructuredSelector} from 'reselect';
import {makeSelectUserDetails} from '../../../../../../../state/selectors/userDetails';
import {connect} from 'react-redux';
import Image from '../../../../../../../components/Image/Image';
import checkImg from '../../../../../../../../assets/images/icon/checked-mark-green-icon.svg';

const mapStateToProps = createStructuredSelector({
    userDetails: makeSelectUserDetails()
});

const VerifyBlockComponent = (props:any) => {
    const {userDetails, cookies} = props;

    const [loader, setLoader] = useState<boolean>(false);
    const [isFirstClick, setIsFirstClick] = useState<boolean>(false);
    const [isNextClick, setIsNextClick] = useState<boolean>(false);
    const cookiesIsSendEmail:boolean = cookies.cookies['verify-plash'] === 'true';

    const onClickVerify = async () => {
        if (cookiesIsSendEmail) {
            setIsFirstClick(false);
            setIsNextClick(true);
        } else {
            setLoader(true);
            verifyService.resendVerification(userDetails.email)
                .then(() => {
                    cookies.set(
                        'verify-plash',
                        true,
                        {maxAge: 15 * 60}
                    );
                    setIsFirstClick(true);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    };

    return (
        <div className={styles.Container}>
            <div className={styles.WrapperVerifyBlock}>
                <div className={styles.TextVerify}>Please verify your email</div>
                <ButtonColor
                    className={classNames(styles.VerifyButton, loader && styles.LoaderButton)}
                    onClick={onClickVerify}
                >
                    <span>Resend verification link</span>
                    {loader &&
                        <div className={styles.Loader}>
                            <Loader
                                type="TailSpin"
                                color="#8C8C8C"
                                height={14}
                                width={14}
                            />
                        </div>
                    }
                </ButtonColor>

                {(cookiesIsSendEmail && isFirstClick) &&
                    <div className={styles.EmailSendWrapper}>
                        <Image src={checkImg} className={styles.Image}/>
                        <span>Email sent</span>
                    </div>
                }

                {(cookiesIsSendEmail && isNextClick) &&
                    <div className={styles.EmailSendWrapper}>
                        <Image src={checkImg} className={styles.Image}/>
                        <span>Email already sent</span>
                    </div>
                }


            </div>
        </div>
    );
};

export default withCookies(connect(mapStateToProps)(VerifyBlockComponent));

import React from 'react';

import styles from './TemplatesComponent.module.scss';
import plusAddImg from '../../../../../../../../../../assets/images/icon/plus-red-icon.svg';
import replyImg from '../../../../../../../../../../assets/images/logo/reply.png';
import templateImg from '../../../../../../../../../../assets/images/icon/template-icon.svg';
import trainingImg from '../../../../../../../../../../assets/images/icon/training-expert-icon.svg';
import bookImg from '../../../../../../../../../../assets/images/emoji/books_1f4da.png';
import rocketImg from '../../../../../../../../../../assets/images/emoji/rocket_1f680.png';
import CreateNewCourseComponent from '../CreateNewCourseComponent/CreateNewCourseComponent';
import Image from '../../../../../../../../../components/Image/Image';
import {createStructuredSelector} from 'reselect';
import {makeSelectTemplatesList} from '../../../../../../../../../state/selectors/admin';
import {connect} from 'react-redux';
import {dispatch} from '../../../../../../../../../state/store';
import {setDraftCourse} from '../../../../../../../../../state/ducks/courseConstructor';
import sessionStorageService from '../../../../../../../../../services/storage/sessionStorageService';
import appRouterService from '../../../../../../../../../services/route/appRouterService';
import courseService from '../../../../../../../../../services/server/courseService/courseService';
import {loadCourseById} from '../../../../../../../../CourseConstructorPage/components/CourseConstructorComponent/draftCourseService';

const mapStateToProps = createStructuredSelector({
    templatesList: makeSelectTemplatesList(),
});

const TemplatesComponent = (props:any) => {
    const {hideCreateNewCourseComponent, isVisibleCreateCourseComponent, onCreateNewCourseClick,
        templatesList} = props;

    const onTemplateClick = async (templateId:number) => {
        const { id } = await courseService.setUseTemplate(templateId);

        const newDraftCourse:any = await loadCourseById(id);
        sessionStorageService.setDraftCourseId(newDraftCourse.id);

        dispatch(setDraftCourse(newDraftCourse));
        appRouterService.forwardToCourseConstructor();
    };

    return (
        <>
            {!isVisibleCreateCourseComponent &&
            <div className={styles.WrapperTemplatesComponent}>
                <div className={styles.MainTitle}>
                    <span>Let’s start creating your course</span>
                    <Image src={rocketImg} className={styles.RocketImg}/>
                </div>
                <div className={styles.TemplateList}>
                    <div
                        className={styles.AddNewCourseContainer}
                        onClick={() => onCreateNewCourseClick()}
                    >
                        <Image className={styles.ImagePlus} src={plusAddImg}/>
                        <div className={styles.TitleAddCourse}>Start from scratch</div>
                    </div>
                    <a className={styles.Link} href='https://pjbfdkis0pq.typeform.com/to/AfcszbnJ' target='_blank'>
                        <div className={styles.WrapperTrainingExpert}>
                            <div className={styles.TrainingItem}>
                                <Image src={trainingImg} className={styles.ImgTraining}/>
                                <div className={styles.TrainingText}>Need a hand?</div>
                                <div className={styles.SubtitleText}>Connect with training expert</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className={styles.WrapperForTemplates}>
                    <div className={styles.TitleTemplate}>
                        <span>Library</span>
                        <Image src={bookImg} className={styles.ImageBook}/>
                    </div>
                    {templatesList.map((item:any) => {
                        const templateId = item.courseId;
                        const titleTemplate = item.name;

                        return (
                            <div key={templateId} className={styles.TemplateListItem}
                                 onClick={() => onTemplateClick(templateId)}>
                                <div className={styles.TemplateContainer}>
                                    <div className={styles.WrapperForTemplateIcon}>
                                        <Image src={templateImg} className={styles.TemplateImg}/>
                                        <span>Template</span>
                                    </div>
                                    <span className={styles.TemplateText}>{titleTemplate}</span>
                                </div>
                                <div className={styles.SignatureContainer}>
                                <span className={styles.SignatureText}>
                                    by
                                </span>
                                    <Image src={replyImg} className={styles.Logo} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            }

            {isVisibleCreateCourseComponent && (
                <CreateNewCourseComponent
                    hideCreateNewCourseComponent={hideCreateNewCourseComponent}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(TemplatesComponent);
import { useState } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import styles from "./CompanyOverview.module.scss";
import clockImg from "../../../../../../../../../../assets/images/icon/hourglass-icon.svg";
import timeIcon from "../../../../../../../../../../assets/images/icon/time-icon.svg";
import multiplyImg from "../../../../../../../../../../assets/images/icon/multiply-icon.svg";
import ratingImg from "../../../../../../../../../../assets/images/icon/rating-icon.svg";
import equalsImg from "../../../../../../../../../../assets/images/icon/equals-icon.svg";
import Image from "../../../../../../../../../components/Image/Image";
import AnalyticsDetails from "./components/AnalyticsDetails";
import Input from "../../../../../../../../../components/Input/Input";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectOverviewList } from "../../../../../../../../../state/selectors/analytics";

const mapStateToProps = createStructuredSelector({
  overviewList: makeSelectOverviewList(),
});

const CompanyOverview = (props: any) => {
  const { overviewList } = props;

  const [inputValue, seyInputValue] = useState(27);
  const result = Math.round(inputValue * (overviewList?.totalSavedHours || 0));

  const onChangeInput = (event: any) => {
    const { value } = event.currentTarget;
    seyInputValue(value);
  };

  return (
    <div>
      <div className={styles.TitleAnalytics}> Company Overview </div>
      <div className={styles.WrapperForComponentsAnalytics}>
        <div className={styles.Analytics}>
          <AnalyticsDetails
            imageSrc={clockImg}
            textAnalytics="Total learning hours"
            resultNumber={overviewList?.totalLearningHours}
            classNameText={styles.TextLearning}
            classNameMargin={styles.AnalyticsComponentsLearning}
          />
          <AnalyticsDetails
            imageSrc={ratingImg}
            textAnalytics="Succesfully finished training:"
            resultNumber={overviewList?.successfullyFinishedCourses}
            classNameText={styles.TextCourse}
            classNameMargin={styles.AnalyticsComponentsCourses}
          />
          <AnalyticsDetails
            imageSrc={timeIcon}
            textAnalytics="Total hours saved:"
            resultNumber={overviewList?.totalSavedHours}
            classNameText={styles.TextHoursSaved}
            classNameMargin={styles.AnalyticsComponentsHours}
          />
        </div>
        <div className={styles.WrapperMoneySaved}>
          <div className={styles.WrapperDetails}>
            <span className={styles.TextMoney}>Money saved:</span>
            <div
              className={classNames(
                styles.ContainerResults,
                styles.TextNumberMarginPerHour
              )}
            >
              <Input
                value={inputValue}
                onChange={onChangeInput}
                className={styles.TextNumberInput}
                type="number"
              />
              <span className={styles.Subtitle}>Rate per hour, $</span>
            </div>
            <Image src={multiplyImg} />
            <div
              className={classNames(
                styles.ContainerResults,
                styles.TextNumberMarginHoursSaved
              )}
            >
              <div className={classNames(styles.TextNumber, styles.TextHours)}>
                {overviewList?.totalSavedHours || 0}
              </div>
              <span className={styles.Subtitle}>Hours saved</span>
            </div>
            <Image src={equalsImg} />
            <span data-tip={`$${result}`} className={styles.Result}>
              ${result}
            </span>
            <ReactTooltip effect="solid" className={styles.Tooltip} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(CompanyOverview);

import React, { useCallback } from "react";
import styles from "../../../PerformanceGapsComponent/components/FiltersGapsComponent/FiltersGapsComponent.module.scss";
import FilterMetricComponent from "../../../PerformanceGapsComponent/components/FiltersGapsComponent/components/FilterMetricsComponent/FilterMetricComponent";
import ButtonColor from "../../../../../../../../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import { Filter } from "../../../../../../../../../../../../../../../../../../types/filter/Filter";

type FiltersGridComponentProps = {
  allLearnerFilters: Filter[];
  chosenLearnerFilters: Filter[];
  setChoseLearnerFilters: Function;

  allMetricFilters: Filter[];
  chosenMetricFilters: Filter[];
  setChosenMetricFilters: Function;
};

const FiltersGridComponent = ({
  allLearnerFilters,
  chosenLearnerFilters,
  setChoseLearnerFilters,
  allMetricFilters,
  chosenMetricFilters,
  setChosenMetricFilters,
}: FiltersGridComponentProps) => {
  const handleResetFilters = useCallback(() => {
    setChosenMetricFilters([]);
    setChoseLearnerFilters([]);
  }, [setChosenMetricFilters, setChoseLearnerFilters]);

  return (
    <div className={styles.Container}>
      <FilterMetricComponent
        classNameContainer={styles.FilterContainerItem}
        textTitle="User"
        classNameDropDown={styles.DropDownContainer}
        classNameDropDownMenu={styles.DropDownMenuContainer}
        listOptions={chosenLearnerFilters}
        sortedListOptions={allLearnerFilters}
        setListOptions={setChoseLearnerFilters}
      />
      <FilterMetricComponent
        classNameContainer={styles.FilterContainerItem}
        textTitle="Metric"
        classNameDropDown={styles.DropDownContainer}
        classNameDropDownMenu={styles.DropDownMenuContainer}
        listOptions={chosenMetricFilters}
        sortedListOptions={allMetricFilters}
        setListOptions={setChosenMetricFilters}
      />
      <ButtonColor className={styles.ResetBtn} onClick={handleResetFilters}>
        Reset filters
      </ButtonColor>
    </div>
  );
};

export default FiltersGridComponent;

import React from 'react';

const AccountSettingsComponent = () => {
    return (
        <div>

        </div>
    )
};

export default AccountSettingsComponent;
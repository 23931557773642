import React from 'react';

import styles from './InputLinkComponent.module.scss';
import youtubeImg from '../../../../../../../../../../assets/images/icon/youtube-button-icon.svg';
import Input from '../../../../../../../../../components/Input/Input';
import Image from '../../../../../../../../../components/Image/Image';
import routerService from '../../../../../../../../../services/route/routerService';

const InputLinkComponent = (props:any) => {
    const {onYouTubeLinkChange, link, reference, isInputError} = props;
    
    const openYouTubeButtonClick = () => {
        routerService.blankLinkOpen('https://www.youtube.com');
    };
    
    return (
        <div className={styles.InputContainer}>
            <Input
                type='text'
                className={`${styles.InputField} ${isInputError && styles.InputError}`}
                placeholder='Link to YouTube video'
                onChange={onYouTubeLinkChange}
                value={link}
                reference={reference}
            />
            <div className={styles.OpenVideoButton} onClick={openYouTubeButtonClick}>
                <Image src={youtubeImg} className={styles.YouTubeIcon} />
                Open YouTube
            </div>
        </div>
    )
};

export default InputLinkComponent;
import urlUtils from "../../../services/utils/urlUtils";
import pipedriveApiService from "./pipedriveApiService";
import appRouterService from "../../../services/route/appRouterService";
import {useEffect} from 'react';
import {dispatch} from '../../../state/store';
import {setActiveTab, setIsAppLoader} from '../../../state/ducks/global';
import {availableTabList} from '../../ConsumerAccountPage/AdminAccountPage/components/MenuListComponent/menuTabList';
import {setIsVisibleConfiguration} from '../../../state/ducks/integrations';
import appLoadingService from '../../../services/server/appLoadingService/appLoadingService';
import {setUserDetails} from '../../../state/ducks/userDetails';

export const PipedriveValidateComponent = () => {

    const [code] = urlUtils.getUrlParams(['code']);

    useEffect(() => {
        if (!!code) {
            pipedriveApiService.validateOauthCode(code)
                .then(() => {
                    dispatch(setIsAppLoader(true));
                    appLoadingService.userDetailsLoading().then((userDetails) => {
                        if (userDetails) {
                            dispatch(setUserDetails(userDetails));
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                    appRouterService.forwardToAdminAccountPage();
                    dispatch(setActiveTab(availableTabList[4]));
                    dispatch(setIsVisibleConfiguration(true));
                    dispatch(setIsAppLoader(false));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },[])

    return null;
};

import React from "react";

import styles from "./GridHeaderComponent.module.scss";
import GridItemComponent from "./components/GridItemComponent/GridItemComponent";
import { PerformanceHeatMap } from "../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceHeatMap";

interface GridHeaderComponentProps {
  performanceGrid: PerformanceHeatMap | undefined;
}

const GridHeaderComponent = ({ performanceGrid }: GridHeaderComponentProps) => {
  const { heatMapResults, metricLabels } = performanceGrid!;

  return (
    <div className={styles.Container}>
      <div className={styles.SubContainer}>
        <div className={styles.WrapperLeanerMetrics}>
          <div className={styles.TitleLearner}>User</div>
          <div className={styles.Header}>
            {metricLabels.map((metricLabel) => (
              <span key={metricLabel}>{metricLabel}</span>
            ))}
          </div>
        </div>
        <GridItemComponent
          metricLabels={metricLabels}
          heatMapResults={heatMapResults}
        />
      </div>
    </div>
  );
};

export default GridHeaderComponent;

import React from 'react';

import styles from './RecordedVideoPreviewComponent.module.scss';
import Modal from '../../../../../../ServiceContainers/Modal/Modal';
import PopupFullPage from '../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import Video from '../../../../../../../components/Video/Video';
import ButtonColor from "../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import {dispatch} from "../../../../../../../state/store";
import {setIsAppLoader} from "../../../../../../../state/ducks/global";
import draftModuleService from "../../draftModuleService";

const RecordedVideoPreviewComponent = (props:any) => {
    const {hideRecordedVideoPreview, flushMediaUrl, draftModule, mediaUrl} = props;

    const completeRecording = () => {
        flushMediaUrl();
        hideRecordedVideoPreview();
    };

    const onDiscardClick = () => {
        completeRecording();
    };

    const onSaveButtonClick = async () => {
        dispatch(setIsAppLoader(true));
        const fetchedData = await fetch(mediaUrl);
        const recordedVideoFile = new File([await fetchedData.blob()], `recorded-screen-file-${Date.now()}.MP4`);

        await draftModuleService.uploadNewMediaContent(recordedVideoFile, "VIDEO", draftModule)
        completeRecording();
        dispatch(setIsAppLoader(false));
    };

    return (
        <Modal>
            <PopupFullPage
                closePopup={completeRecording}
                isSupportOutsidePopupClick
            >
                <div>
                    <span className={styles.RecordVideoPreviewHeader}>
                        Preview recorder video
                    </span>
                </div>
                <div style={{
                    padding: "50px 70px",
                }}>
                <Video
                    src={mediaUrl}
                    classNameVideoElement={styles.RecordedVideoPreviewVideo}/>
                </div>
                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"center",
                    padding: "0px 0px 30px 0px",
                }}>
                <ButtonColor
                    isFilled={false}
                    className={styles.RecordedVideoPreviewButton}
                    onClick={onDiscardClick}
                >
                    Discard
                </ButtonColor>
                <ButtonColor
                    className={styles.RecordedVideoPreviewButton}
                    onClick={onSaveButtonClick}
                >
                    Save
                </ButtonColor>
                </div>
            </PopupFullPage>
        </Modal>
    );
}

export default RecordedVideoPreviewComponent;
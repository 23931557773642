export const getSupportList = [
    {
        id: 1,
        name: 'Priority live chat support',
    },
    {
        id: 2,
        name: 'Video support',
    },
    {
        id: 3,
        name: '4 hours of product training',
    }
];

export default getSupportList;
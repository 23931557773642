import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Loader from "react-loader-spinner";

import styles from "./LearnerSearch.module.scss";
import searchImg from "../../../../../../../../../../assets/images/icon/search-magnifier.svg";
import closeImg from "../../../../../../../../../../assets/images/icon/close-icon.svg";
import Image from "../../../../../../../../../components/Image/Image";
import Input from "../../../../../../../../../components/Input/Input";
import OptionsComponent from "../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent";
import { connect } from "react-redux";
import { dispatch } from "../../../../../../../../../state/store";
import { createStructuredSelector } from "reselect";
import {
  makeSelectFiltersLearners,
  makeSelectSortedLearners,
} from "../../../../../../../../../state/selectors/reports";
import { setSortedLearnersList } from "../../../../../../../../../state/ducks/reports";
import textData from "../../../../../../../../../consts/textData/textData.json";
import reportsService from "../../../../../../../../../services/server/reportsService/reportsService";
import { useOutsideListener } from "../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
  sortedLearnersList: makeSelectSortedLearners(),
  filterLearnersList: makeSelectFiltersLearners(),
});

const LearnerSearch = (props: any) => {
  const {
    classNameContainer,
    classNameDropDownMenu,
    classNameDropDownOverflow,
    sortedLearnersList,
    filterLearnersList,
    learnerFilter,
    onLearnerClick,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [searchString, setSearchString] = useState(learnerFilter.name);
  const [loader, setLoader] = useState(false);
  const filterNameRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const searchedName = learnerFilter.name;

    setSearchString(searchedName);
    filterSearchInput(searchedName);
  }, [learnerFilter]);

  const closeDropDownList = () => setIsOpenDropDown(false);
  useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

  const openDropDownList = async () => {
    setIsOpenDropDown(!isOpenDropDown);
    await reportsService.getFilterReportsLearner();
    setLoader(true);
  };

  const filterSearchInput = (nameToFilter: string) => {
    const filterListName = filterLearnersList.filter((item: any) => {
      return item.name
        .toLowerCase()
        .includes(nameToFilter.toLowerCase().trim());
    });

    dispatch(setSortedLearnersList(filterListName));
  };

  const onChangeInput = (event: any) => {
    const { value } = event.currentTarget;
    filterSearchInput(value);

    setSearchString(value);
  };

  const onClickNameList = (learnerName: string, learnerId: number) => {
    setSearchString(learnerName);
    onLearnerClick(learnerName, learnerId);
    closeDropDownList();
  };

  const onClearInput = () => {
    setSearchString("");
    dispatch(setSortedLearnersList(filterLearnersList));
  };

  return (
    <div className={classNameContainer}>
      <span className={styles.TitleFilter}>User</span>
      <div
        className={styles.InputContainer}
        onClick={openDropDownList}
        ref={selectButtonRef}
      >
        <Input
          type="text"
          className={classNames(
            styles.Input,
            isOpenDropDown && styles.ActiveDropDown
          )}
          onChange={onChangeInput}
          value={searchString}
          placeholder={
            isOpenDropDown
              ? "Select"
              : textData.INPUT_PLACEHOLDER_TEXT_FOR_ONBOARDEE_SEARCH
          }
        />
        <Image
          src={searchString.length ? closeImg : searchImg}
          className={classNames(
            styles.Image,
            searchString.length && styles.ImageClose
          )}
          onClick={onClearInput}
        />
        {isOpenDropDown && (
          <div className={classNameDropDownMenu} ref={dropDownRef}>
            <div className={classNameDropDownOverflow}>
              {loader ? (
                sortedLearnersList.length ? (
                  sortedLearnersList?.map((item: any) => {
                    const learnerName = item.name;
                    const learnerId = item.id;
                    const isActiveLearner = item.id === learnerFilter.id;

                    return (
                      <OptionsComponent
                        className={styles.OptionsText}
                        classNameWraperOptions={styles.OptionsWrapper}
                        isActiveOptions={isActiveLearner}
                        reference={filterNameRef}
                        optionText={learnerName}
                        key={learnerId}
                        onClick={() => onClickNameList(learnerName, learnerId)}
                        classNameShowText={learnerName?.length > 17 && true}
                      />
                    );
                  })
                ) : (
                  <div className={styles.InputNotFound}>Not found user</div>
                )
              ) : (
                <div className={styles.WrapperForLoader}>
                  <Loader
                    type="TailSpin"
                    color="#F85A40"
                    height={25}
                    width={25}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(LearnerSearch);

import {PerformanceReportsInterface} from '../interfaces/performanceReportsInterface';
import {PerformanceReport} from '../../types/performanceReportsType/performanceReports';

export const SET_PERFORMANCE_REPORTS= 'app/performance/SET_PERFORMANCE_REPORTS';
export const SET_SORTED_PERFORMANCE_REPORTS= 'app/performance/SET_SORTED_PERFORMANCE_REPORTS';

const initialState:PerformanceReportsInterface = {
    sortedPerformanceReports: []
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_SORTED_PERFORMANCE_REPORTS: {
            return {...state, sortedPerformanceReports: action.payload};
        }
        default: return state;
    }
};

export const setSortedPerformanceReports = (value:Array<PerformanceReport>) => ({type: SET_SORTED_PERFORMANCE_REPORTS, payload: value});

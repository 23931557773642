import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Loader from "react-loader-spinner";

import styles from "./CourseFilterComponent.module.scss";
import topDropDownImg from "../../../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg";
import bottomDropDownImg from "../../../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg";
import Image from "../../../../../../../../../../../components/Image/Image";
import OptionsComponent from "../../../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectCourseList } from "../../../../../../../../../../../state/selectors/analytics";
import analyticsService from "../../../../../../../../../../../services/server/analyticsService/analyticsService";
import { useOutsideListener } from "../../../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
  filterCourseList: makeSelectCourseList(),
});

const CourseFilterComponent = (props: any) => {
  const {
    filterCourseList,
    filterCourse,
    classNameDropDownContainer,
    classNameDropDownMenu,
    classNameWrapperComponent,
    classNameDropDownOverflow,
    classNameTitle,
    onClickCourse,
    classNameOptionsWrapper,
    classNameOpenDropDown,
    setFilterModule,
    setTypeFilter,
    filterType,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(filterCourse.course);
  const [loader, setLoader] = useState(false);
  const [sortedListOption, setSortedListOption] = useState([]);
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSortedListOption(
      filterCourseList.sort((a: any, b: any) => (a?.id < b?.id ? -1 : 1))
    );
  }, []);

  useEffect(() => {
    setDropDownValue(filterCourse.course);
  }, [filterCourse]);

  const openDropDownList = async () => {
    setIsOpenDropDown(!isOpenDropDown);
    await analyticsService.getFilteredListCourse();
    setLoader(true);
  };

  const closeDropDownList = () => setIsOpenDropDown(false);
  useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

  const onCourseFilterButtonClick = async (
    filterCourseName: string,
    filterCourseId: number
  ) => {
    setDropDownValue(filterCourseName);
    onClickCourse(filterCourseName, filterCourseId);
    setFilterModule("All modules", -1);
    Number(filterCourseId) === -1 && setTypeFilter("Learning time", -1);
    closeDropDownList();
  };

  return (
    <div className={classNameWrapperComponent}>
      <span className={classNameTitle}>Training</span>
      <div
        className={classNames(
          classNameDropDownContainer,
          isOpenDropDown && classNameOpenDropDown
        )}
        onClick={openDropDownList}
        ref={selectButtonRef}
      >
        <span className={styles.Text}>
          {isOpenDropDown ? "Select" : dropDownValue}
        </span>
        <Image
          src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
          className={classNames(
            styles.DropDownIcon,
            isOpenDropDown && styles.isActiveDropDownIcon
          )}
        />
      </div>
      {isOpenDropDown && (
        <div className={classNameDropDownMenu} ref={dropDownRef}>
          <div className={classNameDropDownOverflow}>
            {loader ? (
              sortedListOption?.map((item: any) => {
                const filterCourseName = item.name;
                const filterCourseId = item.id;
                const isActiveOptions = item.id === filterCourse.id;
                const isNotVisibleOption =
                  filterType.id === "progress" && filterCourseId === "-1";

                return (
                  <>
                    {!isNotVisibleOption && (
                      <OptionsComponent
                        isActiveOptions={isActiveOptions}
                        classNameOptionsWrapper={classNameOptionsWrapper}
                        classNameShowText={
                          filterCourseName?.length > 17 && true
                        }
                        reference={optionsRef}
                        key={filterCourseId}
                        optionText={filterCourseName}
                        onClick={() =>
                          onCourseFilterButtonClick(
                            filterCourseName,
                            filterCourseId
                          )
                        }
                      />
                    )}
                  </>
                );
              })
            ) : (
              <div className={styles.WrapperForLoader}>
                <Loader
                  type="TailSpin"
                  color="#F85A40"
                  height={25}
                  width={25}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(CourseFilterComponent);

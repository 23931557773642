import React, {useCallback, useEffect, useState} from 'react';
import styles from './PerformanceReports.module.scss';
import loaderStyles from '../../ContentComponent.module.scss';
import CompanyOverviewComponent from './components/CompanyOverviewComponent/CompanyOverviewComponent';
import ReportsDetailsComponent from './components/ReportsDetailsComponent/ReportsDetailsComponent';
import performanceReportsService
    from '../../../../../../../services/server/performanceReportsService/performanceReportsService';
import {PerformanceReport} from "../../../../../../../types/performanceReportsType/performanceReports";
import {UserDetails} from "../../../../../../../types/UserDetails";
import Loader from "../../../../../../../components/Loader/Loader";

interface PerformanceReportsProps {
    userDetails: UserDetails
}

const PerformanceReports = ({userDetails}:PerformanceReportsProps) => {
    const [performanceReports, setPerformanceReports] = useState<PerformanceReport[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [controlTrigger, setControlTrigger] = useState<boolean>(true);

    useEffect(() => {
       performanceReportsService.getPerformanceReports()
           .then(setPerformanceReports)
           .catch(console.error)
           .finally(() => setIsLoading(false));
    }, [controlTrigger]);

    const setReportStatus = useCallback((reportId:number, status:boolean) => setPerformanceReports(
        performanceReports.map(report => {
            if (report.id === reportId) {
                report.checked = status;
            }
            return report;
        })
    ), [performanceReports]);

    return (
        <div className={styles.PerformanceContainer}>
            {/*{false && <CompanyOverviewComponent/>}*/}
            {isLoading
                ? <Loader
                    classNameLogoContainer={loaderStyles.LogoContainer}
                    classNameMainContainer={loaderStyles.MainContainer}s
                />
                : <ReportsDetailsComponent
                    userDetails={userDetails}
                    performanceReports={performanceReports}
                    setControlTrigger={setControlTrigger}
                    setReportStatus={setReportStatus}
                />
            }
        </div>
    );
};

export default PerformanceReports;

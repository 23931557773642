const isValidName = (value:string) => {
    if (value.length <= 2) {
        return false;
    }
    return true;
};

const isValidPhoneNumber = (value:string) => {
    const phoneRegex = /^\+380\d{3}\d{2}\d{2}\d{2}$/;

    return phoneRegex.test(value);
};

const isValidEmail = (value:string) => {
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return emailRegex.test(value);
};

const isValidPassword = (value:any) => {
    if (value?.length <= 5) {
        return false;
    }
    return true;
};

const isValidPhone = (validLength:any, value:any) => {
    if(value.length === validLength) {
        return true;
    }
    return false;
};

const roundInputValue = (value:any, maxValue:number) => {
    const updatedValue = value > maxValue ? maxValue : Math.abs(value)
    !== 0 ? Math.abs(value): '';
    
    return updatedValue;
};

export default {
    isValidName,
    isValidPhoneNumber,
    isValidEmail,
    isValidPassword,
    isValidPhone,
    roundInputValue
}

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useMediaQuery } from 'react-responsive';

import styles from './PaymentComponent.module.scss';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import invalidInputFields from './components/invalidInputFields';
import validationService from '../../../services/server/validationService/validationService';
import subscriptionService from '../../../services/server/subscriptionService/subscriptionService';
import appLoadingService from '../../../services/server/appLoadingService/appLoadingService';
import {dispatch} from '../../../state/store';
import {setIsAppLoader} from '../../../state/ducks/global';

const PaymentComponent = (props:any) => {
    const {setIsVisiblePaymentPopup, seats, paymentInfo, result, subscribeClickBtn,
        onErrorSubscription, setProcessing} = props;

    const stripe = useStripe();
    const elements:any = useElements();
    const [cardComplete, setCardComplete] = useState(false);
    const [billingDetailsName, setBillingDetailsName] = useState({
        name: '',
        isErrorName: false
    });
    const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
    const [isExpiredComplete, setIsExpiredComplete] = useState(false);
    const [isCvcComplete, setIsCvcComplete] = useState(false);
    const [isCardNumberError, setIsCardNumberError] = useState(false);
    const [isExpiredError, setIsExpiredError] = useState(false);
    const [isCvcError, setIsCvcError] = useState(false);

    const isFull = useMediaQuery({ query: '(min-width: 1920px)' });
    const isWxga = useMediaQuery({ query: '(min-width: 1600px)' });
    const isHd = useMediaQuery({ query: '(min-width: 1280px)' });
    const fontSize = isFull ? 18 : isWxga ? 16 : isHd ? 14 : 14;

    const inputStyle = {
        iconColor: '#F85A40',
        color: '#161413',
        fontWeight: '500',
        fontFamily: "Lato-Medium",
        fontSize: `${fontSize}px`,
        fontSmoothing: 'antialiased',
        letterSpacing: 'initial',
        ':-webkit-autofill': {
            color: '#161413',
        },
        '::placeholder': {
            color: '#CBC3C1',
        }
    }

    useEffect(() => {
        if (isCardNumberComplete && isExpiredComplete && isCvcComplete && billingDetailsName.name.length) {
            setCardComplete(true);
        } else {
            setCardComplete(false);
        }
    }, [isCardNumberComplete, isExpiredComplete, isCvcComplete, billingDetailsName.name]);

    const handleSubmit = async (event:any) => {
        setProcessing(true);
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: billingDetailsName.name
            }
        });

        if (payload.error) {
            onErrorSubscription(payload.error);
        } else {
            hidePaymentPopup();
            subscribeClickBtn();

            await subscriptionService.setPay(paymentInfo.priceId, paymentInfo.customerId, payload.paymentMethod.id,
                seats);

            appLoadingService.subscriptionDataLoading()
                .finally(() => {
                    dispatch(setIsAppLoader(false));
                });
        }
        setProcessing(false);
    };

    const hidePaymentPopup = () => {
        setIsVisiblePaymentPopup(false);
    };

    const onChangeBillingDetailsName = (event:any) => {
        setBillingDetailsName({...billingDetailsName, name: event.target.value});
    };

    const onKeyPressBillingDetailsName = (event:any) => {
        const isValidBillingDetailsName = validationService.isValidName(event.target.value);

        if (!isValidBillingDetailsName) {
            setBillingDetailsName({...billingDetailsName, isErrorName: true});
        } else {
            setBillingDetailsName({...billingDetailsName, isErrorName: false});
        }
    };

    const onElementChangeNumber = (event:any) => {
        if(event.complete) {
            setIsCardNumberComplete(true);
            setIsCardNumberError(false);
        } else {
            setIsCardNumberComplete(false);
            setIsCardNumberError(true);
        }
    };

    const onElementChangeExpiry = (event:any) => {
        if(event.complete) {
            setIsExpiredComplete(true);
            setIsExpiredError(false);
        } else {
            setIsExpiredComplete(false);
            setIsExpiredError(true);
        }
    };

    const onElementChangeCvc = (event:any) => {
        if(event.complete) {
            setIsCvcComplete(true);
            setIsCvcError(false)
        } else {
            setIsCvcComplete(false);
            setIsCvcError(true);
        }
    };

    return (
        <PopupFullPage
            classNameMainContainer={styles.MainPopupContainer}
            isSupportOutsidePopupClick
            closePopup={hidePaymentPopup}
            classNameClose={styles.CloseImg}
        >
            <div>
                <div className={styles.Title}>Enter Card Details </div>
                    <div className={styles.WrapperForm}>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor='name' className={styles.SubtitleInput}>
                                    Name of Owner
                                </label>
                                <input
                                    id='name'
                                    type='text'
                                    autoComplete='name'
                                    placeholder='Enter owner name'
                                    required
                                    value={billingDetailsName.name}
                                    onChange={onChangeBillingDetailsName}
                                    onKeyPress={onKeyPressBillingDetailsName}
                                    className={classNames(styles.InputName, billingDetailsName.isErrorName && styles.ErrorName)}
                                />
                            </div>
                            <label className={styles.SubtitleInput} htmlFor='card'>
                                Card number
                            </label>
                                <div className={classNames(styles.WrapperCardElement, isCardNumberError && styles.CardNumberError)}>
                                    <CardNumberElement
                                        id='card'
                                        options={{
                                            style: {
                                                base: inputStyle,
                                                invalid: invalidInputFields
                                            },
                                        }}
                                        onChange={onElementChangeNumber}
                                    />
                                </div>
                            <div className={styles.ContainerExpiryCvc}>
                                <div>
                                    <label className={styles.SubtitleInput} htmlFor='expiry'>
                                        Expires
                                    </label>
                                    <div className={classNames(styles.WrapperCardElementExpiryCvc, isExpiredError && styles.ExpiredError)}>
                                        <CardExpiryElement
                                            id='expiry'
                                            options={{
                                                style: {
                                                    base: inputStyle,
                                                },
                                            }}
                                            onChange={onElementChangeExpiry}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className={styles.SubtitleInput} htmlFor='cvc'>
                                        CVC
                                    </label>
                                    <div className={classNames(styles.WrapperCardElementExpiryCvc, isCvcError && styles.CvcError)}>
                                        <CardCvcElement
                                            id='cvc'
                                            options={{
                                                style: {
                                                    base: inputStyle,
                                                },
                                            }}
                                            onChange={onElementChangeCvc}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                className={classNames(styles.SubmitButton, !cardComplete && styles.SubmitButtonError)}
                                type="submit"
                                disabled={!cardComplete && true}
                            >
                                Pay ${result} & Subscribe
                            </button>
                        </form>
                    </div>
                </div>
        </PopupFullPage>
    );
};

export default PaymentComponent;
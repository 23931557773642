import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.customBranding;

const makeSelectBrandColor = () => createSelector(selectGlobal, (globalState:any) => globalState.brandColor);

const makeSelectBrandLogo = () => createSelector(selectGlobal, (globalState:any) => globalState.brandLogo);

const makeSelectIsPhoneInvitation = () => createSelector(selectGlobal, (globalState:any) => globalState.isPhoneInvitation);

export {
    makeSelectBrandColor,
    makeSelectBrandLogo,
    makeSelectIsPhoneInvitation
};

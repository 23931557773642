import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import styles from './CourseModulesComponent.module.scss';
import plusImg from '../../../../../../../../../../../../assets/images/icon/add-module-button-icon.svg';
import newStepImg from '../../../../../../../../../../../../assets/images/icon/create-course-new-step-icon.svg';
import ModuleItemComponent from './components/ModuleItemComponent/ModuleItemComponent';
import ButtonText from '../../../../../../../../../../../components/Button/components/ButtonText/ButtonText';
import Image from '../../../../../../../../../../../components/Image/Image';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import {makeSelectDraftCourse} from '../../../../../../../../../../../state/selectors/courseConstructor';
import {createStructuredSelector} from 'reselect';
import {updateCourse} from "../../../../../../../../../../CourseConstructorPage/components/CourseConstructorComponent/draftCourseService";
import appRouterService from '../../../../../../../../../../../services/route/appRouterService';
import sessionStorageService from "../../../../../../../../../../../services/storage/sessionStorageService";
import courseService from '../../../../../../../../../../../services/server/courseService/courseService';
import {dispatch} from '../../../../../../../../../../../state/store';
import {
    setIsVisibleCourseList,
    setIsVisibleCreateCourseComponent,
    setIsVisibleCreateTemplates
} from '../../../../../../../../../../../state/ducks/admin';

const mapStateToProps = createStructuredSelector({
    draftCourse: makeSelectDraftCourse()
});

const CourseModulesComponent = (props:any) => {
    const {stepIconClassName, classNameLabelText, draftCourse} = props;

    const [sortedModules, setSortedModules] = useState([]);
    const [isVisibleErrorField, setIsVisibleErrorField] = useState(false);

    useEffect(() => {
        const draftSortedModules = draftCourse.modules.sort((a:any, b:any)=> a.sequenceId > b.sequenceId ? 1 : -1)
        setSortedModules(draftSortedModules);
    }, [draftCourse]);

    const onAddModuleButtonClick = async () => {
        await courseService.addModuleByCourseId(draftCourse.id);
        updateCourse();
    };
    
    const onDeleteModuleButtonClick = async (moduleId:number, isLastCourseModule:boolean) => {
        if(!isLastCourseModule) {
            await courseService.deleteModuleByModuleId(moduleId);
            updateCourse();
        }
    };
    
    const onModuleNameChange = async (newModuleName:string, moduleId:number) => {
        await courseService.updateModuleById(moduleId, newModuleName);
        updateCourse();
    };
    
    const onSaveButtonClick = () => {
        sessionStorageService.setDraftCourseId(draftCourse.id);
        appRouterService.forwardToCourseConstructor();

        dispatch(setIsVisibleCreateTemplates(false));
        dispatch(setIsVisibleCourseList(true));
        dispatch(setIsVisibleCreateCourseComponent(false));
    };

    const onDragEnd = async (result:any) => {
        const {destination, source, reason} = result;

        if(!destination || reason === 'CANCEL') {
            return;
        }

        if(destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const modules = [...sortedModules];
        const droppedModules = sortedModules[source.index];

        modules.splice(source.index, 1);
        modules.splice(destination.index, 0, droppedModules);

        setSortedModules(modules);

        const sortedIds = modules.map((module:any, index:number) => {
            const sortedModuleSequence:any = sortedModules[index];
            return {
                moduleId: module.id,
                sequenceId: sortedModuleSequence.sequenceId,
            };
        });

        await courseService.updateSequenceModule(sortedIds);
    };
    
    return (
        <div className={styles.CourseModuleContainer}>
            <DragDropContext onDragEnd={onDragEnd}>
                <span className={styles.StepText}>3/3</span>
                <div className={styles.ImageContainer}>
                    <Image
                        src={newStepImg}
                        className={stepIconClassName}
                    />
                    <div className={styles.Line} />
                    <Image
                        src={newStepImg}
                        className={`${stepIconClassName} ${styles.BottomStepIcon}`}
                    />
                </div>
                <div className={styles.ModulesContainer}>
                    <span className={classNameLabelText}>Type a module</span>
                        <Droppable droppableId='id1'>
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <div className={styles.ModuleListContainer}>
                                        {sortedModules.map((item:any, index:number) => {
                                            const moduleId = item.id;
                                            const moduleName = item.name;
                                            return (
                                                <Draggable key={index} draggableId={index+''} index={index} >
                                                    {(provided) => (
                                                        <div ref={provided.innerRef}
                                                             {...provided.draggableProps}
                                                             {...provided.dragHandleProps}
                                                        >
                                                            <ModuleItemComponent
                                                                isVisibleErrorField={isVisibleErrorField}
                                                                moduleId={moduleId}
                                                                name={moduleName}
                                                                onModuleNameChange={onModuleNameChange}
                                                                onDeleteModuleClick={onDeleteModuleButtonClick}
                                                                isLastCourseModule={draftCourse.modules.length < 2}
                                                                key={moduleId}
                                                                sortedModules={sortedModules}
                                                                onAddModuleButtonClick={onAddModuleButtonClick}
                                                                setIsVisibleErrorField={setIsVisibleErrorField}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>

                    <ButtonText className={styles.AddModuleButton} onClick={onAddModuleButtonClick}>
                        <Image src={plusImg} className={styles.PlusImage} />
                        <span className={styles.ButtonText}>Add a module</span>
                    </ButtonText>
                    <ButtonColor className={styles.SaveButton} onClick={onSaveButtonClick}>
                        Save and create a course
                    </ButtonColor>
                </div>
            </DragDropContext>
        </div>
    );
};

export default connect(mapStateToProps)(CourseModulesComponent);
import React from 'react';
import PopupFullPage from '../../../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import styles from './AssistantAIComponent.module.scss';
import Image from '../../../../../../../../../../../components/Image/Image';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import marvelGif from '../../../../../../../../../../../../assets/gifs/marvel.gif';
import pipedriveApiService
    from "../../../../../../../../../../Integration/PipedriveValidateComponent/pipedriveApiService";
import userInfoService from "../../../../../../../../../../../services/server/userInfoService/userInfoService";
import {dispatch} from "../../../../../../../../../../../state/store";
import {setUserDetails} from "../../../../../../../../../../../state/ducks/userDetails";

type AssistantAIComponentProps = {
    onCloseAssistantPopUp:Function
};

const AssistantAIComponent:React.FC<AssistantAIComponentProps> = ({onCloseAssistantPopUp}) => {

    const commitAiAssistant = (submitted:boolean) => {
        pipedriveApiService.setAiAssistantSetting(submitted)
            .finally(() => {
                onCloseAssistantPopUp();
                userInfoService.getUserInfo()
                    .then((userDetails) => {
                        if (userDetails) {
                            dispatch(setUserDetails(userDetails));
                        }
                    });
            });
    };

    const onDisagreeButtonClick = () => {
        commitAiAssistant(false);
    };

    const onAgreeButtonClick = () => {
        commitAiAssistant(true);
    };

    return (
        <PopupFullPage
            closePopup={onCloseAssistantPopUp}
            isVisibleCloseButton={false}
            classNameMainContainer={styles.MainContainer}
            closeContainer={styles.CloseContainer}
        >
            <>
                <Image src={marvelGif} className={styles.Image}/>
                <div className={styles.Title}>Here, at Gradual, we are on the mission to bring AI to assist you growing better people</div>
                <div className={styles.Subtitle}>Do you give your permission to collect your data anonymously (e.g quiz results, performance patters etc.) in order to give better training suggestions?</div>
                <div className={styles.WrapperButtons}>
                    <ButtonColor className={styles.DisagreeBtn} onClick={onDisagreeButtonClick}>
                        No, sorry
                    </ButtonColor>
                    <ButtonColor className={styles.AgreeBtn} onClick={onAgreeButtonClick}>
                        Yes, I’d love to have AI assistant in future
                    </ButtonColor>
                </div>
            </>
        </PopupFullPage>
    );
};

export default AssistantAIComponent;

import React, { useEffect, useRef, useState } from "react";

import styles from "./TeamItem.module.scss";
import Image from "../../../../../../../../../../../components/Image/Image";
import dotsImg from "../../../../../../../../../../../../assets/images/icon/three-dots-black.svg";
import DeleteMemberPopup from "../DeleteMemberPopup/DeleteMemberPopup";
import ModalContainer from "../ModalContainer/ModalContainer";
import { TeamMember } from "../../../../../../../../../../../types/TeamMember";
import { Filter } from "../../../../../../../../../../../types/filter/Filter";

const TeamItem = (props: {
  teamMember: TeamMember;
  setFilterValueName: Filter;
}) => {
  const { teamMember, setFilterValueName } = props;

  const { id, name, email, sitCount, courseNames } = teamMember;
  const [isVisibleDeletePopup, setIsVisibleDeletePopup] = useState(false);
  const [isVisibleDotsModal, setIsVisibleDotsModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const useOutsideClickParse = (popupRef: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setIsVisibleDotsModal(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [popupRef]);
  };

  useOutsideClickParse(modalRef);

  const onDotsClick = () => {
    setIsVisibleDotsModal(!isVisibleDotsModal);
  };

  const hideDeletePopup = () => {
    setIsVisibleDeletePopup(false);
  };

  const openDeletePopup = () => {
    setIsVisibleDeletePopup(true);
    setIsVisibleDotsModal(false);
  };

  return (
    <div key={id} className={styles.ContentItem}>
      <span>{name}</span>
      <span>{email}</span>
      <span>
        {(courseNames.length > 0 &&
          courseNames.map((courseName: string) => {
            return <span>{courseName}</span>;
          })) ||
          "No training created yet"}
      </span>
      <span>{sitCount}</span>
      <div className={styles.ImageContainer}>
        <Image src={dotsImg} className={styles.ImgDots} onClick={onDotsClick} />
        {isVisibleDotsModal && (
          <ModalContainer
            modalRef={modalRef}
            openDeletePopup={openDeletePopup}
          />
        )}
      </div>
      {isVisibleDeletePopup && (
        <DeleteMemberPopup
          memberId={id}
          memberName={name}
          hideDeletePopup={hideDeletePopup}
          setFilterValueName={setFilterValueName}
        />
      )}
    </div>
  );
};

export default TeamItem;

import React from 'react';

import {DeletePopup} from "../../../../../../../components/DeletePopup/DeletePopup";
import {dispatch} from "../../../../../../../state/store";
import {setDraftModule} from "../../../../../../../state/ducks/courseConstructor";
import {updateCourseModuleList} from "../../../draftCourseService";
import moduleService from "../../../../../../../services/server/moduleService/moduleService";

export const DeleteContentComponent = (props:any) => {
    const {contentId, draftModule, finishDeleteContent} = props;

    const deleteContent = async () => {
        await moduleService.deleteContentById(contentId);
        const updatedContentList = draftModule.contents.filter((content:any) => content.id !== contentId);
        const newDraftModule = {...draftModule, contents: updatedContentList};

        dispatch(setDraftModule(newDraftModule));
        updateCourseModuleList(newDraftModule);

        finishDeleteContent();
    };

    return <DeletePopup
        onDelete={deleteContent}
        onHide={finishDeleteContent}
        textData={{
            title:'Are you sure you want to delete it?',
            header:{
                general:'You are about to remove this content piece!',
                specific: ''
            }
        }}
    />;
};
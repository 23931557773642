import React, {useEffect, useState} from 'react';
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import styles from '../../../../../../../../SubscriptionPopupContainer/PaymentComponent/PaymentComponent.module.scss';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import subscriptionService from '../../../../../../../../../services/server/subscriptionService/subscriptionService';
import validationService from '../../../../../../../../../services/server/validationService/validationService';
import invalidInputFields from '../../../../../../../../SubscriptionPopupContainer/PaymentComponent/components/invalidInputFields';

const ChangeCardPopup = (props:any) => {
    const {setIsVisibleChangeCardNumberPopup, onChangeCardProgress, setProcessingChangeCard,
        onErrorChangeCard} = props;

    const stripe = useStripe();
    const elements:any = useElements();
    const [cardComplete, setCardComplete] = useState(false);
    const [billingDetailsName, setBillingDetailsName] = useState({
        name: '',
        isErrorName: false
    });

    const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
    const [isExpiredComplete, setIsExpiredComplete] = useState(false);
    const [isCvcComplete, setIsCvcComplete] = useState(false);
    const [isCardNumberError, setIsCardNumberError] = useState(false);
    const [isExpiredError, setIsExpiredError] = useState(false);
    const [isCvcError, setIsCvcError] = useState(false);

    const isFull = useMediaQuery({ query: '(min-width: 1920px)' });
    const isWxga = useMediaQuery({ query: '(min-width: 1600px)' });
    const isHd = useMediaQuery({ query: '(min-width: 1280px)' });
    const fontSize = isFull ? 18 : isWxga ? 16 : isHd ? 14 : 14;

    const inputStyle = {
        iconColor: '#F85A40',
        color: '#161413',
        fontWeight: '500',
        fontFamily: "Lato-Medium",
        fontSize: `${fontSize}px`,
        fontSmoothing: 'antialiased',
        letterSpacing: 'initial',
        ':-webkit-autofill': {
            color: '#161413',
        },
        '::placeholder': {
            color: '#CBC3C1',
        }
    };

    useEffect(() => {
        if (isCardNumberComplete && isExpiredComplete && isCvcComplete && billingDetailsName.name.length) {
            setCardComplete(true);
        } else {
            setCardComplete(false);
        }
    }, [isCardNumberComplete, isExpiredComplete, isCvcComplete, billingDetailsName.name]);

    const handleSubmit = async (event:any) => {
        setProcessingChangeCard(true);
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: billingDetailsName.name
            }
        });

        if (payload.error) {
            // @ts-ignore
            onErrorChangeCard(payload.error)
        } else {
            hideChangeCardPopup();
            onChangeCardProgress();
            // @ts-ignore
            await subscriptionService.setPaymentInfo(payload.paymentMethod.id);
        }
        setProcessingChangeCard(false);
    };

    const hideChangeCardPopup = () => {
        setIsVisibleChangeCardNumberPopup(false);
    };

    const onChangeBillingDetailsName = (event:any) => {
        setBillingDetailsName({...billingDetailsName, name: event.target.value});
    };

    const onKeyPressBillingDetailsName = (event:any) => {
        const isValidBillingDetailsName = validationService.isValidName(event.target.value);

        if (!isValidBillingDetailsName) {
            setBillingDetailsName({...billingDetailsName, isErrorName: true});
        } else {
            setBillingDetailsName({...billingDetailsName, isErrorName: false});
        }
    };

    const onElementChangeNumber = (event:any) => {
        if(event.complete) {
            setIsCardNumberComplete(true);
            setIsCardNumberError(false);
        } else {
            setIsCardNumberComplete(false);
            setIsCardNumberError(true);
        }
    };

    const onElementChangeExpiry = (event:any) => {
        if(event.complete) {
            setIsExpiredComplete(true);
            setIsExpiredError(false);
        } else {
            setIsExpiredComplete(false);
            setIsExpiredError(true);
        }
    };

    const onElementChangeCvc = (event:any) => {
        if(event.complete) {
            setIsCvcComplete(true);
            setIsCvcError(false)
        } else {
            setIsCvcComplete(false);
            setIsCvcError(true);
        }
    };

    return (
        <PopupFullPage
            classNameMainContainer={styles.MainPopupContainer}
            isSupportOutsidePopupClick
            closePopup={hideChangeCardPopup}
            classNameClose={styles.CloseImg}
        >
            <div>
                <div className={styles.Title}>Enter Card Details </div>
                <div className={styles.WrapperForm}>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor='name' className={styles.SubtitleInput}>
                                Name of Owner
                            </label>
                            <input
                                id='name'
                                type='text'
                                placeholder='Enter owner name'
                                required
                                autoComplete='name'
                                value={billingDetailsName.name}
                                onChange={onChangeBillingDetailsName}
                                onKeyPress={onKeyPressBillingDetailsName}
                                className={classNames(styles.InputName, billingDetailsName.isErrorName && styles.ErrorName)}
                            />
                        </div>
                        <label className={styles.SubtitleInput} htmlFor='card'>
                            Card number
                        </label>
                        <div className={classNames(styles.WrapperCardElement, isCardNumberError && styles.CardNumberError)}>
                            <CardNumberElement
                                id='card'
                                options={{
                                    style: {
                                        base: inputStyle,
                                        invalid: invalidInputFields
                                    },
                                }}
                                onChange={onElementChangeNumber}
                            />
                        </div>
                        <div className={styles.ContainerExpiryCvc}>
                            <div>
                                <label className={styles.SubtitleInput} htmlFor='expiry'>
                                    Expires
                                </label>
                                <div className={classNames(styles.WrapperCardElementExpiryCvc, isExpiredError && styles.ExpiredError)}>
                                    <CardExpiryElement
                                        id='expiry'
                                        options={{
                                            style: {
                                                base: inputStyle,
                                            },
                                        }}
                                        onChange={onElementChangeExpiry}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className={styles.SubtitleInput} htmlFor='cvc'>
                                    CVC
                                </label>
                                <div className={classNames(styles.WrapperCardElementExpiryCvc, isCvcError && styles.CvcError)}>
                                    <CardCvcElement
                                        id='cvc'
                                        options={{
                                            style: {
                                                base: inputStyle,
                                            },
                                        }}
                                        onChange={onElementChangeCvc}
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            className={classNames(styles.SubmitButton, !cardComplete && styles.SubmitButtonError)}
                            type="submit"
                            disabled={!cardComplete && true}
                        >
                            Change Card
                        </button>
                    </form>
                </div>
            </div>
        </PopupFullPage>
    );
};

export default ChangeCardPopup;
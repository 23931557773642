import React from 'react';
import classNames from 'classnames';

import styles from './ControlPanel.module.scss';
import arrowLeftImg from '../../../assets/images/icon/arrow-left.svg';
import arrowRightImg from '../../../assets/images/icon/arror-right.svg';
import Image from '../Image/Image';

const ControlPanel = (props:any) => {
    const {pageNumber, numPages, setPageNumber, pdf} = props;

    const isFirstPage = pageNumber === 1;
    const isLastPage = pageNumber === numPages;

    const firstPageClassName = classNames(styles.ControlPanelBtnPrevious, isFirstPage && styles.ControlPanelDisabled);
    const lastPageClassName = classNames(styles.ControlPanelBtnNext,  isLastPage && styles.ControlPanelDisabled);

    const onGoToPreviousPage = () => {
        if (!isFirstPage){
            setPageNumber(pageNumber - 1);
        }
    };

    const onGoToNextPage = () => {
        if (!isLastPage) {
            setPageNumber(pageNumber + 1);
        }
    };

    return (
        <div className={styles.ControlPanel}>
            <div className={styles.ControlPanelArrowContainer}>
                <Image
                    src={arrowLeftImg}
                    className={firstPageClassName}
                    onClick={onGoToPreviousPage}
                />
                <span>
                    {pageNumber}  of  {numPages}
                </span>
                <Image
                    src={arrowRightImg}
                    className={lastPageClassName}
                    onClick={onGoToNextPage}
                />
            </div>
            <div className={styles.ControlPanelBtnFullScreen}>
                <a
                    className={styles.ControlPanelFullScreen}
                    href={pdf}
                    target="_blank"
                >
                    Full screen
                </a>
            </div>
        </div>
    );
};

export default ControlPanel;
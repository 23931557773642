import adminService from '../adminService/adminService';
import stringUtils from '../../utils/stringUtilsService';
import config from '../../../config/config';
import draftModuleService
    from '../../../containers/CourseConstructorPage/components/CourseConstructorComponent/DraftModuleComponent/draftModuleService';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {textContentPath, contentPath, mediaContentPath, attachFilesPath, quizPath, questionPath,
    taskPath, deleteQuestionPath, questionVariantPath, deleteVariantPath, changeQuizTypePath,
    deleteQuizPath, deleteTaskPath, setSequenceVariantPath, setSequenceQuestionPath, setSequenceContentPath,
    setDelayModuleSettingsPath, setAttachFileQuestion, setAttachFileVariant, deleteAttachFile, attachPdfPath} = externalLinks;

const updateTextContent = async (contentId:number, text:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, textContentPath);
    
    return adminService.updateTextContent(formattedUrl, contentId, text);
};

const deleteContentById = async (contentId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, contentPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, contentId);
    
    return await adminService.deleteContentById(updatedUrl);
};

const addTextBlock = async (moduleId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, textContentPath);
    
    return await adminService.addTextBlock(formattedUrl, moduleId);
};

const addMediaBlock = async (file:any) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, mediaContentPath);
    return await adminService.addMediaBlock(formattedUrl, file);
};

const addAttachFile = async (event:any, draftModule:any, name:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, attachFilesPath);
    const newFiles:any = new FormData();
    newFiles.append('file', event.target.files[0]);
    newFiles.append('moduleId', draftModule.id);
    newFiles.append('name', name);
    
    const newModuleContent = await adminService.addMediaBlock(formattedUrl, newFiles);
    draftModuleService.updateModuleContentList(draftModule, newModuleContent?.data);
};

const addPdfFile = async (event:any, draftModule:any, name:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, attachPdfPath);
    const newFiles:any = new FormData();
    newFiles.append('file', event.target.files[0]);
    newFiles.append('moduleId', draftModule.id);
    newFiles.append('name', name);

    const newModuleContent = await adminService.addPdf(formattedUrl, newFiles);
    draftModuleService.updateModuleContentList(draftModule, newModuleContent?.data);
};

const createNewQuiz = async (moduleId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, quizPath);
    
    return await adminService.createNewQuiz(formattedUrl, moduleId);
};

const updateQuestionTitle = async (questionId:number, title:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, questionPath);
    
    return await adminService.updateQuestionTitle(formattedUrl, questionId, title);
};

const createNewTask = async (moduleId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, taskPath);
    
    return await adminService.createNewTask(formattedUrl, moduleId, '', '');
};

const updateTask = async (taskId:number, name:string, description:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, taskPath);
    
    return await adminService.updateTask(formattedUrl, taskId, name, description);
};

const updateQuizSettings = async (quizId:number, settingsType:string, settingsValue:number|null) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, quizPath);
    
    await adminService.updateQuizTestLimit(formattedUrl, quizId, settingsType, settingsValue);
};

const addNewQuizQuestion = async (quizId:number, typeQuestionId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, questionPath);
    const isMultipleChoice = typeQuestionId === 0;
    const isEssay = typeQuestionId === 1;
    
    return await adminService.addNewQuizQuestion(formattedUrl, quizId, isMultipleChoice, isEssay);
};

const deleteQuizQuestionById = async (questionId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteQuestionPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, questionId);
    
    await adminService.deleteQuizQuestionById(updatedUrl);
};

const updateQuestionVariant = async (variantId:number, title:string, isSelected:boolean) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, questionVariantPath);
    
    return await adminService.updateQuestionVariant(formattedUrl, variantId, title, isSelected);
};

const addQuizVariant = async (questionId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, questionVariantPath);
    
    return await adminService.addQuizVariant(formattedUrl, questionId);
};

const updateVariantSequence = async (variantSequences:any[]) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setSequenceVariantPath);

    await adminService.updateVariantSequence(formattedUrl, variantSequences);
};

const updateQuestionSequence = async (questionSequences:any[]) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setSequenceQuestionPath);

    await adminService.updateQuestionSequence(formattedUrl, questionSequences);
};

const updateContentSequence = async (contentSequences:any[]) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setSequenceContentPath)

    await adminService.updateContentSequence(formattedUrl, contentSequences);
};

const deleteQuizVariant = async (variantId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteVariantPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, variantId);
    
    return await adminService.deleteQuizVariant(updatedUrl);
};

const changeQuestionsType = async (questionId:number, isEssay:boolean, isMultipleChoice:boolean) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, changeQuizTypePath);
    
    return await adminService.changeQuestionsType(formattedUrl, questionId, isEssay, isMultipleChoice);
};

const deleteQuizById = async (quizId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteQuizPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, quizId);
    
    await adminService.deleteQuizById(updatedUrl);
};

const deleteTaskById = async (taskId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteTaskPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, taskId);

    await adminService.deleteTaskById(updatedUrl);
};

const addDelaySettingsToModule = async (delayTime:number, moduleId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setDelayModuleSettingsPath);

    await adminService.addDelaySettingsToModule(formattedUrl, delayTime, moduleId);
};

const addAttachFileQuestion = async (file:any) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setAttachFileQuestion);

    return await adminService.addAttachFileQuestion(formattedUrl, file);
};

const addAttachFileVariant = async (file:any) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setAttachFileVariant);

    return await adminService.addAttachFileVariant(formattedUrl, file);
};

const deleteAttachFileQuiz = async (id:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteAttachFile);
    const updatedUrl = stringUtils.formatString(formattedUrl, id);

    return await adminService.deleteAttachFile(updatedUrl);
};

export default {
    updateTextContent,
    deleteContentById,
    addTextBlock,
    addMediaBlock,
    addAttachFile,
    createNewQuiz,
    updateQuestionTitle,
    createNewTask,
    updateTask,
    updateQuizSettings,
    addNewQuizQuestion,
    deleteQuizQuestionById,
    updateQuestionVariant,
    addQuizVariant,
    deleteQuizVariant,
    changeQuestionsType,
    deleteQuizById,
    deleteTaskById,
    updateVariantSequence,
    updateQuestionSequence,
    updateContentSequence,
    addDelaySettingsToModule,
    addAttachFileQuestion,
    addAttachFileVariant,
    deleteAttachFileQuiz,
    addPdfFile
}
import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.team;

const makeSelectIsEmptyTeamMembersData = () => createSelector(selectGlobal, (globalState:any) => globalState.isEmptyTeamMembersData);

const makeSelectListOfTeamMembers = () => createSelector(selectGlobal, (globalState:any) => globalState.listOfTeamMembers);

const makeSelectSortedListOfTeamMembers = () => createSelector(selectGlobal, (globalState:any) => globalState.sortedListOfMembers);

const makeSelectFiltersName = () => createSelector(selectGlobal, (globalState:any) => globalState.filtersName);

const makeSelectSortedFiltersName = () => createSelector(selectGlobal, (globalState:any) => globalState.sortedFiltersName);

const makeSelectFiltersEmail = () => createSelector(selectGlobal, (globalState:any) => globalState.filtersEmail);

export {
    makeSelectIsEmptyTeamMembersData,
    makeSelectListOfTeamMembers,
    makeSelectSortedListOfTeamMembers,
    makeSelectFiltersName,
    makeSelectSortedFiltersName,
    makeSelectFiltersEmail,
}

import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import Loader from "react-loader-spinner";

import styles from './FilterComponent.module.scss'
import Image from '../Image/Image';
import topDropDownImg from '../../../assets/images/icon/drop-down-top-icon.svg';
import bottomDropDownImg from '../../../assets/images/icon/drop-down-bottom-icon.svg';
import OptionsComponent from '../DropDownList/component/OptionsComponent/OptionsComponent';
import learnersService from '../../services/server/learnersService/learnersService';
import checkedStatusImg from '../../../assets/images/icon/checked-mark-green-icon.svg';
import newStatusImg from '../../../assets/images/icon/new-mark-tomato-icon.svg';
import {useOutsideListener} from "../../hooks/useOutsideListener";
import {useMediaQuery} from 'react-responsive';

const FilterComponent = (props:any) => {
    const {classNameContainer, classNameTitle, textTitle, classNameDropDown,
        classNameDropDownMenu, listOptions, filterValue, onOptionFilterClick, signUp,
        classNameOptions, DropDownMenuContainerNotSelected, isShowCalendar, isPerformanceReports, optionsWrapper} = props;

        const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [dropDownValue, setDropDownValue] = useState(filterValue.name);
    const [sortedListOption, setSortedListOption] = useState([]);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectButtonRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setDropDownValue(filterValue.name);
    },[filterValue]);

    useEffect(() => {
        setSortedListOption(listOptions.sort((a:any, b:any) => a?.id < b?.id ? -1 : 1));
    }, [listOptions]);

    const openDropDownList = async () => {
        setIsOpenDropDown(!isOpenDropDown);
        learnersService.getFiltersLearnerCourse()
            .catch(console.error)
            .finally(() => setLoader(true));
    };

    const onValueFilterButtonClick = (filterValue:string, id:number) => {
        onOptionFilterClick(filterValue, id);
        setDropDownValue(filterValue);
        closeDropDownList();
    };

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    return (
        <div className={classNames(styles.FilterContainer, classNameContainer)}>
            <span className={classNames(styles.Title, classNameTitle)}>{textTitle}</span>
            <div className={isOpenDropDown ? classNames(classNameDropDown, styles.DropDownContainer, styles.ActiveDropDown) :
                classNames(classNameDropDown, styles.DropDownContainer)}
                 onClick={openDropDownList} ref={selectButtonRef}>
                <span className={styles.DropDownValue}>{isOpenDropDown ? 'Select' : dropDownValue}</span>
                <Image src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
                       className={classNames(styles.DropDownIcon, isOpenDropDown && styles.isActiveDropDownIcon)}
                />
            </div>
            {isOpenDropDown && (
                <div className={classNames((signUp && isTabletOrMobile) ? styles.DropDownMenuContainerSignUp : styles.DropDownMenuContainer, classNameDropDownMenu, isShowCalendar && DropDownMenuContainerNotSelected)} ref={dropDownRef}>
                    <div className={styles.DropDownOverflow}>
                        {loader ?
                            sortedListOption?.map((item:any, index:any) => {
                                const filterId = item.id;
                                const filterName = item.name;
                                const isActiveOptions = filterId === filterValue.id;
                                const imgOptions = filterName === 'Checked' ? checkedStatusImg :
                                    filterName === 'New' ? newStatusImg : '';

                                return (
                                    <OptionsComponent
                                        classNameWraperOptions={classNames(styles.OptionsWrapper, optionsWrapper)}
                                        isActiveOptions={isActiveOptions}
                                        reference={optionsRef}
                                        key={filterId}
                                        className={classNameOptions}
                                        optionText={filterName}
                                        onClick={() => onValueFilterButtonClick(filterName, filterId)}
                                        classNameShowText={filterName?.length > 17 && !signUp && true}
                                        optionImg={imgOptions}
                                        isPerformanceReports={isPerformanceReports}
                                    />
                                )
                            }):
                            <div className={styles.WrapperForLoader}>
                                <Loader
                                    type="TailSpin"
                                    color="#F85A40"
                                    height={25}
                                    width={25}
                                />
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
}
export default FilterComponent;

import classNames from 'classnames';
import styles from './AnalyticsDetails.module.scss';

import Image from '../../../../../../../../../../components/Image/Image';

const AnalyticsDetails = (props:any) => {
    const {imageSrc, textAnalytics, resultNumber, classNameText, classNameMargin} = props;

    return (
        <div className={classNames(classNameMargin, styles.AnalyticsComponents)}>
            <Image clasName={styles.Image} src={imageSrc} />
            <span className={classNames(classNameText, styles.TextAnalytics)}>{textAnalytics}</span>
            <span className={styles.Result}>{resultNumber}</span>
        </div>
    )
}

export default AnalyticsDetails;
import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import styles from './Calendar.module.scss'
import {ReactComponent as CalendarImg} from '../../../../../../../../../../../../assets/images/icon/calendar-icon.svg';
import Svg from '../../../../../../../../../../../components/SvgComponent/SvgComponent';
import date from '../../../../../../../../../../../services/date/date';
import calendar from '../../../../../../../../../../../services/date/calendar';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import { useOutsideListener } from '../../../../../../../../../../../hooks/useOutsideListener';

type CalendarProps = {
    isShowCalendar: boolean
    setIsShowCalendar: Function
    onSelectFirstDate: Function
    onSelectLastDate: Function
    classNameWrapperCalendar?: string
    periodId?: number
    getDataMetrics?: Function
    setDateRange?: Function
    isPerformanceReports?: boolean
};

const Calendar:React.FC<CalendarProps> = ({isShowCalendar, setIsShowCalendar, onSelectFirstDate, onSelectLastDate,
    classNameWrapperCalendar, periodId, setDateRange, getDataMetrics, isPerformanceReports}) => {

    const [ranges, setRanges] = useState<any>([
        {
            startDate: calendar.firstDayThisWeek(),
            endDate: calendar.lastDayThisWeek(),
            key: "selection"
        }
    ]);

    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const rangeObject : any = filteredByPeriod();
        if (setDateRange && !!rangeObject) {
            setDateRange([
                date.formattedDate(rangeObject?.startDate),
                date.formattedDate(rangeObject?.endDate)
            ]);
        }
    },[periodId]);

    useEffect(() => {
        const { startDate, endDate } = ranges[0];
        if (!!startDate && !!endDate) {
            const _startDate = date.formattedDate(startDate);
            const _endDate = date.formattedDate(endDate);

            onSelectFirstDate(_startDate);
            onSelectLastDate(_endDate);
        }
    }, [ranges]);


    const closeDropDownList = () => setIsShowCalendar(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    const onToggleClick = () => {
        setIsShowCalendar(!isShowCalendar);
    };

    const filteredByPeriod = () => {
        let rangeObj;
        if (periodId === 1) {
            rangeObj = {
                startDate: calendar.firstDayThisWeek(),
                endDate: calendar.lastDayThisWeek(),
                key: 'selection'
            };
        } else if (periodId === 2) {
            rangeObj = {
                startDate: calendar.lastMondayPreviousWeek(),
                endDate: calendar.lastSundayPreviousWeek(),
                key: 'selection'
            };
        } else if (periodId === 3) {
            rangeObj = {
                startDate: calendar.thisMonthFirstDay(),
                endDate: calendar.thisMonthLastDay(),
                key: 'selection'
            };
        } else if (periodId === 4) {
            rangeObj = {
                startDate: calendar.previousMonthFirstDay(),
                endDate: calendar.previousMonthLastDay(),
                key: 'selection'
            };
        } else if (periodId === 5) {
            rangeObj = {
                startDate: calendar.thisYearFirstDay(),
                endDate: calendar.thisYearLastDay(),
                key: 'selection'
            };
        } else if (periodId === 6) {
            rangeObj = {
                startDate: calendar.previousYearFirstDay(),
                endDate: calendar.previousYearLastDay(),
                key: 'selection'
            };
        }

        if (!!rangeObj) {
            setRanges([rangeObj]);
            return rangeObj;
        }

        return null;
    };

    const onClickSaveBtn = () => {
        if (getDataMetrics) {
            getDataMetrics();
        }
        closeDropDownList();
    };

    return (
        <div className='calendar'>
            <div
                onClick={onToggleClick}
                className={classNames(styles.CalendarIcon, isShowCalendar && styles.ActiveIcon, isPerformanceReports && styles.PerformanceReports)}
                ref={selectButtonRef}
            >
                <Svg className={styles.IconCalendar}>
                    <CalendarImg />
                </Svg>
            </div>
            {isShowCalendar &&
                <div
                    className={classNames(styles.WrapperCalendar, classNameWrapperCalendar)}
                    key={JSON.stringify(ranges)}
                    ref={dropDownRef}
                >
                    <DateRange
                        startDatePlaceholder="Start Date"
                        endDatePlaceholder="End Date"
                        ranges={ranges}
                        onChange={ranges => setRanges([ranges.selection])}
                        rangeColors={['#f85a40']}
                        className={styles.Calendar}
                        fixedHeight={true}
                        retainEndDateOnFirstSelection={true}
                        showMonthAndYearPickers={false}
                        editableDateInputs={true}
                        weekStartsOn={1}
                    />
                    <ButtonColor
                        className={styles.BtnSave}
                        onClick={onClickSaveBtn}
                    >
                        Save
                    </ButtonColor>
                </div>
            }
        </div>
    );
};

export default Calendar;

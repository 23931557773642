import React from 'react';
import classNames from "classnames";

import styles from './ContentFieldItems.module.scss';
import Image from '../../../../../../../../../../../components/Image/Image';

const ContentFieldItems = (props:any) => {
    const {src, name, onClick} = props;

    const className = classNames(styles.Icon);
    
    return (
        <div className={styles.ItemContainer}>
            <Image src={src} onClick={onClick} className={className} />
            <span className={styles.FieldName} onClick={onClick}>{name}</span>
        </div>
    );
};

export default ContentFieldItems;
import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';

import styles from './PaginationComponent.module.scss';
import {createStructuredSelector} from 'reselect';
import {makeSelectCustomersList} from '../../../../../../../../../state/selectors/home';
import {connect} from 'react-redux';
import {dispatch} from '../../../../../../../../../state/store';
import {setCurrentPageData} from '../../../../../../../../../state/ducks/home';

const mapStateToProps = createStructuredSelector({
    customersList: makeSelectCustomersList()
});

const PaginationComponent = (props:any) => {
    const {customersList} = props;
    const [currentPage, setCurrentPage] = useState(0);

    const customersPerPage = 7;
    const pageCount = Math.ceil(customersList?.length/ customersPerPage);
    const offSet = currentPage * customersPerPage;

    const currentPageCustomers = customersList?.slice(offSet, offSet + customersPerPage);
    dispatch(setCurrentPageData(currentPageCustomers));

    function handlePageClick(e:any) {
        setCurrentPage(e.selected);
    };

    return (
        <div className={styles.PaginationContainer}>
            <div className={styles.TextNumberLearner}>
                {customersList?.length> 7 ?
                    `Showing ${offSet + 1} - ${offSet + customersPerPage} of ${customersList?.length}` :
                    `Showing 1 - ${customersList?.length} of ${customersList?.length}`
                }
            </div>
            <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                breakClassName={styles.ListPagination}
                pageClassName={styles.ListPagination}
                activeLinkClassName={styles.ActiveBtnPaginate}
                containerClassName={styles.ContainerPaginate}
                previousLabel={'<'}
                nextLabel = {'>'}
                previousClassName={styles.Previous}
                nextClassName={styles.Next}
                onPageChange={handlePageClick}
                forcePage={currentPage}
            />
        </div>
    );
};

export default connect(mapStateToProps)(PaginationComponent);
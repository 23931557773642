import {useState, useRef, useEffect} from 'react';

import styles from './AudioComponent.module.scss';

const AudioComponent = ({src}:{src:string}) => {
    const [isVideoPlay, setIsVideoPlay] = useState(false);

    const speedModes = [
        {
            id: 1,
            text : 'Normal👌',
            speedValue: 1.0
        },
        {
            id: 2,
            text: 'Slowed🐌',
            speedValue: 0.75
        },
        {
            id: 3,
            text: 'High speed🚀',
            speedValue: 1.5
        }
    ];
    const defaultSpeedMode:any = speedModes.find(item => item.id === 1);
    const [activeSpeedMode, setActiveSpeedMode] = useState(defaultSpeedMode);
    const audioRef = useRef<HTMLAudioElement>(null);

    const audioPlay = () => {
        setIsVideoPlay(true);
    };

    const audioPause = () => {
        setIsVideoPlay(false);
    };

    const onSpeedModeClick = () => {
        const isLastSpeedMode = (activeSpeedMode.id + 1) > 3;

        if(!isLastSpeedMode) {
            const newSpeedMode = speedModes.find(item => item.id === activeSpeedMode.id + 1);
            setActiveSpeedMode(newSpeedMode);
        } else {
            setActiveSpeedMode(speedModes[0]);
        }
    };

    useEffect(() => {
        if(audioRef.current) {
            audioRef.current.playbackRate = activeSpeedMode.speedValue;
        }
    }, [activeSpeedMode]);

    return (
        <div className={styles.AudioContainer}>
            <audio
                src={src}
                controls
                className={styles.Audio}
                onPlay={audioPlay}
                onPause={audioPause}
                ref={audioRef}
                controlsList="nodownload"
            />
            {isVideoPlay && (
                <span className={styles.PlayBackText} onClick={onSpeedModeClick}>{activeSpeedMode.text}</span>
            )}
        </div>
    );
}

export default AudioComponent;
import {SubscriptionStorage} from '../interfaces/subscriptionInterface';

export const SET_PAYMENT_INFO = 'app/subscription/SET_PAYMENT_INFO';
export const SET_RESPONSE_PAY = 'app/subscription/SET_RESPONSE_PAY';
export const SET_IS_VISIBLE_CARD_AND_NEXT_PAYMENT_POPUP = 'app/subscription/SET_IS_VISIBLE_CARD_AND_NEXT_PAYMENT_POPUP';
export const SET_IS_VISIBLE_PRICE_AND_SEATS = 'app/subscription/SET_IS_VISIBLE_PRICE_AND_SEATS';
export const SET_SEATS_INFO = 'app/subscription/SET_SEATS_INFO';
export const SET_RESPONSE_CHANGE_CARD = 'app/subscription/SET_RESPONSE_CHANGE_CARD';
export const SET_IS_LEGACY_USER = 'app/subscription/SET_IS_LEGACY_USER';

const initialState:SubscriptionStorage = {
    paymentInfo: [],
    responsePay: '',
    isVisibleCardAndNextPayment: false,
    isVisiblePriceAndSeats: false,
    seatsInfo: [],
    responseChangeCard: '',
    isLegacyUser: true
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_PAYMENT_INFO: {
            return {...state, paymentInfo: action.payload};
        }
        case SET_RESPONSE_PAY: {
            return {...state, responsePay: action.payload};
        }
        case SET_IS_VISIBLE_CARD_AND_NEXT_PAYMENT_POPUP: {
            return {...state, isVisibleCardAndNextPayment: action.payload};
        }
        case SET_IS_VISIBLE_PRICE_AND_SEATS: {
            return {...state, isVisiblePriceAndSeats: action.payload};
        }
        case SET_SEATS_INFO: {
            return {...state, seatsInfo: action.payload};
        }
        case SET_RESPONSE_CHANGE_CARD: {
            return {...state, responseChangeCard: action.payload};
        }
        case SET_IS_LEGACY_USER: {
            return {...state, isLegacyUser: action.payload};
        }
        default: return state;
    }
}

export const setPaymentInfo = (value:Array<any>|[]) => ({type: SET_PAYMENT_INFO, payload: value});

export const setResponsePay = (value:String) => ({type: SET_RESPONSE_PAY, payload: value});

export const setIsVisibleCardAndNextPayment = (value:Boolean) => ({type: SET_IS_VISIBLE_CARD_AND_NEXT_PAYMENT_POPUP, payload: value});

export const setIsVisiblePriceAndSeats = (value:Boolean) => ({type: SET_IS_VISIBLE_PRICE_AND_SEATS, payload: value});

export const setSeatsInfo = (value:Array<any>|[]) => ({type: SET_SEATS_INFO, payload: value});

export const setResponseChangeCard = (value:String) => ({type: SET_RESPONSE_CHANGE_CARD, payload: value});

export const setIsLegacyUser = (value:String) => ({type: SET_IS_LEGACY_USER, payload: value});
import React, { useEffect, useState } from "react";

import styles from "./ConfigureComponent.module.scss";
import avatarImg from "../../../../../../../../../../../../assets/images/icon/avatar-icon.svg";
import chartImg from "../../../../../../../../../../../../assets/images/icon/chart-icon.svg";
import PopupFullPage from "../../../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage";
import Image from "../../../../../../../../../../../components/Image/Image";
import ConfigureLearnerItem from "./components/ConfigureLearnerItem/ConfigureLearnerItem";
import ConfigureMetrics from "./components/ConfigureMetrics/ConfigureMetrics";
import ButtonColor from "../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import classNames from "classnames";
import integrationsService from "../../../../../../../../../../../services/server/integrationsService/integrationsService";
import { dispatch } from "../../../../../../../../../../../state/store";
import {
  setIsVisibleAssistantPopUp,
  setIsVisibleConfiguration,
} from "../../../../../../../../../../../state/ducks/integrations";
import { createStructuredSelector } from "reselect";
import {
  makeSelectLearnersListByPipedrive,
  makeSelectMetricsListByPipedrive,
} from "../../../../../../../../../../../state/selectors/integrations";
import { connect } from "react-redux";
import {
  dataForPipedrive,
  metricsListByPipedrive,
} from "../../../../../../../../../../../types/integrationType/dataForPipedrive";
import Loader from "react-loader-spinner";
import userInfoService from "../../../../../../../../../../../services/server/userInfoService/userInfoService";
import { UserDetails } from "../../../../../../../../../../../types/UserDetails";
import learnersService from "../../../../../../../../../../../services/server/learnersService/learnersService";
import {
  setIsEmptyLearnersData,
  setLearnersList,
  setSortedLearnerList,
} from "../../../../../../../../../../../state/ducks/admin";

const mapStateToProps = () =>
  createStructuredSelector({
    learnersListByPipedrive: makeSelectLearnersListByPipedrive(),
    metricsListByPipedrive: makeSelectMetricsListByPipedrive(),
  });

type ConfigureComponentProps = {
  learnersListByPipedrive: Array<dataForPipedrive>;
  metricsListByPipedrive: Array<metricsListByPipedrive>;
  userDetails: UserDetails | undefined;
};

//TODO: rename to PipedriveConfiguration
const ConfigureComponent: React.FC<ConfigureComponentProps> = ({
  learnersListByPipedrive,
  metricsListByPipedrive,
  userDetails,
}) => {
  const [isActiveTab, setIsActiveTab] = useState(1);
  const [learnerListForRequest, setLearnerListForRequest] = useState<any>([]);
  const [metricsListForRequest, setMetricsListForRequest] = useState<any>([]);
  const [loaderLearnersList, setLoaderLearnersList] = useState(false);
  const [isLearnersDataEmpty, setIsLearnersDataEmpty] = useState(false);

  // TODO: would be good to transfer to enums
  const isActiveLearners = isActiveTab === 1;
  const isActiveMetrics = isActiveTab === 2;
  const isAiSettingSet = typeof userDetails?.isNlp === "boolean";

  useEffect(() => {
    getDataForConfigure();
  }, []);

  useEffect(() => {
    let learnerList: any = [];

    learnersListByPipedrive.filter((learner) => {
      learnerList.push({
        learnerId: learner.learnerId,
        pipedriveId: learner.pipedriveId,
        submitted: learner.submitted,
      });
    });
    setLearnerListForRequest(learnerList);
  }, [learnersListByPipedrive]);

  useEffect(() => {
    let metricsList: any = [];

    metricsListByPipedrive.filter((metric) => {
      metric.metricType.keyWord === "CONVERSION_RATES"
        ? metricsList.push({
            metricType: metric?.metricType.keyWord,
            isSelected: metric.isSelected,
            pipelines: metric.pipelines,
          })
        : metricsList.push({
            metricType: metric.metricType.keyWord,
            isSelected: metric.isSelected,
          });
    });
    setMetricsListForRequest(metricsList);
  }, [metricsListByPipedrive]);

  const hidePopup = () => {
    dispatch(setIsVisibleConfiguration(false));

    if (!isAiSettingSet) {
      dispatch(setIsVisibleAssistantPopUp(true));
    }
  };

  const onButtonSaveClick = async () => {
    await integrationsService.submitLearnersForPipedrive(learnerListForRequest);
    await integrationsService.submitMetricsForPipedrive(metricsListForRequest);

    learnersService
      .getFilterLearners() // update learners
      .then((learnersList) => {
        dispatch(setIsEmptyLearnersData(!learnersList.learners.length));
        dispatch(setLearnersList(learnersList.learners.reverse()));
        dispatch(setSortedLearnerList(learnersList.learners));
      })
      .finally(hidePopup);
  };

  const onClickLearnerButton = () => {
    setIsActiveTab(1);
  };

  const onClickMetricsButton = () => {
    setIsActiveTab(2);
  };

  const getDataForConfigure = async () => {
    try {
      await integrationsService.getLearnersByPipedrive();
    } catch (e) {
      setIsLearnersDataEmpty(true);
    } finally {
      await integrationsService.getMetricsForIntegration();
      setLoaderLearnersList(true);
    }
  };

  return (
    <PopupFullPage
      classNameMainContainer={styles.MainPopupContainer}
      isSupportOutsidePopupClick
      isVisibleCloseButton={false}
      closePopup={hidePopup}
    >
      <div className={styles.Title}>Configure connection</div>
      {loaderLearnersList ? (
        <>
          <div className={styles.WrapperDetails}>
            <div
              className={classNames(
                styles.WrapperTab,
                isActiveLearners ? styles.ActiveTabLearner : styles.NoActive
              )}
            >
              <div className={styles.Tab} onClick={onClickLearnerButton}>
                <div className={styles.Circle}>
                  <Image src={avatarImg} className={styles.ImageDetails} />
                </div>
                <span className={styles.Text}>Users</span>
              </div>
            </div>
            <div
              className={classNames(
                styles.WrapperTab,
                isActiveMetrics ? styles.ActiveTabMetrics : styles.NoActive
              )}
            >
              <div className={styles.Tab} onClick={onClickMetricsButton}>
                <div className={styles.Circle}>
                  <Image src={chartImg} className={styles.ImageDetails} />
                </div>
                <span className={styles.Text}>Metrics</span>
              </div>
            </div>
          </div>
          {isActiveLearners && (
            <>
              <div className={styles.Subtitle}>Choose users</div>
              <ConfigureLearnerItem isLearnersDataEmpty={isLearnersDataEmpty} />
            </>
          )}
          {isActiveMetrics && (
            <>
              <div className={styles.Subtitle}>Select metrics</div>
              <ConfigureMetrics />
            </>
          )}
          <ButtonColor
            className={styles.ButtonSave}
            onClick={onButtonSaveClick}
          >
            Save
          </ButtonColor>
        </>
      ) : (
        <Loader type="TailSpin" color="#F85A40" height={30} width={30} />
      )}
    </PopupFullPage>
  );
};

export default connect(mapStateToProps)(ConfigureComponent);

import coursesIcon from "../../../../../../assets/images/icon/courses-account-icon.svg";
import activeCourseIcon from "../../../../../../assets/images/icon/active-courses-account-icon.svg";
import reportIcon from "../../../../../../assets/images/icon/report-account-icon.svg";
import activeReportIcon from "../../../../../../assets/images/icon/active-report-account-icon.svg";
import performanceReportIcon from "../../../../../../assets/images/icon/performance-reports.svg";
import activePerformanceIcon from "../../../../../../assets/images/icon/active-performance-reports.svg";
import analyticsIcon from "../../../../../../assets/images/icon/analytics-account-icon.svg";
import activeAnalyticsIcon from "../../../../../../assets/images/icon/active-analytics-account-icon.svg";
import onboardeesIcon from "../../../../../../assets/images/icon/onboardees-account-icon.svg";
import activeOnboardeesIcon from "../../../../../../assets/images/icon/active-onboardees-account-icon.svg";
import integrationsIcon from "../../../../../../assets/images/icon/integrations-account-icon.svg";
import activeIntegrationsIcon from "../../../../../../assets/images/icon/active-integrations-account-icon.svg";
import customBrandingIcon from "../../../../../../assets/images/icon/custom-branding-account-icon.svg";
import activeCustomBrandingIcon from "../../../../../../assets/images/icon/active-custom-branding-account-icon.svg";
import subscriptionIcon from "../../../../../../assets/images/icon/subscription-icon.svg";
import activeSubscriptionIcon from "../../../../../../assets/images/icon/active-subscription-icon.svg";
import teamIcon from "../../../../../../assets/images/icon/team-icon.svg";
import activeTeamIcon from "../../../../../../assets/images/icon/active-team-icon.svg";
import { AdminMenuTab } from "../../../../../types/tab/Tab";

export enum TabListIndex {
  Training,
  Reporting,
  PerformanceReports,
  Analytics,
  Learners,
  Integrations,
  CustomBranding,
  Subscription,
  Team,
}

export enum TabListNames {
  Training = "Training",
  Reporting = "Reporting",
  PerformanceReports = "Performance reports",
  Analytics = "Analytics",
  Learners = "Team",
  Integrations = "Integrations",
  CustomBranding = "Custom Branding",
  Subscription = "Subscription",
  Team = "Admins",
}

export const availableTabList: AdminMenuTab[] = [
  {
    id: TabListIndex.Training,
    name: TabListNames.Training,
    icon: coursesIcon,
    activeIcon: activeCourseIcon,
  },
  {
    id: TabListIndex.Reporting,
    name: TabListNames.Reporting,
    icon: reportIcon,
    activeIcon: activeReportIcon,
  },
  {
    id: TabListIndex.PerformanceReports,
    name: TabListNames.PerformanceReports,
    icon: performanceReportIcon,
    activeIcon: activePerformanceIcon,
  },
  {
    id: TabListIndex.Analytics,
    name: TabListNames.Analytics,
    icon: analyticsIcon,
    activeIcon: activeAnalyticsIcon,
  },
  {
    id: TabListIndex.Learners,
    name: TabListNames.Learners,
    icon: onboardeesIcon,
    activeIcon: activeOnboardeesIcon,
  },
  {
    id: TabListIndex.Integrations,
    name: TabListNames.Integrations,
    icon: integrationsIcon,
    activeIcon: activeIntegrationsIcon,
  },
  {
    id: TabListIndex.CustomBranding,
    name: TabListNames.CustomBranding,
    icon: customBrandingIcon,
    activeIcon: activeCustomBrandingIcon,
  },
  {
    id: TabListIndex.Subscription,
    name: TabListNames.Subscription,
    icon: subscriptionIcon,
    activeIcon: activeSubscriptionIcon,
  },
  {
    id: TabListIndex.Team,
    name: TabListNames.Team,
    icon: teamIcon,
    activeIcon: activeTeamIcon,
  },
];

import classNames from 'classnames';
import React from 'react';

import Button from '../../../Button/Button';
import styles from './ButtonBorder.module.scss';

const ButtonColor = (props:any) => {
    const {className, reference} = props;
    const btnClassName = classNames(className, styles.Button);

    return (
        <Button
            reference={reference}
            className={btnClassName}
            {...props}
        />
    );
};

export default ButtonColor;
import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import styles from './FilterDetailsQuiz.module.scss';
import topDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg';
import bottomDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg';
import OptionsComponent
    from '../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent';
import Image from '../../../../../../../../../components/Image/Image';
import {filterDetailsList} from './filterDetailsList';
import {useOutsideListener} from "../../../../../../../../../hooks/useOutsideListener";

const FilterDetailsQuiz = (props:any) => {
    const {onTypeClick, type} = props;

    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [dropDownValue, setDropDownValue] = useState(type.name);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectButtonRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    const openDropDownList = () => setIsOpenDropDown(!isOpenDropDown);

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    const onTypeButtonClick = (typeItem:any) => {
        setDropDownValue(typeItem.name);
        onTypeClick(typeItem);
        closeDropDownList();
    };

    return (
        <div className={styles.Container}>
            <div className={styles.WrapperMain}>
                <span className={styles.Title}>Type of question</span>
                <div className={classNames(styles.DropDownContainer, isOpenDropDown && styles.ActiveDropDown)}
                     onClick={openDropDownList} ref={selectButtonRef}>
                    <span className={styles.DropDownValue}>{isOpenDropDown ? 'Select' : dropDownValue}</span>
                    <Image src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
                           className={classNames(styles.DropDownIcon, isOpenDropDown && styles.isActiveDropDownIcon)}
                    />
                </div>
            </div>
            {isOpenDropDown && (
                <div className={styles.DropDownMenuContainer} ref={dropDownRef}>
                    <div className={styles.DropDownOverflow}>
                    {filterDetailsList.map((item:any) => {
                        const typeName = item.name;
                        const id = item.name;
                        const isActiveOptions = item.name === type.name;

                        return (
                            <OptionsComponent
                                className={styles.OptionsText}
                                classNameWraperOptions={styles.OptionsWrapper}
                                isActiveOptions={isActiveOptions}
                                reference={optionsRef}
                                key={id}
                                optionText={typeName}
                                onClick={() => onTypeButtonClick(item)}
                            />
                        )
                    })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default FilterDetailsQuiz;
import localStorageService from '../../services/storage/localStorageService';
import {ROOT} from '../../consts/routes/routePaths';
import urlUtils from '../../services/utils/urlUtils';


export const ExternalAuthComponent = () => {
    const [a_t, r_t] : (string | null) [] = urlUtils.getUrlParams(['a_t', 'r_t']);

    if (a_t) {
        localStorageService.setAccessToken(a_t);
    }

    if (r_t) {
        localStorageService.setRefreshToken(r_t);
    }

    window.location.replace(ROOT);
};
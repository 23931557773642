import React from 'react';
import styles from './CurrentPlanDetails.module.scss';
import {ReactComponent as ChangePlanImg} from '../../../../../../../../../../assets/images/icon/change-card-details.svg';
import {createStructuredSelector} from 'reselect';
import {makeSelectSeatsInfo} from '../../../../../../../../../state/selectors/subscription';
import {connect} from 'react-redux';
import Svg from '../../../../../../../../../components/SvgComponent/SvgComponent';

const mapStateToProps = createStructuredSelector({
    seatsInfo: makeSelectSeatsInfo()
});

const CurrentPlanDetails = (props:any) => {
    const {price, onClickToChangeSeats, seatsInfo} = props;

    return (
        <div className={styles.WrapperForSeatsAndPrice}>
            <div>
                <div>${price}</div>
                <div className={styles.SubtitleSeatsAndPrice}>per seat</div>
            </div>
            <div>
                <div>{seatsInfo?.paySeatsNumber} seats</div>
                <div className={styles.SubtitleSeatsAndPrice}>+{seatsInfo?.freeSeatsNumber} free seats</div>
            </div>
            <Svg
                className={styles.ChangeSeatsImg}
                onClick={onClickToChangeSeats}
            >
                <ChangePlanImg />
            </Svg>
        </div>
    );
};

export default connect(mapStateToProps)(CurrentPlanDetails);
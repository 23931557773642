export const firstBenefitsList = [
    {
        id: 1,
        name: 'Unlimited amount of courses',
    },
    {
        id: 2,
        name: 'Intuitive course editor',
    },
    {
        id: 3,
        name: 'Analytics and smart notifications',
    },
    {
        id: 4,
        name: 'Amazon AWS'
    },
];

export default firstBenefitsList;


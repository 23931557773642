import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import styles from './ForgotPasswordPage.module.scss';
import Image from '../../components/Image/Image';
import backgroundImg from '../../../assets/images/icon/background-login.png';
import InputAuthField from '../../components/Input/InputAuthField/InputAuthField';
import validationService from '../../services/server/validationService/validationService';
import ButtonColor from '../../components/Button/components/ButtonColor/ButtonColor';
import Line from '../../components/Line/Line';
import adminAuthService from '../../services/server/adminService/adminAuthService';
import {BAD_REQUEST} from '../../consts/httpStatus/httpStatus';
import {USER_NOT_FOUND} from '../../consts/error/errorTypesMessage';
import ButtonText from '../../components/Button/components/ButtonText/ButtonText';
import appRouterService from '../../services/route/appRouterService';
import AuthErrorModal from '../../components/Error/AuthErrorComponent/AuthErrorComponent';
import SuccessContainer from './components/SuccessContainer';
import smileImg from '../../../assets/images/icon/smile-icon.svg';
import classNames from 'classnames';
import Logo from '../../components/Logo/Logo';

const ForgotPasswordPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isVisibleSuccessText, setIsVisibleSuccessText] = useState(false);
    const [isErrorOccurred, setIsErrorOccurred] = useState(false);
    const [isInputError, setIsInputError] = useState({
        isEmailError: false
    });

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };

    const onEnterKeyPressEmail = (event: React.ChangeEvent<HTMLInputElement> & React.KeyboardEvent) => {
        const isValidEmail = validationService.isValidEmail(event.target.value);

        if (isValidEmail) {
            setIsErrorOccurred(false);
        }

        if (event.code === "Enter" && isValidEmail && !isErrorOccurred) {
            onGetNewPasswordClick()
        } else {
            setIsInputError({...isInputError, isEmailError: !isValidEmail});
        }
    };

    const onGetNewPasswordClick = async () => {
        adminAuthService.setRecoveryEmail(email)
            .then(() => {
               setIsVisibleSuccessText(true);
            })
            .catch((error) => {
                const {data, status} = error;

                setIsErrorOccurred(true);

                if (status === BAD_REQUEST && data.includes(USER_NOT_FOUND)) {
                    setErrorMessage('User with this email is not found!');
                } else {
                    setErrorMessage('Opps... Something went wrong');
                }
            });
    };

    const onSignUpButtonClick = () => {
        appRouterService.forwardToAdminSignUpPage();
    };

    return (
        <div className={styles.MainContainer}>
            {!isTabletOrMobile &&
                <div>
                    <Image src={backgroundImg} className={styles.BackgroundImage} />
                    <div className={styles.SignInContainer}>
                        <div className={styles.TitleContainer}>
                            <span className={styles.TitleText}>Welcome back to <span>Gradual</span></span>
                        </div>
                        {!isVisibleSuccessText &&
                            <div>
                                <div className={styles.WrapperSubtitle}>
                                    <div className={styles.Password}>Need a new password?</div>
                                    <div className={styles.Subtitle}>Please provide your email address and we'll send you</div>
                                    <div className={styles.Subtitle}>instructions on how to change your password.</div>
                                </div>
                                <div className={styles.InputContainer}>
                                    <InputAuthField
                                        type='text'
                                        labelText='Email'
                                        classNameInput={styles.InputField}
                                        classNameLabel={styles.LabelInputText}
                                        value={email}
                                        placeholderInput='myemail@company.com'
                                        onChange={onEmailChange}
                                        onKeyPress={onEnterKeyPressEmail}
                                        isInputError={isInputError.isEmailError}
                                    />
                                    {isErrorOccurred &&
                                        <AuthErrorModal
                                            errorMessage={errorMessage}
                                            onClick={onSignUpButtonClick}
                                        />
                                    }
                                    <ButtonColor
                                        className={classNames(styles.LoginButton, isErrorOccurred && styles.DisabledBtn)}
                                        onClick={onGetNewPasswordClick}
                                        disabled={isErrorOccurred}
                                    >
                                        Get a new password
                                    </ButtonColor>
                                    <div className={styles.TitleDescriptionContainer}>
                                        <Line className={styles.Line} />
                                        <span>Do not have an account?</span>
                                        <Line className={styles.Line} />
                                    </div>
                                    <ButtonText className={styles.SignUpButton} onClick={onSignUpButtonClick}>
                                        Sign up
                                    </ButtonText>
                                </div>
                            </div>
                        }
                        {isVisibleSuccessText &&
                            <SuccessContainer />
                        }
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div className={styles.NotAdaptiveWrapper}>
                    <Logo className={styles.LogoImage} />
                    <div className={styles.NotAdaptive}>
                        <span className={styles.TitleNotAdaptive}>
                            Please switch to full screen mode or use Gradual with desktop for better experience
                        </span>
                        <Image src={smileImg} className={styles.SmileImg}/>
                    </div>
                </div>
            }
        </div>
    );
};

export default ForgotPasswordPage;

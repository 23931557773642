import React from 'react';

import styles from './MetricsComponent.module.scss';
import MetricsItemComponent from './components/MetricsItemComponent/MetricsItemComponent';
import CompanyClosedAmountComponent from './components/CompanyClosedAmountComponent/CompanyClosedAmountComponent';
import {createStructuredSelector} from 'reselect';
import {makeSelectMetricsListForDashboard} from '../../../../../../../../../../../state/selectors/performance';
import {connect} from 'react-redux';
import {MetricsListForDashboard} from '../../../../../../../../../../../types/performanceType/performanceType';

const mapStateToProps = createStructuredSelector({
    metricsListForDashboard: makeSelectMetricsListForDashboard()
});

type MetricsComponentProps = {
    metricsListForDashboard: Array<MetricsListForDashboard>
    learnerName: string
    learnerId: number
};

const MetricsComponent:React.FC<MetricsComponentProps> = ({metricsListForDashboard, learnerName, learnerId}) => {

    return (

        <div>
            {metricsListForDashboard.length > 0 ?
                <div>
                    <div className={styles.HeaderMetrics}>
                        <span>Metrics</span>
                        <span className={styles.Action}>Action</span>
                    </div>
                    <div className={styles.MetricsAndAmount}>
                        <MetricsItemComponent
                            learnerName={learnerName}
                            learnerId={learnerId}
                            metricsListForDashboard={metricsListForDashboard}
                        />
                        {false && // TODO: uncomment when ready
                            <CompanyClosedAmountComponent/>
                        }
                    </div>
                </div>
                 :
                <div className={styles.NoData}>No data for this period</div>
            }
        </div>
    );
};

export default connect(mapStateToProps)(MetricsComponent);

import React from 'react';

import styles from './TextEditorComponent.module.scss';
import TextEditor from '../../../../../../../../../../components/TextEditor/TextEditor';

const TextEditorComponent = (props:any) => {
    const {value, contentId, updateTextContent} = props;
    
    const changeText = async (value:string) => {
        await updateTextContent(value, contentId);
    }

    return (
        <TextEditor
            value={JSON.parse(decodeURI(value))}
            onChange={changeText}
            placeholder='Type a text...'
            className={styles.TextEditor}
        />
    );
};

export default TextEditorComponent;
import React, {useEffect, useState} from 'react';

import {
    PerformanceReport,
} from '../../../../../../../../../../../../types/performanceReportsType/performanceReports';
import dateModify from '../../../../../../../../../../../../services/utils/dateModify/dateModify';
import pipedriveImg from '../../../../../../../../../../../../../assets/images/icon/pipedrive-reports-icon.svg';
import Image from '../../../../../../../../../../../../components/Image/Image';

import styles from './ReportsItem.module.scss';
import checkedImg from '../../../../../../../../../../../../../assets/images/icon/checked-mark-green-icon.svg';
import newImg from '../../../../../../../../../../../../../assets/images/icon/new-mark-tomato-icon.svg';
import ReactTooltip from 'react-tooltip';
import ReportDetails from './components/ReportDetails/ReportDetails';
import performanceReportsService
    from "../../../../../../../../../../../../services/server/performanceReportsService/performanceReportsService";
import {PerformanceDetails} from "../../../../../../../../../../../../types/performanceReportsType/PerformanceDetails";
import {CRM_SOURCES} from "../../../../../../../../../../../../consts/stringLiterals/crmDataSource";
import PaginationComponent
    from "../../../../../../../../../../../../components/PaginationComponent/PaginationComponent";

type ReportsItemType = {
    performanceReports: PerformanceReport[]
    setReportStatus: Function
};

const ReportsItem = ({performanceReports, setReportStatus}: ReportsItemType) => {
    const [currentReportsData, setCurrentReportsData] = useState<PerformanceReport[]>([]);
    const [performanceDetailData, setPerformanceDetailData] = useState<PerformanceDetails>();
    const [isVisibleReportDetails, setIsVisibleReportDetails] = useState(false);
    const [creationTime, setCreationTime] = useState<number>(0);
    const [loading, setLoadingDetails] = useState(true);

    const unmountDetailsData = () => {
        if (!isVisibleReportDetails) {
            setLoadingDetails(true);
            setPerformanceDetailData(undefined);
        }
    };

    useEffect(unmountDetailsData, [isVisibleReportDetails]);

    const handleOpenReportDetails = (reportId: number, _creationTime:number) => {
        setIsVisibleReportDetails(true);
        setCreationTime(_creationTime);
        performanceReportsService.getReportsDetails(reportId)
            .then(res => {
                setPerformanceDetailData(res);
                setLoadingDetails(false);

                // Sets report status to checked automatically
                performanceReportsService.setReportsStatus(reportId, true)
                    .then(() => setReportStatus(reportId, true))
                    .catch(console.error);
            })
            .catch(console.error);
    };

    return (
        <>
            <div>
                {currentReportsData.map((item) => {
                    const id = item.id;
                    const reportType = item.reportType;
                    const isPipedriveSource = item.dataSource === CRM_SOURCES.PIPEDRIVE;
                    const date = item.timeStamp;
                    const checked = item.checked;

                    return (
                        <div
                            key={id}
                            className={styles.WrapperItem}
                            data-tip='View report'
                            onClick={() => handleOpenReportDetails(id, date)}
                        >
                            <span>{reportType}</span>
                            <span className={styles.Date}>{dateModify.setModifyDate(date)}</span>
                            <span className={styles.Image}>
                                <Image
                                    src={isPipedriveSource && pipedriveImg}
                                />
                            </span>
                            <span>
                                <Image className={styles.CheckedImg} src={checked ? checkedImg : newImg}/>
                                <span>{checked ? 'Checked' : 'New'}</span>
                            </span>
                            <ReactTooltip
                                effect='solid'
                                className={styles.Tooltip}
                            />
                        </div>
                    )
                })}
            </div>
            <PaginationComponent
                list={performanceReports}
                elementsPerPage={5}
                handleWorkingListUpdate={setCurrentReportsData}
            />
            {isVisibleReportDetails &&
                <ReportDetails
                    performanceDetailData={performanceDetailData}
                    setIsVisibleReportDetails={setIsVisibleReportDetails}
                    isLoading={loading}
                    creationTime={creationTime}
                />
            }
        </>
    );
};

export default ReportsItem;

import React, { useEffect, useRef, useState } from "react";
import classNames from 'classnames';
import Loader from "react-loader-spinner";

import styles from './FilterComponentInput.module.scss';
import Image from '../Image/Image';
import Input from '../Input/Input';
import closeImg from '../../../assets/images/icon/close-icon.svg';
import searchImg from '../../../assets/images/icon/search-magnifier.svg';
import OptionsComponent from '../DropDownList/component/OptionsComponent/OptionsComponent';
import {setFilterNameLearnerList} from '../../state/ducks/admin';
import {dispatch} from '../../state/store';
import learnersService from '../../services/server/learnersService/learnersService';
import {setFiltersName, setSortedFiltersName} from '../../state/ducks/team';
import {useOutsideListener} from "../../hooks/useOutsideListener";

const FilterComponentInput = (props: any) => {
    const {textTitle, listOptions, classNameContainer, classNameDropDown,
        classNameDropDownMenu, onOptionFilterClick, classNameTitle,
        onNameInputChange, filterValue, sortedListOptions, learners, team,
        classNameInputContainer, isPipedriveReports, setMatchingLearnerFilters
    } = props;

    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [searchString, setSearchString] = useState(filterValue.name);
    const [loader, setLoader] = useState(false);
    const filterNameRef = useRef <HTMLDivElement> (null);
    const dropDownRef = useRef <HTMLDivElement> (null);
    const selectButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const searchedName = filterValue.name;

        setSearchString(searchedName);
        filterSearchInput(searchedName);
    },[filterValue]);

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    const openDropDownList = async () => {
        setIsOpenDropDown(!isOpenDropDown);
        await learnersService.getFiltersLearnerName();
        setLoader(true);
    };


    const filterSearchInput = (nameToFilter:string) => {
        const filterListName = sortedListOptions.filter((item:any) => {
            return item.name.toLowerCase().includes(nameToFilter.toLowerCase().trim())
        });

        learners && dispatch(setFilterNameLearnerList(filterListName));
        team && dispatch(setFiltersName(filterListName));

        if (isPipedriveReports && setMatchingLearnerFilters) {
            setMatchingLearnerFilters(filterListName);
        }
    };

    const onChangeInput = (event: any) => {
        const { value } = event.currentTarget;
        filterSearchInput(value);

        setSearchString(value);
        onNameInputChange(value);
    };

    const onClickList = (learnerName:string, id:number) => {
        setSearchString(learnerName);
        onOptionFilterClick(learnerName, id);
        closeDropDownList();
    };

    const onClearInput = () => {
        setSearchString('');
        onNameInputChange('');
        learners && dispatch(setFilterNameLearnerList(sortedListOptions));
        team && dispatch(setFiltersName(sortedListOptions));
    };

    return (
        <div className={classNames(styles.FilterComponentWrapper, classNameContainer)}>
            <span className={classNames(styles.TitleFilter, classNameTitle)}>{textTitle}</span>
            <div
                className={classNames(styles.InputContainer, classNameInputContainer)}
                onClick={openDropDownList}
                ref={selectButtonRef}
            >
                <Input
                    type="text"
                    className={isOpenDropDown ? classNames(classNameDropDown, styles.Input, styles.ActiveDropDown) :
                        classNames(classNameDropDown, styles.Input)}
                    onChange={onChangeInput}
                    value={searchString}
                    placeholder={isPipedriveReports ? 'All' : 'All names'}
                />
                <Image
                    src={searchString.length ? closeImg : searchImg}
                    className={classNames(styles.Image, searchString.length && styles.ImageClose)}
                    onClick={onClearInput}
                />
                {isOpenDropDown && (
                    <div className={classNames(styles.DropDownMenuContainer, classNameDropDownMenu)} ref={dropDownRef}>
                        <div className={styles.DropDownOverflow}>
                            {loader ?
                                listOptions.length ? listOptions.map((item:any) => {
                                const id = item.id;
                                const learnerName = item.name;
                                const isActiveLearner = item.id === filterValue.id;

                                return (
                                    <OptionsComponent
                                        classNameWraperOptions={styles.OptionsWrapper}
                                        className={styles.OptionsText}
                                        isActiveOptions={isActiveLearner}
                                        reference={filterNameRef}
                                        optionText={learnerName}
                                        key={id}
                                        onClick={() => onClickList(learnerName, id)}
                                        classNameShowText={learnerName?.length > 17 && true}
                                    />
                                )}):
                                    <div className={styles.InputNotFound}>Not found learner</div> :
                                <div className={styles.WrapperForLoader}>
                                    <Loader
                                        type="TailSpin"
                                        color="#F85A40"
                                        height={25}
                                        width={25}
                                    />
                            </div>}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilterComponentInput;

import React from 'react';

import styles from './GridItemComponent.module.scss';
import {
    PerformanceHeatMap
} from "../../../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceHeatMap";

const getCellColor = (result: number) => {
    const isPositive = result >= 0;
    const isMedium = Math.abs(result) <= 1;

    return {
        background: isMedium ? "#FFECAA" : isPositive ? "#C7EFD3" : "#FFCCC3",
        color: isMedium ? "#FFC700" : isPositive ? "#05C03C" : "#FF0000"
    };
}

const GridItemComponent = ({metricLabels, heatMapResults}:PerformanceHeatMap) => {

    return (
        <>
            {Object.keys(heatMapResults).map(learnerName => {

                return (
                    <div className={styles.WrapperLearnerMetrics}>

                        <span className={styles.Learner}>{learnerName}</span>
                        <div className={styles.WrapperItems}>
                            {
                                metricLabels.map(metricLabel => {
                                    const result = heatMapResults[learnerName][metricLabel];
                                    const cellStyles = getCellColor(result);
                                    const isPositive = result > 0;
                                    return (
                                        <div
                                            className={styles.Item}
                                            style={{
                                                background: cellStyles.background,
                                                color: cellStyles.color
                                            }}
                                        >
                                            {isPositive ? "+" : ""}{result}%
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default GridItemComponent;

import adminService from '../adminService/adminService';
import stringUtils from '../../utils/stringUtilsService';
import config from '../../../config/config';
import {TeamMember} from '../../../types/TeamMember';
import {TeamMemNameFilter} from '../../../types/filter/teamMember/TeamMemNameFilter';
import {TeamMemEmailFilter} from '../../../types/filter/teamMember/TeamMemEmailFilter';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {getTeamMembers, deleteTeamMember, getFiltersTeam} = externalLinks;

const getDataTeamMembers = async () : Promise<TeamMember[] | undefined> => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getTeamMembers);

    return await adminService.getDataTeamMembers(formattedUrl);
};

const deleteMember = async (id:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteTeamMember);

    const updatedUrl = stringUtils.formatString(formattedUrl, id);

    return await adminService.deleteTeamMember(updatedUrl);
};

const getFiltersMemberName = async () : Promise<TeamMemNameFilter | undefined> => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFiltersTeam);

    const updatedUrl = stringUtils.formatString(formattedUrl,'name');

    return await adminService.getFiltersMember<TeamMemNameFilter>(updatedUrl);
};

const getFiltersMemberEmail = async () : Promise<TeamMemEmailFilter | undefined> => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFiltersTeam);

    const updatedUrl = stringUtils.formatString(formattedUrl,'email');

    return await adminService.getFiltersMember<TeamMemEmailFilter>(updatedUrl);
};

export default {
    getDataTeamMembers,
    deleteMember,
    getFiltersMemberName,
    getFiltersMemberEmail
}

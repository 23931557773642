import config from '../../../config/config';
import stringUtils from '../../utils/stringUtilsService';
import adminService from '../adminService/adminService';
import {dispatch} from '../../../state/store';
import {setLearnersListByPipedrive, setMetricsListByPipedrive} from '../../../state/ducks/integrations';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {getIntegrationsPath, setIntegrationsTelegramPath, setIntegratoinsSlackPath, connectPipedrivePath,
    getLearnersByPipedrivePath, setLearnersToConnectPipedrivePath, disconnectPipedrivePath, getMetricsForIntegrationPath,
    setMetricsForIntegrationPath
} = externalLinks;

const getIntegrationsBots = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getIntegrationsPath);

    return await adminService.getIntegrationsBots(formattedUrl);
};

const setTelegramIntegrations = async (currentId:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setIntegrationsTelegramPath);

    return await adminService.setTelegramIntegrations(formattedUrl, currentId);
};

const setSlackIntegrations = async (currentUrl:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setIntegratoinsSlackPath);

    return await adminService.setSlackIntegrations(formattedUrl, currentUrl);
};

const setPipedriveIntegration = async (code:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, connectPipedrivePath);

    return await adminService.setPipedriveIntegration(formattedUrl, code);
};

const disconnectPipedrive = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, disconnectPipedrivePath);

    return await adminService.disconnectPipedrive(formattedUrl);
};

const getLearnersByPipedrive = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getLearnersByPipedrivePath);

    const learnersList = await adminService.getLearnersByPipedrive(formattedUrl);

    dispatch(setLearnersListByPipedrive(learnersList));
};

const submitLearnersForPipedrive = async (submittedLearners:Array<number>) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setLearnersToConnectPipedrivePath);

    return await adminService.submitLearnersForPipedrive(formattedUrl, submittedLearners);
};

const getMetricsForIntegration = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getMetricsForIntegrationPath);

    const metricsList = await adminService.getMetricsForIntegration(formattedUrl);

    dispatch(setMetricsListByPipedrive(metricsList));
};

const submitMetricsForPipedrive = async (updatedMetrics: Array<number>) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setMetricsForIntegrationPath);

    return await adminService.submitMetricsForPipedrive(formattedUrl, updatedMetrics);
};

export default {
    getIntegrationsBots,
    setTelegramIntegrations,
    setSlackIntegrations,
    setPipedriveIntegration,
    getLearnersByPipedrive,
    submitLearnersForPipedrive,
    disconnectPipedrive,
    getMetricsForIntegration,
    submitMetricsForPipedrive
}

import {MutableRefObject, useEffect} from "react";

export const useOutsideListener = (
    dropDownRef: MutableRefObject<any>,
    selectButtonRef: MutableRefObject<any>,
    closeDropDownList: () => void
) => {
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (selectButtonRef && dropDownRef && !dropDownRef.current?.contains(event.target) &&
                !selectButtonRef.current?.contains(event.target)) {
                closeDropDownList();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRef]);

};
export const firstNeedsList = [
    {
        id: 1,
        name: 'Newcomer Onboarding',
        isChecked: false
    },
    {
        id: 2,
        name: 'Upskilling',
        isChecked: false
    },
    {
        id: 3,
        name: 'Knowledge assesment',
        isChecked: false
    },
]

export default firstNeedsList;
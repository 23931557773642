import React, {MouseEventHandler} from 'react';
import styles from './StepSignUpComponent.module.scss';
import Image from '../../../../../../components/Image/Image';
import introducedStepImg from '../../../../../../../assets/images/icon/create-course-introduced-step-icon.svg';
import step2Img from '../../../../../../../assets/images/icon/sign-up-step-2.svg';
import step3Img from '../../../../../../../assets/images/icon/sign-up-step-3.svg';
import finishStep from '../../../../../../../assets/images/icon/sign-up-finish-step.svg';
import classNames from 'classnames';
import {useMediaQuery} from 'react-responsive';
import Svg from '../../../../../../components/SvgComponent/SvgComponent';
import {ReactComponent as ArrowImg} from '../../../../../../../assets/images/icon/arrow-left-dashboard.svg';

type StepSignUpComponentProps = {
    isOpen3Step:boolean;
    handleGoBack?: MouseEventHandler<HTMLDivElement>;
}

const StepSignUpComponent:React.FC<StepSignUpComponentProps> = ({isOpen3Step, handleGoBack}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    const classNamePassed = classNames(styles.Text, isOpen3Step && styles.StepPassed);

    return (
        <div className={isTabletOrMobile ? styles.WrapperMobile : ''}>
            {!isTabletOrMobile &&
                <div className={styles.WrapperGoBack} onClick={handleGoBack}>
                    <Svg className={styles.ImageMenu}>
                        <ArrowImg/>
                    </Svg>
                    <span className={styles.TextGoBack}>Go back</span>
                </div>
            }
            <div className={styles.ImageContainer}>
                <div className={styles.StepContainer}>
                    <Image
                        src={introducedStepImg}
                        className={styles.StepIcon}
                    />
                    {!isTabletOrMobile && <div className={classNames(styles.Text, styles.StepPassed)}>Your Gradual account</div>}
                </div>
                {!isTabletOrMobile && <div className={styles.Line} />}
            </div>
            <div className={styles.ImageContainer}>
                <div className={styles.StepContainer}>
                    <Image
                        src={isOpen3Step ? introducedStepImg : step2Img}
                        className={styles.StepIcon}
                    />
                        {!isTabletOrMobile && <div className={classNamePassed}>Tell us about your company</div>}
                    </div>
                {!isTabletOrMobile && <div className={styles.Line} />}
            </div>
            <div className={styles.ImageContainer}>
                <div className={styles.StepContainer}>
                    <Image
                        src={step3Img}
                        className={styles.StepIcon}
                    />
                    {!isTabletOrMobile && <div className={classNames(styles.Text, !isOpen3Step && styles.TextFinish)}>Tell us about your needs</div>}
                </div>
                {!isTabletOrMobile && <div className={styles.Line} />}
            </div>
            <div className={styles.ImageContainer}>
                <div className={styles.StepContainer}>
                    <Image
                        src={finishStep}
                        className={styles.StepIcon}
                    />
                    {!isTabletOrMobile && <div className={classNames(styles.Text, styles.TextFinish)}>Get started training</div>}
                </div>
            </div>
            {isTabletOrMobile && <div className={styles.LineMobile} />}
        </div>
    );
};

export default StepSignUpComponent;

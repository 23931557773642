import React, {useState, useRef, useEffect} from 'react';
import classNames from 'classnames';

import styles from './ModuleItemComponent.module.scss';
import {ReactComponent as DeleteModuleImg} from '../../../../../../../../../../../../../../assets/images/icon/delete-course-icon.svg';
import {ReactComponent as DragAndDropImg} from '../../../../../../../../../../../../../../assets/images/icon/drag-drop-quiz-questions-icon.svg'
import Input from '../../../../../../../../../../../../../components/Input/Input';
import Svg from '../../../../../../../../../../../../../components/SvgComponent/SvgComponent';

const ModuleItemComponent = (props:any) => {
    const {moduleId, name, onModuleNameChange, onDeleteModuleClick, isLastCourseModule,
        isVisibleErrorField, sortedModules, onAddModuleButtonClick, setIsVisibleErrorField} = props;

    const classNameInputField = classNames(styles.InputField, isVisibleErrorField && !name.length && styles.ErrorInputField);
    const divRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState(name || '');
    const [lastInputElement, setLastInputElement] = useState(sortedModules[sortedModules.length - 1]);

    useEffect(() => {
        setLastInputElement(sortedModules[sortedModules.length - 1])
    },[sortedModules]);

    useEffect(() => {
        divRef?.current?.scrollIntoView({ behavior: 'smooth' });
    },[sortedModules.length]);

    const onKeyPressEnter = (event:any) => {
        if(event.code === 'Enter' && lastInputElement.id === moduleId && sortedModules[0].name !== null) {
            onAddModuleButtonClick()
        }
    };

    const changeModuleName = (event: React.FormEvent<HTMLInputElement>) => {
        onModuleNameChange(event.currentTarget.value, moduleId);
        setInputValue(event.currentTarget.value);
    };

    return (
        <div className={styles.ModuleItemContainer} ref={divRef}>
            <Svg className={classNames(styles.VisibleIcons, styles.DragAndDrop)}>
                <DragAndDropImg />
            </Svg>
            <Input
                autoFocus
                placeholder='Give your module a name ...'
                className={classNameInputField}
                value={inputValue}
                onChange={changeModuleName}
                onKeyPress={onKeyPressEnter}
            />
            <Svg
                className={classNames(styles.VisibleIcons,styles.DeleteIcon)}
                onClick={() => onDeleteModuleClick(moduleId, isLastCourseModule)}
            >
                <DeleteModuleImg />
            </Svg>
        </div>
    );
};

export default ModuleItemComponent;
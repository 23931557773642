import React, {useMemo} from 'react';
import FilterComponent from '../../../../../../../../../../../components/FilterComponent/FilterComponent';
import styles from './FiltersPerformanceReports.module.scss';
import classNames from 'classnames';
import {PerformanceReport} from '../../../../../../../../../../../types/performanceReportsType/performanceReports';
import dateModify from '../../../../../../../../../../../services/utils/dateModify/dateModify';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import {Filter} from "../../../../../../../../../../../types/filter/Filter";

interface FiltersPerformanceReportsType {
    date: Filter
    status: Filter
    onClickStatus: Function
    onClickDate: Function
    performanceReports: PerformanceReport[]
    handleResetBtnClick: Function
}

const FiltersPerformanceReports = ({date, onClickDate, status, onClickStatus, performanceReports, handleResetBtnClick}: FiltersPerformanceReportsType) => {
    const dateOptions: Filter[] = useMemo(() =>
        [{name: 'All dates', id: -1} , ...performanceReports.map((item, index) => {
            return {
                name: dateModify.setModifyDate(item.timeStamp),
                id: index + 1
            }
        })], [performanceReports]);

    return (
        <div className={styles.WrapperFilters}>
            <FilterComponent
                textTitle='Date'
                filterValue={date}
                listOptions={dateOptions}
                classNameDropDown={classNames(styles.DropDownContainer, styles.DropDownContainerData)}
                classNameDropDownMenu={styles.DropDownMenuContainer}
                classNameContainer={styles.FilterContainerItem}
                classNameTitle={styles.Title}
                onOptionFilterClick={onClickDate}
            />
            <FilterComponent
                textTitle='Status'
                filterValue={status}
                listOptions={[{name:'All', id: -1}, {name:'New', id: 1}, {name: 'Checked', id: 2}]}
                classNameDropDown={styles.DropDownContainer}
                classNameDropDownMenu={styles.DropDownMenuContainer}
                classNameContainer={styles.FilterContainerItem}
                classNameTitle={styles.Title}
                onOptionFilterClick={onClickStatus}
                isPerformanceReports={true}
            />
            <ButtonColor className={styles.BtnReset} onClick={handleResetBtnClick}>
                Reset filters
            </ButtonColor>
        </div>
    );
};

export default FiltersPerformanceReports;

import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.performance;

const makeSelectMetricsListForDashboard = () => createSelector(selectGlobal, (globalState:any) => globalState.metricsListForDashboard);

export {
    makeSelectMetricsListForDashboard
}


export default {
  data: {
    appLoaderGif: "https://media3.giphy.com/media/TF6ZSOZWtXVBOeMIc6/giphy.gif",
    defaultApiLink: "https://server.gradual.io/admin/{0}",
    defaultApi: "https://server.gradual.io",
    localizationPath: "localization/{0}",
    auth: {
      authSignUpPath: "auth/signup",
      authContributorSignUpPath: "auth/contributor/signup",
      authLoginPath: "auth/login",
      authSessionPath: "auth/refresh/session",
      setRecoveryEmailPath: "email/admin/recovery",
      changePasswordPath: "settings/password/change",
      setInfoForRegistrationPath: "intro/basic",
      setDataForQuestionnairePath: "intro/questionnaire",
    },
    externalLinks: {
      coursePath: "course",
      getCourseByIdPath: "course/?id={0}",
      getAllCoursesPath: "course/list",
      brandingColorPath: "branding/color",
      brandingLogoPath: "branding/logo",
      learnersListPath: "learner/list",
      learnerFilterPath: "learner/filter?type={0}",
      deleteLearnerPath: "learner/{0}",
      getIntegrationsPath: "bot",
      setIntegrationsPath: "bot/add",
      getReportsPath: "notification?pageSize={0}&pageNumber={1}",
      getFilterReportsPath: "notification/filter?type={0}",
      getInfoReportTaskPath: "report/task?notificationId={0}",
      getInfoReportQuizPath: "report/quiz?notificationId={0}",
      setIntegrationsTelegramPath: "bot/add/telegram",
      setIntegratoinsSlackPath: "bot/add/slack",
      setCourseGoalPath: "course/goal",
      addModuleByCourseIdPath: "module/create",
      updateModuleByIdPath: "module/update",
      deleteModuleByIdPath: "module/delete",
      deleteBrandLogoPath: "branding/logo/delete",
      getCustomInfoPath: "account/customInfo",
      setModuleGoalPath: "module/field/details",
      setBackgroundCoursePath: "course/background",
      textContentPath: "content/text",
      mediaContentPath: "content/media",
      contentPath: "content/{0}",
      attachFilesPath: "content/attachment",
      attachPdfPath: "content/pdf",
      quizPath: "quiz",
      questionPath: "question",
      taskPath: "task",
      deleteTaskPath: "task/{0}",
      deleteQuestionPath: "question/{0}",
      questionVariantPath: "question/variant",
      deleteQuestionVariantPath: "question/variant/{0}",
      deleteVariantPath: "question/variant/{0}",
      changeQuizTypePath: "question/type",
      deleteQuizPath: "quiz/{0}",
      getAnalyticsPath: "analytics",
      getProgressPath: "progress?courseId={0}",
      getOverviewPath: "analytics/overview",
      getFilteredListAnalyticsPath: "analytics/filter?type={0}",
      getFilteredListPath: "analytics?courseId={0}",
      getCvsPath: "csv/learners",
      setSequenceModulePath: "module/swipe",
      setSequenceContentPath: "content/swipe",
      publicInviteLinkPath: "invite/course/public",
      publicInviteLinkUserPath: "invite/company/public",
      emailInvitationUserPath: "invite/company/emails",
      emailInvitationToCoursePath: "invite/course/emails",
      phoneInvitationToCoursePath: "invite/course/phones",
      emailInvitationToTeamPath: "invite/team/emails",
      setSequenceQuestionPath: "question/swipe",
      setSequenceVariantPath: "question/variant/swipe",
      setDelayModuleSettingsPath: "module/delay/add",
      getTemplatesPath: "template/list",
      setUseTemplatePath: "template/use",
      setRenameFirstNamePath: "learner/info/firstName",
      setRenameLastNamePath: "learner/info/lastName",
      getPaymentInfoPath: "payment/info",
      setPaymentInfoPath: "payment/changePaymentMethod",
      setPayPath: "payment/pay",
      setSeatPath: "seat",
      getSeatsPath: "seat/info",
      getInfoPath: "info",
      getIsLegacyUser: "payment/isLegacy",
      getUserInfoPath: "account/userInfo",
      resetAttemptsPath: "analytics/resetAttempts",
      resetProgressPath: "analytics/resetProgress",
      getListCustomersPath: "lead/list?partnerId={0}",
      getPartnerInfoPath: "partner?partnerId={0}",
      setAttachFileQuestion: "question/attach",
      setAttachFileVariant: "question/attach/variant",
      deleteAttachFile: "question/attach/{0}",
      getTeamMembers: "team/list",
      deleteTeamMember: "team/{0}",
      getFiltersTeam: "team/filter?type={0}",
      statusReportsPath: "notification/status",
      resendVerificationPath: "email/admin/verify",
      emailVerifyPath: "settings/verify/email",
      connectPipedrivePath: "integrations/pipedrive/connect",
      submitAiAssistantAgreement: "integrations/pipedrive/submitNlp",
      disconnectPipedrivePath: "integrations/pipedrive/disconnect",
      getLearnersByPipedrivePath:
        "integrations/pipedrive/getLearnersByPipedrive",
      setLearnersToConnectPipedrivePath:
        "integrations/pipedrive/submitLearnersToConnectPipedrive",
      getMetricsForIntegrationPath: "analytics/pipedrive/metrics/type",
      setMetricsForIntegrationPath: "analytics/pipedrive/metrics/type",
      getMetricsForAnalyticsPath:
        "analytics/pipedrive/metrics?startDate={0}&endDate={1}&learnerId={2}&userId={3}",
      reviewCoursePath: "email/admin/course/review",
      getPerformanceReportsPath: "analytics/pipedrive/reports",
      getPerformanceReportDetailsPath: "analytics/pipedrive/reports/{0}",
      setPerformanceReportStatusPath:
        "analytics/pipedrive/reports/{0}?status={1}",
      getRandomPerformanceReportsPath:
        "report/pipedrive?batchSize={0}&allChecked={1}",
      setPerformanceOverviewPath: "performance-overview/config",
    },
  },
};

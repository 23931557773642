import classNames from 'classnames'
import React from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './OptionsComponent.module.scss';
import Image from '../../../Image/Image';

const OptionsComponent = (props:any) => {
    const {optionText, onClick, isActiveOptions, reference, className, optionImg, classNameType,
        classNameWraperOptions, classNameNotAvailable, classNameShowText, isPerformanceReports} = props;
    const classNameOptions = classNames(styles.Options, className, classNameType, isActiveOptions && styles.ActiveOptions, classNameNotAvailable && classNameNotAvailable, isPerformanceReports && styles.ReportsImg);
    const classNameWrapperOptions = classNames(styles.WrapperOptions, classNameWraperOptions);

    return (
        <div className={classNameWrapperOptions}>
            <Image className={optionImg ? styles.OptionsImg : ''} src={optionImg}/>
            <span data-tip={classNameNotAvailable ? 'Choose a course!' : classNameShowText ? optionText : ''} className={classNameOptions} onClick={!classNameNotAvailable && onClick} ref={reference}>
                {optionText}
            </span>
            <ReactTooltip
                className={styles.Tooltip}
                effect='solid'
            />
        </div>
    );
};

export default OptionsComponent;
import React from 'react';

const Svg = (props:any) => {
    const {children, className, onClick, style} = props;

    return (
        <div
            className={className}
            onClick={onClick}
            style={style}
        >
            {children}
        </div>
    );
};

export default Svg;
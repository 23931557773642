import React from "react";

import styles from "./ContentComponent.module.scss";
import CoursesComponent from "./components/CoursesComponent/CoursesComponent";
import CustomBrandingComponent from "./components/CustomBrandingComponent/CustomBrandingComponent";
import LearnersComponent from "./components/LearnersComponent/LearnersComponent";
import IntegrationsComponent from "./components/IntegrationsComponent/IntegrationsComponent";
import ReportsComponent from "./components/ReportsComponent/ReportsComponent";
import PerformanceReports from "./components/PerformanceReports/PerformanceReports";
import HelpCenterComponent from "./components/HelpCenterComponent/HelpCenterComponent";
import AccountSettingsComponent from "./components/AccountSettingsComponent/AccountSettingsComponent";
import AnalyticsComponent from "./components/AnalyticsComponent/AnalyticsComponent";
import SubscriptionComponent from "./components/SubscriptionComponent/SubscriptionComponent";
import { connect } from "react-redux";
import {
  makeSelectIsTabLoaderCourses,
  makeSelectIsTabLoaderHome,
  makeSelectIsTabLoaderReports,
  makeSelectIsTabLoaderAnalytics,
  makeSelectIsTabLoaderLearners,
  makeSelectIsTabLoaderIntegrations,
  makeSelectIsTabLoaderCustomBranding,
  makeSelectIsTabLoaderSubscription,
  makeSelectIsTabLoaderTeam,
  makeSelectIsTabLoaderPerformanceReports,
} from "../../../../../state/selectors/global";
import { createStructuredSelector } from "reselect";
import Loader from "../../../../../components/Loader/Loader";
import { TabListIndex } from "../MenuListComponent/menuTabList";
import TeamComponent from "./components/TeamComponent/TeamComponent";
import VerifyBlockComponent from "./components/VerifyBlockComponent/VerifyBlockComponent";
import { makeSelectUserDetails } from "../../../../../state/selectors/userDetails";

const mapStateToProps = createStructuredSelector({
  isTabLoaderCourses: makeSelectIsTabLoaderCourses(),
  isTabLoaderHome: makeSelectIsTabLoaderHome(),
  isTabLoaderReports: makeSelectIsTabLoaderReports(),
  isTabLoaderPerformanceReports: makeSelectIsTabLoaderPerformanceReports(),
  isTabLoaderAnalytics: makeSelectIsTabLoaderAnalytics(),
  isTabLoaderLearners: makeSelectIsTabLoaderLearners(),
  isTabLoaderIntegrations: makeSelectIsTabLoaderIntegrations(),
  isTabLoaderCustomBranding: makeSelectIsTabLoaderCustomBranding(),
  isTabLoaderSubscription: makeSelectIsTabLoaderSubscription(),
  isTabLoaderTeam: makeSelectIsTabLoaderTeam(),
  userDetails: makeSelectUserDetails(),
});

const ContentComponent = (props: any) => {
  const {
    activeTab,
    isTabLoaderCourses,
    isTabLoaderReports,
    isTabLoaderAnalytics,
    isTabLoaderLearners,
    isTabLoaderIntegrations,
    isTabLoaderCustomBranding,
    isTabLoaderSubscription,
    isTabLoaderTeam,
    userDetails,
    isTabLoaderPerformanceReports,
  } = props;

  return (
    <div className={styles.ContentContainer}>
      {!userDetails.isEmailVerified && <VerifyBlockComponent />}
      {activeTab.id === TabListIndex.Training &&
        (!isTabLoaderCourses ? (
          <CoursesComponent />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.Reporting &&
        (!isTabLoaderReports ? (
          <ReportsComponent />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.PerformanceReports &&
        (!isTabLoaderPerformanceReports ? (
          <PerformanceReports userDetails={userDetails} />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.Analytics &&
        (!isTabLoaderAnalytics ? (
          <AnalyticsComponent />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.Learners &&
        (!isTabLoaderLearners ? (
          <LearnersComponent />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.Integrations &&
        (!isTabLoaderIntegrations ? (
          <IntegrationsComponent userDetails={userDetails} />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.CustomBranding &&
        (!isTabLoaderCustomBranding ? (
          <CustomBrandingComponent />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.Subscription &&
        (!isTabLoaderSubscription ? (
          <SubscriptionComponent />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === TabListIndex.Team &&
        (!isTabLoaderTeam ? (
          <TeamComponent />
        ) : (
          <Loader
            classNameLogoContainer={styles.LogoContainer}
            classNameMainContainer={styles.MainContainer}
          />
        ))}
      {activeTab.id === 9 && <HelpCenterComponent />}
      {activeTab.id === 10 && <AccountSettingsComponent />}
    </div>
  );
};

export default connect(mapStateToProps)(ContentComponent);

import classNames from 'classnames'
import React from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './OptionsComponentCheckBox.module.scss';
import Input from '../../../Input/Input';

const OptionsComponentCheckBox = (props:any) => {
    const {optionText, onClick, isActiveOptions, reference, className, classNameType, checked, isCheckBoxOption,
        classNameWraperOptions, classNameShowText, isPerformanceReports} = props;

    const classNameOptions = classNames(styles.Options, className, classNameType, isActiveOptions && styles.ActiveOptions, isPerformanceReports && styles.ReportsImg, isCheckBoxOption && styles.CheckBoxOption);
    const classNameWrapperOptions = classNames(styles.WrapperOptions, classNameWraperOptions);

    return (
        <div className={classNameWrapperOptions}>
            <label className={styles.Label}>
                <Input
                    type='checkbox'
                    checked={checked}
                    onChange={onClick}
                />
                <span data-tip={classNameShowText ? optionText : ''} className={classNameOptions} ref={reference}>
                    {optionText}
                </span>
            </label>
            <ReactTooltip
                className={styles.Tooltip}
                effect='solid'
            />
        </div>
    );
};

export default OptionsComponentCheckBox;
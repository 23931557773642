import {IntegrationsStorage} from '../interfaces/integrationsInterface';
import {dataForPipedrive, metricsListByPipedrive} from '../../types/integrationType/dataForPipedrive';

export const SET_TELEGRAM_ID = 'app/integrations/SET_TELEGRAM_ID';
export const SET_SlACK_URL = 'app/integrations/SET_SlACK_URL';
export const SET_IS_VISIBLE_CONFIGURATION = 'app/integration/SET_IS_VISIBLE_CONFIGURATION';
export const SET_IS_VISIBLE_ASSISTANT_POP_UP = 'app/integration/SET_IS_VISIBLE_ASSISTANT_POP_UP';
export const SET_LEARNERS_LIST_BY_PIPEDRIVE = 'app/integration/SET_LEARNERS_LIST_BY_PIPEDRIVE';
export const SET_METRICS_LIST_BY_PIPEDRIVE = 'app/integration/SET_METRICS_LIST_BY_PIPEDRIVE';

const initialState:IntegrationsStorage = {
    telegramId: null,
    slackUrl: null,
    isVisibleConfiguration: false,
    isVisibleAssistantPopUp: false,
    learnersListByPipedrive: [],
    metricsListByPipedrive: []
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_TELEGRAM_ID: {
            return {...state, telegramId: action.payload};
        }
        case SET_SlACK_URL: {
            return {...state, slackUrl: action.payload};
        }
        case SET_IS_VISIBLE_CONFIGURATION: {
            return {...state, isVisibleConfiguration: action.payload}
        }
        case SET_IS_VISIBLE_ASSISTANT_POP_UP: {
            return {...state, isVisibleAssistantPopUp: action.payload}
        }
        case SET_LEARNERS_LIST_BY_PIPEDRIVE: {
            return {...state, learnersListByPipedrive: action.payload}
        }
        case SET_METRICS_LIST_BY_PIPEDRIVE: {
            return {...state, metricsListByPipedrive: action.payload}
        }
        default: return state;
    }
}

export const setTelegramId = (value:number|null) => ({type: SET_TELEGRAM_ID, payload: value});

export const setSlackUrl = (value:string|null) => ({type: SET_SlACK_URL, payload: value});

export const setIsVisibleConfiguration = (value:boolean) => ({type: SET_IS_VISIBLE_CONFIGURATION, payload: value});

export const setIsVisibleAssistantPopUp = (value:boolean) => ({type: SET_IS_VISIBLE_ASSISTANT_POP_UP, payload: value});

export const setLearnersListByPipedrive = (value:Array<dataForPipedrive>) => ({type: SET_LEARNERS_LIST_BY_PIPEDRIVE, payload: value});

export const setMetricsListByPipedrive = (value:Array<metricsListByPipedrive>) => ({type: SET_METRICS_LIST_BY_PIPEDRIVE, payload: value});
import React from 'react';

const Image = (props:any) => {
    const {src, alt, className, onClick, onMouseOver, onMouseLeave} = props;

    return (
        <img
            src={src}
            alt={alt}
            className={className}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        />
    );
};

export default Image;
import React from 'react';

import styles from './SuccessContainer.module.scss';
import Image from '../../../components/Image/Image';
import Line from '../../../components/Line/Line';
import ButtonText from '../../../components/Button/components/ButtonText/ButtonText';
import appRouterService from '../../../services/route/appRouterService';
import successIcon from '../../../../assets/images/icon/check-mark-white.svg';

const SuccessContainer = () => {

    const onSignUpButtonClick = () => {
        appRouterService.forwardToAdminSignUpPage();
    };

    return (
        <div>
            <div className={styles.WrapperTitle}>
                <div className={styles.Title}>Check your inbox</div>
                <span className={styles.Circle}>
                    <Image src={successIcon} className={styles.Image}/>
                </span>
            </div>
            <div className={styles.WrapperSubtitle}>
                <div>We have sent an email containing instructions on how</div>
                <div>to change your password to the email address you supplied.</div>
            </div>
            <div className={styles.WrapperSignUp}>
                <div className={styles.TitleDescriptionContainer}>
                    <Line className={styles.Line} />
                    <span>Do not have an account?</span>
                    <Line className={styles.Line} />
                </div>
                <ButtonText className={styles.SignUpButton} onClick={onSignUpButtonClick}>
                    Sign up
                </ButtonText>
            </div>
        </div>
    );
};

export default SuccessContainer;

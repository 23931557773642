export const rolesList = [
    {
        id: 1,
        name: 'Sales Leader',
    },
    {
        id: 2,
        name: 'Sales Enablement Leader',
    },
    {
        id: 3,
        name: 'SDR Leader',
    },
    {
        id: 4,
        name: 'Learning & Development Leader',
    },
    {
        id: 5,
        name: 'Business Trainer',
    },
    {
        id: 6,
        name: 'Sales Operations Leader',
    },
    {
        id: 7,
        name: 'Support Leader',
    },
    {
        id: 8,
        name: 'Business Development Leader',
    },
    {
        id: 9,
        name: 'Other',
    },
]

export default rolesList;
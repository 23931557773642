import React from 'react';

import quizImg from '../../../../../../../../../../../../assets/images/icon/quiz-block-button.svg';
import taskImg from '../../../../../../../../../../../../assets/images/icon/task-block-button.svg';
import fileImg from '../../../../../../../../../../../../assets/images/icon/file-block-button.svg';
import settingsImg from '../../../../../../../../../../../../assets/images/icon/settings-block-button.svg';
import ContentFieldItems from '../ContentFieldItems/ContentFieldItems';
import InputFile from '../../../../../../../../../../../components/Input/InputFile/InputFile';
import {dispatch} from '../../../../../../../../../../../state/store';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    setDraftModule,
    setDraftModuleQuiz,
    setDraftQuizQuestion
} from '../../../../../../../../../../../state/ducks/courseConstructor';
import {updateCourseModuleList} from "../../../../../../../draftCourseService";
import {makeSelectDraftModule} from '../../../../../../../../../../../state/selectors/courseConstructor';
import moduleService from '../../../../../../../../../../../services/server/moduleService/moduleService';

const mapStateToProps = createStructuredSelector({
    draftModule: makeSelectDraftModule()
});

const MoreFieldsList = (props:any) => {
    const {classNameContainerList, draftModule, hideNewContentModal, openTaskPopup, openQuizPopup,
        openDelayPopup} = props;
    
    const onFileClick = async (event:any) => {
        await moduleService.addAttachFile(event, draftModule, event.target.files[0].name);
        hideNewContentModal();
    };
    
    const onTaskClick = async () => {
        hideNewContentModal();

        if(!(draftModule?.task)) {
            const newTask = await moduleService.createNewTask(draftModule.id);
            const updatedModule = {...draftModule, task: newTask};

            dispatch(setDraftModule(updatedModule));
            updateCourseModuleList(updatedModule);
        }
        openTaskPopup();
    };
    
    const onQuizClick = async () => {
        hideNewContentModal();
        
        if(!draftModule?.quiz) {
            const newQuiz = await moduleService.createNewQuiz(draftModule.id);
            const updatedModule = {...draftModule, quiz: newQuiz};

            dispatch(setDraftModule(updatedModule));
            dispatch(setDraftModuleQuiz(newQuiz));
            dispatch(setDraftQuizQuestion(newQuiz.questions[0]));
            updateCourseModuleList(updatedModule);
        }
        openQuizPopup();
    };
    
    const onSettingsClick = () => {
        hideNewContentModal();
        openDelayPopup();
    };
    
    return (
        <div className={classNameContainerList}>
            <ContentFieldItems
                name='Quiz'
                src={quizImg}
                onClick={onQuizClick}
            />
            <ContentFieldItems
                name='Task'
                src={taskImg}
                onClick={onTaskClick}
            />
            <InputFile onChange={onFileClick}>
                <ContentFieldItems
                    name='File'
                    src={fileImg}
                />
            </InputFile>
            <ContentFieldItems
                name='Settings'
                src={settingsImg}
                onClick={onSettingsClick}
            />
        </div>
    );
};

export default connect(mapStateToProps)(MoreFieldsList);
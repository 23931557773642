import React, {useEffect} from 'react';
import Modal from '../../containers/ServiceContainers/Modal/Modal';
import PopupFullPage from '../Popup/PopupFullPage/PopupFullPage';
import styles from './DeletePopup.module.scss';
import ButtonColor from '../Button/components/ButtonColor/ButtonColor';

type Props = {
    className?: any;
    classNameBackground?: any;
    onDelete: Function;
    onHide: Function;
    textData: DeleteTextData;
};

type DeleteTextData = {
    title: string;
    header: {
        general?:string;
        specific?:string;
        note?:string;
    };
    deleteText?: string
    learnerName?: string
    courseName?: string
}

export const DeletePopup = (props:Props) => {
    const {onDelete, onHide, textData, className, classNameBackground} = props;
    const {title, header, deleteText, learnerName, courseName} = textData;
    const {general = '', specific = '', note = ''} = header;

    return (
        <Modal>
            <PopupFullPage
                classNameBackground={classNameBackground}
                className={className}
                classNameMainContainer={styles.MainPopupContainer}
                closePopup={onHide}
                isSupportOutsidePopupClick
            >
                <div className={styles.MainContainer}>
                    {title ?
                        <span className={styles.TitleText}>{title}</span> :
                        <span className={styles.TitleText}>
                            Send the notification to <span className={styles.LearnerName}>{learnerName}</span> to review <span className={styles.CourseName}>{courseName}?</span>
                        </span>
                    }
                    <div className={styles.FirstDescriptionText}>
                        {general}
                        <span> {specific}</span>
                    </div>
                    <span className={styles.SecondDescriptionText}>
                        {note}
                    </span>
                    <div className={styles.ButtonContainer}>
                        <ButtonColor
                            className={styles.CancelButton}
                            onClick={onHide}
                        >
                            Cancel
                        </ButtonColor>
                        <ButtonColor
                            className={styles.ConfirmButton}
                            onClick={onDelete}
                        >
                            {deleteText ? deleteText : 'Yes, delete it'}
                        </ButtonColor>
                    </div>
                </div>
            </PopupFullPage>
        </Modal>
    );
};
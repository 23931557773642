import React, {useEffect, useRef} from 'react';

import styles from './DropDownTypesQuizComponent.module.scss';
import dropDownBottomImg from '../../../../../../../../../../assets/images/icon/drop-down-type-question-bottom.svg';
import Image from '../../../../../../../../../components/Image/Image';
import {typesQuestionList} from '../../typesQuestionList';

const DropDownTypesQuizComponent = (props:any) => {
    const {activeTypesQuestion, isVisibleDownList, setIsVisibleDownList, onTypeQuizClick,
        draftQuizQuestion} = props;

    const optionsListRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);
    
    const useOutsideAlerter = () => {
        useEffect(() => {
            function handleClickOutside(event:any) {
                if (dropDownRef.current && dropDownRef.current.contains(event.target)) {
                    setIsVisibleDownList(!isVisibleDownList);
                } else if (optionsListRef.current && !optionsListRef.current.contains(event.target)) {
                    setIsVisibleDownList(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [optionsListRef, isVisibleDownList]);
    };
    
    useOutsideAlerter();
    
    const onItemButtonClick = (optionsId:number) => {
        onTypeQuizClick(optionsId, draftQuizQuestion.id);
        setIsVisibleDownList(false);
    };
    
    return (
        <div className={styles.DropDownContainer}>
            <span className={styles.DropDownLabel}>Type of question</span>
            <div className={styles.DropDown} ref={dropDownRef}>
                <span className={styles.DropDownName}>{activeTypesQuestion?.name}</span>
                <Image
                    src={dropDownBottomImg}
                    className={`${styles.DropDownIcon} ${isVisibleDownList && styles.ActiveDropDownIcon}`}
                />
            </div>
            {isVisibleDownList && (
                <div className={styles.OptionsList} ref={optionsListRef}>
                    {typesQuestionList.map((item:any, index:any) => {
                        const optionsName = item.name;
                        const optionsId = item.id;
                        const isActiveTypeQuestion = activeTypesQuestion.id === item.id;

                        return (
                            <div
                                className={`${styles.OptionItem} ${isActiveTypeQuestion && styles.ActiveOptionItem}`}
                                onClick={!isActiveTypeQuestion && (() => onItemButtonClick(optionsId)) || (() => {})}
                                key={optionsId}
                            >
                                {optionsName}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
};

export default DropDownTypesQuizComponent;
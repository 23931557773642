import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import ColorPicker, { useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

import styles from "./NewBrandColorComponent.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectBrandColor } from "../../../../../../../../../state/selectors/customBranding";

const mapStateToProps = createStructuredSelector({
  brandColor: makeSelectBrandColor(),
});

const NewBrandColorComponent = (props: any) => {
  const { setColor, resetBrandingColor, brandColor } = props;

  const [isVisibleColorPicker, setIsVisibleColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useColor(
    "hex",
    `${brandColor ? brandColor : "#f85a40"}`
  );
  const colorPikerRef = useRef<HTMLDivElement>(null);
  const colorPikerContainerRef = useRef<HTMLDivElement>(null);

  const openColorPicker = () => {
    setIsVisibleColorPicker(true);
  };

  const hideColorPicker = () => {
    setIsVisibleColorPicker(false);
  };

  useEffect(() => {
    setColor(currentColor.hex);
  }, [currentColor]);

  function useOutsideAlerter(colorPikerRef: any) {
    useEffect(() => {
      async function handleClickOutside(event: any) {
        if (
          colorPikerRef.current &&
          colorPikerContainerRef.current &&
          !colorPikerRef.current.contains(event.target) &&
          !colorPikerContainerRef.current.contains(event.target)
        ) {
          await resetBrandingColor();
          await hideColorPicker();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [colorPikerRef]);
  }

  useOutsideAlerter(colorPikerRef);

  return (
    <div className={styles.BrandColorContainer}>
      <div className={styles.TitleText}>Brand color</div>
      <div>
        <div
          className={styles.NewColorContainer}
          ref={colorPikerContainerRef}
          onClick={openColorPicker}
        >
          {isVisibleColorPicker && (
            <div className={styles.ColorPickerContainer} ref={colorPikerRef}>
              <ColorPicker
                width={228}
                height={176}
                color={currentColor}
                onChange={setCurrentColor}
                hideHSB
                hideRGB
              />
            </div>
          )}
          <div
            className={classNames(
              styles.ColorBlock,
              currentColor.hex === "#ffffff" && styles.BorderColor
            )}
            style={{ backgroundColor: `${currentColor.hex}` }}
          />
          <div className={styles.ColorCode}>
            {currentColor.hex.toUpperCase()}
          </div>
        </div>
        <span className={styles.SignatureText}>
          Logo and brand color are appeared on published training.
        </span>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(NewBrandColorComponent);

import moduleService from '../../../../../../../services/server/moduleService/moduleService';
import {dispatch} from '../../../../../../../state/store';
import {setDraftModuleQuiz, setDraftQuizQuestion} from "../../../../../../../state/ducks/courseConstructor";
import draftModuleService from "../../draftModuleService";

const updateDraftModuleQuizQuestion = (draftModuleQuiz:any, newDraftQuestion:any) => {
    const updatedQuestionList = draftModuleQuiz.questions.map((question:any) => {
        if (question.id === newDraftQuestion.id) {
            return newDraftQuestion;
        }
        return question;
    });
    const updatedQuiz = {...draftModuleQuiz, questions: updatedQuestionList};

    dispatch(setDraftModuleQuiz(updatedQuiz));

    draftModuleService.updateModuleQuiz(updatedQuiz);
};

const updateDraftQuizQuestionVariantList = (draftQuizQuestion:any, updatedVariantList:any[]) => {
    const newDraftQuestion = {...draftQuizQuestion, multipleChoiceQuestion:{...draftQuizQuestion.multipleChoiceQuestion, variants: updatedVariantList}};

    dispatch(setDraftQuizQuestion(newDraftQuestion));

    return newDraftQuestion;
};

const updateVariantsListSequence = (updatedVariants:any[], previousVariants:any[]) => {
    const sortedVariantSequences = updatedVariants.map((variant:any, index:number) => {
        const previousVariant:any = previousVariants[index];
        return {
            variantId: variant.id,
            sequenceId: previousVariant.sequenceId
        };
    });

    const mutatedVariantListBySequence = updatedVariants.map((variant:any, index:number) => {
        variant.sequenceId = sortedVariantSequences[index].sequenceId;
        return variant;
    });

    moduleService.updateVariantSequence(sortedVariantSequences);

    return mutatedVariantListBySequence;
};

export default {
    updateVariantsListSequence,
    updateDraftModuleQuizQuestion,
    updateDraftQuizQuestionVariantList
}
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import styles from './ListQuestionsItemComponent.module.scss';
import essayImg from '../../../../../../../../../../../assets/images/icon/essay-icon.svg';
import Image from '../../../../../../../../../../components/Image/Image';
import Input from '../../../../../../../../../../components/Input/Input';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectFilteredQuizDetails} from '../../../../../../../../../../state/selectors/reports';
import ImageFullContainer
    from '../../../../../../../../../CourseConstructorPage/components/CourseConstructorComponent/DraftModuleComponent/componentns/QuizPopupComponent/components/QuestionComponent/components/MultipleChoiceQuestionComponent/components/ImageFullContainer/ImageFullContainer';

const mapStateToProps = createStructuredSelector({
    filteredQuizDetails: makeSelectFilteredQuizDetails(),
});

const ListQuestionsItemComponent = (props:any) => {
    const {filteredQuizDetails, attempts} = props;

    const [isVisibleImgFull, setIsVisibleImgFull] = useState(false);
    const [activeAnswer, setActiveAnswer] = useState({data: filteredQuizDetails?.questionReports[0], id: 1});
    const isEssayQuestion = activeAnswer.data?.essayAnswer !== undefined;
    const isCorrect = activeAnswer.data?.isCorrect;
    const classNameNumber = classNames(styles.NumberListDetails,
        isCorrect ? styles.NumberPassed : isEssayQuestion ? styles.NumberEssay : styles.NumberFailed);
    const [image, setImage] = useState();

    useEffect(() => {
        setActiveAnswer({data: filteredQuizDetails?.questionReports[0], id: 1});
    }, [filteredQuizDetails]);

    const onHeaderButtonClick = (id:number, index:number) => {
        const newActiveQuestion = filteredQuizDetails?.questionReports.find((question:any) => question.id === id);
        setActiveAnswer({data: newActiveQuestion, id: index});
    };

    const onOpenImage = (image:any) => {
        setImage(image);
        setIsVisibleImgFull(true);
    };

    return (
        <>
            <div className={styles.ContainerListQuestionAndDetails}>
                <div className={classNames(styles.ListQuestionsContainer, attempts < 1 && styles.ListQuestionsContainerAttempts)} >
                    {filteredQuizDetails?.questionReports?.map((item:any, index:any) => {
                        const id = item.id;
                        const nameTitle = item?.title;
                        const essayType = item?.essayAnswer !== undefined;
                        const isCorrect = item?.isCorrect;
                        const activeQuestion = id === activeAnswer.data.id || activeAnswer.data.id === 1;

                        return (
                            <div key={id} className={classNames(styles.ListQuestions, activeQuestion && styles.ActiveQuestion )}>
                                <label
                                    className={isCorrect ?
                                        styles.WrapperCheckbox :
                                        classNames(styles.WrapperCheckbox, styles.InputFailed)}>
                                    {essayType ?
                                        <Image src={essayImg}
                                               className={styles.EssayImg} /> :
                                        <Input type='checkbox'/>
                                    }
                                    <span
                                        onClick={() => onHeaderButtonClick(id, index + 1)}
                                        className={styles.NumberList}>{index + 1}
                                    </span>
                                    <div
                                        onClick={() => onHeaderButtonClick(id, index + 1)}
                                        className={styles.TitleQuestions}>
                                        {nameTitle}
                                    </div>
                                </label>
                            </div>
                        )})}
                </div>
                <div className={classNames(styles.WrapperAnswer, attempts < 1 && styles.WrapperAnswerAttempts)}>
                    <div className={styles.WrapperAnswer}>
                        {activeAnswer.data?.attachFile &&
                            <Image src={activeAnswer.data?.attachFile?.link}
                                   className={styles.ImageAttach}
                                   onClick={() => onOpenImage(activeAnswer.data?.attachFile?.link)}/>
                        }
                        <div className={styles.ContainerQuestion}>
                            <span className={classNameNumber}>{activeAnswer?.id}</span>
                            <span className={styles.TitleQuestionsDetails}>{activeAnswer.data?.title}</span>
                        </div>
                        {!isEssayQuestion && activeAnswer.data?.answers?.map((item: any) => {
                            const id = item.id;
                            const attachFile = item?.attachFile;
                            const learnerAnswer = item.learnerAnswer;
                            const expectedAnswer = item.expectedAnswer;
                            const classNameExpectInput = classNames(styles.InputExpected, expectedAnswer ?
                                styles.isCorrectExpected : styles.isNotCorrectExpected);
                            const classNameAnswerInput = classNames(learnerAnswer && expectedAnswer ?
                                styles.isCorrect : !expectedAnswer && learnerAnswer ? styles.FailedAnswer :
                                    !learnerAnswer ? '' : '');

                            return (
                                <>
                                {attachFile && <Image src={attachFile?.link} className={styles.ImageAttach} onClick={() => onOpenImage(attachFile?.link)}/>}
                                    <div key={id} className={styles.ContainerDetails}>
                                        <label className={styles.WrapperCheckboxDetails}>
                                            <Input
                                                type='checkbox'
                                                className={classNameExpectInput}
                                            />
                                            <Input
                                                type='checkbox'
                                                className={classNameAnswerInput}
                                            />
                                        </label>
                                        <div className={styles.TitleDetails}>{item?.title}</div>
                                    </div>
                                </>
                            )
                        })}
                        {isEssayQuestion && (
                            <div className={styles.TextEssay}>{activeAnswer?.data?.essayAnswer}</div>
                        )}
                    </div>
                </div>
            </div>
            {isVisibleImgFull &&
                <ImageFullContainer
                    imageSrc={image}
                    setIsVisibleImgFull={setIsVisibleImgFull}
                />
            }
        </>
    )
};

export default connect(mapStateToProps)(ListQuestionsItemComponent);
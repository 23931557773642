import React from 'react';

import styles from './FinishedInvitation.module.scss';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';

const FinishedInvitation = (props:any) => {
    const {hideInviteLearnersPopup, invitationCount} = props;

    return (
        <div>
            <span className={styles.TitleText}>
                Invitations are on their way 🚀
            </span>
            <span className={styles.SummaryText}>
                    Number of invitations successfully sent {invitationCount}
            </span>
            <div className={styles.DoneButtonContainer}>
            <ButtonColor onClick={hideInviteLearnersPopup} className={styles.DoneInvitationButton}>
                Done
            </ButtonColor>
            </div>
        </div>
    );
};

export default FinishedInvitation;

export const streamlineListPro = [
    {
        id: 1,
        name: 'Training editor',
    },
    {
        id: 2,
        name: 'Quiz and task editor',
    },
    {
        id: 3,
        name: 'Training reports and analytics',
    },
    {
        id: 4,
        name: 'Templates and courses library',
    },
    {
        id: 5,
        name: 'Integration with messengers',
    },
];

export default streamlineListPro;
import React from "react";

import {dispatch} from "../../../../../../../state/store";
import {setDraftModule} from "../../../../../../../state/ducks/courseConstructor";
import {DeletePopup} from "../../../../../../../components/DeletePopup/DeletePopup";
import {updateCourseModuleList} from "../../../draftCourseService";
import moduleService from "../../../../../../../services/server/moduleService/moduleService";

const DeleteTaskComponent = (props:any) => {
    const {draftModule, finishDeleteTask} = props;

    const deleteTask = async () => {
        await moduleService.deleteTaskById(draftModule?.task.id);

        const updatedModule = {...draftModule, task: null};
        dispatch(setDraftModule(updatedModule));
        updateCourseModuleList(updatedModule);

        finishDeleteTask();
    };

    return (
        <DeletePopup
            onDelete={deleteTask}
            onHide={finishDeleteTask}
            textData={{
                title:'Are you sure?',
                header:{
                    general:'You are about to remove this task!',
                    specific: '',
                    note:'It\'ll be gone forever and we won\'t be able to recover it.'
                }
            }}
        />
    );
};

export default DeleteTaskComponent;
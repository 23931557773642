import React from 'react';

import styles from './AttachFileComponent.module.scss';
import attachFileImg from '../../../../../../../../../../../assets/images/icon/file-block-button.svg';
import Image from '../../../../../../../../../../components/Image/Image';
import routerService from '../../../../../../../../../../services/route/routerService';

const AttachFileComponent = (props:any) => {
    const {attachFileContent} = props;
    const {name, link} = attachFileContent;
    
    const onAttachFileClick = () => {
        routerService.blankLinkOpen(link);
    };
    
    return (
        <div className={styles.AttachFilesContainer} onClick={onAttachFileClick}>
            <Image src={attachFileImg} className={styles.Image} />
            <span>{name}</span>
        </div>
    );
};

export default AttachFileComponent;
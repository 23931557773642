import classNames from 'classnames';
import React, {useState} from 'react';

import styles from './CourseGoalComponent.module.scss';
import introducedStepImg
    from '../../../../../../../../../../../../assets/images/icon/create-course-introduced-step-icon.svg';
import newStepImg from '../../../../../../../../../../../../assets/images/icon/create-course-new-step-icon.svg';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import Image from '../../../../../../../../../../../components/Image/Image';
import TextArea from '../../../../../../../../../../../components/TextArea/TextArea';
import validationService from '../../../../../../../../../../../services/server/validationService/validationService';

const CourseGoalComponent = (props:any) => {
    const {courseGoal, isGoalIntroduced, onNextStepButtonClick, onGoalChange, stepIconClassName,
        classNameLabelText} = props;

    const [isGoalError, setIsGoalError] = useState(false);
    const [count, setCount] = useState(courseGoal.length || 0);
    const classNameTextarea = classNames(styles.InputField, isGoalError && styles.ErrorInput,
        isGoalIntroduced && styles.IntroducedGoal
    );
    const classNameInputContainer = classNames(styles.InputContainer, isGoalIntroduced && styles.IntroducedGoalInputContainer);
    const classNameCharacterCount = classNames(
        styles.CharactersCount,
        isGoalError && styles.ErrorCharactersCount,
        isGoalIntroduced && styles.IntroducedName
    );

    const onKeyPressGoalCourse = (event:any) => {
        if (event.code === "Enter") {
            onNextStepClick()
        }
    };

    const onInputFieldChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const isValidCourseGoal = validationService.isValidName(
            event.currentTarget.value
        );
        setCount(event.currentTarget.value.length);
        onGoalChange(event.currentTarget.value);
        setIsGoalError(!isValidCourseGoal);
    };
    
    const onNextStepClick = () => {
        const isValidCourseGoal = validationService.isValidName(courseGoal);
    
        onNextStepButtonClick(isValidCourseGoal, courseGoal.length);
        setIsGoalError(!isValidCourseGoal);
    };
    
    return (
        <div className={styles.GoalComponent}>
            <span className={styles.StepText}>2/3</span>
            <div className={styles.ImageContainer}>
                <Image
                    src={isGoalIntroduced ? introducedStepImg : newStepImg}
                    className={stepIconClassName}
                />
                <div className={styles.Line} />
            </div>
            <div className={classNameInputContainer}>
                <span className={classNameLabelText}>Type the goal</span>
                <TextArea
                    autoFocus
                    isDisableInput={isGoalIntroduced}
                    className={classNameTextarea}
                    onChange={onInputFieldChange}
                    value={courseGoal}
                    maxLength={236}
                    onKeyPress={onKeyPressGoalCourse}
                />
                <div className={classNameCharacterCount}>{count}/236</div>
                {!isGoalIntroduced && (
                    <ButtonColor
                        className={styles.NextStepButton}
                        onClick={onNextStepClick}
                    >
                        Next step
                    </ButtonColor>
                )}
            </div>
        </div>
    );
};

export default CourseGoalComponent;
import adminService from '../adminService/adminService';
import stringUtils from '../../utils/stringUtilsService';
import config from '../../../config/config';
import {dispatch} from '../../../state/store';
import {setResponseChangeCard, setResponsePay} from '../../../state/ducks/subscription';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {getPaymentInfoPath, setPayPath, setSeatPath, getSeatsPath, setPaymentInfoPath, getIsLegacyUser} = externalLinks;

const getPaymentInfo = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getPaymentInfoPath);

    return await adminService.getPaymentInfo(formattedUrl);
};

const setPaymentInfo = async (newPayment:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setPaymentInfoPath);

    const responseChangeCard = await adminService.setPaymentInfo(formattedUrl, newPayment);

    dispatch(setResponseChangeCard(responseChangeCard));
};

const setPay = async (priceId:string, customerId:string, paymentMethodId:string, seatsNumber:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setPayPath);

    const responsePay = await adminService.setPay(formattedUrl, priceId, customerId, paymentMethodId, seatsNumber);

    dispatch(setResponsePay(responsePay));
};

const setSeats = async (seat:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setSeatPath);

    return await adminService.setSeats(formattedUrl, seat);
};

const getSeats = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getSeatsPath);

    return await adminService.getSeats(formattedUrl);
};

const getInfoAboutUser = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getIsLegacyUser);

    return await adminService.getInfoAboutUser(formattedUrl);
};

export default {
    getPaymentInfo,
    setPay,
    setSeats,
    getSeats,
    setPaymentInfo,
    getInfoAboutUser
}
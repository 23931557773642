import React, {useEffect, useRef, useState} from 'react';

import styles from './AddYouTubeVideoPopup.module.scss';
import Modal from '../../../../../../ServiceContainers/Modal/Modal';
import PopupFullPage from '../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import ButtonColor from '../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import InputLinkComponent from './components/InputLinkComponent/InputLinkComponent';
import {dispatch} from '../../../../../../../state/store';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectDraftModule} from '../../../../../../../state/selectors/courseConstructor';
import {setDraftModule} from '../../../../../../../state/ducks/courseConstructor';
import {updateCourseModuleList} from "../../../draftCourseService";
import moduleService from '../../../../../../../services/server/moduleService/moduleService';
import {setIsContentLoader} from '../../../../../../../state/ducks/global';

const mapStateToProps = createStructuredSelector({
    draftModule: makeSelectDraftModule()
});

const AddYouTubeVideoPopup = (props:any) => {
    const {hideYouTubePopup, draftModule} = props;

    const [link, setLink] = useState('');
    const inputFieldRef = useRef<HTMLInputElement>(null);
    const [isInputError, setIsInputError] = useState(false);
    
    const onSaveButtonClick = async () => {
        if(!isInputError) {
            let currentLink = link;
    
            if (currentLink.includes("watch?v=")) {
                currentLink = currentLink.replace("watch?v=", "embed/");
                const n = currentLink.indexOf('&');
                currentLink = currentLink.substring(0, n !== -1 ? n : currentLink.length);
            }
            if (currentLink.includes("youtu.be")){
                currentLink = currentLink.replace("https://youtu.be/", "https://www.youtube.com/embed/");
            }

            const newFiles:any = new FormData();
            newFiles.append('link', currentLink);
            newFiles.append('type', 'VIDEO');
            newFiles.append('moduleId', draftModule.id);
            dispatch(setIsContentLoader(true));
            const newContent = await moduleService.addMediaBlock(newFiles);
            
            const contentList = draftModule.contents;
            await contentList.push(newContent?.data);
            const newDraftModule = {...draftModule, contents: contentList};
            dispatch(setDraftModule(newDraftModule));
            updateCourseModuleList(newDraftModule);
            hideYouTubePopup();
            dispatch(setIsContentLoader(false));
        }
    };
    
    useEffect(() => {
        if(inputFieldRef && inputFieldRef.current) {
            inputFieldRef.current.focus();
        }
    }, [link]);
    
    const onYouTubeLinkChange = (event: React.FormEvent<HTMLInputElement>) => {
        const isValidYouTubeLink = event.currentTarget.value.includes('https://www.youtube.com') || event.currentTarget.value.includes('https://youtu.be');
        setLink(event.currentTarget.value);
        setIsInputError(!isValidYouTubeLink);
    };
    
    return (
        <Modal>
            <PopupFullPage
                classNameMainContainer={styles.MainPopupContainer}
                closePopup={hideYouTubePopup}
                isSupportOutsidePopupClick
            >
                <div className={styles.ContentContainer}>
                    <span className={styles.PopupTitle}>Add YouTube video</span>
                    <InputLinkComponent
                        link={link}
                        onYouTubeLinkChange={onYouTubeLinkChange}
                        reference={inputFieldRef}
                        isInputError={isInputError}
                    />
                    <ButtonColor className={styles.SaveButton} onClick={onSaveButtonClick}>
                        Save
                    </ButtonColor>
                </div>
            </PopupFullPage>
        </Modal>
    )
};

export default connect(mapStateToProps)(AddYouTubeVideoPopup);
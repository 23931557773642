import React from 'react';

import FilterComponent from '../../../../../../../../components/FilterComponent/FilterComponent';
import styles from './TeamSizeComponent.module.scss';
import teamSizeList from './teamSizeList';

type sizeType = {
    name: string;
    id: number;
};

type TeamSizeComponentProps = {
    size: sizeType;
    setSize: Function;
};

const TeamSizeComponent:React.FC<TeamSizeComponentProps> = ({size, setSize}) => {

    const onSizeClick = (name: string, id: number) => {
        setSize({name: name, id: id})
    };

    return (
        <FilterComponent
            filterValue={size}
            classNameOptions={styles.Options}
            classNameDropDown={styles.DropDownContainer}
            classNameDropDownMenu={styles.DropDownMenuContainer}
            classNameContainer={styles.FilterContainerItem}
            onOptionFilterClick={onSizeClick}
            textTitle='Team size'
            classNameTitle={styles.Title}
            listOptions={teamSizeList}
            signUp={true}
        />
    );
};

export default TeamSizeComponent;

import React, {useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import {withCookies} from 'react-cookie';

import styles from './CourseConstructorPage.module.scss';
import ModuleListComponent from './components/ModuleListComponent/ModuleListComponent';
import CourseConstructorComponent from './components/CourseConstructorComponent/CourseConstructorComponent';
import Image from '../../components/Image/Image';
import {connect} from 'react-redux';
import {dispatch} from '../../state/store';
import {createStructuredSelector} from 'reselect';
import {makeSelectDraftCourse} from '../../state/selectors/courseConstructor';
import {
    setDraftModule,
    setDraftModuleQuiz,
    setDraftQuizQuestion
} from '../../state/ducks/courseConstructor';
import smileImg from '../../../assets/images/icon/smile-icon.svg';
import sessionStorageService from '../../services/storage/sessionStorageService';
import appRouterService from '../../services/route/appRouterService';
import {Course, loadCourseById} from './components/CourseConstructorComponent/draftCourseService';
import Logo from '../../components/Logo/Logo';

const mapStateToProps = createStructuredSelector({
    draftCourse: makeSelectDraftCourse()
});

const CourseConstructorPage = (props:any) => {
    const {draftCourse, cookies} = props;
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    const loadCourse = async (courseId:number) => {
        const draftCourse:Course = await loadCourseById(courseId);
        setDraftCourse(draftCourse);
    };

    const setDraftCourse = (draftCourse:Course) => {
        if (!!Object.keys(draftCourse).length) {
            const draftCourseId = draftCourse?.id;
            const lastModuleId = Number(cookies.cookies[draftCourseId]);
            const lastOpenModule = draftCourse.modules.find((module: any) => module.id === lastModuleId);
            const isSetCookies = lastModuleId && lastOpenModule;

            dispatch(setDraftModule(isSetCookies ? lastOpenModule : draftCourse?.modules[0]));
            dispatch(setDraftModuleQuiz(isSetCookies ? lastOpenModule.quiz : draftCourse?.modules[0]?.quiz));
            dispatch(setDraftQuizQuestion(isSetCookies ? lastOpenModule.quiz?.questions[0] : draftCourse?.modules[0]?.quiz?.questions[0]));

            if (!isSetCookies) {
                cookies.set(draftCourseId, draftCourse?.modules[0].id);
            }
        }
    };

    useEffect(() => {
        const courseId = sessionStorageService.getDraftCourseId();

        if (!courseId) {
            appRouterService.forwardToAdminAccountPage();
        } else {
            loadCourse(Number(courseId)).catch((e) => console.log(e));
        }
    }, []);


    return (
        <>
            {!isTabletOrMobile &&
            <div className={styles.CourseConstructorContainer}>
                <ModuleListComponent draftCourse={draftCourse} />
                <CourseConstructorComponent />
            </div>}
            {isTabletOrMobile &&
                <div className={styles.NotAdaptiveWrapper}>
                    <Logo className={styles.LogoImage}  />
                    <div className={styles.NotAdaptive}>
                        <span className={styles.TitleNotAdaptive}>Please switch to full screen mode or use Gradual with desktop for better experience</span>
                        <Image src={smileImg} className={styles.SmileImg}/>
                    </div>
                </div>
            }
        </>
    );
};

export default withCookies(connect(mapStateToProps)(CourseConstructorPage));
import React from 'react';
import classNames from 'classnames';

import styles from './NewLogoComponent.module.scss';
import plusImg from '../../../../../../../../../../assets/images/icon/plus-add-icon.svg';
import {ReactComponent as DeleteImg} from '../../../../../../../../../../assets/images/icon/delete-course-icon.svg';
import InputFile from '../../../../../../../../../components/Input/InputFile/InputFile';
import Image from '../../../../../../../../../components/Image/Image';
import Svg from '../../../../../../../../../components/SvgComponent/SvgComponent';
import {connect} from 'react-redux';
import {dispatch} from '../../../../../../../../../state/store';
import {createStructuredSelector} from 'reselect';
import {makeSelectBrandLogo} from '../../../../../../../../../state/selectors/customBranding';
import {setBrandLogo} from '../../../../../../../../../state/ducks/customBranding';
import customBrandingService
    from '../../../../../../../../../services/server/customBrandigService/customBrandingService';

const mapStateToProps = createStructuredSelector({
    brandLogo: makeSelectBrandLogo(),
});

const NewLogoComponent = (props:any) => {
    const {brandLogo} = props;

    const onLogoImageChange = async (value:any) => {
        const newFiles:any = new FormData();
        newFiles.append('logo', value.target.files[0]);

        const newLogo:string = await customBrandingService.setBrandingLogo(newFiles) || '';
        dispatch(setBrandLogo(newLogo));
    };

    const deleteLogoImage = async () => {
        await dispatch(setBrandLogo(undefined));
        await customBrandingService.deleteBrandingLogo();
    };

    return (
        <>
            <div className={classNames(brandLogo && styles.WrapperForLogoAndDeleteIcon)}>
                <div className={classNames(styles.NewLogoContainer, brandLogo && styles.BrandLogoDowload)}>
                    <div className={styles.TitleText}>
                        Company logo
                    </div>
                    <div>
                        <div className={styles.LogoAndBtnContainer}>
                            <div className={classNames(styles.LogoContainerEmply, brandLogo && styles.LogoContainer)}>
                                <InputFile
                                    accept='.png,.jpeg,.jpg,.gif,.svg'
                                    onChange={onLogoImageChange}
                                    className={styles.InputContainer}
                                >
                                    <Image src={brandLogo || plusImg} className={brandLogo ? styles.NewImg : styles.LogoImage} />
                                    {brandLogo && (
                                        <div className={styles.DarkBackground}>
                                            <Image src={plusImg} />
                                        </div>
                                    )}
                                </InputFile>
                            </div>
                        </div>
                        <span className={styles.SignatureText}>
                            Image size must be at least 232x96px. Supported formats: png, jpeg/jpg, gif, svg.
                        </span>
                    </div>
                </div>
                {brandLogo &&
                    <Svg onClick={deleteLogoImage} className={styles.DeleteImage}>
                        <DeleteImg/>
                    </Svg>
                }
            </div>
        </>
    );
};

export default connect(mapStateToProps)(NewLogoComponent);
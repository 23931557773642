import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.analytics;

const makeSelectIsEmptyAnalyticsData = () => createSelector(selectGlobal, (globalState:any) => globalState.isEmptyAnalyticsData);

const makeSelectAnalyticsList = () => createSelector(selectGlobal, (globalState:any) => globalState.analyticsList);

const makeSelectSortedAnalyticsList = () => createSelector(selectGlobal, (globalState:any) => globalState.sortedAnalyticsList);

const makeSelectAnalyticsListProgress = () => createSelector(selectGlobal, (globalState:any) => globalState.analyticsListProgress);

const makeSelectSortedAnalyticsListProgress = () => createSelector(selectGlobal, (globalState:any) => globalState.sortedAnalyticsListProgress);

const makeSelectOverviewList = () => createSelector(selectGlobal, (globalState:any) => globalState.overviewList);

const makeSelectTypeList = () => createSelector(selectGlobal, (globalState:any) => globalState.filterTypeList);

const makeSelectCourseList = () => createSelector(selectGlobal, (globalState:any) => globalState.filterCourseList);

const makeSelectModuleList = () => createSelector(selectGlobal, (globalState:any) => globalState.filterModuleList);

const makeSelectPeriodList = () => createSelector(selectGlobal, (globalState:any) => globalState.filterPeriodList);

const makeSelectLearnersList = () => createSelector(selectGlobal, (globalState:any) => globalState.filterLearnersList);

const makeSelectSortedLearnersList = () => createSelector(selectGlobal, (globalState:any) => globalState.sortedLearnersList);

export {
    makeSelectIsEmptyAnalyticsData,
    makeSelectAnalyticsList,
    makeSelectSortedAnalyticsList,
    makeSelectAnalyticsListProgress,
    makeSelectSortedAnalyticsListProgress,
    makeSelectOverviewList,
    makeSelectTypeList,
    makeSelectCourseList,
    makeSelectModuleList,
    makeSelectPeriodList,
    makeSelectLearnersList,
    makeSelectSortedLearnersList
}
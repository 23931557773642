import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import styles from '../../ListLearners.module.scss';

const ListAnalytics = (props:any) => {
    const {filterType, renderedAnalytics} = props;

    const convertMillisecondsToTime = (learningTime:number) => {
        let minutes = Math.floor(learningTime/60000);
        let hours = Math.floor(minutes/60);
        let days = Math.floor(hours/24);
        return hours !== 0 ? days !== 0 ? days.toString().
            concat("d ") + (hours - 24 * days).toString().
            concat("h ") + (minutes - hours * 60).toString().concat("m")
            : hours.toString().concat("h ") + (minutes - hours * 60).toString().concat("m")
            : minutes.toString().concat("m");
    };

    return (
        <>
            {renderedAnalytics?.map((item:any) => {
                const learnerName = item.learner.name;
                const learnerId = item.learner.id;
                const learningTime = item.learningTime;
                const rank = item?.rank;
                const quizResult = item?.quizResult;
                const performance = item?.performance;

                const TextByFilter = filterType.type === 'Quiz result'  ? `${quizResult} %`:
                    filterType.type === 'Performance' ? `${performance} %` :
                        filterType.type === 'Learning time' ? convertMillisecondsToTime(learningTime)
                            : '';

                const classNameByFilter = classNames(styles.LearnerItem,
                    filterType.type === 'Quiz result' && styles.LearnerItemRankAndStatus);

                const textTooltipRank = rank === 0 ? 'Poor' :
                    rank === 1 ? 'Below average' :
                        rank === 2 ? 'Average' :
                            rank === 3 ? 'Above average' :
                                rank === 4 ? 'Perfect' : '';

                return (
                    <div className={classNameByFilter} key={learnerId}>
                        <span className={styles.NameLearner}>{learnerName}</span>
                        <span className={styles.DetailsLearner}>{TextByFilter}</span>
                            {filterType.type === 'Quiz result' &&
                            <div className={styles.WrapperRank}  data-tip={textTooltipRank} >
                                {[0, 1, 2, 3, 4].map((item, index) =>
                                    <span
                                        key={index.toString()}
                                        className={classNames(styles.Rank, rank >= item &&
                                            styles.RankActive)}>
                                    </span>
                                )}
                                <ReactTooltip
                                    className={styles.Tooltip}
                                    effect='solid'
                                />
                            </div>}
                    </div>
            )})}
        </>
    );
}

export default ListAnalytics;
import React, {useEffect, useRef, useState} from 'react';

import styles from './AddNewQuestionComponent.module.scss';
import plusImg from '../../../../../../../../../../assets/images/icon/plus-add-tomato.svg';
import Image from '../../../../../../../../../components/Image/Image';
import ButtonBorder from '../../../../../../../../../components/Button/components/ButtonBorder/ButtonBorder';
import {typesQuestionList} from '../../typesQuestionList';

const AddNewQuestionComponent = (props:any) => {
    const {onItemClick} = props;

    const [isVisibleAddNewQuestionModal, setIsVisibleAddNewQuestionModal] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const addButtonRef = useRef<HTMLButtonElement>(null);
    
    const useOutsideAlerter = () => {
        useEffect(() => {
            function handleClickOutside(event:any) {
                if(addButtonRef.current && addButtonRef.current.contains(event.target)) {
                    setIsVisibleAddNewQuestionModal(!isVisibleAddNewQuestionModal);
                } else if (modalRef.current && !modalRef.current.contains(event.target)) {
                    setIsVisibleAddNewQuestionModal(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [modalRef, isVisibleAddNewQuestionModal]);
    };
    
    useOutsideAlerter();
    
    const onItemButtonClick = (id:number) => {
        onItemClick(id);
        setIsVisibleAddNewQuestionModal(false);
    };
    
    return (
        <>
            <ButtonBorder className={styles.AddQuestionsButton} reference={addButtonRef}>
                <Image src={plusImg} className={styles.ButtonImg} />
                <span className={styles.AddQuestionsText}>Add a question</span>
            </ButtonBorder>
            {isVisibleAddNewQuestionModal && (
                <div className={styles.OptionsList} ref={modalRef}>
                    {typesQuestionList.map((item:any, index:any) => {
                        const id = item.id;

                        return (
                            <div className={styles.OptionItem} onClick={() => onItemButtonClick(item.id)} key={id}>
                                {item.name}
                            </div>
                        )
                    })}
                </div>
            )}
        </>
        
    );
};

export default AddNewQuestionComponent;
import React from 'react';
import styles from './HeaderMetricsComponet.module.scss';

const HeaderMetricsComponent = () => {
    return (
        <div className={styles.WrapperExplanation}>
            <div className={styles.Personal}> </div>
            <div className={styles.TitleExplanation}>Personal</div>
            <div className={styles.Team}> </div>
            <div className={styles.TitleExplanation}>Team average</div>
            <div className={styles.PersonalLast}> </div>
            <div className={styles.TitleExplanation}>Personal for the past period</div>
        </div>
    );
};

export default HeaderMetricsComponent;

import React, {useEffect, useRef, useState} from 'react';

import styles from './AddContentComponent.module.scss';
import addButtonImg from '../../../../../../../../assets/images/icon/add-block-button.svg';
import Image from '../../../../../../../components/Image/Image';
import ModalContentComponent from './components/ModalContentComponent/ModalContentComponent';

const AddContentComponent = (props:any) => {
    const {openYouTubePopup, openTaskPopup, openQuizPopup, openDelayPopup, setRecorder, isRecording} = props;

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<any>(null);
    
    const onAddBlockClick = () => {
        setIsVisibleModal(!isVisibleModal);
    };
    
    const hideNewContentModal = () => {
        setIsVisibleModal(false);
    };
    
    const useOutsideAlerter = (popupRef:any) => {
        useEffect(() => {
            function handleClickOutside(event:any) {
                if (modalRef.current && !modalRef.current.contains(event.target) && buttonRef.current &&
                    !buttonRef.current.contains(event.target)) {
                    setIsVisibleModal(false);
                }
            }
            
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [popupRef]);
    };
    
    useOutsideAlerter(modalRef);

    useEffect(() => {
        modalRef?.current?.scrollIntoView({ behavior: 'smooth' });
    });
    
    return (
        <div className={styles.AddContentContainer}>
            <Image src={addButtonImg} className={styles.AddButtonImage} onClick={onAddBlockClick} reference={buttonRef} />
            <span className={styles.AddButtonText} onClick={onAddBlockClick} ref={buttonRef}>Add block</span>
            {isVisibleModal && (
                <ModalContentComponent
                    openYouTubePopup={openYouTubePopup}
                    openTaskPopup={openTaskPopup}
                    openQuizPopup={openQuizPopup}
                    openDelayPopup={openDelayPopup}
                    reference={modalRef}
                    hideNewContentModal={hideNewContentModal}
                    setRecorder={setRecorder}
                    isRecording={isRecording}
                />
            )}
        </div>
    );
};

export default AddContentComponent;
import historyService from '../history/historyService';

const forwardTo = (location:string) => {
    const history = historyService.getHistory();

    history.push(location);
};

const blankLinkOpen = (url:string) => {
    window.open(url, '_blank');
};

const selfLinkOpen = (url:string) => {
    window.open(url, '_self');
};

export default {
    blankLinkOpen,
    selfLinkOpen,
    forwardTo
};

import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Loader from "react-loader-spinner";

import styles from "./ModuleFilterComponent.module.scss";
import topDropDownImg from "../../../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg";
import bottomDropDownImg from "../../../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg";
import Image from "../../../../../../../../../../../components/Image/Image";
import OptionsComponent from "../../../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectModuleList } from "../../../../../../../../../../../state/selectors/analytics";
import analyticsService from "../../../../../../../../../../../services/server/analyticsService/analyticsService";
import { useOutsideListener } from "../../../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
  filterModuleList: makeSelectModuleList(),
});

const ModuleFilterComponent = (props: any) => {
  const {
    filterModule,
    filterType,
    filterCourse,
    filterModuleList,
    classNameDropDownContainer,
    onClickModule,
    classNameDropDownMenu,
    classNameWrapperComponent,
    classNameTitle,
    classNameDropDownOverflow,
    classNameOptionsWrapper,
    classNameOpenDropDown,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(filterModule.module);
  const [loader, setLoader] = useState(false);
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDropDownValue(filterModule.module);
  }, [filterModule]);

  const classNameDropDown =
    filterCourse.course === "All training" &&
    filterType.type === "Training progress"
      ? styles.DisabledSelect
      : filterCourse.course === "All training"
      ? styles.DisabledSelect
      : filterType.type === "Training progress"
      ? styles.DisabledSelect
      : isOpenDropDown
      ? classNameOpenDropDown
      : "";

  const dropDownOpenCondition =
    filterCourse.course === "All training" &&
    filterType.type === "Training progress"
      ? false
      : filterCourse.course === "All courses"
      ? false
      : filterType.type === "Training progress"
      ? false
      : !isOpenDropDown;

  const openDropDownList = async () => {
    setIsOpenDropDown(dropDownOpenCondition);
    filterCourse.course !== "All training" &&
      filterType.type !== "Training progress" &&
      (await analyticsService.getFilteredListModule(filterCourse.id));
    setLoader(true);
  };

  const closeDropDownList = () => setIsOpenDropDown(false);
  useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

  const onModuleFilterButtonClick = (
    filterModuleName: string,
    filterModuleId: number
  ) => {
    setDropDownValue(filterModuleName);
    onClickModule(filterModuleName, filterModuleId);
    closeDropDownList();
  };

  return (
    <div className={classNameWrapperComponent}>
      <span className={classNameTitle}>Module</span>
      <div
        className={classNames(classNameDropDownContainer, classNameDropDown)}
        onClick={openDropDownList}
        ref={selectButtonRef}
      >
        <span className={styles.Text}>
          {isOpenDropDown ? "Select" : dropDownValue}
        </span>
        <Image
          src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
          className={classNames(
            styles.DropDownIcon,
            isOpenDropDown && styles.isActiveDropDownIcon
          )}
        />
      </div>
      {isOpenDropDown && (
        <div className={classNameDropDownMenu} ref={dropDownRef}>
          <div className={classNameDropDownOverflow}>
            {loader ? (
              filterModuleList?.map((item: any) => {
                const filterModuleName = item.name;
                const filterModuleId = item.id;
                const isActiveOptions = filterModuleId === filterModule.id;

                return (
                  <OptionsComponent
                    isActiveOptions={isActiveOptions}
                    classNameOptionsWrapper={classNameOptionsWrapper}
                    reference={optionsRef}
                    key={filterModuleId}
                    optionText={filterModuleName}
                    classNameShowText={filterModuleName?.length > 18 && true}
                    onClick={() =>
                      onModuleFilterButtonClick(
                        filterModuleName,
                        filterModuleId
                      )
                    }
                  />
                );
              })
            ) : (
              <div className={styles.WrapperForLoader}>
                <Loader
                  type="TailSpin"
                  color="#F85A40"
                  height={25}
                  width={25}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ModuleFilterComponent);

import React from 'react';

import textImg from '../../../../../../../../../../../../assets/images/icon/text-block-button.svg';
import recordImg from '../../../../../../../../../../../../assets/images/icon/record-video-block-button.svg';
import stopRecordImg from '../../../../../../../../../../../../assets/images/icon/record-video-block-pause-button.svg';
import youtubeImg from '../../../../../../../../../../../../assets/images/icon/youtube-video-block-button.svg';
import uploadVideo from '../../../../../../../../../../../../assets/images/icon/upload-video-block-button.svg';
import imageImg from '../../../../../../../../../../../../assets/images/icon/image-block-button.svg';
import pdfImg from '../../../../../../../../../../../../assets/images/icon/pdf-icon.svg';
import audioImg from '../../../../../../../../../../../../assets/images/icon/audio-block-button.svg';
import ContentFieldItems from '../ContentFieldItems/ContentFieldItems';
import InputFile from '../../../../../../../../../../../components/Input/InputFile/InputFile';
import {connect} from 'react-redux';
import {dispatch} from '../../../../../../../../../../../state/store';
import {makeSelectDraftModule} from '../../../../../../../../../../../state/selectors/courseConstructor';
import {createStructuredSelector} from 'reselect';
import draftModuleService from '../../../../../../draftModuleService';
import moduleService from '../../../../../../../../../../../services/server/moduleService/moduleService';
import {setIsContentLoader} from '../../../../../../../../../../../state/ducks/global';
import {loading} from "../../../../../../../../../../../components/Loader/AppLoader/loading";

const mapStateToProps = createStructuredSelector({
    draftModule: makeSelectDraftModule()
});

const ContentFieldsList = (props:any) => {
    const {classNameContainerList, draftModule, hideNewContentModal, openYouTubePopup, setRecorder,
        isRecording} = props;

    const onTextBlockClick = async () => {
        const newTextBlock = await moduleService.addTextBlock(draftModule.id);
        draftModuleService.updateModuleContentList(draftModule, newTextBlock?.data);
        hideNewContentModal();
    };

    const onYoutubeClick = () => {
        openYouTubePopup(true);
        hideNewContentModal();
    };

    const onScreenRecorderClick = () => {
        setRecorder();
    };

    const onPdfClick = async (event:any) => {
        const createPdf = async () => await moduleService.addPdfFile(event, draftModule, event.target.files[0].name);
        await loading<void>(createPdf);
        hideNewContentModal();
    };
    
    const onUploadVideoClick = async (value:any) => {
        dispatch(setIsContentLoader(true));
        await draftModuleService.uploadNewMediaContent(value.target.files[0], 'VIDEO', draftModule);
        hideNewContentModal();
        dispatch(setIsContentLoader(false));
    };
    
    const onImageClick = async (value:any) => {
        dispatch(setIsContentLoader(true));
        await draftModuleService.uploadNewMediaContent(value.target.files[0], 'PHOTO', draftModule);
        hideNewContentModal();
        dispatch(setIsContentLoader(false));
    };
    
    const onAudioClick = async (value:any) => {
        dispatch(setIsContentLoader(true));
        await draftModuleService.uploadNewMediaContent(value.target.files[0], 'AUDIO', draftModule);
        hideNewContentModal();
        dispatch(setIsContentLoader(false));
    };
    
    return (
        <div className={classNameContainerList}>
            <ContentFieldItems
                name='Text'
                src={textImg}
                onClick={onTextBlockClick}
            />
            { !isRecording
            ? <ContentFieldItems
                name='Record video'
                src={recordImg}
                onClick={onScreenRecorderClick}
            />
            : <ContentFieldItems
                    name='Stop recording'
                    src={stopRecordImg}
                    onClick={onScreenRecorderClick}
                />
            }
            <ContentFieldItems
                name='YouTube video'
                src={youtubeImg}
                onClick={onYoutubeClick}
            />
            <InputFile
                onChange={onUploadVideoClick}
            >
                <ContentFieldItems
                    name='Upload video'
                    src={uploadVideo}
                />
            </InputFile>
            <InputFile
                accept='.png,.jpeg,.jpg'
                onChange={onImageClick}
            >
                <ContentFieldItems
                    name='Image'
                    src={imageImg}
                />
            </InputFile>
            <InputFile
                accept='.pdf'
                onChange={onPdfClick}
            >
                <ContentFieldItems
                    name='Upload PDF'
                    src={pdfImg}
                />
            </InputFile>
            <InputFile
                onChange={onAudioClick}
            >
                <ContentFieldItems
                    name='Audio'
                    src={audioImg}
                />
            </InputFile>
        </div>
    );
};

export default connect(mapStateToProps)(ContentFieldsList);
import React from 'react';

import styles from './TimeLimitComponent.module.scss';
import Input from '../../../../../../../../../../../components/Input/Input';
import Slider from '../../../../../../../../../../../components/Slider/Slider';

const TimeLimitComponent = (props:any) => {
    const {isActiveTimeLimit, changeActiveTimeLimit, classNameSliderTitleText,
        classNameInputField, timeLimit, timeLimitChange} = props;
    const inputFieldValue = timeLimit === null ? '' : timeLimit;
    
    return (
        <div className={styles.MainContainer}>
            <div className={styles.ChangeContainer}>
                <div className={styles.SliderContainer}>
                    <Slider isChecked={isActiveTimeLimit} onChange={changeActiveTimeLimit} />
                    <span className={classNameSliderTitleText}>Test Time Limit</span>
                </div>
                <span className={styles.DescriptionText}>Enable Time Limit</span>
            </div>
            <div className={styles.InputContainer}>
                <Input
                    value={inputFieldValue}
                    onChange={timeLimitChange}
                    isDisabledInput={!isActiveTimeLimit}
                    className={classNameInputField}
                    placeholder='5'
                    type='number'
                />
                <span className={styles.InputDescription}>min</span>
            </div>
        </div>
    );
};

export default TimeLimitComponent;
import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import styles from './HomeAccountPage.module.scss';
import Image from '../../../components/Image/Image';
import ContentPartnersComponent from '../HomeAccountPage/components/ContentPartnersComponent/ContentPartnersComponent';
import smileImg from '../../../../assets/images/icon/smile-icon.svg';
import MenuListPartners from './components/MenuListPartners/MenuListPartners';
import homeService from '../../../services/server/homeService/homeService';
import urlUtils from '../../../services/utils/urlUtils';
import {availableTabListPartners} from './components/MenuListPartners/menuTabListPartners';
import Logo from '../../../components/Logo/Logo';

const HomeAccountPage = () => {
    const [activeTab, setActiveTab] = useState(availableTabListPartners[0] as object);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    useEffect(() => {
        const [partnerId] : (string | null) [] = urlUtils.getUrlParams(['partnerId']);

        homeService.getPartnerInfo(Number(partnerId));
        homeService.getListOfCustomers(Number(partnerId));
    }, [])

    const onTabButtonClick = (activeTabId: number) => {
        const activeTab = availableTabListPartners.find((item) => item.id === activeTabId);

        setActiveTab(activeTab as object);
    };

    const onLogoClick = () => {
        window.location.reload();
    };

    return (
        <div>
            <div className={styles.LearnerAccountPage}>
                {!isTabletOrMobile &&
                    <>
                        <div className={styles.MenuContainer}>
                            <div className={styles.LogoContainer} onClick={onLogoClick}>
                                <Logo className={styles.LogoImg} />
                                <div className={styles.SubTitle}>FOR PARTNERS</div>
                            </div>
                            <MenuListPartners
                                activeTab={activeTab}
                                onTabButtonClick={onTabButtonClick}
                            />
                        </div>
                        <div className={styles.MainContainer}>
                            <ContentPartnersComponent activeTab={activeTab} setActiveTab={setActiveTab} />
                        </div>
                    </>
                }
                {isTabletOrMobile &&
                    <div className={styles.NotAdaptiveWrapper}>
                        <Logo className={styles.LogoImage} />
                        <div className={styles.NotAdaptive}>
                            <span className={styles.TitleNotAdaptive}>Please switch to full screen mode or use Gradual with desktop for better experience</span>
                            <Image src={smileImg} className={styles.SmileImg}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default HomeAccountPage;
import {HomeStorage} from '../interfaces/homeInterface';

export const SET_PARTNER_LINK = 'app/home/SET_PARTNER_LINK';
export const SET_CUSTOMERS_LIST = 'app/home/SET_CUSTOMERS_LIST';
export const SET_CURRENT_PAGE_DATA = 'app/home/SET_CURRENT_PAGE_DATA';

const initialState:HomeStorage = {
    partnerLink: [],
    customersList: [],
    currentPageData: []
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_CUSTOMERS_LIST: {
            return {...state, customersList: action.payload};
        }
        case SET_PARTNER_LINK: {
            return {...state, partnerLink: action.payload};
        }
        case SET_CURRENT_PAGE_DATA: {
            return {...state, currentPageData: action.payload};
        }
        default: return state;
    }
}

export const setPartnerLink = (value:Array<any>|[]) => ({type: SET_PARTNER_LINK, payload: value});

export const setCustomersList = (value:Array<any>|[]) => ({type: SET_CUSTOMERS_LIST, payload: value});

export const setCurrentPageData = (value:Array<any>|[]) => ({type: SET_CURRENT_PAGE_DATA, payload: value});
import React from 'react';

import styles from './VideoComponent.module.scss';
import Video from '../../../../../../../../../../components/Video/Video';

const VideoComponent = ({src}: {src:string}) => {
    const isFrameVideo = src.includes('youtube.com');

    return (
        <Video
            src={src}
            classNameVideoContainer={styles.VideoContainer}
            classNameVideoElement={styles.Video}
            isIframe={isFrameVideo}
        />
    );
};

export default VideoComponent;
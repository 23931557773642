import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import styles from './CompanyInputsComponent.module.scss';
import CompanyNameComponent from './components/CompanyNameComponent/CompanyNameComponent';
import CompanyNumberComponent from './components/CompanyNumberComponent/CompanyNumberComponent';
import TeamSizeComponent from './components/TeamSizeComponent/TeamSizeComponent';
import RoleComponent from './components/RoleComponent/RoleComponent';
import ButtonColor from '../../../../../../components/Button/components/ButtonColor/ButtonColor';
import CompanyNeedsComponent from './components/CompanyNeedsComponent/CompanyNeedsComponent';
import StepSignUpComponent from '../StepSignUpComponent/StepSignUpComponent';
import classNames from 'classnames';
import CalendarComponent from '../../../CalendarComponent/CalendarComponent';
import adminAuthService from '../../../../../../services/server/adminService/adminAuthService';
import firstNeedsList from './components/CompanyNeedsComponent/firstNeedsList';
import secondNeedsList from './components/CompanyNeedsComponent/secondNeedsList';
import gtmService from "../../../../../../services/dataTrigger/gtmService";

type CompanyInputComponentType = {
    setIsOpen3Step: Function;
    isOpen3Step: boolean;
    email: string;
    isVisibleCalendar: boolean;
    setIsVisibleCalendar: Function;
    setIsIntroNeeded: Function;
    isVisibleNeedsCompany: boolean;
    setIsVisibleNeedsCompany: Function;
    isVisibleCompanyInputs: boolean;
    setIsVisibleCompanyInputs: Function;
}

const CompanyInputsComponent:React.FC<CompanyInputComponentType> = ({setIsOpen3Step, isOpen3Step, email,
    isVisibleCalendar, setIsVisibleCalendar, setIsIntroNeeded, isVisibleNeedsCompany, setIsVisibleNeedsCompany,
    setIsVisibleCompanyInputs, isVisibleCompanyInputs}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });
    let listNeeds:string[] = [];

    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [size, setSize] = useState({name: 'Select team size' , id: -1});
    const [role, setRole] = useState({name: 'Select your role', id: -1});
    const [inputRole, setInputRole] = useState<string>('');
    const [isActiveBtn, setIsDisabledBtn] = useState(false);

    const isFilledFields = name.length && phone.length && size.id !== -1 && role.id !== -1 && role.id !== 9;
    const isFilledInputsRole = name.length && phone.length && size.id !== -1 && role.id !== -1 && role.id === 9 && inputRole.length;

    useEffect(() => {
        if (isFilledFields) {
            setIsDisabledBtn(true);
        } else if (isFilledInputsRole) {
            setIsDisabledBtn(true);
        } else {
            setIsDisabledBtn(false);
        }
    }, [name, phone, size, role, inputRole]);


    const onClickContinueButton = async () => {
        if (isActiveBtn) {
            role.id !== 9 ? await adminAuthService.setDataForRegistration(name, phone, size.name, role.name)
                : await adminAuthService.setDataForRegistration(name, phone, size.name, inputRole)
            setIsVisibleCompanyInputs(false);
            setIsOpen3Step(true);
            setIsVisibleNeedsCompany(true);
            gtmService.addCompanyPortfolio({
                companyName:name, teamSize:size.name, role: role.id !== 9 ? role.name : inputRole, phoneNumber:phone
            });
        }
    };

    const onClickContinueButtonAfterNeeds = async () => {
        onSetNeedsData();
        await adminAuthService.setDataForQuestionnaire(listNeeds);
        gtmService.addUsageGoals(listNeeds);
        setIsVisibleNeedsCompany(false);
        setIsVisibleCalendar(true);
    };

    const onHideCalendarClick = () => {
        setIsIntroNeeded(false);
    };

    const onSetNeedsData = () => {
        firstNeedsList.filter((item) => {
            if(item.isChecked) {
                listNeeds.push(item.name);
            }})
        secondNeedsList.filter((item) => {
            if(item.isChecked) {
                listNeeds.push(item.name);
            }});
    };

    return (
        <div className={isTabletOrMobile ? styles.MainContainerMobile : styles.MainContainer}>
            <div className={classNames(isVisibleCompanyInputs ? styles.Container : styles.ContainerNeeds, isVisibleCalendar && styles.ContainerCalendar)}>
                {!isVisibleCalendar && <div className={styles.TitleContainer}>
                    <span className={styles.TitleText}>Welcome to <span>Gradual</span></span>
                </div>
                }
                {(isTabletOrMobile && !isVisibleCalendar) &&
                    <StepSignUpComponent
                        isOpen3Step={isOpen3Step}
                    />
                }
                {isVisibleCompanyInputs && <div className={styles.Description}>Let’s learn more about your company</div>}
                {isVisibleNeedsCompany && <div className={styles.Description}>What you will use Gradual for?</div>}
                <div className={classNames(styles.InputContainer, isVisibleCalendar && styles.InputContainerCalendar)}>
                    {isVisibleCompanyInputs && 
                        <>
                            <CompanyNameComponent
                                name={name}
                                setName={setName}
                            />
                            <CompanyNumberComponent
                                phone={phone}
                                setPhone={setPhone}
                            />
                            <TeamSizeComponent
                                size={size}
                                setSize={setSize}
                            />
                            <RoleComponent
                                role={role}
                                setRole={setRole}
                                inputRole={inputRole}
                                setInputRole={setInputRole}
                            />
                            <ButtonColor
                                className={classNames(styles.ContinueButton, !isActiveBtn && styles.DisabledContinueButton)}
                                onClick={onClickContinueButton}
                            >
                                Continue
                            </ButtonColor>
                        </>
                    }
                </div>
                {isVisibleNeedsCompany &&
                    <CompanyNeedsComponent
                        onClickContinueButton={onClickContinueButtonAfterNeeds}
                    />
                }
                {isVisibleCalendar &&
                    <CalendarComponent
                        onHideCalendarClick={onHideCalendarClick}
                        email={email}
                        name={name}
                    />
                }
            </div>
        </div>
    );
};

export default CompanyInputsComponent;

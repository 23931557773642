import React, {useState, useRef} from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import styles from './SignInPage.module.scss';
import backgroundImg from '../../../assets/images/icon/background-login.png';
import Image from '../../components/Image/Image';
import Line from '../../components/Line/Line';
import InputAuthField from '../../components/Input/InputAuthField/InputAuthField';
import ButtonColor from '../../components/Button/components/ButtonColor/ButtonColor';
import ButtonText from '../../components/Button/components/ButtonText/ButtonText';
import appRouterService from '../../services/route/appRouterService';
import adminAuthService from "../../services/server/adminService/adminAuthService";
import {INCORRECT_CREDENTIALS, USER_NOT_FOUND} from "../../consts/error/errorTypesMessage";
import {BAD_REQUEST, FORBIDDEN} from "../../consts/httpStatus/httpStatus";
import AuthErrorModal from "../../components/Error/AuthErrorComponent/AuthErrorComponent";
import validationService from '../../services/server/validationService/validationService';
import {ROOT} from "../../consts/routes/routePaths";
import {usePresentSession} from '../../hooks/usePresentSession';
import Logo from '../../components/Logo/Logo';

const SignInPage = () => {
    usePresentSession();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorOccurred, setIsErrorOccurred] = useState(false);
    const [isInputError, setIsInputError] = useState({
        isEmailError: false,
        isPasswordError: false,
    });

    const textFieldForEmailRef = useRef<HTMLInputElement>(null)!;
    const textFieldForPasswordRef = useRef<HTMLInputElement>(null)!;

    const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };
    
    const onPasswordChange = (event: any) => {
        setPassword(event.currentTarget.value);
    };

    const onEnterKeyPressEmail = (event: any) => {
        const isValidEmail = validationService.isValidEmail(event.target.value);

        if (event.code === "Enter" && isValidEmail) {
            textFieldForPasswordRef!.current!.focus();
        } else {
            setIsInputError({...isInputError, isEmailError: !isValidEmail});
        }
    };

    const onEnterKeyPressPassword = (event: any) => {
        if(event.code === "Enter") {
            onLoginButtonClick();
        }
    };

    const onLoginButtonClick = async () => {
        adminAuthService.loginUser(email, password)
            .then(() => {
                window.location.replace(ROOT);
            })
            .catch((error) => {
                const {data, status} = error;

                if (status === BAD_REQUEST && data.includes(USER_NOT_FOUND)) {
                    setIsErrorOccurred(true);
                    setErrorMessage('User with this email is not found!');
                }

                if (status === FORBIDDEN && data.includes(INCORRECT_CREDENTIALS)) {
                    setIsErrorOccurred(true);
                    setErrorMessage('Incorrect password!');
                }
            });
    };
    
    const onSignUpButtonClick = () => {
        appRouterService.forwardToAdminSignUpPage();
    };

    const onResetButtonClick = () => {
        appRouterService.forwardToAdminForgotPage();
    };
    
    return (
        <div className={isTabletOrMobile ? styles.MainContainerMobile : styles.MainContainerDesktop}>
            {isTabletOrMobile && <Logo className={styles.LogoImage}/>}
            <div className={styles.SubContainer}>
                {!isTabletOrMobile && <Image src={backgroundImg} className={styles.BackgroundImage} />}
                <div className={styles.SignInContainer}>
                    <div className={styles.TitleContainer}>
                        <span className={styles.TitleText}>Welcome back to <span>Gradual</span></span>
                    </div>
                    <div className={styles.InputContainer}>
                        <div className={styles.TitleDescriptionContainer}>
                            <Line className={styles.Line} />
                            <span>Log in with email</span>
                            <Line className={styles.Line} />
                        </div>
                        <InputAuthField
                            type='text'
                            labelText='Email'
                            classNameInput={styles.InputField}
                            classNameLabel={styles.LabelInputText}
                            value={email}
                            placeholderInput='myemail@company.com'
                            onChange={onEmailChange}
                            onKeyPress={onEnterKeyPressEmail}
                            reference={textFieldForEmailRef}
                            isInputError={isInputError.isEmailError}
                        />
                        <InputAuthField
                            labelText='Password'
                            classNameInput={styles.InputField}
                            classNameLabel={styles.LabelInputText}
                            value={password}
                            placeholderInput='**********'
                            onChange={onPasswordChange}
                            onKeyPress={onEnterKeyPressPassword}
                            isInputError={isInputError.isPasswordError}
                            reference={textFieldForPasswordRef}
                            isPasswordViewButton
                        />
                        {!isTabletOrMobile &&
                            <ButtonText
                                className={classNames(styles.SignUpButton, styles.ResetButton)} onClick={onResetButtonClick}>
                                Forgot password?
                            </ButtonText>
                        }
                        {isErrorOccurred &&
                            <AuthErrorModal
                                errorMessage={errorMessage}
                                onClick={onSignUpButtonClick}
                            />
                        }
                        <ButtonColor className={styles.LoginButton} onClick={onLoginButtonClick}>
                            Log in
                        </ButtonColor>
                        <div className={styles.TitleDescriptionContainer}>
                            <Line className={styles.Line} />
                            <span>Do not have an account?</span>
                            <Line className={styles.Line} />
                        </div>
                        <ButtonText className={styles.SignUpButton} onClick={onSignUpButtonClick}>
                            Sign up
                        </ButtonText>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignInPage;
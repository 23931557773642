import React from 'react';
import styles from './SuccessContainer.module.scss';
import ButtonColor from '../../../../components/Button/components/ButtonColor/ButtonColor';
import Image from '../../../../components/Image/Image';
import appRouterService from '../../../../services/route/appRouterService';
import emojiImg from '../../../../../assets/images/emoji/upside-down-face_1f643.png'

const SuccessContainer = () => {

    const onLogInButtonClick = () => {
        appRouterService.forwardToAdminLoginPage()
    };

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>Nice one! However, we don’t know what</div>
            <div className={styles.WrapperSubTitleImg}>
                <div>you have there</div>
                <Image src={emojiImg} className={styles.Image}/>
            </div>
            <ButtonColor className={styles.LoginButton} onClick={onLogInButtonClick}>
                Log in with new password
            </ButtonColor>
        </div>
    );
};

export default SuccessContainer;

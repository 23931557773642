import { useEffect, useState, useRef } from "react";

import styles from "./LearnerInviteCell.module.scss";
import { ReactComponent as DeleteImg } from "../../../../../../../../../../../../../../../../assets/images/icon/delete-content-icon.svg";
import Svg from "../../../../../../../../../../../../../../../components/SvgComponent/SvgComponent";
import Input from "../../../../../../../../../../../../../../../components/Input/Input";

const LearnerInviteCell = (props: any) => {
  const { invitation, onInvitationDelete, onInvitationUpdate } = props;
  const divRef = useRef<HTMLDivElement>(null);

  const [updatedInvitationValue, setUpdatedInvitationValue] =
    useState(invitation);

  useEffect(() => {
    setUpdatedInvitationValue(invitation);
    scrollToBottom();
  }, [invitation]);

  const scrollToBottom = () => {
    divRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onClickDeleteInvitation = () => {
    onInvitationDelete(invitation);
  };

  const onChangeInvitation = (event: any) => {
    const newInvitationValue = event.currentTarget.value;
    setUpdatedInvitationValue(newInvitationValue);
  };

  const onInvitationKeyPress = (event: any) => {
    const keyPressed = event.key;
    const invitationCellInput = event.currentTarget;

    if (keyPressed === "Enter") {
      invitationCellInput.blur();
    }
  };

  const onBlurInvitation = () => {
    onInvitationUpdate(invitation, updatedInvitationValue);
  };

  return (
    <div className={styles.LearnerInviteCell} ref={divRef}>
      <Input
        className={styles.EditableInviteInput}
        value={updatedInvitationValue}
        onKeyPress={onInvitationKeyPress}
        onChange={onChangeInvitation}
        onBlur={onBlurInvitation}
      />
      <Svg
        className={styles.RemoveInvitationButton}
        onClick={onClickDeleteInvitation}
      >
        <DeleteImg />
      </Svg>
    </div>
  );
};

export default LearnerInviteCell;

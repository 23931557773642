import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.integrations;

const makeSelectUserIdTelegram = () => createSelector(selectGlobal, (globalState:any) => globalState.telegramId);

const makeSelectUserUrlSlack = () => createSelector(selectGlobal, (globalState:any) => globalState.slackUrl);

const makeSelectIsVisibleConfiguration = () => createSelector(selectGlobal, (globalState:any) => globalState.isVisibleConfiguration);

const makeSelectIsVisibleAssistantPopUp = () => createSelector(selectGlobal, (globalState:any) => globalState.isVisibleAssistantPopUp);

const makeSelectLearnersListByPipedrive = () => createSelector(selectGlobal, (globalState:any) => globalState.learnersListByPipedrive);

const makeSelectMetricsListByPipedrive = () => createSelector(selectGlobal, (globalState:any) => globalState.metricsListByPipedrive);

export {
    makeSelectUserIdTelegram,
    makeSelectUserUrlSlack,
    makeSelectIsVisibleConfiguration,
    makeSelectIsVisibleAssistantPopUp,
    makeSelectLearnersListByPipedrive,
    makeSelectMetricsListByPipedrive
};

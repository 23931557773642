import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.global;

const makeSelectActiveTab = () => createSelector(selectGlobal, (globalState:any) => globalState.activeTab);

const makeSelectIsAppLoader = () => createSelector(selectGlobal, (globalState:any) => globalState.isAppLoader);

const makeSelectIsNetworkOnline = () => createSelector(selectGlobal, (globalState:any) => globalState.isNetworkOnline);

const makeSelectLayoutType = () => createSelector(selectGlobal, (globalState:any) => globalState.layout);

const makeSelectIsContentLoader = () => createSelector(selectGlobal, (globalState:any) => globalState.isContentLoader);

const makeSelectIsTabLoader = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoader);

const makeSelectIsTabLoaderCourses = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderCourses);

const makeSelectIsTabLoaderHome = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderHome);

const makeSelectIsTabLoaderReports = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderReports);

const makeSelectIsTabLoaderPerformanceReports = () => createSelector(selectGlobal, (globalState:any) => globalState.makeSelectIsTabLoaderPerformanceReports);

const makeSelectIsTabLoaderAnalytics = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderAnalytics);

const makeSelectIsTabLoaderLearners = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderLearners);

const makeSelectIsTabLoaderIntegrations = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderIntegrations);

const makeSelectIsTabLoaderCustomBranding = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderCustomBranding);

const makeSelectIsTabLoaderSubscription = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderSubscription);

const makeSelectIsVisibleUpgradeProPopUp = () => createSelector(selectGlobal, (globalState:any) => globalState.isVisibleUpgradeProPopUp);

const makeSelectIsVisibleAddSeatsProPopUp = () => createSelector(selectGlobal, (globalState:any) => globalState.isVisibleAddSeatsPopUp);

const makeSelectIsTabLoaderTeam = () => createSelector(selectGlobal, (globalState:any) => globalState.isTabLoaderTeam);

const makeSelectIsVisibleSubscriptionPopup = () => createSelector(selectGlobal, (globalState:any) => globalState.isVisibleSubscriptionPopup);

export {
    makeSelectActiveTab,
    makeSelectIsAppLoader,
    makeSelectIsNetworkOnline,
    makeSelectLayoutType,
    makeSelectIsContentLoader,
    makeSelectIsTabLoader,
    makeSelectIsTabLoaderCourses,
    makeSelectIsTabLoaderReports,
    makeSelectIsTabLoaderAnalytics,
    makeSelectIsTabLoaderLearners,
    makeSelectIsTabLoaderIntegrations,
    makeSelectIsTabLoaderCustomBranding,
    makeSelectIsTabLoaderSubscription,
    makeSelectIsTabLoaderHome,
    makeSelectIsVisibleUpgradeProPopUp,
    makeSelectIsVisibleAddSeatsProPopUp,
    makeSelectIsTabLoaderTeam,
    makeSelectIsVisibleSubscriptionPopup,
    makeSelectIsTabLoaderPerformanceReports
};

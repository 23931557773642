import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import styles from './PipedriveComponent.module.scss';
import pipedriveImg from '../../../../../../../../../../assets/images/icon/pipedrive-icon.svg';
import completeImg from '../../../../../../../../../../assets/images/icon/complete-integrations-icon.svg';
import topDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-top-black.svg';
import bottomDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-bottom-black.svg';
import {ReactComponent as LightBulbImg} from '../../../../../../../../../.././assets/images/icon/light-bulb-icon.svg';
import Image from '../../../../../../../../../components/Image/Image';
import ButtonText from '../../../../../../../../../components/Button/components/ButtonText/ButtonText';
import ConfigureComponent from './components/ConfigureComponent/ConfigureComponent';
import Svg from '../../../../../../../../../components/SvgComponent/SvgComponent';
import ConfigureTextComponent from './components/ConfigureTextComponent/ConfigureTextComponent';
import ConnectComponent from './components/ConnectComponent/ConnectComponent';
import MetricsDetailsComponent from './components/MetricsDetailsComponent/MetricsDetailsComponent';
import AssistantAIComponent from './components/AssistantAIComponent/AssistantAIComponent';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {
    makeSelectIsVisibleAssistantPopUp,
    makeSelectIsVisibleConfiguration
} from '../../../../../../../../../state/selectors/integrations';
import {dispatch} from '../../../../../../../../../state/store';
import {
    setIsVisibleAssistantPopUp,
    setIsVisibleConfiguration
} from '../../../../../../../../../state/ducks/integrations';
import {makeSelectUserDetails} from '../../../../../../../../../state/selectors/userDetails';
import integrationsService from '../../../../../../../../../services/server/integrationsService/integrationsService';
import userInfoService from '../../../../../../../../../services/server/userInfoService/userInfoService';
import appLoadingService from '../../../../../../../../../services/server/appLoadingService/appLoadingService';
import {setUserDetails} from '../../../../../../../../../state/ducks/userDetails';
import {DeletePopup} from '../../../../../../../../../components/DeletePopup/DeletePopup';

const mapStateToProps = () => createStructuredSelector({
    isVisibleConfiguration: makeSelectIsVisibleConfiguration(),
    isVisibleAssistantPopUp: makeSelectIsVisibleAssistantPopUp(),
    userDetails: makeSelectUserDetails()
});

const PipedriveComponent= (props:any) => {
    const {classNameContainer, classNameHeader, classNameStatusContainer,
        classNameHeaderContainer, classNameImage, classNameCompleteImage,
        classNameWrapperInputContainer, isVisibleConfiguration,
        isVisibleAssistantPopUp, userDetails
    } = props;

    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleHint, setIsVisibleHint] = useState(false);
    const [isShowDisconnectPopUp, setIsShowDisconnectPopUp] = useState(false);
    const isIntegrationPipedriveSet = userDetails.isPipedriveConnected;

    const onHeaderButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const onConfigureButtonClick = () => {
        dispatch(setIsVisibleConfiguration(true));
    }

    const onOpenDetailsConnect = () => {
        setIsVisibleHint(true);
    };

    const onCloseDetailsConnect = () => {
        setIsVisibleHint(false);
    };

    const onCloseAssistantPopUp = () => {
        dispatch(setIsVisibleAssistantPopUp(false));
    };

    const onDisconnectButtonClick = async () => {
        await integrationsService.disconnectPipedrive();
        await userInfoService.getUserInfo();
        appLoadingService.userDetailsLoading()
            .then((userDetails) => {
                if (userDetails) {
                    dispatch(setUserDetails(userDetails));
                }
            })
            .catch(console.log)
            .finally(onClickHideDisconnectPopUp);
    };

    const onClickShowDisconnectPopUp = () => {
        setIsShowDisconnectPopUp(true);
    };

    const onClickHideDisconnectPopUp = () => {
        setIsShowDisconnectPopUp(false);
    };

    return (
        <div className={styles.WrapperPipedrive}>
            <div className={classNameContainer}>
                <div className={classNameHeaderContainer} onClick={onHeaderButtonClick}>
                    <div className={classNameHeader}>
                        <Image src={pipedriveImg}  className={classNameImage}/>
                        <span>Pipedrive</span>
                        {!isIntegrationPipedriveSet &&
                            <div onMouseEnter={onOpenDetailsConnect} onMouseLeave={onCloseDetailsConnect} className={styles.WrapperIcon} >
                                <div className={styles.WrapperImg}>
                                    <Svg className={styles.LightImg} >
                                        <LightBulbImg />
                                    </Svg>
                                </div>
                                {isVisibleHint &&
                                    <MetricsDetailsComponent />
                                }
                            </div>
                        }
                    </div>
                    <div className={classNameStatusContainer}>
                        {isIntegrationPipedriveSet && (
                            <Image src={completeImg} className={classNameCompleteImage} />
                        )}
                        <Image
                            src={isVisible ? topDropDownImg : bottomDropDownImg}
                            className={classNames(styles.DropDownIcon, isVisible && styles.isActiveDropDownIcon)}
                        />
                    </div>
                </div>
                {isVisible && (
                    <div className={classNameWrapperInputContainer}>
                        <div className={styles.InfoContainer}>
                            {isIntegrationPipedriveSet &&
                                <ConfigureTextComponent
                                    onConfigureButtonClick={onConfigureButtonClick}
                                />
                            }
                            {!isIntegrationPipedriveSet &&
                                <ConnectComponent
                                />
                            }
                        </div>
                        <div className={styles.WrapperDescription}>
                            {!isIntegrationPipedriveSet &&
                                <div className={styles.DescriptionAndLightImg}>
                                    Choose among 11 sales performance metrics: conversion rates, activities and sales cycle
                                </div>
                            }
                            {isIntegrationPipedriveSet &&
                                <ButtonText
                                    onClick={onClickShowDisconnectPopUp}
                                    className={styles.DisconnectButton}
                                >
                                    Disconnect
                                </ButtonText>
                            }
                        </div>
                    </div>
                )}
            </div>
            {isVisibleConfiguration &&
                <ConfigureComponent userDetails={userDetails}/>
            }
            {isVisibleAssistantPopUp &&
                <AssistantAIComponent
                    onCloseAssistantPopUp={onCloseAssistantPopUp}
                />
            }
            {isShowDisconnectPopUp &&
                <DeletePopup
                    classNameBackground={styles.Wrapper}
                    onHide={onClickHideDisconnectPopUp}
                    onDelete={onDisconnectButtonClick}
                    textData={{
                        title:'Are you sure?',
                        header:{
                            general:'You are about to disconnect Pipedrive!',
                            specific: '',
                            note:''
                        },
                        deleteText: 'Yes, disconnect'
                    }}
                />
            }
        </div>
    );
};

export default connect(mapStateToProps)(PipedriveComponent);

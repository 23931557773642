import config from '../../../config/config';
import stringUtils from '../../utils/stringUtilsService';
import adminService from '../adminService/adminService';
import {dispatch} from '../../../state/store';
import {
     setFilteredQuizDetailsList, setFilterModuleList, setQuizDetailsList, setTaskDetailsList
} from '../../../state/ducks/reports';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {getReportsPath, getFilterReportsPath, getInfoReportTaskPath, getInfoReportQuizPath,
    resetAttemptsPath, statusReportsPath} = externalLinks;

const getAllReports = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getReportsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, '1', '0');

    return await adminService.getAllReports(updatedUrl);
};

const setStatusReport = async (reportId:number, status:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, statusReportsPath);

    return await adminService.setStatusReport(formattedUrl, reportId, status);
};

const getFilteredReports = async (numberOfReport:number, currentPage:any, learnerIdFilter:any, typeFilter:any,
    courseFilter:any, moduleFilter:any, statusFilter:any) => {

    const formattedUrl = stringUtils.formatString(defaultApiLink, getReportsPath);
    let updatedUrl = stringUtils.formatString(formattedUrl, numberOfReport, currentPage ? currentPage : '0');

    if(Number(learnerIdFilter) !== -1) {
        updatedUrl = updatedUrl.concat(`&learnerId=${learnerIdFilter}`);
    }
    if(Number(typeFilter) !== -1) {
        updatedUrl = updatedUrl.concat(`&type=${typeFilter}`);
    }
    if(Number(courseFilter) !== -1) {
        updatedUrl = updatedUrl.concat(`&courseId=${courseFilter}`);
    }
    if(Number(moduleFilter) !== -1) {
        updatedUrl = updatedUrl.concat(`&moduleId=${moduleFilter}`);
    }
    if(Number(statusFilter) !== -1) {
        updatedUrl = updatedUrl.concat(`&status=${statusFilter}`);
    }
    return await adminService.getFilteredReports(updatedUrl);
}

const getFilterReportsLearner = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilterReportsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'learner');

    return await adminService.getFilterReportsList(updatedUrl);
};

const getFilterReportsType = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilterReportsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'type');

    return await adminService.getFilterReportsList(updatedUrl);
};

const getFilterReportsCourse = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilterReportsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'course');

    return await adminService.getFilterReportsList(updatedUrl);
};

const getFilterReportsModule = async (courseId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilterReportsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'module');
    const finalUrl = updatedUrl.concat(`&courseId=${courseId}`);

    const filterReports = await adminService.getFilterReportsList(finalUrl);
    const sortedFilterReports = filterReports.moduleFilters.sort((a:any, b:any) => a?.id < b?.id ? -1 : 1);

    await dispatch(setFilterModuleList(sortedFilterReports));
};

const getFilterReportsStatus = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilterReportsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'status');

    return await adminService.getFilterReportsList(updatedUrl);
};

const getDetailsQuiz = async (reportId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getInfoReportQuizPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, reportId);

    const detailsQuiz = await adminService.getDetailsQuiz(updatedUrl);

    dispatch(setQuizDetailsList(detailsQuiz));
    dispatch(setFilteredQuizDetailsList(detailsQuiz));
};

const getDetailsTask = async (reportId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getInfoReportTaskPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, reportId);

    const detailsTask = await adminService.getDetailsTask(updatedUrl);

    dispatch(setTaskDetailsList(detailsTask));
};

const resetAttempts = async (learnerId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, resetAttemptsPath);

    const responseReset = await adminService.resetAttempts(formattedUrl, learnerId);

    console.log('responseReset', responseReset)
};

export default {
    getAllReports,
    getFilteredReports,
    setStatusReport,
    getFilterReportsLearner,
    getFilterReportsType,
    getFilterReportsCourse,
    getFilterReportsModule,
    getFilterReportsStatus,
    getDetailsQuiz,
    getDetailsTask,
    resetAttempts
}

import {dispatch, getState} from '../../../../../state/store';
import {setDraftModule} from '../../../../../state/ducks/courseConstructor';
import moduleService from '../../../../../services/server/moduleService/moduleService';
import {updateCourseModuleList} from '../draftCourseService';

const getDraftModuleFromState = () => getState().courseConstructor.draftModule;

const updateModuleQuiz = (newQuiz:any) => {
    const draftModule = getDraftModuleFromState();
    const updatedDraftModule = {...draftModule, quiz:newQuiz};

    dispatch(setDraftModule(updatedDraftModule));

    updateCourseModuleList(updatedDraftModule);
};

const updateModuleContentList = (draftModule:any, newContent:any) => {
    const contentList = [...draftModule.contents];
    contentList.push(newContent);
    const updatedModule = {...draftModule, contents: contentList};

    dispatch(setDraftModule(updatedModule));

    updateCourseModuleList(updatedModule);
};

const uploadNewMediaContent = async (file:File, typeContent:string, draftModule:any) => {
    const newFiles:any = new FormData();
    newFiles.append('file', file);
    newFiles.append('type', typeContent);
    newFiles.append('moduleId', draftModule.id);
    const newMediaContent = await moduleService.addMediaBlock(newFiles);
    
    updateModuleContentList(draftModule, newMediaContent?.data);
};

export default {
    updateModuleContentList,
    uploadNewMediaContent,
    updateModuleQuiz,
}
import React from "react";

import styles from "./InformationComponent.module.scss";
import Image from "../../../../components/Image/Image";
import firstAdvantageImg from "../../../../../assets/images/icon/first-advantage-icon.svg";
import secondAdvantageImg from "../../../../../assets/images/icon/second-advantage-icon.svg";
import thirdAdvantageIcon from "../../../../../assets/images/icon/third-advantage-icon.svg";
import reviewerImg from "../../../../../assets/images/icon/login-review-reply.png";

const InformationComponent = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.TextContainer}>
        <div className={styles.TitleContainer}>
          <span>Sign up to Gradual</span>
        </div>
        <span className={styles.DescriptionTitleText}>
          Make your sales guys more effective with interactive training
        </span>
        <div className={styles.InformationContainer}>
          <div className={styles.ReviewsContainer}>
            <div className={styles.SavedHoursContainer}>
              <span className={styles.SavedHoursTitle}>
                Sales teams already saved
              </span>
              <div className={styles.Round}>
                <span>34772</span>
                <span>hours of work</span>
              </div>
            </div>
            <div className={styles.ReviewTextContainer}>
              <span className={styles.ReviewText}>
                "Gradual helped us to decrease our SDR ramp time from 3 to 2
                months and we create internal training to level up SDR's
                understanding about how modern sales development works."
              </span>
              <Image src={reviewerImg} className={styles.ReviewerPhoto} />
              <span className={styles.ReviewerName}>William</span>
              <span className={styles.ReviewerPositionText}>
                SDR Team Lead at Reply
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationComponent;

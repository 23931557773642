import React from 'react';
import {Role} from '../../types/UserDetails';

type Props = {
  userRoles: Role[];
  allowedTo: Role[];
};

const Restricted: React.FunctionComponent<Props> = ({userRoles, allowedTo, children}) => {

    const fallbackElements = children;

    if (userRoles && allowedTo.some(role => userRoles.includes(role))) {

        return <>{children}</>;
    }

    if (!userRoles) {

        return <>{fallbackElements}</>;
    }

    return null;
};

export default Restricted;

import React from "react";

import styles from "./AddTeamMemberButton.module.scss";
import Svg from "../../../../../../../../../components/SvgComponent/SvgComponent";
import { ReactComponent as AddTeamMember } from "../../../../../../../../../../assets/images/icon/invite-member.svg";
import ButtonBorder from "../../../../../../../../../components/Button/components/ButtonBorder/ButtonBorder";

const AddTeamMemberButton = (props: any) => {
  const { onOpenAddTeamMemberPopup } = props;

  return (
    <ButtonBorder
      className={styles.ButtonAddMember}
      onClick={onOpenAddTeamMemberPopup}
    >
      <Svg className={styles.ImageAdd}>
        <AddTeamMember />
      </Svg>
      <span>Add admin</span>
    </ButtonBorder>
  );
};

export default AddTeamMemberButton;

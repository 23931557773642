import React from 'react';
import {Route} from 'react-router-dom';

import WindowResizeChecker from '../ServiceContainers/WindowResizeChecker/WindowResizeChecker';
import AppLoader from '../../components/Loader/AppLoader/AppLoader';
import OfflineDetector from '../ServiceContainers/OfflineDetector/OfflineDetector';
import SubscriptionPopupContainer from '../SubscriptionPopupContainer/SubscriptionPopupContainer';
import SubscriptionPopup from '../SubscriptionPopupContainer/SubscriptionPopup/SubscriptionPopup';

const AppLayout = (props:any) => {
    const {component: Component, path} = props;

    return (
        <Route
            path={path}
            render={(matchProps) => (
                <div>
                    <Component {...matchProps} />
                    <WindowResizeChecker />
                    <AppLoader />
                    <OfflineDetector />
                    <SubscriptionPopupContainer />
                    <SubscriptionPopup />
                </div>
            )}
        />
    );
};

export default AppLayout;

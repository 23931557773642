import React, {useState} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {useMediaQuery} from 'react-responsive';

import styles from './PdfContentComponent.module.scss';
import ControlPanel from '../ControlPanel/ControlPanel';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const taylorWidth = (height: number, originalHeight: number,  originalWidth:number) => {
    return (height / originalHeight) * originalWidth;
};

const taylorHeight = (width: number, originalWidth:number, originalHeight: number) => {
    return (width / originalWidth) * originalHeight;
};

const pdfCanvasWidth  = {
    wxgaSize: 900,
    fullSize: 920,
    fallbackSize: 850
};

const pdfCanvasHeight = {
    wxgaSize: 506,
    fullSize: 506,
    fallbackSize: 456
};

const PdfContentComponent = React.memo((props:any) => {
    const {attachPdfFile}  = props;
    const {link} = attachPdfFile;

    const isWxgaScreen = useMediaQuery({query: '(min-width: 1599px)'});
    const isFullScreen = useMediaQuery({ query: '(min-width: 1919px)' });

    const width = isWxgaScreen ? pdfCanvasWidth.wxgaSize : isFullScreen ? pdfCanvasWidth.fullSize : pdfCanvasWidth.fallbackSize,
          height = isWxgaScreen ? pdfCanvasHeight.wxgaSize : isFullScreen ? pdfCanvasHeight.fullSize : pdfCanvasHeight.fallbackSize;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageWidth, setPageWidth] = useState(width);

    const loadingPlaceHolder = <div style={{height: height + 'px'}}>Loading...</div>;

    function onPageRenderSuccess(pageData:any) {
        const {originalWidth, originalHeight} = pageData;

        let dimW: {w:number, h:number} = {w:0, h:0},
            dimH: {w:number, h:number} = {w:0, h:0};

        const newHeight = taylorHeight(width, originalWidth, originalHeight);
        if (newHeight > height) {
            const newWidth = taylorWidth(height, newHeight, width);

            dimW.w = newWidth;
            dimW.h = height;
        } else {
            dimW.w = width;
            dimW.h = newHeight;
        }

        const newWidth = taylorWidth(height, originalHeight, originalWidth);
        if (newWidth > width) {
            const newHeight = taylorHeight(width, newWidth, height);

            dimH.w = width;
            dimH.h = newHeight;
        } else {
            dimH.w = newWidth;
            dimH.h = height;
        }

        const _width = dimW.w * dimW.h > dimH.w * dimH.h ? dimW.w : dimH.w;
        setPageWidth(_width);
    };

    function onDocumentLoadSuccess(docLoadData:any) {
        const {numPages} = docLoadData;
        setNumPages(numPages);
    };

    return (
        <div className={styles.ContentComponent}>
            <ControlPanel
                pdf={link}
                pageNumber={pageNumber}
                numPages={numPages}
                setPageNumber={setPageNumber}
            />
            <Document
                file={{
                    url: link,
                }}
                loading={loadingPlaceHolder}
                onLoadSuccess={onDocumentLoadSuccess}

                //@ts-ignore
                width={pageWidth}
            >
                <Page
                    pageNumber={pageNumber}
                    loading={loadingPlaceHolder}

                    //@ts-ignore
                    width={pageWidth}
                    //@ts-ignore
                    onRenderSuccess={onPageRenderSuccess}
                />
            </Document>

        </div>
    );
}, (prevProps:any, nextProps:any) => {
    const sequenceIdPrev = prevProps.sequenceId;
    const sequenceIdNext = nextProps.sequenceId;

    if (sequenceIdNext === sequenceIdPrev) {
        return true;
    }

    return false;
});

export default PdfContentComponent;
import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';

import styles from './TypeFilterComponent.module.scss';
import topDropDownImg from '../../../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg';
import bottomDropDownImg from '../../../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg';
import analyticsService from '../../../../../../../../../../../services/server/analyticsService/analyticsService';
import Image from '../../../../../../../../../../../components/Image/Image';
import OptionsComponent
    from '../../../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectTypeList} from '../../../../../../../../../../../state/selectors/analytics';
import {useOutsideListener} from "../../../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
    filterTypeList: makeSelectTypeList(),
});

const TypeFilterComponent = (props:any) => {
    const {filterTypeList, filterType, filterCourse, classNameDropDownContainer, classNameDropDownMenu,
        classNameDropDownOverflow, classNameWrapperComponent, classNameTitle, onClickType,
        classNameOptionsWrapper, classNameOpenDropDown
    } = props;

    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [dropDownValue, setDropDownValue] = useState(filterType.type);
    const [loader, setLoader] = useState(false);
    const selectButtonRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setDropDownValue(filterType.type);
    }, [filterType]);

    const openDropDownList = async () => {
        setIsOpenDropDown(!isOpenDropDown);
        await analyticsService.getFilteredListType();
        setLoader(true);
    };

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    const onTypeFilterButtonClick = async (filterTypeName:string, filterTypeId:number) => {
        setDropDownValue(filterTypeName);
        onClickType(filterTypeName, filterTypeId);
        closeDropDownList();
    };

    return (
        <div className={classNameWrapperComponent}>
            <span className={classNameTitle}>Type</span>
            <div className={classNames(classNameDropDownContainer, isOpenDropDown && classNameOpenDropDown)}
                 onClick={openDropDownList} ref={selectButtonRef}>
                <span className={styles.Text}>{isOpenDropDown ? 'Select' : dropDownValue}</span>
                <Image src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
                       className={classNames(styles.DropDownIcon, isOpenDropDown && styles.isActiveDropDownIcon)}/>
            </div>
            {isOpenDropDown && (
                <div className={classNameDropDownMenu} ref={dropDownRef}>
                    <div className={classNameDropDownOverflow}>
                        {loader ?
                            filterTypeList?.map((item:any) => {
                                const filterTypeName = item.name;
                                const filterTypeId = item.id;
                                const isActiveOptions = item.name === dropDownValue;
                                const isNotAvailableOption = filterTypeName === 'Course progress' &&
                                Number(filterCourse.id) === -1 ? styles.DisabledOptions : '';

                                return (
                                    <OptionsComponent
                                        key={filterTypeId}
                                        classNameNotAvailable={isNotAvailableOption}
                                        classNameOptionsWrapper={classNameOptionsWrapper}
                                        isActiveOptions={isActiveOptions}
                                        reference={optionsRef}
                                        optionText={filterTypeName}
                                        onClick={() => onTypeFilterButtonClick(filterTypeName, filterTypeId)}
                                    />
                                )
                            }):
                            <div className={styles.WrapperForLoader}>
                                <Loader
                                    type="TailSpin"
                                    color="#F85A40"
                                    height={25}
                                    width={25}
                                />
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default connect(mapStateToProps)(TypeFilterComponent);
export const filterDetailsList = [
    {
        name: 'All',
        id: -1,
    },
    {
        name: 'Multiple choice',
        id: 0,
    },
    {
        name: 'Essay',
        id: 1,
    }
];
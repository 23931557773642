import validationService from "../../../../../../../../../services/server/validationService/validationService";

const removeSeparators = (newInputValue:string) => {
    const editedInput = newInputValue.replace(/[,;]/g, ' ');
    return editedInput;
};

const turnIntoList = (invitationString:string) => {
    const rawList = splitInvitationsIntoList(invitationString);
    return rawList.filter((value:string, index:number) => {
        return rawList.indexOf(value) === index;
    });
};

const splitInvitationsIntoList = (invitationString:string) => {
    return invitationString.split(/\s/);
}

const isInvitationValid = (isPhoneInvitation:boolean, invitation:string) => {
    if (isPhoneInvitation) {
        return validationService.isValidPhoneNumber(invitation);
    } else {
        return validationService.isValidEmail(invitation);
    }
};

const isInvitationInList = (invitationList:string[], invitation:string) => {
    return invitationList.find((el:string) => el === invitation) !== undefined;
};

export default {
    removeSeparators,
    splitInvitationsIntoList,
    isInvitationValid,
    isInvitationInList,
    turnIntoList
};
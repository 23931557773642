export const rolesList = [
    {
        id: 1,
        name: '1-10',
    },
    {
        id: 2,
        name: '11-50',
    },
    {
        id: 3,
        name: '51-200',
    },
    {
        id: 4,
        name: '201-500',
    },
    {
        id: 5,
        name: '501-1000',
    },
    {
        id: 6,
        name: '1001-5000',
    },
    {
        id: 7,
        name: '5000+',
    }
]

export default rolesList;
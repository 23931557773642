import React from 'react';
import styles from '../../PipedriveComponent.module.scss';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import Image from '../../../../../../../../../../../components/Image/Image';
import gearImg from '../../../../../../../../../../../../assets/images/icon/gear.icon.svg';

type ConfigureTextComponentProps = {
    onConfigureButtonClick:Function
};

const ConfigureTextComponent:React.FC<ConfigureTextComponentProps> = ({onConfigureButtonClick}) => {
    return (
        <>
            <div className={styles.InfoText}>
                <div>Choose among 11 sales performance metrics: conversion rates,</div>
                <div>activities and sales cycle.</div>
            </div>
            <ButtonColor
                className={styles.ConnectionBtn}
                onClick={onConfigureButtonClick}
            >
                <Image src={gearImg} className={styles.ImgBtn}/>
                <div>Configure</div>
            </ButtonColor>
        </>
    );
};

export default ConfigureTextComponent;

import React from 'react';
import {createStructuredSelector} from 'reselect';

import styles from './CourseConstructorComponent.module.scss';
import DraftModuleComponent from './DraftModuleComponent/DraftModuleComponent';
import {connect} from 'react-redux';
import {
    makeSelectDraftCourse,
    makeSelectDraftModule
} from '../../../../state/selectors/courseConstructor';
import VerifyBlockComponent
    from '../../../ConsumerAccountPage/AdminAccountPage/components/ContentComponent/components/VerifyBlockComponent/VerifyBlockComponent';
import {makeSelectUserDetails} from '../../../../state/selectors/userDetails';

const mapStateToProps = createStructuredSelector({
    draftCourse: makeSelectDraftCourse(),
    draftModule: makeSelectDraftModule(),
    userDetails: makeSelectUserDetails()
});

const CourseConstructorComponent = (props:any) => {
    const {draftCourse, draftModule, userDetails} = props;

    return (
        <>
            <div className={styles.CourseConstructorContainer}>
                {!userDetails.isEmailVerified && <VerifyBlockComponent/>}
                {draftModule?.id && (
                    <DraftModuleComponent
                        draftModule={draftModule}
                        draftCourse={draftCourse}
                    />
                )}
            </div>
        </>
    );
};

export default connect(mapStateToProps)(CourseConstructorComponent);
import React, {useState} from 'react';

import styles from './EssayQuestionComponent.module.scss';
import AttachImageComponent
    from '../MultipleChoiceQuestionComponent/components/AttachImageComponent/AttachImageComponent';
import ImageFullContainer from '../MultipleChoiceQuestionComponent/components/ImageFullContainer/ImageFullContainer';
import TextArea from '../../../../../../../../../../../components/TextArea/TextArea';

const EssayQuestionComponent = (props:any) => {
    const {activeQuestion, onQuestionTitleChange, addImageToQuestion, onImageDelete} = props;
    const [isVisibleImgFull, setIsVisibleImgFull] = useState<boolean>(false);

    const originalImage = activeQuestion?.attachFile?.link;

    const titleChange = (event: React.FormEvent<HTMLInputElement>) => {
        onQuestionTitleChange(event.currentTarget.value, activeQuestion.id);
    };
    
    return (
        <div className={styles.EssayContainer}>
            <div className={styles.WrapperImg}>
                <div className={styles.WrapperInputField}>
                    <TextArea
                        className={styles.InputField}
                        value={activeQuestion.essayQuestion.title ? activeQuestion.essayQuestion.title : ''}
                        placeholder='Type a questions'
                        onChange={titleChange}
                    />
                </div>
                <AttachImageComponent
                    isEssayImg
                    setIsVisibleImgFull={setIsVisibleImgFull}
                    originalImage={originalImage}
                    id={activeQuestion?.id}
                    addImage={addImageToQuestion}
                    deleteImage={onImageDelete}
                />
            </div>
            {isVisibleImgFull &&
                <ImageFullContainer
                    imageSrc={originalImage}
                    setIsVisibleImgFull={setIsVisibleImgFull}
                />
            }
        </div>
    );
};

export default EssayQuestionComponent;
import React, {useEffect, useState, useRef} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './ActionComponent.module.scss';
import Image from '../../../../../../../../../../../../../components/Image/Image';
import {actionsList} from './actionsList';
import {dispatch} from '../../../../../../../../../../../../../state/store';
import {
    setCurrentCourseList,
    setIsVisibleCourseList,
    setIsVisibleCreateTemplates
} from '../../../../../../../../../../../../../state/ducks/admin';
import {setActiveTab} from '../../../../../../../../../../../../../state/ducks/global';
import {availableTabList} from '../../../../../../../../../MenuListComponent/menuTabList';
import {createStructuredSelector} from 'reselect';
import {makeSelectCourseList} from '../../../../../../../../../../../../../state/selectors/admin';
import {connect} from 'react-redux';
import {CourseMetaData} from '../../../../../../../../../../../../../types/CourseMetaData';
import courseService from '../../../../../../../../../../../../../services/server/courseService/courseService';
import {DeletePopup} from '../../../../../../../../../../../../../components/DeletePopup/DeletePopup';
import learnersService from '../../../../../../../../../../../../../services/server/learnersService/learnersService';
import classNames from 'classnames';

const mapStateToProps = createStructuredSelector({
    currentCourseList: makeSelectCourseList(),
});

const ActionComponent = (props:any) => {
    const {currentCourseList, onClickReview, Container} = props;

    const classNameContainer = classNames(styles.Container, Container);

    const onClickAction = (id:number) => {
        if (id === 1 || id === 2) {
            window.open('https://calendar.google.com/','_blank');
        }
        if (id === 3) {
            window.open('https://pjbfdkis0pq.typeform.com/to/AfcszbnJ?typeform-source=localhost','_blank');
        }
        if (id === 4) {
            dispatch(setActiveTab(availableTabList[0]));
            dispatch(setIsVisibleCourseList(false));
            dispatch(setIsVisibleCreateTemplates(true));
        }
    };

    return (
        <>
            <div className={classNameContainer}>
                <div className={styles.Title}>Company Training</div>
                <div className={styles.SubContainer}>
                    {currentCourseList.map((item:CourseMetaData) => {
                        const id = item.id;
                        const name = item.name;

                        return (
                            <div className={styles.WrapperCourses} key={id}>
                                <div className={styles.CoursesItem}>
                                    <div className={styles.NameCourse}>{name}</div>
                                    <div
                                        className={styles.Review}
                                        onClick={() => onClickReview(name, id)}
                                    >
                                        Ask to review
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                    <div className={styles.Title}>Actions</div>
                    {actionsList.map((item) => {
                        const name = item.name;
                        const id = item.id;
                        const icon = item.icon;

                        return (
                            <div
                                key={id}
                                className={styles.WrapperItem}
                                onClick={() => onClickAction(id)}
                            >
                                <Image src={icon} className={styles.ImgAction}/>
                                <div className={styles.ItemCourse}>{name}</div>
                            </div>
                        )
                    })}
            </div>

        </>
    );
};

export default connect(mapStateToProps)(ActionComponent);

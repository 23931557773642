import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {withCookies} from 'react-cookie';

import styles from './ModuleListComponent.module.scss';
import plusImg from '../../../../../assets/images/icon/module-add-icon.svg';
import ModuleNameCellComponent from './components/ModuleNameCellComponent/ModuleNameCellComponent';
import ButtonColor from "../../../../components/Button/components/ButtonColor/ButtonColor";
import Image from '../../../../components/Image/Image';
import {
    deleteModuleFromCourse,
    updateCourse,
    updateCourseModuleList
} from '../CourseConstructorComponent/draftCourseService';
import {connect} from 'react-redux';
import {dispatch} from '../../../../state/store';
import {
    setDraftModule,
    setDraftModuleQuiz,
    setDraftQuizQuestion
} from '../../../../state/ducks/courseConstructor';
import {makeSelectDraftCourse, makeSelectDraftModule} from '../../../../state/selectors/courseConstructor';
import {createStructuredSelector} from 'reselect';
import courseService from "../../../../services/server/courseService/courseService";
import {ROOT} from "../../../../consts/routes/routePaths";
import Logo from '../../../../components/Logo/Logo';

const mapStateToProps = createStructuredSelector({
    draftModule: makeSelectDraftModule(),
    draftCourse: makeSelectDraftCourse()
});

const ModuleListComponent = (props:any) => {
    const {draftCourse, draftModule, cookies} = props;

    const [sortedModules, setSortedModules] = useState([]);

    useEffect(() => {
        const sortedModules = draftCourse?.modules?.sort((a:any, b:any) => a.sequenceId > b.sequenceId ? 1 : -1);
        setSortedModules(sortedModules);
    }, [draftCourse]);

    const onModuleClick = (moduleId:number) => {
        const newDraftModule = draftCourse.modules.find((module:any) => module.id === moduleId);

        dispatch(setDraftModule(newDraftModule));
        dispatch(setDraftModuleQuiz(newDraftModule.quiz));
        dispatch(setDraftQuizQuestion(newDraftModule.quiz?.questions[0]));

        cookies.set(draftCourse.id, moduleId);
    };

    const onModuleNameChange = async (moduleId:number, newModuleName:string) => {
        await courseService.updateModuleById(moduleId, newModuleName);
        const updatedModule = draftCourse.modules.find((module:any) => module.id === moduleId);

        updatedModule.name = newModuleName;
        updateCourseModuleList(updatedModule);
    };

    const onDeleteModuleClick = async (event:any, moduleId:number) => {
        event.stopPropagation();
        if(sortedModules.length > 1) {
            deleteModuleFromCourse(moduleId);
            await courseService.deleteModuleByModuleId(moduleId);
            dispatch(setDraftModule(draftCourse.modules[0]));
        }
    };

    const onAddModuleButtonClick = async () => {
        await courseService.addModuleByCourseId(draftCourse.id);
        await updateCourse();
    };

    const onLogoClick = () => {
        window.location.replace(ROOT);
    };

    const onDragEnd = async (result:any) => {
        const {destination, source, reason} = result;

        if(!destination || reason === 'CANCEL') {
            return;
        }

        if(destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const modules = [...sortedModules];
        const droppedModules = sortedModules[source.index];

        modules.splice(source.index, 1);
        modules.splice(destination.index, 0, droppedModules);

        const sortedIds = modules.map((module:any, index:number) => {
            const previousModule:any = sortedModules[index];
            return {
                moduleId: module.id,
                sequenceId: previousModule.sequenceId,
            };
        });

        setSortedModules(modules);

        await courseService.updateSequenceModule(sortedIds);
        await updateCourse();
    };

    return (
        <div className={styles.ModuleListContainer}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='id3'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <div className={styles.LogoContainer}>
                                <Logo className={styles.Logo} onClick={onLogoClick} />
                            </div>
                            <span className={styles.CourseName}>{draftCourse?.name?.toUpperCase()}</span>
                            <div>
                                {sortedModules?.map((item:any, index:number) => {
                                    const moduleName = item.name;
                                    const moduleId = item.id;
                                    const isActiveModule = moduleId === draftModule?.id;

                                    return (
                                        <Draggable key={index} draggableId={index + ''} index={index}>
                                            {(provided:any) => (
                                                <div ref={provided.innerRef}
                                                     {...provided.draggableProps}
                                                     {...provided.dragHandleProps}
                                                >
                                                    <ModuleNameCellComponent
                                                        onClick={() => onModuleClick(moduleId)}
                                                        onModuleNameChange={(newModuleName:string) =>
                                                            onModuleNameChange(moduleId, newModuleName)}
                                                        onDeleteModuleClick={(event:any) =>
                                                            onDeleteModuleClick(event, moduleId)}
                                                        moduleName={moduleName}
                                                        isActiveModule={isActiveModule}
                                                        key={moduleId}
                                                        sortedModules={sortedModules}
                                                    />
                                                </div>
                                                )}
                                        </Draggable>
                                    );
                                })}
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className={styles.WrapperBtn}>
                <ButtonColor
                    className={styles.AddModuleButton}
                    onClick={onAddModuleButtonClick}
                >
                    <Image src={plusImg} className={styles.PlusImage} />
                    <span>Add module</span>
                </ButtonColor>
            </div>
        </div>
    );
};

export default withCookies(connect(mapStateToProps)(ModuleListComponent));
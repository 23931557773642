import stringUtils from '../../utils/stringUtilsService';
import adminService from '../adminService/adminService';
import config from '../../../config/config';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {brandingColorPath, brandingLogoPath, deleteBrandLogoPath, getCustomInfoPath} = externalLinks;

const setBrandingColor = async (color:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, brandingColorPath);

    return await adminService.setBrandingColor(formattedUrl, color);
};

const setBrandingLogo = async (logo:any) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, brandingLogoPath);

    return await adminService.setBrandingLogo(formattedUrl, logo);
};

const getBrandingColor = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, brandingColorPath);

    return await adminService.getBrandingColor(formattedUrl);
};

const getBrandingLogo = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, brandingLogoPath);

    return await adminService.getBrandingLogo(formattedUrl);
};

const deleteBrandingLogo = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteBrandLogoPath);

    return await adminService.deleteBrandingLogo(formattedUrl);
};

const getCustomInfo = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getCustomInfoPath);

    return await adminService.getCustomInfo(formattedUrl);
};

export default {
    setBrandingColor,
    setBrandingLogo,
    getBrandingColor,
    getBrandingLogo,
    deleteBrandingLogo,
    getCustomInfo
}
import React from "react";

import styles from "./AnalyticsComponent.module.scss";
import CompanyOverview from "./components/CompanyOverview/CompanyOverview";
import CourseAnalytics from "./components/CourseAnalytics/CourseAnalytics";
import { createStructuredSelector } from "reselect";
import { makeSelectIsEmptyAnalyticsData } from "../../../../../../../state/selectors/analytics";
import { connect } from "react-redux";
import analyticsImg from "../../../../../../../../assets/images/emoji/bar-chart_1f4ca.png";

const mapStateToProps = createStructuredSelector({
  isEmptyAnalyticsData: makeSelectIsEmptyAnalyticsData(),
});

const AnalyticsComponent = (props: any) => {
  const { isEmptyAnalyticsData } = props;

  return (
    <div className={styles.WrapperAnalytics}>
      {!isEmptyAnalyticsData && (
        <>
          <CompanyOverview />
          <CourseAnalytics />
        </>
      )}
      {isEmptyAnalyticsData && (
        <div className={styles.AnalyticsNone}>
          <div className={styles.WrapperTitleNone}>
            <div className={styles.TitleNone}>There are no insights yet!</div>
            <img
              src={analyticsImg}
              alt="analytics_emoji"
              style={{ height: "25px" }}
            />
          </div>
          <div className={styles.Subtitle}>
            But updates are coming once your users start training.
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(AnalyticsComponent);

import React, {FC, useEffect, useRef, useState} from 'react';
import ReactPaginate from 'react-paginate';

import styles from './GapsItem.module.scss';
import Svg
    from '../../../../../../../../../../../../../../../../../../../../../../components/SvgComponent/SvgComponent';
import {ReactComponent as ArrowTop} from '../../../../../../../../../../../../../../../../../../../../../../../assets/images/icon/arrow-top.svg';
import {ReactComponent as ArrowBottom} from '../../../../../../../../../../../../../../../../../../../../../../../assets/images/icon/arrow-bottom.svg';
import ActionComponent
    from '../../../../../../../../../../../../../../../../LearnersComponent/components/CrmAnalytics/components/MetricsComponent/components/ActionComponent/ActionComponent';
import {
    LearnerGaps
} from "../../../../../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceGapData";
import {
    TEAM
} from "../../../../../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceMetricType";
import {SetActionComponent} from "./SetActionComponent";
import ReviewCourseComponent
    from "../../../../../../../../../../../../../../../../LearnersComponent/components/CrmAnalytics/components/MetricsComponent/components/MetricsItemComponent/components/ReviewCourseComponent/ReviewCourseComponent";
import {
    OverviewType
} from "../../../../../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceOverview";

interface GapItemType {
    learnerGaps: LearnerGaps;
}

const GapsItem = ({learnerGaps }: GapItemType) => {
    const {name, email, id, personalGaps} = learnerGaps;

    const [isOpenReview, setIsOpenReview] = useState(false);
    const courseMeta = useRef<{name: string, id: number}>(null);
    const keys: string[] = Object.keys(personalGaps);

    return (
        <div className={styles.WrapperItem}>
            <div className={styles.Item}>
                <span className={styles.Name}>{name}</span>
                <span className={styles.Email}>{email}</span>
                <div>
                    {
                        keys.map(metricName => {
                            return (
                                <div className={styles.GapsColor}>{metricName}</div>
                            );
                        })
                    }
                </div>
                <div>
                    {
                        keys.map(metricName => {
                            const gap = personalGaps[metricName];
                            const isPercentage = gap.sign === OverviewType.PERCENTAGE;
                            return (
                                <div className={styles.DetailsColor}>
                                    {gap.result}{isPercentage ? '%' : ''} below {gap.type === TEAM ? 'team average' : 'previous personal'}
                                </div>
                            );
                        })
                    }
                </div>
                <div>
                    {
                        keys.map(_ => {
                            return <SetActionComponent setIsOpenReview={setIsOpenReview} courseMeta={courseMeta}/>
                        })
                    }
                </div>
            </div>

            {isOpenReview &&
                <div>
                    <ReviewCourseComponent
                        courseId={courseMeta.current!.id}
                        courseName={courseMeta.current!.name}
                        learnerId={id}
                        learnerName={name}
                        setIsShowReviewPopUp={setIsOpenReview}
                    />
                </div>
            }
        </div>
    );
};

export default GapsItem;

import React, {useCallback, useEffect, useMemo, useState} from 'react';

import styles from './PerformanceGridComponent.module.scss'
import FiltersGridComponent from './components/FiltersGridComponent/FiltersGridComponent';
import GridHeaderComponent from './components/GridIHeaderComponent/GridHeaderComponent';
import {
    PerformanceHeatMap
} from "../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceHeatMap";
import {Filter} from "../../../../../../../../../../../../../../../../types/filter/Filter";
import {copyObject} from "../../../../../../../../../../../../../../../../services/utils/objectCopy";
import {EmptyPerformanceDataComponent} from "../EmptyPerformanceDataComponent/EmptyPerformanceDataComponent";

interface PerformanceGridType {
    performanceGrid: PerformanceHeatMap | undefined
}

const extractFilters = (performanceGrid: PerformanceHeatMap) : {learnerFilters: Filter[], metricFilters: Filter[]} => {
    const {metricLabels, heatMapResults} = performanceGrid;

    const metricFilters:Filter[] = metricLabels.map((metricLabel, idx) => {
        return {
            name: metricLabel,
            id: idx + 1
        };
    });

    const learnerFilters:Filter[] = Object.keys(heatMapResults).map((learnerName, idx) => {
        return {
            name: learnerName,
            id: idx + 1
        }
    });

    return {learnerFilters, metricFilters};
}

const PerformanceGridComponent = ({performanceGrid}: PerformanceGridType) => {
    const {learnerFilters, metricFilters} = useMemo(() => extractFilters(performanceGrid!), [performanceGrid]);

    const [chosenLearnerFilters, setChosenLearnerFilters] = useState<Filter[]>([]);
    const [chosenMetricFilters, setChosenMetricFilters] = useState<Filter[]>([]);
    const [filteredPG, setFilteredPG] = useState<PerformanceHeatMap>(performanceGrid!);

    const filterPerformanceGrid = useCallback(() => {
        let _heatMap = copyObject(performanceGrid!.heatMapResults);
        if (!!chosenLearnerFilters.length) {
            Object.keys(_heatMap)
                .forEach(learnerName => {
                    if (!chosenLearnerFilters.map(learnerFilter => learnerFilter.name).includes(learnerName)) {
                        delete _heatMap[learnerName];
                    }
                });
        }

        let _metricLabels: string[] = copyObject(performanceGrid!.metricLabels);
        if (!!chosenMetricFilters.length) {
            _metricLabels = _metricLabels.filter(metricName => chosenMetricFilters
                .map(metricFilter => metricFilter.name)
                .includes(metricName));
        }

        setFilteredPG({heatMapResults: _heatMap, metricLabels: _metricLabels});
    }, [chosenLearnerFilters, chosenMetricFilters]);

    useEffect(filterPerformanceGrid, [chosenLearnerFilters, chosenMetricFilters]);
    return (
        <div>
            <div className={styles.Title}>Performance grid</div>
            {!!Object.keys(performanceGrid!.heatMapResults).length ?
                <>
                    <FiltersGridComponent
                        allLearnerFilters={learnerFilters}
                        chosenLearnerFilters={chosenLearnerFilters}
                        setChoseLearnerFilters={setChosenLearnerFilters}

                        allMetricFilters={metricFilters}
                        chosenMetricFilters={chosenMetricFilters}
                        setChosenMetricFilters={setChosenMetricFilters}
                    />
                    <GridHeaderComponent performanceGrid={filteredPG}/>
                </> :
                <EmptyPerformanceDataComponent waringMessage={'No data found for performance grid'} />
            }
        </div>
    );
};

export default PerformanceGridComponent;

import React from 'react';
// @ts-ignore
import {Offline} from 'react-detect-offline';

import {setIsNetworkOnline} from '../../../state/ducks/global';
import {dispatch} from '../../../state/store';
import NoInternetConnectionPopup from '../../../components/Popup/PopupFullPage/NoInternetConnectionPopup/NoInternetConnectionPopup';

const OfflineDetector = () => {
    const changesInternetConnection = (isOnline:boolean) => {
        if (isOnline) {
            dispatch(setIsNetworkOnline(true));

            console.log(`OfflineDetector: Internet connection is online`);
        } else {
            dispatch(setIsNetworkOnline(false));

            console.log(`OfflineDetector: Internet connection is offline`);
        }
    };

    return (
        <>
            <Offline
                polling={{
                    interval: 500000000,
                }}
                onChange={changesInternetConnection}
            />
            <NoInternetConnectionPopup />
        </>
    );
};

export default OfflineDetector;

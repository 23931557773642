import React, { useEffect, useState } from "react";

import styles from "./CourseListComponent.module.scss";
import CourseCellComponent from "./components/CourseCellComponent/CourseCellComponent";
import WelcomeComponent from "./components/WelcomeComponent/WelcomeComponent";
import Image from "../../../../../../../../../components/Image/Image";
import plusAddImg from "../../../../../../../../../../assets/images/icon/plus-add-icon.svg";
import searchImage from "../../../../../../../../../../assets/images/icon/search-icon-courses.svg";
import crossImg from "../../../../../../../../../../assets/images/icon/close-popup-icon.svg";
import { connect } from "react-redux";
import {
  makeSelectCourseList,
  makeSelectIsEmptyCoursesData,
} from "../../../../../../../../../state/selectors/admin";
import { createStructuredSelector } from "reselect";
import appRouterService from "../../../../../../../../../services/route/appRouterService";
import sessionStorageService from "../../../../../../../../../services/storage/sessionStorageService";
import Input from "../../../../../../../../../components/Input/Input";
import { CourseMetaData } from "../../../../../../../../../types/CourseMetaData";
import { loadCourseById } from "../../../../../../../../CourseConstructorPage/components/CourseConstructorComponent/draftCourseService";

const mapStateToProps = createStructuredSelector({
  currentCourseList: makeSelectCourseList(),
  isEmptyCoursesData: makeSelectIsEmptyCoursesData(),
});

const CoursesListComponent = (props: any) => {
  const {
    currentCourseList,
    viewCreateCourseComponent,
    onOpenTemplateComponent,
    isEmptyCoursesData,
  } = props;

  const [courseList, setCourseList] =
    useState<CourseMetaData[]>(currentCourseList);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const isVisibleCourses = currentCourseList?.length;

  useEffect(() => {
    filterCoursesByName(inputSearchValue);
  }, [currentCourseList]);

  const filterCoursesByName = (name: string) => {
    const filteredCoursesByName = currentCourseList?.filter((item: any) => {
      if (item.name.toLowerCase().trim().includes(name.toLowerCase().trim())) {
        return item;
      }
    });
    setCourseList(filteredCoursesByName);
  };

  const onChangeSearchInput = (event: any) => {
    const { value } = event.currentTarget;
    filterCoursesByName(value);
    setInputSearchValue(value);
  };

  const onCourseClick = async (courseId: any) => {
    const newDraftCourse: any = await loadCourseById(courseId);
    sessionStorageService.setDraftCourseId(courseId);

    const isModuleListNotComplete = newDraftCourse?.modules.length <= 1;
    const isModuleWithoutName =
      newDraftCourse.modules[0]?.name === null ||
      !newDraftCourse.modules[0]?.name?.length;

    if (isModuleListNotComplete && isModuleWithoutName) {
      viewCreateCourseComponent(true);
    } else {
      appRouterService.forwardToCourseConstructor();
    }
  };

  const onClearInputValue = () => {
    setInputSearchValue("");
    setCourseList(currentCourseList);
  };

  return (
    <>
      {isEmptyCoursesData && (
        <WelcomeComponent onClick={onOpenTemplateComponent} />
      )}
      {!isEmptyCoursesData && (
        <div className={styles.WrapperForCourseList}>
          <div className={styles.WrapperFilter}>
            <Image
              src={inputSearchValue.length ? crossImg : searchImage}
              className={
                inputSearchValue.length ? styles.ImageClose : styles.SearchImage
              }
              onClick={onClearInputValue}
            />
            <Input
              placeholder="Search for a training"
              className={styles.InputSearch}
              value={inputSearchValue}
              type="text"
              onChange={onChangeSearchInput}
            />
          </div>
          {isVisibleCourses ? (
            <div className={styles.CourseList}>
              <div
                className={styles.AddNewCourseContainer}
                onClick={onOpenTemplateComponent}
              >
                <Image className={styles.ImagePlus} src={plusAddImg} />
                <div className={styles.TitleAddCourse}>Create a course</div>
              </div>
              {courseList?.map((course: any) => {
                const name = course.name;
                const backgroundColor = course.background;
                const courseId = course.id;
                const learnersSize = course.learnersSize;

                return (
                  <CourseCellComponent
                    currentCourseList={currentCourseList}
                    onCourseClick={onCourseClick}
                    courseName={name}
                    backgroundColor={backgroundColor}
                    courseId={courseId}
                    key={courseId}
                    learnersSize={learnersSize}
                  />
                );
              })}
            </div>
          ) : (
            <div className={styles.CoursesNone}>
              No training with given search name
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(CoursesListComponent);

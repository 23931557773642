import {useState, useRef} from 'react';

const useScreenRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const stream = useRef<MediaStream | null>(null);
    const dataChunks = useRef<Blob[]>([]);
    const recorder = useRef<MediaRecorder | null>(null);
    const [mediaUrl, setMediaUrl] = useState<string | null>(null);

    let options: { mimeType: string };
    if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9, opus')) {
        options = {mimeType:'video/webm;codecs=vp9, opus'};
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8, opus')){
        options = {mimeType: 'video/webm;codecs=vp8, opus'};
    } else {
        options = {mimeType: 'video/mp4'};
    }

    const onStopRecording = () => {
        setIsRecording(false)

        stream.current?.getTracks().forEach((track) => {
            track.stop();
        });

        const blobFile = new Blob(dataChunks.current, {type: "video/mp4;codecs=opus"});
        setMediaUrl(URL.createObjectURL(blobFile));
    };

    const onStartRecording = async () => {
        //@ts-ignore
        const displayStream = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: true
        });

        const audioStream = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true
        });

        stream.current = new MediaStream([...displayStream.getVideoTracks(), ...audioStream.getAudioTracks()]);
        recorder.current = new MediaRecorder(stream.current, options);

        stream.current.getVideoTracks()[0].onended = () => {
            recorder.current?.stop();
        };

        recorder.current.onstop = () => {
            onStopRecording();
        };

        recorder.current.ondataavailable = (event) => {
            dataChunks.current.push(event.data);
        };

        recorder.current.start();
        setIsRecording(true);
    };

    const pauseRecording = () => {
        if (recorder.current?.state === "recording") {
            recorder.current.pause();
        }
    };

    const resumeRecording = () => {
        if (recorder.current?.state === "paused") {
            recorder.current.resume();
        }
    };

    const stopRecording = () => {
        recorder.current?.stop();
    };

    const startRecording = async () => {
        onStartRecording();
    };

    const flushMediaUrl = () => {
        setMediaUrl(null);
        dataChunks.current = [];
    }

    return {
        stopRecording,
        startRecording,
        resumeRecording,
        pauseRecording,
        flushMediaUrl,
        isRecording: isRecording,
        mediaUrl:mediaUrl
    };
}

export default useScreenRecorder;
import React from "react";
import classNames from "classnames";

import styles from "../../ListLearners.module.scss";

const ListProgress = (props: any) => {
  const { renderedProgress } = props;

  return (
    <>
      {renderedProgress?.map((item: any) => {
        const learnerName = item.learner.name;
        const learnerId = item.learner.id;
        const isCourseFinished = item.isCourseFinished;
        const isCourseStarted = item.isCourseStarted;
        const progress = item.progress;

        const textStatus = !isCourseStarted
          ? "Not yet started"
          : isCourseStarted && isCourseFinished
          ? "Training finished"
          : isCourseStarted && !isCourseFinished
          ? item.module.name
          : "";

        const textProgress = !isCourseStarted
          ? "-"
          : isCourseStarted && isCourseFinished
          ? "100"
          : isCourseStarted && !isCourseFinished
          ? progress
          : "";

        const classNameProgress = classNames(styles.DetailsLearner);

        return (
          <div
            className={classNames(
              styles.LearnerItem,
              styles.LearnerItemCourseProgress
            )}
            key={learnerId}
          >
            <span className={styles.NameLearner}>{learnerName}</span>
            <span className={classNameProgress}>{`${textProgress}%`}</span>
            <span className={styles.DetailsLearner}>{textStatus}</span>
          </div>
        );
      })}
    </>
  );
};

export default ListProgress;

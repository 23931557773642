import { createSelector } from "reselect";

const selectGlobal = (state: any) => state.admin;

const makeSelectIsEmptyLearnersData = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.isEmptyLearnersData
  );

const makeSelectCourseList = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.currentCourseList
  );

const makeSelectSortedCourseList = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.sortedCourseList
  );

const makeSelectLearnersList = () =>
  createSelector(selectGlobal, (globalState: any) => globalState.learnersList);

const makeSelectSortedLearnerList = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.sortedLearnerList
  );

const makeSelectFilterCourseLearner = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.filterLearnerCourse
  );

const makeSelectFilterNameLearner = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.filterLearnerName
  );

const makeSelectSortedLearnerName = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.sortedLearnerName
  );

const makeSelectTemplatesList = () =>
  createSelector(selectGlobal, (globalState: any) => globalState.templatesList);

const makeSelectIsVisibleCourseList = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.isVisibleCourseList
  );

const makeSelectIsVisibleCreateTemplates = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.isVisibleCreateTemplates
  );

const makeSelectIsVisibleCreateCourseComponent = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.isVisibleCreateCourseComponent
  );

const makeSelectIsEmptyCoursesData = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.isEmptyCoursesData
  );

const makeSelectPublicInviteLink = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.publicInviteLink
  );

const makeSelectPublicUserInviteLink = () =>
  createSelector(
    selectGlobal,
    (globalState: any) => globalState.publicUserInviteLink
  );

export {
  makeSelectIsEmptyLearnersData,
  makeSelectCourseList,
  makeSelectSortedCourseList,
  makeSelectLearnersList,
  makeSelectSortedLearnerList,
  makeSelectFilterCourseLearner,
  makeSelectFilterNameLearner,
  makeSelectSortedLearnerName,
  makeSelectTemplatesList,
  makeSelectIsVisibleCourseList,
  makeSelectIsVisibleCreateTemplates,
  makeSelectIsVisibleCreateCourseComponent,
  makeSelectIsEmptyCoursesData,
  makeSelectPublicInviteLink,
  makeSelectPublicUserInviteLink,
};

import axios from "axios";
import localStorageService from "../storage/localStorageService";
import {
  INVALID_ACCESS_TOKEN_MESSAGE,
  ACCESS_TOKEN_CORRUPTED_MESSAGE,
} from "../../consts/error/errorTypesMessage";
import authService from "./authService/authService";

const get = (url: string, requestConfig = {}) =>
  requestApi("get", url, requestConfig);
const post = (url: string, requestConfig = {}) =>
  requestApi("post", url, requestConfig);
const deleteMethod = (url: string, requestConfig = {}) =>
  requestApi("delete", url, requestConfig);
const put = (url: string, requestConfig = {}) =>
  requestApi("put", url, requestConfig);

const requestApi = async (method: string, url: string, requestConfig = {}) => {
  try {
    const accessToken = localStorageService.getAccessToken();
    let config: object = { method: method, url: url };

    if (!!accessToken) {
      config = {
        ...config,
        headers: { Authorization: "Bearer " + accessToken },
      };
    }

    config = { ...config, ...requestConfig };

    return await request(config);
  } catch (e) {
    console.log(`requestApi: url: ${url}; requestConfig: ${JSON.stringify(
      requestConfig
    )}, 
        method: ${method}; error: ${e}; response data: ${JSON.stringify(
      e.response?.data
    )}`);

    throw e;
  }
};

const request = (requestConfig: object) => axios.request(requestConfig);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response?.data.message === INVALID_ACCESS_TOKEN_MESSAGE ||
      error.response?.data.message === ACCESS_TOKEN_CORRUPTED_MESSAGE
    ) {
      const newAuthData = await authService.getNewAuthData();
      const accessToken = localStorageService.getAccessToken();
      error.config.headers.Authorization = "Bearer " + accessToken;

      if (newAuthData) {
        return await axios.request(error.config);
      }
    }

    throw error;
  }
);

export default {
  get,
  post,
  deleteMethod,
  put,
};

import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Loader from "react-loader-spinner";

import styles from "./CourseFilterComponent.module.scss";
import topDropDownImg from "../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg";
import bottomDropDownImg from "../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg";
import Image from "../../../../../../../../../components/Image/Image";
import OptionsComponent from "../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectFilterCourse } from "../../../../../../../../../state/selectors/reports";
import reportsService from "../../../../../../../../../services/server/reportsService/reportsService";
import { useOutsideListener } from "../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
  filterCourseList: makeSelectFilterCourse(),
});

const CourseFilterComponent = (props: any) => {
  const {
    classNameContainer,
    classNameDropDown,
    classNameDropDownMenu,
    classNameDropDownOverflow,
    filterCourseList,
    courseFilter,
    onCourseClick,
    setValueModule,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(courseFilter.name);
  const [sortedListOption, setSortedListOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSortedListOption(
      filterCourseList.sort((a: any, b: any) => (a?.id < b?.id ? -1 : 1))
    );
  }, []);

  useEffect(() => {
    setDropDownValue(courseFilter.name);
  }, [courseFilter]);

  const openDropDownList = async () => {
    setIsOpenDropDown(!isOpenDropDown);
    await reportsService.getFilterReportsCourse();
    setLoader(true);
  };

  const closeDropDownList = () => setIsOpenDropDown(false);
  useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

  const onModuleFilterButtonClick = (courseName: string, courseId: number) => {
    setDropDownValue(courseName);
    onCourseClick(courseName, courseId);
    setValueModule("All modules", -1);
    closeDropDownList();
  };

  return (
    <div className={classNameContainer}>
      <span className={styles.TitleCourse}>Training</span>
      <div
        className={
          isOpenDropDown
            ? classNames(styles.ActiveDropDown, classNameDropDown)
            : classNameDropDown
        }
        onClick={openDropDownList}
        ref={selectButtonRef}
      >
        <span className={styles.DropDownText}>
          {isOpenDropDown ? "Select" : dropDownValue}
        </span>
        <Image
          src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
          className={classNames(
            styles.DropDownIcon,
            isOpenDropDown && styles.isActiveDropDownIcon
          )}
        />
      </div>
      {isOpenDropDown && (
        <div className={classNameDropDownMenu} ref={dropDownRef}>
          <div className={classNameDropDownOverflow}>
            {loader ? (
              sortedListOption?.map((item: any) => {
                const courseName = item.name;
                const courseId: number = item.id;
                const isActiveOptions = item.id === courseFilter.id;

                return (
                  <OptionsComponent
                    className={styles.OptionsText}
                    classNameShowText={courseName?.length > 18 && true}
                    classNameWraperOptions={styles.OptionsWrapper}
                    isActiveOptions={isActiveOptions}
                    reference={optionsRef}
                    key={courseId}
                    optionText={courseName}
                    onClick={() =>
                      onModuleFilterButtonClick(courseName, courseId)
                    }
                  />
                );
              })
            ) : (
              <div className={styles.WrapperForLoader}>
                <Loader
                  type="TailSpin"
                  color="#F85A40"
                  height={25}
                  width={25}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(CourseFilterComponent);

import React from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './AttemptsComponent.module.scss';
import Input from '../../../../../../../../../../../components/Input/Input';
import Slider from '../../../../../../../../../../../components/Slider/Slider';

const AttemptsComponent = (props:any) => {
    const {isActiveAttempts, changeActiveAttempts, classNameSliderTitleText, classNameInputField,
        isActivePassingGrade, attempts, attemptsChange} = props;
    const inputFieldValue = attempts === null ? '' : attempts;
    
    return (
        <div className={styles.MainContainer}>
            <div className={styles.ChangeContainer}>
                <div className={styles.SliderContainer}>
                    <div data-tip='Enable passing grade to set quiz attempts.'>
                        <Slider
                            isChecked={isActiveAttempts}
                            onChange={changeActiveAttempts}
                            isDisable={!isActivePassingGrade}
                        />
                        {!isActivePassingGrade &&
                            <ReactTooltip
                                effect='solid'
                                place='bottom'
                                className={styles.Tooltip}
                                offset={{'right': 80}}
                            />
                        }
                     </div>
                    <span className={classNameSliderTitleText}>Attempts</span>
                </div>
                <span className={styles.DescriptionText}>Enable Attempts</span>
            </div>
            <div className={styles.InputContainer}>
                <Input
                    value={inputFieldValue}
                    onChange={attemptsChange}
                    isDisabledInput={!isActiveAttempts}
                    className={classNameInputField}
                    placeholder='3'
                    type='number'
                />
            </div>
        </div>
    );
};

export default AttemptsComponent;
import React, { isValidElement, useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./RenameLearnerPopup.module.scss";
import PopupFullPage from "../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage";
import Input from "../../../../../../../../../components/Input/Input";
import ButtonColor from "../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import { dispatch } from "../../../../../../../../../state/store";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectLearnersList } from "../../../../../../../../../state/selectors/admin";
import { setLearnersList } from "../../../../../../../../../state/ducks/admin";
import learnersService from "../../../../../../../../../services/server/learnersService/learnersService";
import { makeSelectIsPhoneInvitation } from "../../../../../../../../../state/selectors/customBranding";

const mapStateToProps = createStructuredSelector({
  learnersList: makeSelectLearnersList(),
  isPhoneInvitation: makeSelectIsPhoneInvitation(),
});

const RenameLearnerPopup = (props: any) => {
  const { hideRenamePopup, learnersList, learnerId, isPhoneInvitation } = props;
  const [currentLearnerName, setCurrentLearnerName] = useState("");
  const [currentLearnerLastname, setCurrentLearnerLastname] = useState("");
  const [countName, setCountName] = useState(0);
  const [countLastname, setCountLastname] = useState(0);

  useEffect(() => {
    getName();
  }, [learnerId]);

  const getName = () => {
    return learnersList.filter((item: any) => {
      if (item.id === learnerId) {
        setCurrentLearnerName(item.firstName);
        setCurrentLearnerLastname(item.lastName);
        setCountName(item?.firstName?.length);
        setCountLastname(item?.lastName?.length);
      }
    });
  };

  const onNameChange = (event: any) => {
    setCurrentLearnerName(event.currentTarget.value);
    setCountName(event.currentTarget.value.length);
  };

  const onLastNameChange = (event: any) => {
    setCurrentLearnerLastname(event.currentTarget.value);
    setCountLastname(event.currentTarget.value.length);
  };

  const onRenameButtonClick = async () => {
    const newLearnersList = learnersList.map((learner: any) => {
      if (learner.id === learnerId) {
        learner.firstName = currentLearnerName;
        if (!isPhoneInvitation) {
          learner.lastName = currentLearnerLastname;
        }
        learner.name = `${currentLearnerName} ${
          learner.lastName && currentLearnerLastname
        }`;
      }

      return learner;
    });

    dispatch(setLearnersList(newLearnersList));

    await learnersService.renameLearnerFirstName(learnerId, currentLearnerName);

    if (!isPhoneInvitation) {
      await learnersService.renameLearnerLastName(
        learnerId,
        currentLearnerLastname
      );
    }

    hideRenamePopup();
  };

  return (
    <PopupFullPage
      classNameMainContainer={styles.MainPopupContainer}
      closePopup={hideRenamePopup}
      isSupportOutsidePopupClick
    >
      <div className={styles.MainContainer}>
        <div className={styles.TitleText}>Rename this user</div>
        {!isPhoneInvitation && (
          <>
            <Input
              className={styles.InputField}
              onChange={onNameChange}
              value={currentLearnerName}
              type="text"
              maxLength={120}
              placeholder="First name"
            />
            <div className={styles.CharactersCount}>{countName || 0}/120</div>
            <Input
              className={styles.InputField}
              onChange={onLastNameChange}
              value={currentLearnerLastname}
              type="text"
              maxLength={120}
              placeholder="Last name"
            />
            <div className={styles.CharactersCount}>
              {countLastname || 0}/120
            </div>
          </>
        )}
        {isPhoneInvitation && (
          <>
            <Input
              className={styles.InputField}
              onChange={onNameChange}
              value={currentLearnerName}
              type="text"
              maxLength={120}
              placeholder="Full name"
            />
            <div className={styles.CharactersCount}>{countName || 0}/120</div>
          </>
        )}
        <ButtonColor className={styles.Button} onClick={onRenameButtonClick}>
          Save
        </ButtonColor>
      </div>
    </PopupFullPage>
  );
};

export default connect(mapStateToProps)(RenameLearnerPopup);

import React, {useState} from 'react';

import styles from './InviteMemberContainer.module.scss';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import LearnerInviteContainer
    from '../../../../../../../../../../CourseConstructorPage/components/CourseConstructorComponent/DraftModuleComponent/componentns/InviteLearnersPopup/components/PersonalInviteComponent/components/LearnerInviteContainer/LearnerInviteContainer';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import invitationService from '../../../../../../../../../../../services/server/invitationService/invitationService';
import {createStructuredSelector} from 'reselect';
import {makeSelectUserDetails} from '../../../../../../../../../../../state/selectors/userDetails';
import {connect} from 'react-redux';

const mapStateToProps = () => createStructuredSelector({
    userDetails: makeSelectUserDetails()
});

const InviteMemberContainer = (props:any) => {
    const {onFinishInvitation, userDetails} = props;

    const [invitationList, setInvitationList] = useState<string[]>([]);
    const [isCorrect, setIsCorrect] = useState(true);
    const [isPressedEnter, setIsPressedEnter] = useState(false);

    const sendInvitations = async () => {
        await invitationService.sendEmailInvitationToTeam(userDetails.companyId, invitationList);
    };

    const onInviteButtonClick = () => {
        if (invitationList.length) {
            sendInvitations().then(() => {
                onFinishInvitation(invitationList.length);
            });
        } else {
            setIsCorrect(false);
            setTimeout(() => {
                setIsCorrect(true);
            }, 1500);
        }
    };

    return (
        <div>
            <div className={styles.Title}>Add team member</div>
            <div className={styles.Subtitle}>Invite team members to create training materials and track learning progress</div>
            <div className={styles.InputContainer}>
                <LearnerInviteContainer
                    invitationList={invitationList}
                    setInvitationList={setInvitationList}
                    isCorrect={isCorrect}
                    setIsCorrect={setIsCorrect}
                    setIsPressedEnter={setIsPressedEnter}
                    memberInvitation='true'
                />
                <div data-tip={!isPressedEnter ? "Press Enter to add member!" : ''}>
                    <ButtonColor
                        className={classNames(styles.InviteButtonClick, (!isPressedEnter && styles.DisabledBtn))}
                        onClick={onInviteButtonClick}
                        disabled={!isPressedEnter && !invitationList.length}
                    >
                        Invite
                    </ButtonColor>
                    <ReactTooltip
                        effect='solid'
                        className={styles.Tooltip}
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(InviteMemberContainer);

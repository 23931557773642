export const streamlineList = [
  {
    id: 1,
    name: "Training editor",
  },
  {
    id: 2,
    name: "Quiz and task editor",
  },
  {
    id: 3,
    name: "Training reports and analytics",
  },
  {
    id: 4,
    name: "Templates and training library",
  },
];

export default streamlineList;

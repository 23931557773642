import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Loader from "react-loader-spinner";

import styles from './ContentListComponent.module.scss';
import quizImg from '../../../../../../../../assets/images/icon/quiz-block-button.svg';
import taskImg from '../../../../../../../../assets/images/icon/task-block-button.svg';
import {ReactComponent as DeleteImg} from '../../../../../../../../assets/images/icon/delete-content-icon.svg';
import Image from '../../../../../../../components/Image/Image';
import ContentComponent from "./components/ContentComponent/ContentComponent";
import DeleteQuizComponent from "../DeleteQuizComponent/DeleteQuizComponent";
import DeleteTaskComponent from "../DeleteTaskComponent/DeleteTaskComponent";
import Svg from "../../../../../../../components/SvgComponent/SvgComponent";
import {dispatch} from '../../../../../../../state/store';
import {connect} from 'react-redux';
import {makeSelectDraftModule} from '../../../../../../../state/selectors/courseConstructor';
import {createStructuredSelector} from 'reselect';
import {setDraftModule} from '../../../../../../../state/ducks/courseConstructor';
import moduleService from "../../../../../../../services/server/moduleService/moduleService";
import {makeSelectIsContentLoader} from '../../../../../../../state/selectors/global';
import classNames from 'classnames';

const mapStateToProps = createStructuredSelector({
    draftModule: makeSelectDraftModule(),
    isContentLoader: makeSelectIsContentLoader()
});

const ContentListComponent = (props:any) => {
    const {draftModule, openTaskPopup, openQuizPopup, isContentLoader} = props;

    const [isQuizDeleting, setIsQuizDeleting] = useState(false);
    const [isTaskDeleting, setIsTaskDeleting] = useState(false);
    const [contentsList, setContentsList] = useState(draftModule?.contents);
    const classNameActionButton = classNames(styles.VisibleActionButton);

    useEffect(() => {
        setContentsList([...draftModule?.contents.sort((a:any, b:any) => a.sequenceId > b.sequenceId ? 1 : -1)]);
    },[draftModule]);

    const onDeleteQuizClick = () => {
        setIsQuizDeleting(true);
    };

    const finishDeleteQuiz = () => {
        setIsQuizDeleting(false);
    };

    const onDeleteTaskClick = () => {
        setIsTaskDeleting(true);
    };

    const finishDeleteTask = () => {
        setIsTaskDeleting(false);
    };

    const onDragEnd = async (result:any) => {
        const {destination, source, reason} = result;

        if(!destination || reason === 'CANCEL') {
            return;
        }

        if(destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const contents = [...contentsList];
        const droppedContents = contentsList[source.index];

        contents.splice(source.index, 1);
        contents.splice(destination.index, 0, droppedContents);

        const contentSequences = contents.map((item:any, index:number) => {
            return {
                contentId: item.id,
                sequenceId: contentsList[index].sequenceId
            }
        });

        const mutatedContentListBySequences = contents.map((item:any, index:number) => {
            item.sequenceId = contentSequences[index].sequenceId;
            return item;
        });

        dispatch(setDraftModule({...draftModule, contents: mutatedContentListBySequences}));
        moduleService.updateContentSequence(contentSequences);
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='id5'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {contentsList.map((item:any, index:number) => {
                                const contentId = item?.id;
                                const sequenceId = item?.sequenceId;
                                const isTextContentType = item?.textContent;
                                const textContent = item?.textContent?.text;
                                const isMediaContentType = item?.mediaContent;
                                const isVideContentType = isMediaContentType?.type === 'VIDEO';
                                const isAudioContentType = isMediaContentType?.type === 'AUDIO';
                                const isPhotoContentType = isMediaContentType?.type === 'PHOTO';
                                const mediaContent = item?.mediaContent?.link;
                                const isAttachFileContent = item?.attachFileContent;
                                const attachFileContent = item?.attachFileContent?.attachFile;
                                const attachPdfFile = item?.pdfContent;

                                return (
                                    <Draggable key={contentId.toString()} draggableId={contentId + ''} index={index}>
                                        {(provided:any) => (
                                            <div ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}
                                            >
                                                <ContentComponent
                                                    contentId={contentId}
                                                    sequenceId={sequenceId}
                                                    isTextContentType={isTextContentType}
                                                    textContent={textContent}
                                                    isMediaContentType={isMediaContentType}
                                                    isVideContentType={isVideContentType}
                                                    isAudioContentType={isAudioContentType}
                                                    isPhotoContentType={isPhotoContentType}
                                                    mediaContent={mediaContent}
                                                    isAttachFileContent={isAttachFileContent}
                                                    attachFileContent={attachFileContent}
                                                    isAttachPdfFile={attachPdfFile}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {isContentLoader &&
            <div className={styles.WrapperForLoader}>
                <Loader
                    type="TailSpin"
                    color="#F85A40"
                    height={30}
                    width={30}
                />
            </div>
            }
            {draftModule.quiz && (
                <div className={styles.QuizContainer}>
                    <div className={styles.AssignmentContainer} onClick={openQuizPopup}>
                        <Image src={quizImg} className={styles.AssignmentIcon} />
                        <span className={styles.AssignmentText}>Quiz</span>
                    </div>
                    <Svg
                        className={classNames(classNameActionButton, styles.DeleteIcon)}
                        onClick={onDeleteQuizClick}
                    >
                        <DeleteImg/>
                    </Svg>
                    {isQuizDeleting && (
                        <DeleteQuizComponent
                            draftModule={draftModule}
                            finishDeleteQuiz={finishDeleteQuiz}
                        />
                    )}
                </div>
            )}
            {draftModule.task && (
                <div className={styles.TaskContainer}>
                    <div className={styles.AssignmentContainer} onClick={openTaskPopup}>
                        <Image src={taskImg} className={styles.AssignmentIcon} />
                        <span className={styles.AssignmentText}>Task</span>
                    </div>
                    <Svg
                        className={classNames(classNameActionButton, styles.DeleteIcon)}
                        onClick={onDeleteTaskClick}
                    >
                        <DeleteImg/>
                    </Svg>

                    {isTaskDeleting && (
                        <DeleteTaskComponent
                            draftModule={draftModule}
                            finishDeleteTask={finishDeleteTask}
                        />
                    )}
                </div>
            )}
    </>
    );
};

export default connect(mapStateToProps)(ContentListComponent);
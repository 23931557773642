import React, {useState} from 'react';
import classNames from 'classnames';

import styles from './DelayModuleComponent.module.scss';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import Slider from '../../../../../../../../../components/Slider/Slider';
import Input from '../../../../../../../../../components/Input/Input';
import ButtonColor from '../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import moduleService from "../../../../../../../../../services/server/moduleService/moduleService";



const DelayModuleComponent = (props:any) => {
    const {moduleId, hideDelayPopup} = props;

    const [isActiveDelayModule, setIsActiveDelayModule] = useState(false);
    const [delayValue, setDelayValue] = useState('');
    const classNameInputField = classNames(styles.InputField, !isActiveDelayModule && styles.DisableInputField);

    const sendDelaySettings = async () => {
        await moduleService.addDelaySettingsToModule(parseInt(delayValue), moduleId);
    };

    const onDelayValueChange = (event: React.FormEvent<HTMLInputElement>) => {
        setDelayValue(event.currentTarget.value);
    };
    
    const onSliderChange = () => {
        setIsActiveDelayModule(!isActiveDelayModule);
    };
    
    const onSaveButtonClick = () => {
        sendDelaySettings().catch((error) => {
            console.log(error);
        });
        hideDelayPopup();
    };
    
    return (
        <PopupFullPage
            classNameMainContainer={styles.PopupContainer}
            closePopup={hideDelayPopup}
            isSupportOutsidePopupClick
        >
            <div className={styles.MainPopupContainer}>
                <div className={styles.TextContainer}>
                    <div className={styles.SliderContainer}>
                        <Slider isChecked={isActiveDelayModule} onChange={onSliderChange} />
                        <span>Allow access to next module in:</span>
                    </div>
                    <span className={styles.DescriptionContainer}>Enable Delay Time</span>
                </div>
                <div className={styles.InputContainer}>
                    <Input
                        value={delayValue}
                        placeholder='5'
                        className={classNameInputField}
                        isDisabledInput={!isActiveDelayModule}
                        onChange={onDelayValueChange}
                    />
                    <span className={styles.InputLabelText}>days</span>
                </div>
            </div>
            <ButtonColor className={styles.SaveButton} onClick={onSaveButtonClick}>
                Save
            </ButtonColor>
        </PopupFullPage>
    );
};

export default DelayModuleComponent;
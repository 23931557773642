import React, {useState} from 'react';

import styles from './DatePopUpComponent.module.scss';
import PopupFullPage from '../../../../../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import FilterComponent from '../../../../../../../../../../../../../components/FilterComponent/FilterComponent';
import ButtonColor from '../../../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import classNames from 'classnames';
import performanceReportsService
    from '../../../../../../../../../../../../../services/server/performanceReportsService/performanceReportsService';
import {daysList} from './daysList';
import userInfoService from "../../../../../../../../../../../../../services/server/userInfoService/userInfoService";
import {setUserDetails} from "../../../../../../../../../../../../../state/ducks/userDetails";
import {dispatch} from "../../../../../../../../../../../../../state/store";

type DatePopUpComponentProps = {
    handleClosePopUp: Function
    setControlTrigger: Function
};

const DatePopUpComponent:React.FC<DatePopUpComponentProps> = ({handleClosePopUp, setControlTrigger}) => {
    const [filterValue, setFilterValue] = useState({name: 'Choose the day', id: -1});

    const handleClickOption = (name: string, id: number) => {
        setFilterValue({name: name, id: id})
    };

    const handleClickDay = async () => {
        const dayToUpperCase = filterValue.name.toUpperCase().trim();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (filterValue.id !== -1) {
            await performanceReportsService.setPerformanceOverview(dayToUpperCase, timeZone);
            const userDetails = await userInfoService.getUserInfo();
            if (userDetails) {
                dispatch(setUserDetails(userDetails));
            }
            handleClosePopUp();
            setControlTrigger((prevTrigger:boolean) => !prevTrigger);
        }
    };

    return (
        <PopupFullPage
            closePopup={handleClosePopUp}
            isSupportOutsidePopupClick
            classNameMainContainer={styles.MainContainer}
        >
            <div className={styles.Title}>When do you want to get performance reports?</div>
            <div className={styles.Description}>You get weekly metrics overview, performance gaps and team overview to set instant training actions.</div>
            <div className={styles.WrapperFilter}>
                <FilterComponent
                    filterValue={filterValue}
                    listOptions={daysList}
                    classNameDropDown={classNames(styles.DropDownContainer, filterValue.id === -1 && styles.DropDownContainerGray)}
                    classNameDropDownMenu={styles.DropDownMenuContainer}
                    classNameContainer={styles.FilterContainerItem}
                    onOptionFilterClick={handleClickOption}
                />
            </div>
            <ButtonColor
                className={classNames(styles.SaveButton, filterValue.id === -1 && styles.DisableSaveBtn)}
                onClick={handleClickDay}
            >
                Save
            </ButtonColor>
        </PopupFullPage>
    );
};

export default DatePopUpComponent;

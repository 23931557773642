import React from "react";

import styles from "./ReportListComponent.module.scss";
import ReportsItem from "./components/ReportsItem/ReportsItem";

const ReportsListComponent = (props: any) => {
  const { learnerFilter } = props;

  return (
    <div className={styles.MainContainer}>
      <div className={styles.ReportsHeader}>
        <span>User</span>
        <span>Type</span>
        <span>Training</span>
        <span>Module</span>
        <span>Date</span>
        <span>Status</span>
      </div>
      <div className={styles.ReportListContainer}>
        <ReportsItem learnerFilter={learnerFilter} />
      </div>
    </div>
  );
};

export default ReportsListComponent;

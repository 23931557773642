import {
    ACCESS_TOKEN,
    IS_REGISTRATION_REDIRECT,
    REFRESH_TOKEN
} from "../../consts/storage/localStorageCariables";
import serverService from '../server/serverService';

const setAccessToken = (accessToken:string) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
};

const setRefreshToken = (refreshToken:string) => {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

const setIsRegistrationRedirect = () => {
  localStorage.setItem(IS_REGISTRATION_REDIRECT, 'non_empty');
};

const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN);
};

const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
};

const getIsRegistrationRedirect = () => {
  return localStorage.getItem(IS_REGISTRATION_REDIRECT);
};

const clearAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
};

const clearRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
};

const clearIsRegistrationRedirect = () => {
    localStorage.removeItem(IS_REGISTRATION_REDIRECT);
};

const clearLocaleStorage = () => {
    localStorage.clear();
};

const getCurrentGeoData = async () => {
    try {
        const currentGeoData = await serverService.get('https://ipapi.co/json/');
        localStorage.setItem('currentCountryCode', currentGeoData.data.country_code.toLowerCase());

        return currentGeoData.data;
    } catch (e) {
        console.log(e);
    }
};

export default {
    setAccessToken,
    setRefreshToken,
    setIsRegistrationRedirect,
    getAccessToken,
    getRefreshToken,
    getIsRegistrationRedirect,
    clearAccessToken,
    clearRefreshToken,
    clearIsRegistrationRedirect,
    clearLocaleStorage,
    getCurrentGeoData
};
import React, {useState} from 'react';
import styles from './PartnerLink.module.scss';
import Input from '../../../../../../../../../components/Input/Input';
import Image from '../../../../../../../../../components/Image/Image';
import copyLinkImg from '../../../../../../../../../../assets/images/icon/copy-public-link-icon.svg';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {makeSelectPartnerLink} from '../../../../../../../../../state/selectors/home';

const mapStateToProps = createStructuredSelector({
    partnerLink: makeSelectPartnerLink(),
});

const PartnerLink = (props:any) => {
    const {partnerLink} = props;

    const [isCopied, setIsCopied] = useState(false);
    const inputValue = 'https://app.gradual.io/admin-sign-up?partnerId=' + (partnerLink?.id || '');

    const callback = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);

        navigator.clipboard.writeText(inputValue)
            .catch((error) => {
                console.log('Could not write to clipboard!\n' + error);
                setIsCopied(false);
            });
    };

    return (
        <div className={styles.WrapperSubtitleAndPublicLink}>
            <div className={styles.SubtitleLink}>
                <div className={styles.PartnerText}>Partner link</div>
                <div className={styles.Description}>Use it to indentify customers via Gradual sign up page</div>
            </div>
            <div className={styles.WrapperPublicLink}>
                <Input
                    readOnly
                    value={inputValue}
                    className={styles.PublicInputField}
                />
                <div
                    className={styles.CopyPublicLinkContainer}
                    onClick={callback}
                >
                    <Image  src={copyLinkImg} className={styles.CopyImage} />
                </div>

            </div>
            {isCopied &&
                <div className={styles.WrapperForHint}>Copied!</div>
            }
        </div>
    );
};

export default connect(mapStateToProps)(PartnerLink);
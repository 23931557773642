export const companyAccountList = [
    {
        id: 1,
        name: '5 admin users',
    },
    {
        id: 2,
        name: 'Company Branding',
    },
];

export default companyAccountList;
export const upskillTeamList = [
    {
        id: 1,
        name: 'Integration with sales tools',
    },
    {
        id: 2,
        name: 'Metrics dashboard',
    },
    {
        id: 3,
        name: 'Performance-based training suggestions',
    }
];

export default upskillTeamList;
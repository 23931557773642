import React, {useMemo, useState} from 'react';

import styles from './GapsHeader.module.scss';
import GapsItem from './components/GapsItem/GapsItem';
import {
    LearnerGaps,
    PerformanceGapData
} from "../../../../../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceGapData";
import PaginationComponent from '../../../../../../../../../../../../../../../../../../../../components/PaginationComponent/PaginationComponent';

interface GapsHeaderType {
    performanceGapsData: PerformanceGapData | undefined
}

const GapsHeader = React.memo(({performanceGapsData}: GapsHeaderType) => {
    const perPage = useMemo(() => 5, []); // might consist of some adaptive logic
    const [currentPageData, setCurrentPageData] = useState<LearnerGaps[]>([]);

    return (
        <>
            <div className={styles.Container}>
                <div className={styles.Header}>
                    <span>Learner</span>
                    <span>Email</span>
                    <span>Performance gaps</span>
                    <span className={styles.DetailsHeader}>Details</span>
                    <span className={styles.Action}>Action</span>
                </div>

                {performanceGapsData &&
                    currentPageData
                        .filter(learnerGaps => !!Object.keys(learnerGaps.personalGaps).length)
                        .map(learnerGaps => {
                            return (
                                <GapsItem learnerGaps={learnerGaps}/>
                            );
                        })
                }
            </div>
            <PaginationComponent
                list={performanceGapsData!.allGaps}
                elementsPerPage={perPage}
                handleWorkingListUpdate={setCurrentPageData}
            />
        </>
    );
});

export default GapsHeader;

import React, {useState} from 'react';
import classNames from 'classnames';

import styles from "../../ContentListComponent.module.scss";
import {ReactComponent as DeleteImg} from "../../../../../../../../../../assets/images/icon/delete-content-icon.svg";
import {ReactComponent as DragAndDropImage} from '../../../../../../../../../../assets/images/icon/drag-drop-quiz-questions-icon.svg';
import TextEditorComponent from "./TextEditorComponent/TextEditorComponent";
import VideoComponent from "./VideoComponent/VideoComponent";
import PhotoComponent from "./PhotoComponent/PhotoComponent";
import AudioComponent from "./AudioComponent/AudioComponent";
import AttachFileComponent from "./AttachFileComponent/AttachFileComponent";
import {DeleteContentComponent} from "../../../DeleteContentComponent/DeleteContentComponent";
import Svg from "../../../../../../../../../components/SvgComponent/SvgComponent";
import {connect} from "react-redux";
import {dispatch} from "../../../../../../../../../state/store";
import {setDraftModule} from "../../../../../../../../../state/ducks/courseConstructor";
import {updateCourseModuleList} from "../../../../../draftCourseService";
import {createStructuredSelector} from "reselect";
import {makeSelectDraftModule} from "../../../../../../../../../state/selectors/courseConstructor";
import moduleService from "../../../../../../../../../services/server/moduleService/moduleService";
import PdfContentComponent from '../../../../../../../../../components/PdfContentComponent/PdfContentComponent';


const mapStateToProps = createStructuredSelector({
    draftModule: makeSelectDraftModule(),
});

const ContentComponent = (props:any) => {
    const {draftModule, contentId, isTextContentType, textContent, isVideContentType,
        isPhotoContentType, isAudioContentType, mediaContent, isAttachFileContent, attachFileContent,
        isAttachPdfFile, sequenceId
    } = props;

    const classNameActionButton = classNames(styles.VisibleActionButton);
    const [isContentDeleting, setIsContentDeleting] = useState(false);

    const updateTextContentInUi = (value:string) => {
        const updatedContents = draftModule.contents.map((content:any) => {
            if (content.id === contentId) {
                content.textContent.text = value;
            }
            return content;
        });
        const updatedModule = {...draftModule, contents:updatedContents};
        dispatch(setDraftModule(updatedModule));
        updateCourseModuleList(updatedModule);
    };

    const updateTextContent = async (value:string) => {
        updateTextContentInUi(encodeURI(JSON.stringify(value)));

        await moduleService.updateTextContent(contentId, encodeURI(JSON.stringify(value)));
    };

    const onDeleteContentClick = () => {
        setIsContentDeleting(true);
    };

    const finishDeleteContent = () => {
        setIsContentDeleting(false);
    };

    return (
        <div className={styles.ContentContainer}>
            <Svg className={classNames(classNameActionButton, styles.DragAndDropImg)}>
                <DragAndDropImage />
            </Svg>
            {isTextContentType && (
                <TextEditorComponent
                    value={textContent}
                    contentId={contentId}
                    updateTextContent={updateTextContent}
                />
            )}
            {isVideContentType && (
                <VideoComponent src={mediaContent} />
                )}
            {isPhotoContentType && (
                <PhotoComponent src={mediaContent} />
                )}
            {isAudioContentType && (
                <AudioComponent src={mediaContent} />
                )}
            {isAttachFileContent && (
                <AttachFileComponent attachFileContent={attachFileContent} />
                )}
            {isAttachPdfFile && (
                <PdfContentComponent
                    sequenceId={sequenceId}
                    attachPdfFile={isAttachPdfFile}
                />
            )}
            <Svg
                className={classNames(classNameActionButton, styles.DeleteIcon)}
                onClick={onDeleteContentClick}
                >
            <DeleteImg />
            </Svg>
            {isContentDeleting && (
                <DeleteContentComponent
                    draftModule={draftModule}
                    contentId={contentId}
                    finishDeleteContent={finishDeleteContent}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(ContentComponent);
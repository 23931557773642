import React, {useEffect, useState} from 'react';

import styles from '../../MultipleChoiceQuestionComponent.module.scss';
import s from '../VariantsListComponent/VariantsListComponent.module.scss';
import style from '../../../EssayQuestionComponent/EssayQuestionComponent.module.scss';
import Image from '../../../../../../../../../../../../../components/Image/Image';
import deleteImg from '../../../../../../../../../../../../../../assets/images/icon/cross-icon.svg';
import InputFile from '../../../../../../../../../../../../../components/Input/InputFile/InputFile';
import Svg from '../../../../../../../../../../../../../components/SvgComponent/SvgComponent';
import {ReactComponent as ImageAdd} from '../../../../../../../../../../../../../../assets/images/icon/image-add-icon.svg';
import rescaleImage from '../../../../../../../../../../../../../services/utils/rescaleImage/rescaleImage';
import {dispatch} from '../../../../../../../../../../../../../state/store';
import {setIsAppLoader} from '../../../../../../../../../../../../../state/ducks/global';

const AttachImageComponent = (props:any) => {
    const {originalImage, setIsVisibleImgFull, isVariantImg, isEssayImg,
        id, addImage, deleteImage} = props;
    const [addedImage, setAddedImage] = useState('');

    useEffect(() => {
        if (originalImage) {
            rescaleImage.rescaleImage(originalImage, 'img', (img: string) => {
                setAddedImage(img);
            });
        } else {
            setAddedImage('');
        }
    }, [originalImage]);

    const onAddImage = async (value:any) => {
        const file = value.target.files[0];

        dispatch(setIsAppLoader(true));
        await addImage(file, id);
        dispatch(setIsAppLoader(false));
    };

    const onOpenImg = () => {
        setIsVisibleImgFull(true);
    };

    const onDeleteImg = () => {
        deleteImage();
    };

    return (
        <>
            {addedImage ?
                <div>
                    <Image
                        src={deleteImg} className={isVariantImg ? s.DeleteImg : isEssayImg ? style.DeleteImg : styles.DeleteImg}
                        onClick={onDeleteImg}/>
                    <Image
                        src={addedImage}  className={isVariantImg ? s.newImage : isEssayImg ? style.newImage : styles.newImage}
                        onClick={onOpenImg}/>
                </div>
                :
                <InputFile
                    accept='.png,.jpeg,.jpg'
                    onChange={onAddImage}
                >
                    <Svg className={isVariantImg ? s.WrapperImgAdd : isEssayImg ? style.WrapperImgAdd : styles.WrapperImgAdd}>
                        <ImageAdd />
                    </Svg>
                </InputFile>
            }
        </>
    );
};

export default AttachImageComponent;

export const SET_DRAFT_COURSE = 'app/courseConstructor/SET_DRAFT_COURSE';
export const SET_DRAFT_MODULE = 'app/courseConstructor/SET_DRAFT_MODULE';
export const SET_DRAFT_MODULE_QUIZ = 'app/courseConstructor/SET_DRAFT_MODULE_QUIZ';
export const SET_DRAFT_QUIZ_QUESTION = 'app/courseConstructor/SET_DRAFT_QUIZ_QUESTION';
export const SET_FILTERED_DRAFT_MODULE_QUIZ = 'app/courseConstructor/SET_FILTERED_DRAFT_MODULE_QUIZ';

const initialState = {
    draftCourse: {},
    draftModule: {},
    filteredDraftModuleQuiz: {},
    draftModuleQuiz: {},
    draftQuizQuestion: {},
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_DRAFT_COURSE: {
            return {...state, draftCourse: action.payload};
        }
        case SET_DRAFT_MODULE: {
            return {...state, draftModule: action.payload};
        }
        case SET_FILTERED_DRAFT_MODULE_QUIZ: {
            return {...state, filteredDraftModuleQuiz: action.payload};
        }
        case SET_DRAFT_MODULE_QUIZ: {
            return {...state, draftModuleQuiz: action.payload};
        }
        case SET_DRAFT_QUIZ_QUESTION: {
            return {...state, draftQuizQuestion: action.payload};
        }
        default: return state;
    }
}

export const setDraftCourse = (value:object|undefined) => ({type: SET_DRAFT_COURSE, payload: value});

export const setDraftModule = (value:object|undefined) => ({type: SET_DRAFT_MODULE, payload: value});

export const setFilteredDraftModuleQuiz = (value:object|undefined) => ({type: SET_FILTERED_DRAFT_MODULE_QUIZ, payload: value});

export const setDraftModuleQuiz = (value:object|undefined) => ({type: SET_DRAFT_MODULE_QUIZ, payload: value});

export const setDraftQuizQuestion = (value:object|undefined) => ({type: SET_DRAFT_QUIZ_QUESTION, payload: value});

import React from "react";

import styles from "./DeleteLearnerPopup.module.scss";
import { DeletePopup } from "../../../../../../../../../components/DeletePopup/DeletePopup";
import { dispatch } from "../../../../../../../../../state/store";
import { connect } from "react-redux";
import {
  setFilterNameLearnerList,
  setLearnersList,
  setSortedLearnerList,
  setSortedLearnerName,
} from "../../../../../../../../../state/ducks/admin";
import { createStructuredSelector } from "reselect";
import { makeSelectLearnersList } from "../../../../../../../../../state/selectors/admin";
import learnersService from "../../../../../../../../../services/server/learnersService/learnersService";

const mapStateToProps = createStructuredSelector({
  learnersList: makeSelectLearnersList(),
});

const DeleteLearnerPopup = (props: any) => {
  const { hideDeletePopup, learnerName, learnerId, setFilterName } = props;

  const deleteCourseButtonClick = async () => {
    await learnersService.deleteLearners(learnerId);

    const learnersNewList: any = await learnersService.getFilterLearners();
    dispatch(setLearnersList(learnersNewList.learners.reverse()));
    dispatch(setSortedLearnerList(learnersNewList.learners));

    const listOfNamesLearners: any =
      await learnersService.getFiltersLearnerName();
    dispatch(setFilterNameLearnerList(listOfNamesLearners.learnerFilters));
    dispatch(setSortedLearnerName(listOfNamesLearners.learnerFilters));

    setFilterName({ name: "", id: -1 });
    hideDeletePopup();
  };

  return (
    <DeletePopup
      onHide={hideDeletePopup}
      onDelete={deleteCourseButtonClick}
      textData={{
        title: "Delete this user?",
        header: {
          general: "You are about to delete",
          specific: learnerName,
          note: "All analytics and reports related to this user will be lost, and we will not be able to restore it.",
        },
      }}
    />
  );
};

export default connect(mapStateToProps)(DeleteLearnerPopup);

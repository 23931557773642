import config from '../../../config/config';
import stringUtils from '../../utils/stringUtilsService';
import serverService from '../serverService';
import localStorageService from "../../storage/localStorageService";
import {ROOT} from "../../../consts/routes/routePaths";
import gtmService from "../../dataTrigger/gtmService";
const {data} = config;
const {defaultApiLink, auth} = data;
const {authSignUpPath, authContributorSignUpPath, authLoginPath, setRecoveryEmailPath, changePasswordPath,
    setInfoForRegistrationPath, setDataForQuestionnairePath} = auth;

const requestConfig = (data:object) => {
    return {
        data: data,
    };
};

const loginUser = async (email:string, password:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, authLoginPath);
    const modifiedEmail = email.toLowerCase();
    const modifiedPassword = password.trim();

    try {
        const authData = await serverService.post(formattedUrl, requestConfig({email: modifiedEmail, password: modifiedPassword}));

        const {accessToken, refreshToken} = authData.data;

        localStorageService.setAccessToken(accessToken);
        localStorageService.setRefreshToken(refreshToken);

        gtmService.addSignIn(email);
    } catch (error) {
        throw error?.response;
    }
};

const signUpUser = async (email:string, password:string, stringUrl:string, partnerId:any, zoneId: string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, authSignUpPath);
    const modifiedEmail = email.toLowerCase();
    const partnerIdToNumber = Number(partnerId);

    try {
        await serverService.post(formattedUrl, requestConfig({email: modifiedEmail, password: password,
            urlParams: stringUrl, partnerId: partnerIdToNumber, zoneId: zoneId}));

    } catch (error) {

        throw error?.response;
    }
};

const signUpContributor = async (email:string, password:string, firstName:string, lastName:string, companyId:number, zoneId:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, authContributorSignUpPath);
    const modifiedEmail = email.toLowerCase();

    try {
        return await serverService.post(formattedUrl, requestConfig({email: modifiedEmail, password: password,
            companyId: companyId, firstName: firstName, lastName:lastName, zoneId:zoneId}))

    } catch (error) {

        throw error?.response;
    }
};

const setRecoveryEmail = async (email:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setRecoveryEmailPath);
    const modifiedEmail = email.toLowerCase();

    try {
        return await serverService.post(formattedUrl, requestConfig({email: modifiedEmail}))
    } catch (error) {
        throw error?.response;
    }
};

const changePassword = async (identificationToken:string, password:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, changePasswordPath);

    try {
        return await serverService.post(formattedUrl, requestConfig({identificationToken: identificationToken,
            newPassword: password}));
    } catch (error) {
        throw error?.response;
    }
};

const setDataForRegistration = async (companyName:string, phone:string|null, teamSize:string, role:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setInfoForRegistrationPath);

    try {
        return await serverService.post(formattedUrl, requestConfig({companyName: companyName, phone: phone, teamSize: teamSize, role: role}));
    } catch (error) {
        throw error?.response;
    }
};

const setDataForQuestionnaire = async (answers: Array<string>) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setDataForQuestionnairePath);

    try {
        return await serverService.post(formattedUrl, requestConfig({answers: answers}));
    } catch (error) {
        throw error?.response;
    }
};

export default {
    loginUser,
    signUpUser,
    signUpContributor,
    setRecoveryEmail,
    changePassword,
    setDataForRegistration,
    setDataForQuestionnaire
}

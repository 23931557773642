import React from 'react';

import styles from './ResetPopup.module.scss';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import ButtonColor from '../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import learnersService from '../../../../../../../../../services/server/learnersService/learnersService';

const ResetPopup = (props:any) => {
    const {hideResetPopup, courseName, courseId, learnerName, learnerId} = props;

    const onClickReset = async () => {
        await learnersService.resetProgress(learnerId, courseId);
        hideResetPopup();
    };

    return (
        <PopupFullPage
            classNameMainContainer={styles.MainPopupContainer}
            classNameBackground={styles.Background}
            closePopup={hideResetPopup}
            isSupportOutsidePopupClick
        >
            <div className={styles.Title}>Reset this course progress?</div>
            <div className={styles.Subtitle}>
                <div>You are about to reset</div>
                <div className={styles.EmphasizedName}> {courseName}</div>
            </div>
            <div className={styles.WrapperForBtns}>
                <ButtonColor className={styles.CancelBtn} onClick={hideResetPopup}>Cancel</ButtonColor>
                <ButtonColor className={styles.ResetBtn} onClick={onClickReset}>Yes, reset it</ButtonColor>
            </div>
        </PopupFullPage>
    );
};

export default ResetPopup;
import React from 'react';

import styles from './CurrentPlanFree.module.scss';
import {createStructuredSelector} from 'reselect';
import {makeSelectSeatsInfo} from '../../../../../../../../../state/selectors/subscription';
import {connect} from 'react-redux';
import YourPlanFreeDetailsComponent from './YourPlanFreeDetailsComponent/YourPlanFreeDetailsComponent';
import UpgradeToPro from './UpgradeToPro/UpgradeToPro';
import GetExtrasProComponent from './GetExtrasProComponent/GetExtrasProComponent';

const mapStateToProps = createStructuredSelector({
    seatsInfo: makeSelectSeatsInfo()
});

const CurrentPlanFree = (props:any) => {
    const {seatsInfo} = props;

    return (
        <div className={styles.WrapperCurrentPlanFree}>
            <div className={styles.ContainerForFreeAndPro}>
                <YourPlanFreeDetailsComponent
                    freeSeatsNumber={seatsInfo?.freeSeatsNumber}
                />
                <UpgradeToPro />
            </div>
            <GetExtrasProComponent />
        </div>
    );
};

export default connect(mapStateToProps)(CurrentPlanFree);
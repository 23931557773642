import React, { useEffect, useRef, useState } from "react";

import styles from "./CourseCellComponent.module.scss";
import dotsImg from "../../../../../../../../../../../../assets/images/icon/three-dots-black.svg";
import Image from "../../../../../../../../../../../components/Image/Image";
import DeleteCoursePopup from "../DeleteCoursePopup/DeleteCoursePopup";
import RenameCoursePopup from "../RenameCoursePopup/RenameCoursePopup";
import ModalColor from "./components/ModalColorChoose/ModalColor";
import { dispatch } from "../../../../../../../../../../../state/store";
import { setCurrentCourseList } from "../../../../../../../../../../../state/ducks/admin";
import courseService from "../../../../../../../../../../../services/server/courseService/courseService";

const CourseCellComponent = (props: any) => {
  const {
    courseName,
    backgroundColor,
    onCourseClick,
    courseId,
    currentCourseList,
    learnersSize,
  } = props;

  const [isVisibleDotsModal, setIsVisibleDotsModal] = useState(false);
  const [isVisibleRenamePopup, setIsVisibleRenamePopup] = useState(false);
  const [isVisibleDeletePopup, setIsVisibleDeletePopup] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const useOutsideClickParse = (popupRef: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setIsVisibleDotsModal(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [popupRef]);
  };

  useOutsideClickParse(modalRef);

  const backgroundCourseChange = async (background: any) => {
    const newCourseList = currentCourseList.filter((course: any) => {
      if (course.id === courseId) {
        course.background = background;
      }
      return course;
    });

    await courseService.setBackgroundCourse(courseId, background);

    dispatch(setCurrentCourseList(newCourseList));
  };

  const hideDotsModal = () => {
    setIsVisibleDotsModal(false);
  };

  const openRenamePopup = () => {
    setIsVisibleRenamePopup(true);
    hideDotsModal();
  };

  const openDeletePopup = () => {
    setIsVisibleDeletePopup(true);
    hideDotsModal();
  };

  const hideRenamePopup = () => {
    setIsVisibleRenamePopup(false);
  };

  const hideDeletePopup = () => {
    setIsVisibleDeletePopup(false);
  };

  return (
    <>
      <div className={styles.CourseCellItem}>
        <div
          className={styles.CourseCellContainer}
          onClick={() => onCourseClick(courseId)}
          onChange={backgroundCourseChange}
          style={{ background: backgroundColor }}
        >
          <span className={styles.CourseName}>{courseName}</span>
        </div>
        <div className={styles.SignatureContainer}>
          <div className={styles.LearnersWrapper}>
            <span className={styles.Learner}>Users</span>
            <span className={styles.LearnersNumbers}>{learnersSize}</span>
          </div>
          <div className={styles.DotsContainer}>
            <Image
              src={dotsImg}
              className={styles.DotsImage}
              onClick={() => setIsVisibleDotsModal(true)}
            />
            {isVisibleDotsModal && (
              <ModalColor
                openRenamePopup={openRenamePopup}
                modalRef={modalRef}
                openDeletePopup={openDeletePopup}
                backgroundCourseChange={backgroundCourseChange}
              />
            )}
          </div>
        </div>
      </div>
      {isVisibleRenamePopup && (
        <RenameCoursePopup
          hideRenamePopup={hideRenamePopup}
          courseName={courseName}
          courseId={courseId}
        />
      )}
      {isVisibleDeletePopup && (
        <DeleteCoursePopup
          currentCourseList={currentCourseList}
          courseName={courseName}
          hideDeletePopup={hideDeletePopup}
          courseId={courseId}
        />
      )}
    </>
  );
};

export default CourseCellComponent;

import React from 'react';

import styles from './LearnerInviteList.module.scss';
import LearnerInviteCell from "./components/LearnerInviteCell/LearnerInviteCell";

const LearnerInviteList = (props:any) => {
    const {invitationList, onInvitationDelete, onInvitationUpdate} = props;

    return (
        <div className={styles.LearnerInviteList}>
            {invitationList.map((item:string, idx:number) => {
                return (
                    <LearnerInviteCell
                        key={idx.toString()}
                        onInvitationDelete={onInvitationDelete}
                        onInvitationUpdate={onInvitationUpdate}
                        invitation={item}
                    />
                );
            })}
        </div>
    );
};

export default LearnerInviteList;
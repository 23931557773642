import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";

import styles from "./ReportsComponent.module.scss";
import reportsService from "../../../../../../../services/server/reportsService/reportsService";
import TypeFilterComponent from "./components/TypeFilterComponent/TypeFilterComponent";
import StatusFilterComponent from "./components/StatusFilterComponent/StatusFilterComponent";
import LearnerSearch from "./components/LearnerSearch/LearnerSearch";
import CourseFilterComponent from "./components/CourseFilterComponent/CourseFilterComponent";
import ModuleFilterComponent from "./components/ModuleFilterComponent/ModuleFilterComponent";
import {
  makeSelectIsEmptyReportsData,
  makeSelectReportsList,
  makeSelectTotalReportPages,
} from "../../../../../../../state/selectors/reports";
import ReportsListComponent from "./components/ReportsListComponent/ReportsListComponent";
import { connect } from "react-redux";
import { dispatch } from "../../../../../../../state/store";
import { createStructuredSelector } from "reselect";
import {
  setReportsList,
  setTotalReportPages,
} from "../../../../../../../state/ducks/reports";
import reportEmoji from "../../../../../../../../assets/images/emoji/bell_1f514.png";
import ButtonColor from "../../../../../../../components/Button/components/ButtonBorder/ButtonBorder";

const mapStateToProps = createStructuredSelector({
  isEmptyReportsData: makeSelectIsEmptyReportsData(),
  totalReportPages: makeSelectTotalReportPages(),
  reportsList: makeSelectReportsList(),
});

const ReportsComponent = (props: any) => {
  const { reportsList, totalReportPages, isEmptyReportsData } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 6;
  const totalReports = totalReportPages * perPage;
  const offSet = currentPage * perPage;

  const [learnerFilter, setLearnerFilter] = useState({
    name: "",
    id: -1,
  });
  const [typeFilter, setTypeFilter] = useState({
    name: "All updated",
    id: -1,
  });
  const [courseFilter, setCourseFilter] = useState({
    name: "All training",
    id: -1,
  });
  const [moduleFilter, setModuleFilter] = useState({
    name: "All modules",
    id: -1,
  });
  const [statusFilter, setStatusFilter] = useState({
    name: "All reports",
    id: -1,
  });

  function handlePageClick(e: any) {
    setCurrentPage(e.selected);
  }

  const onLearnerClick = (learnerName: string, learnerId: any) => {
    setLearnerFilter({ name: learnerName, id: learnerId });
  };

  const onTypeFilterClick = (filterTypeName: string, filterTypeId: any) => {
    setTypeFilter({ name: filterTypeName, id: filterTypeId.toUpperCase() });
  };

  const onCourseFilterClick = (courseName: string, courseId: number) => {
    setCourseFilter({ name: courseName, id: courseId });
  };

  const onModuleFilterClick = (moduleName: string, moduleId: any) => {
    setModuleFilter({ name: moduleName, id: moduleId });
  };

  const onStatusClick = (statusName: string, statusId: any) => {
    setStatusFilter({ name: statusName, id: statusId });
  };

  useEffect(() => {
    filterReports().catch(console.error);
  }, [
    currentPage,
    typeFilter,
    statusFilter,
    learnerFilter,
    courseFilter,
    moduleFilter,
  ]);

  useEffect(() => {
    setCurrentPage(0);
  }, [typeFilter, statusFilter, learnerFilter, courseFilter, moduleFilter]);

  const filterReports = async () => {
    const filteredReports = await reportsService.getFilteredReports(
      perPage,
      currentPage,
      learnerFilter.id,
      typeFilter.id,
      courseFilter.id,
      moduleFilter.id,
      statusFilter.id
    );

    dispatch(setReportsList(filteredReports.notifications));
    dispatch(setTotalReportPages(filteredReports.totalPages));
  };

  const setValueModule = (name: string, id: number) => {
    setModuleFilter({ name: name, id: id });
  };

  const onResetFiltersClick = () => {
    setLearnerFilter({ name: "", id: -1 });
    setTypeFilter({ name: "All updated", id: -1 });
    setCourseFilter({ name: "All training", id: -1 });
    setModuleFilter({ name: "All modules", id: -1 });
    setStatusFilter({ name: "All reports", id: -1 });
  };

  return (
    <div className={styles.ReportsContainer}>
      <>
        {!isEmptyReportsData && (
          <>
            <div className={styles.WrapperFilters}>
              <LearnerSearch
                classNameContainer={styles.MainFilterContainer}
                classNameDropDown={styles.DropDownContainer}
                classNameDropDownMenu={classNames(
                  styles.DropDownMenuContainerLearner,
                  styles.DropDownMenuContainer
                )}
                classNameDropDownOverflow={styles.DropDownOverflow}
                learnerFilter={learnerFilter}
                onLearnerClick={onLearnerClick}
              />
              <TypeFilterComponent
                classNameContainer={styles.MainFilterContainer}
                classNameDropDown={styles.DropDownContainer}
                classNameArrow={styles.DragDropArrow}
                classNameDropDownMenu={classNames(
                  styles.DropDownMenuContainerModuleType,
                  styles.DropDownMenuContainer
                )}
                classNameDropDownOverflow={styles.DropDownOverflow}
                onTypeFilterClick={onTypeFilterClick}
                typeFilter={typeFilter}
              />
              <CourseFilterComponent
                classNameContainer={styles.MainFilterContainer}
                classNameDropDown={classNames(
                  styles.DropDownContainer,
                  styles.DropDownContainerCourse
                )}
                classNameArrow={styles.DragDropArrow}
                classNameDropDownMenu={styles.DropDownMenuContainer}
                classNameDropDownOverflow={styles.DropDownOverflow}
                courseFilter={courseFilter}
                onCourseClick={onCourseFilterClick}
                setValueModule={setValueModule}
              />
              <ModuleFilterComponent
                classNameContainer={styles.MainFilterContainer}
                classNameDropDown={styles.DropDownContainer}
                classNameArrow={styles.DragDropArrow}
                classNameDropDownMenu={classNames(
                  styles.DropDownMenuContainerModuleType,
                  styles.DropDownMenuContainer
                )}
                classNameDropDownOverflow={styles.DropDownOverflow}
                moduleFilter={moduleFilter}
                onModuleClick={onModuleFilterClick}
                courseFilter={courseFilter}
              />
              <StatusFilterComponent
                classNameContainer={styles.MainFilterContainer}
                classNameDropDown={styles.DropDownContainer}
                classNameArrow={styles.DragDropArrow}
                classNameDropDownMenu={classNames(
                  styles.DropDownMenuContainerStatus,
                  styles.DropDownContainer
                )}
                onStatusClick={onStatusClick}
                statusFilter={statusFilter}
              />
              <ButtonColor
                className={styles.ResetFilters}
                onClick={onResetFiltersClick}
              >
                Reset filters
              </ButtonColor>
            </div>
            {reportsList?.length ? (
              <>
                <ReportsListComponent learnerFilter={learnerFilter} />
                <div className={styles.PaginationContainer}>
                  <div className={styles.TextNumberLearner}>
                    {totalReports > 7
                      ? `Showing ${offSet + 1} - ${
                          offSet + perPage
                        } of ${totalReports}`
                      : `Showing 1 - ${reportsList.length} of ${reportsList.length}`}
                  </div>
                  <ReactPaginate
                    pageCount={totalReportPages}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    breakClassName={styles.ListPagination}
                    pageClassName={styles.ListPagination}
                    activeLinkClassName={styles.ActiveBtnPaginate}
                    containerClassName={styles.ContainerPaginate}
                    previousLabel={"<"}
                    nextLabel={">"}
                    previousClassName={styles.Previous}
                    nextClassName={styles.Next}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                  />
                </div>
              </>
            ) : (
              <div className={styles.ResultNoFound}>This page seems empty!</div>
            )}
          </>
        )}
        {isEmptyReportsData && (
          <div className={styles.ReportsNone}>
            <div className={styles.WrapperTittleNone}>
              <div className={styles.TitleNone}>Hey!</div>
              <img
                src={reportEmoji}
                alt="report_emoji"
                style={{ height: "25px" }}
              />
            </div>
            <div className={styles.Subtitle}>
              The reports will appear to notify you about the learning progress.
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default connect(mapStateToProps)(ReportsComponent);

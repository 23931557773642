import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsAppLoader} from '../../../state/selectors/global';
import Loader from '../Loader';

const mapStateToProps = createStructuredSelector({
    isAppLoader: makeSelectIsAppLoader(),
});

const AppLoader = (props:any) => {
    const {isAppLoader} = props;

    return isAppLoader && <Loader />;
}

export default connect(mapStateToProps)(AppLoader);

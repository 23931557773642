import ImageZoom from "react-medium-image-zoom";

import styles from "./PhotoComponent.module.scss";

const PhotoComponent = ({ src }: { src: string }) => {
  return (
    <div className={styles.ImageContainer}>
      <ImageZoom
        image={{
          src: src,
          style: {
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: "10px",
          },
        }}
      />
    </div>
  );
};

export default PhotoComponent;

import style from './EmptyPerformanceDataComponent.module.scss';

interface EmptyPerformanceData {
    waringMessage: string
}

export const EmptyPerformanceDataComponent = ({waringMessage}: EmptyPerformanceData) => {

    return (
        <div className={style.Warning}>
            {waringMessage}
        </div>
    );
};
import stringUtilsService from '../../utils/stringUtilsService';

import config from '../../../config/config';
import adminService from '../adminService/adminService';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {resendVerificationPath, emailVerifyPath} = externalLinks;

const resendVerification = async (email:string) => {
    const url:string = stringUtilsService.formatString(defaultApiLink, resendVerificationPath);

    return await adminService.resendVerification(url, email);
};

const verifyEmail = async (token:string) => {
    const url:string = stringUtilsService.formatString(defaultApiLink, emailVerifyPath);

    return await adminService.verifyEmail(url, token);
};

export default {
    resendVerification,
    verifyEmail
}
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Loader from "react-loader-spinner";

import styles from "./LearnerFilterComponent.module.scss";
import closeImg from "../../../../../../../../../../../../assets/images/icon/close-icon.svg";
import searchImg from "../../../../../../../../../../../../assets/images/icon/search-magnifier.svg";
import Image from "../../../../../../../../../../../components/Image/Image";
import Input from "../../../../../../../../../../../components/Input/Input";
import OptionsComponent from "../../../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent";
import { connect } from "react-redux";
import { dispatch } from "../../../../../../../../../../../state/store";
import { createStructuredSelector } from "reselect";
import { setFilterLearnersListAnalytics } from "../../../../../../../../../../../state/ducks/analytics";
import {
  makeSelectLearnersList,
  makeSelectSortedLearnersList,
} from "../../../../../../../../../../../state/selectors/analytics";
import analyticsService from "../../../../../../../../../../../services/server/analyticsService/analyticsService";
import { useOutsideListener } from "../../../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
  filterLearnersList: makeSelectLearnersList(),
  sortedLearnersList: makeSelectSortedLearnersList(),
});

const LearnerFilterComponent = (props: any) => {
  const {
    filterLearnersList,
    filterModule,
    filterLearners,
    sortedLearnersList,
    classNameOptionsWrapper,
    classNameWrapperComponent,
    classNameDropDownMenu,
    classNameDropDownOverflow,
    classNameTitle,
    onClickLearners,
    classNameOpenDropDown,
    filterCourse,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [searchString, setSearchString] = useState(filterLearners.learner);
  const [loader, setLoader] = useState(false);
  const filterNameRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const searchedName = filterLearners.learner;

    setSearchString(searchedName);
    filterSearchInput(searchedName);
  }, [filterLearners]);

  useEffect(() => {
    setSearchString("");
  }, [filterCourse, filterModule]);

  const closeDropDownList = () => setIsOpenDropDown(false);
  useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

  const openDropDownList = async () => {
    setIsOpenDropDown(!isOpenDropDown);
    await analyticsService.getFilteredListLearners();
    setLoader(true);
  };

  const filterSearchInput = (nameToFilter: string) => {
    const filterListName = sortedLearnersList.filter((item: any) => {
      return item.name
        .toLowerCase()
        .includes(nameToFilter.toLowerCase().trim());
    });

    dispatch(setFilterLearnersListAnalytics(filterListName));
  };

  const onChangeInput = (event: any) => {
    const { value } = event.currentTarget;
    filterSearchInput(value);

    setSearchString(value);
  };

  const onClearInput = () => {
    setSearchString("");
    dispatch(setFilterLearnersListAnalytics(sortedLearnersList));
  };

  const onClickNameList = (learnerName: any, learnerId: number) => {
    onClickLearners(learnerName, learnerId);
    setSearchString(learnerName);
    closeDropDownList();
  };

  return (
    <div className={classNameWrapperComponent}>
      <span className={classNameTitle}>Users</span>
      <div
        className={styles.InputContainer}
        onClick={openDropDownList}
        ref={selectButtonRef}
      >
        <Input
          type="text"
          className={classNames(
            styles.Input,
            isOpenDropDown ? classNameOpenDropDown : ""
          )}
          onChange={onChangeInput}
          value={searchString}
          placeholder={isOpenDropDown ? "Select" : "All users"}
        />
        <Image
          src={searchString.length ? closeImg : searchImg}
          className={classNames(
            styles.Image,
            searchString.length && styles.ImageClose
          )}
          onClick={onClearInput}
        />
      </div>
      {isOpenDropDown && (
        <div className={classNameDropDownMenu} ref={dropDownRef}>
          <div className={classNameDropDownOverflow}>
            {loader ? (
              filterLearnersList.length ? (
                filterLearnersList?.map((item: any) => {
                  const learnerName = item.name;
                  const learnerId = item.id;
                  const isActiveLearner = item.id === filterLearners.id;

                  return (
                    <OptionsComponent
                      classNameOptionsWrapper={classNameOptionsWrapper}
                      isActiveOptions={isActiveLearner}
                      reference={filterNameRef}
                      optionText={learnerName}
                      key={learnerId}
                      onClick={() => onClickNameList(learnerName, learnerId)}
                      classNameShowText={learnerName?.length > 17 && true}
                    />
                  );
                })
              ) : (
                <div className={styles.InputNotFound}>Not found user</div>
              )
            ) : (
              <div className={styles.WrapperForLoader}>
                <Loader
                  type="TailSpin"
                  color="#F85A40"
                  height={25}
                  width={25}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(LearnerFilterComponent);

import classNames from 'classnames';
import React from 'react';

import styles from './Slider.module.scss';

const Slider = (props:any) => {
    const {isDisable, isChecked, onChange} = props;
    const classNameSlider = classNames(styles.Slider, styles.Round, isDisable && styles.DisabledSlider);
    
    return (
        <label className={styles.SwitchContainer}>
            <input type="checkbox" checked={isChecked} onChange={onChange} disabled={isDisable} />
            <span className={classNameSlider} />
        </label>
    );
};

export default Slider;
import React from 'react';

import styles from './ReportsHeader.module.scss';
import ReportsItem from './components/ReportsItem';
import PerformanceReportsService
    from "../../../../../../../../../../../services/server/performanceReportsService/performanceReportsService";
import {PerformanceReport} from "../../../../../../../../../../../types/performanceReportsType/performanceReports";

type ReportsHeaderProps = {
    performanceReports: PerformanceReport[]
    setReportStatus: Function
}

const ReportsHeader = ({performanceReports, setReportStatus}: ReportsHeaderProps) => {
    return (
        <div>
            <div className={styles.Header}>
                <span>Report</span>
                <span>Date</span>
                <span>Data Source</span>
                <span>Status</span>
            </div>
            <ReportsItem
                performanceReports={performanceReports}
                setReportStatus={setReportStatus}
            />
        </div>
    );
};

export default ReportsHeader;

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import styles from './TelegramIntegrationComponent.module.scss';
import telegramImg from '../../../../../../../../../../assets/images/icon/telegram-icon.svg';
import completeImg from '../../../../../../../../../../assets/images/icon/complete-integrations-icon.svg';
import topDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-top-black.svg';
import bottomDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-bottom-black.svg';
import informationImg from '../../../../../../../../../../assets/images/icon/information-icon.svg';
import Image from '../../../../../../../../../components/Image/Image';
import Input from '../../../../../../../../../components/Input/Input';
import ButtonColor from '../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectUserIdTelegram} from '../../../../../../../../../state/selectors/integrations';
import integrationsService from '../../../../../../../../../services/server/integrationsService/integrationsService';
import {dispatch} from "../../../../../../../../../state/store";
import {setTelegramId} from "../../../../../../../../../state/ducks/integrations";

const mapStateToProps = createStructuredSelector({
    telegramId: makeSelectUserIdTelegram(),
});

const TelegramIntegrationComponent = (props:any) => {
    const {telegramId, classNameContainer, classNameHeader, classNameStatusContainer,
        classNameHeaderContainer, classNameImage, classNameCompleteImage,
        classNameWrapperInputContainer, classNameInput, classnameInputError, classNameInputLabelContainer ,
        classNameConnectButton, сlassNameDisabledButton, classNameIntagration, classNameInvalid, classNameValid
    } = props;

    const isIntegrationSet = !!telegramId;

    const [isVisible, setIsVisible] = useState(false);
    const [currentId, setCurrentId] = useState<string>(isIntegrationSet ? telegramId.toString() : '');
    const [isWrongToken, setIsWrongToken] = useState<boolean>(false);
    const [isRightToken, setIsRightToken] = useState<boolean>(false);

    const resetIntegrationToken = () => {
        if (isWrongToken) {
            setIsWrongToken(false);
        }

        if(isRightToken) {
            setIsRightToken(false);
        }
    };

    const onHeaderButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const onConnectButtonClick = async () => {
        try {
            currentId && await integrationsService.setTelegramIntegrations(currentId);
            dispatch(setTelegramId(Number(currentId)));
            setIsRightToken(true);
            setIsWrongToken(false);
        } catch  {
            setIsWrongToken(true);
            setIsRightToken(false);
        }
    };

    const onInputIdChange = (value:any) => {
        setCurrentId(value.target.value);
        resetIntegrationToken();
    };

    return (
        <div className={classNameContainer}>
            <div className={classNameHeaderContainer} onClick={onHeaderButtonClick}>
                <div className={classNameHeader}>
                    <Image src={telegramImg}  className={classNameImage}/>
                    <span>Telegram</span>
                </div>
                <div className={classNameStatusContainer}>
                    {isIntegrationSet && (
                        <Image src={completeImg} className={classNameCompleteImage} />
                    )}
                    <Image src={isVisible ? topDropDownImg : bottomDropDownImg}
                           className={classNames(styles.DropDownIcon, isVisible && styles.isActiveDropDownIcon)}/>
                </div>
            </div>
            {isVisible && (
                <div className={classNameWrapperInputContainer}>
                    <div className={styles.InputContainer}>
                        <Input
                            type="number"
                            className={isWrongToken ? classNames(classnameInputError, classNameInput) : classNameInput}
                            placeholder='Type your integration ID here '
                            onChange={onInputIdChange}
                            value={currentId}
                        />
                        <ButtonColor
                            className={ !!currentId.length ?
                                classNameConnectButton : classNames(сlassNameDisabledButton, classNameConnectButton)}
                            onClick={onConnectButtonClick}
                        >
                            {telegramId ? 'Reconnect' : 'Connect'}
                        </ButtonColor>
                    </div>
                    {isWrongToken && <div className={classNameInvalid}>Token invalid!</div>}
                    {isRightToken && <div className={classNames(classNameInvalid, classNameValid)}>Connected successfully!</div>}
                    <div className={classNameInputLabelContainer}>
                        <div className={styles.InputDescriptionText}>
                            {!telegramId && <Image src={informationImg} className={styles.Icon}/>}
                            <div className={classNameIntagration}>
                                Click
                                <a className={styles.NotificationBot} target='_blank' href='https://t.me/onboard_notifications_bot'>@onboard_notifications_bot </a>
                                to connect Telegram
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps)(TelegramIntegrationComponent);
import serverService from "../serverService";
import stringUtilsService from "../../utils/stringUtilsService";
import config from "../../../config/config";
import appRouterService from "../../route/appRouterService";
import localStorageService from "../../storage/localStorageService";
import {
  ADMIN_SIGN_UP,
  TEAM_SIGN_UP,
  VERIFY_EMAIL,
} from "../../../consts/routes/routePaths";

const { data } = config;
const { defaultApiLink, auth } = data;
const { authSessionPath } = auth;

const requestConfig = (data: any) => {
  return {
    data: data,
  };
};

const clearAuthData = () => {
  localStorageService.clearRefreshToken();
  localStorageService.clearAccessToken();
};

const getNewAuthData = async () => {
  const newAuthData = await refreshAccessToken();
  const { refreshToken, accessToken } = newAuthData?.data || {};
  localStorageService.setAccessToken(accessToken);
  localStorageService.setRefreshToken(refreshToken);

  return newAuthData;
};

const isRegistrationPath = (pathName: string): boolean => {
  return (
    pathName === ADMIN_SIGN_UP ||
    pathName === TEAM_SIGN_UP ||
    pathName.includes("/reset-password") ||
    pathName === VERIFY_EMAIL
  );
};

const refreshAccessToken = async () => {
  const currentRefreshToken = localStorageService.getRefreshToken();
  const formattedUrl = stringUtilsService.formatString(
    defaultApiLink,
    authSessionPath
  );

  try {
    return await serverService.post(
      formattedUrl,
      requestConfig({ refreshToken: currentRefreshToken })
    );
  } catch (e) {
    if (e.response) {
      clearAuthData();

      const pathName = document.location.pathname;
      const isRegistration = isRegistrationPath(pathName);

      if (!isRegistration) {
        appRouterService.forwardToAdminLoginPage();
      }
    }

    return null;
  }
};

export default {
  refreshAccessToken,
  getNewAuthData,
  clearAuthData,
};

import serverService from "../serverService";
import { UserDetails } from "../../../types/UserDetails";
import { TeamMember } from "../../../types/TeamMember";
import { CourseMetaData } from "../../../types/CourseMetaData";
import { PerformanceReportResponse } from "../../../types/performanceReportsType/performanceReports";
import { PerformanceDetails } from "../../../types/performanceReportsType/PerformanceDetails";
import localStorageService from "../../storage/localStorageService";

const requestConfig = (data: object) => {
  return {
    data: data,
  };
};

const createNewCourse = async (
  url: string,
  name: string,
  background: string
) => {
  try {
    const newCourseData = await serverService.post(
      url,
      requestConfig({ name: name, background: background })
    );

    return newCourseData.data;
  } catch (e) {
    //
  }
};

const getAllCourses = async (
  url: string
): Promise<CourseMetaData[] | undefined> => {
  try {
    const allCourses: { data: CourseMetaData[] } = await serverService.get(url);

    return allCourses.data;
  } catch (e) {
    //
  }
};

const deleteCourse = async (url: string, courseId: number) => {
  try {
    return await serverService.deleteMethod(
      url,
      requestConfig({ id: courseId })
    );
  } catch (e) {
    //
  }
};

const renameCourse = async (
  url: string,
  courseId: number,
  courseName: string
) => {
  try {
    return await serverService.put(
      url,
      requestConfig({ id: courseId, name: courseName })
    );
  } catch (e) {
    //
  }
};

const setBrandingColor = async (url: string, brandingColor: string) => {
  try {
    const newBrandColor: any = await serverService.post(
      url,
      requestConfig({ color: brandingColor })
    );

    return newBrandColor.data.color as string;
  } catch (e) {
    //
  }
};

const setBrandingLogo = async (url: string, brandingLogo: any) => {
  try {
    const accessToken = localStorageService.getAccessToken();

    const newBrandLogo: any = await serverService.post(url, {
      data: brandingLogo,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
      },
    });

    return newBrandLogo.data.path as string;
  } catch (e) {
    //
  }
};

const getBrandingColor = async (url: string) => {
  try {
    const brandingColor = await serverService.get(url);

    return brandingColor.data.color as string;
  } catch (e) {
    //
  }
};

const getBrandingLogo = async (url: string) => {
  try {
    const brandingLogo = await serverService.get(url);

    return brandingLogo.data.path as string;
  } catch (e) {
    //
  }
};

const getOnboaardeesList = async (url: string) => {
  try {
    const onboardeesList = await serverService.get(url);

    return onboardeesList.data as Array<object>;
  } catch (e) {
    //
  }
};

const deleteLearners = async (url: string) => {
  try {
    await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const getFilterLearnerList = async (url: string) => {
  try {
    const learnerListFilter = await serverService.get(url);

    return learnerListFilter.data;
  } catch (e) {
    //
  }
};

const getIntegrationsBots = async (url: string) => {
  try {
    const integrationsData = await serverService.get(url);

    return integrationsData.data;
  } catch (e) {
    //
  }
};

const setTelegramIntegrations = async (url: string, currentId: string) => {
  try {
    return await serverService.post(url, requestConfig({ token: currentId }));
  } catch (e) {
    throw e;
  }
};

const setSlackIntegrations = async (url: string, currentUrl: string) => {
  try {
    return await serverService.post(url, requestConfig({ token: currentUrl }));
  } catch (e) {
    throw e;
  }
};

const getAllReports = async (url: string) => {
  try {
    const allReports = await serverService.get(url);

    return allReports.data;
  } catch (e) {
    //
  }
};

const setCourseGoal = async (
  url: string,
  courseId: number,
  newGoal: string
) => {
  try {
    return await serverService.post(
      url,
      requestConfig({ id: courseId, goal: newGoal })
    );
  } catch (e) {
    //
  }
};

const getCourseById = async (url: string) => {
  try {
    const course = await serverService.get(url);

    return course.data;
  } catch (e) {
    //
  }
};

const addModuleByCourseId = async (url: string, courseId: number) => {
  try {
    return await serverService.post(url, requestConfig({ courseId: courseId }));
  } catch (e) {
    //
  }
};

const updateModuleById = async (
  url: string,
  moduleId: number,
  newCourseName: string
) => {
  try {
    return await serverService.put(
      url,
      requestConfig({ moduleId: moduleId, name: newCourseName })
    );
  } catch (e) {
    //
  }
};

const deleteModuleByModuleId = async (url: string, moduleId: number) => {
  try {
    return await serverService.deleteMethod(
      url,
      requestConfig({ moduleIdToDelete: moduleId })
    );
  } catch (e) {
    //
  }
};

const deleteBrandingLogo = async (url: string) => {
  try {
    return await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const updateModuleGoal = async (
  url: string,
  courseId: number,
  newGoal: string
) => {
  try {
    await serverService.put(
      url,
      requestConfig({ moduleId: courseId, details: newGoal })
    );
  } catch (e) {
    //
  }
};

const setBackgroundCourse = async (
  url: string,
  courseId: number,
  background: string
) => {
  try {
    await serverService.put(
      url,
      requestConfig({ id: courseId, background: background })
    );
  } catch (e) {
    //
  }
};

const getFilteredReports = async (url: string) => {
  try {
    const filteredReports = await serverService.get(url);

    return filteredReports.data;
  } catch (e) {
    //
  }
};

const getFilterReportsList = async (url: string) => {
  try {
    const filterList = await serverService.get(url);

    return filterList.data;
  } catch (e) {
    //
  }
};

const getDetailsQuiz = async (url: string) => {
  try {
    const detailsQuiz = await serverService.get(url);

    return detailsQuiz.data;
  } catch (e) {
    //
  }
};

const getDetailsTask = async (url: string) => {
  try {
    const detailsTask = await serverService.get(url);

    return detailsTask.data;
  } catch (e) {
    //
  }
};

const updateTextContent = async (
  url: string,
  contentId: number,
  text: string
) => {
  try {
    return await serverService.put(
      url,
      requestConfig({ contentId: contentId, text: text })
    );
  } catch (e) {
    //
  }
};

const deleteContentById = async (url: string) => {
  try {
    return await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const addTextBlock = async (url: string, moduleId: number) => {
  try {
    return await serverService.post(
      url,
      requestConfig({ moduleId: moduleId, text: encodeURI(JSON.stringify("")) })
    );
  } catch (e) {
    //
  }
};

const addMediaBlock = async (url: string, file: any) => {
  try {
    const accessToken = localStorageService.getAccessToken();

    return await serverService.post(url, {
      data: file,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (e) {
    //
  }
};

const addPdf = async (url: string, file: any) => {
  try {
    const accessToken = localStorageService.getAccessToken();

    return await serverService.post(url, {
      data: file,
      headers: {
        Authorization: "Bearer " + accessToken,

        "Content-Type": "multipart/form-data",
      },
    });
  } catch (e) {
    //
  }
};

const createNewQuiz = async (url: string, moduleId: number) => {
  try {
    const newQuiz = await serverService.post(
      url,
      requestConfig({ moduleId: moduleId })
    );

    return newQuiz.data;
  } catch (e) {
    //
  }
};

const updateQuestionTitle = async (
  url: string,
  questionId: number,
  title: string
) => {
  try {
    const updatedQuestion = await serverService.put(
      url,
      requestConfig({ questionId: questionId, title: title })
    );

    return updatedQuestion.data;
  } catch (e) {
    //
  }
};

const createNewTask = async (
  url: string,
  moduleId: number,
  name: string,
  description: string
) => {
  try {
    const newTask = await serverService.post(
      url,
      requestConfig({
        moduleId: moduleId,
        name: name,
        description: description,
      })
    );

    return newTask.data;
  } catch (e) {
    //
  }
};

const updateTask = async (
  url: string,
  taskId: number,
  name: string,
  description: string
) => {
  try {
    const updatedTask = await serverService.put(
      url,
      requestConfig({ taskId: taskId, name: name, description: description })
    );

    return updatedTask.data;
  } catch (e) {
    //
  }
};

const updateQuizTestLimit = async (
  url: string,
  quizId: number,
  settingsType: string,
  settingsValue: number | null
) => {
  try {
    await serverService.put(
      url,
      requestConfig({
        quizId: quizId,
        settingsType: settingsType,
        value: settingsValue,
      })
    );
  } catch (e) {
    //
  }
};

const addNewQuizQuestion = async (
  url: string,
  quizId: number,
  isMultipleChoice: boolean,
  isEssay: boolean
) => {
  try {
    const newQuestion = await serverService.post(
      url,
      requestConfig({
        quizId: quizId,
        isMultipleChoice: isMultipleChoice,
        isEssay: isEssay,
      })
    );

    return newQuestion.data;
  } catch (e) {
    //
  }
};

const deleteQuizQuestionById = async (url: string) => {
  try {
    await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const updateQuestionVariant = async (
  url: string,
  variantId: number,
  title: string,
  isSelected: boolean
) => {
  try {
    const updatedQuestionVariant = await serverService.put(
      url,
      requestConfig({
        variantId: variantId,
        title: title,
        isSelected: isSelected,
      })
    );

    return updatedQuestionVariant.data;
  } catch (e) {
    //
  }
};

const addQuizVariant = async (url: any, questionId: number) => {
  try {
    const newQuizVariant = await serverService.post(
      url,
      requestConfig({ questionId: questionId })
    );

    return newQuizVariant.data;
  } catch (e) {
    //
  }
};

const deleteQuizVariant = async (url: string) => {
  try {
    await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const changeQuestionsType = async (
  url: string,
  questionId: number,
  isEssay: boolean,
  isMultipleChoice: boolean
) => {
  try {
    const updatedQuestions = await serverService.put(
      url,
      requestConfig({
        questionId: questionId,
        isEssay: isEssay,
        isMultipleChoice: isMultipleChoice,
      })
    );

    return updatedQuestions.data;
  } catch (e) {
    //
  }
};

const deleteQuizById = async (url: string) => {
  try {
    await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const deleteTaskById = async (url: string) => {
  try {
    await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const sendPhoneInvitations = async (url: string, phones: string[]) => {
  try {
    await serverService.post(url, requestConfig(phones));
  } catch (e) {
    //
  }
};

const sendEmailInvitationsTeam = async (url: string, invitationData: any) => {
  try {
    await serverService.post(url, requestConfig(invitationData));
  } catch (e) {
    //
  }
};

const sendEmailInvitations = async (url: string, emails: string[]) => {
  try {
    await serverService.post(url, requestConfig(emails));
  } catch (e) {
    //
  }
};

const getPublicInviteLink = async (url: string) => {
  try {
    const res = await serverService.get(url);
    return res.data;
  } catch (e) {
    throw e;
  }
};

const getInviteLinkForUser = async (url: string) => {
  try {
    const res = await serverService.get(url);
    return res.data;
  } catch (e) {
    throw e;
  }
};

const getAnalytics = async (url: string) => {
  try {
    const analytics = await serverService.get(url);

    return analytics.data;
  } catch (e) {
    //
  }
};

const getOverview = async (url: string) => {
  try {
    const overview = await serverService.get(url);

    return overview.data;
  } catch (e) {
    //
  }
};

const getFilteredList = async (url: string) => {
  try {
    const filteredList = await serverService.get(url);

    return filteredList.data;
  } catch (e) {
    //
  }
};

const getAnalyticsListByFilter = async (url: string) => {
  try {
    const analyticsList = await serverService.get(url);

    return analyticsList.data;
  } catch (e) {
    //
  }
};

const getProgress = async (url: string) => {
  try {
    const progressList = await serverService.get(url);

    return progressList.data;
  } catch (e) {
    //
  }
};

const getFiltersLearner = async (url: string) => {
  try {
    const filters = await serverService.get(url);

    return filters.data;
  } catch (e) {
    //
  }
};

const getCsvLearners = async (url: string) => {
  try {
    const csv = await serverService.get(url, { responseType: "blob" });

    return csv.data;
  } catch (e) {
    //
  }
};

const updateSequenceModule = async (url: string, moduleSequences: any[]) => {
  try {
    await serverService.put(
      url,
      requestConfig({ moduleSequences: moduleSequences })
    );
  } catch (e) {
    //
  }
};

const updateQuestionSequence = async (
  url: string,
  questionSequences: any[]
) => {
  try {
    await serverService.put(
      url,
      requestConfig({ questionSequence: questionSequences })
    );
  } catch (e) {
    //
  }
};

const updateVariantSequence = async (url: string, variantSequences: any[]) => {
  try {
    await serverService.put(
      url,
      requestConfig({ variantSequence: variantSequences })
    );
  } catch (e) {
    //
  }
};

const updateContentSequence = async (url: string, contentSequences: any[]) => {
  try {
    await serverService.put(
      url,
      requestConfig({ contentSequence: contentSequences })
    );
  } catch (e) {
    //
  }
};

const setStatusReport = async (url: string, id: number, status: string) => {
  try {
    await serverService.put(url, requestConfig({ id: id, status: status }));
  } catch (e) {
    //
  }
};

const getCustomInfo = async (url: string) => {
  try {
    const customInfoData = await serverService.get(url);

    return customInfoData.data;
  } catch (e) {
    //
  }
};

const addDelaySettingsToModule = async (
  url: string,
  delayTime: number,
  moduleId: number
) => {
  try {
    await serverService.post(
      url,
      requestConfig({ delayTime: delayTime, moduleId: moduleId })
    );
  } catch (e) {
    //
  }
};

const getTemplates = async (url: string) => {
  try {
    const templates = await serverService.get(url);

    return templates.data;
  } catch (e) {
    //
  }
};

const setUseTemplate = async (url: string, courseId: number) => {
  try {
    const getReplyInfo = await serverService.post(
      url,
      requestConfig({ courseId: courseId })
    );

    return getReplyInfo.data;
  } catch (e) {
    //
  }
};

const renameLearnerFirstName = async (
  url: string,
  id: number,
  firstName: string
) => {
  try {
    const setRenameFirstName = await serverService.put(
      url,
      requestConfig({ id: id, firstName: firstName })
    );

    return setRenameFirstName.data;
  } catch (e) {
    //
  }
};

const renameLearnerLastName = async (
  url: string,
  id: number,
  lastName: string
) => {
  try {
    const setRenameLastName = await serverService.put(
      url,
      requestConfig({ id: id, lastName: lastName })
    );

    return setRenameLastName.data;
  } catch (e) {
    //
  }
};

const getPaymentInfo = async (url: string) => {
  try {
    const getPaymentInfo = await serverService.get(url);

    return getPaymentInfo.data;
  } catch (e) {
    //
  }
};

const setPay = async (
  url: string,
  priceId: string,
  customerId: string,
  paymentMethodId: string,
  seatsNumber: number
) => {
  try {
    const setPayInfo = await serverService.post(
      url,
      requestConfig({
        priceId: priceId,
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        seatsNumber: seatsNumber,
      })
    );

    return setPayInfo.data;
  } catch (e) {
    //
  }
};

const setSeats = async (url: string, seats: number) => {
  try {
    const setSeats = await serverService.post(
      url,
      requestConfig({ amount: seats })
    );

    return setSeats.data;
  } catch (e) {
    //
  }
};

const getSeats = async (url: string) => {
  try {
    const getSeats = await serverService.get(url);

    return getSeats.data;
  } catch (e) {
    //
  }
};

const setPaymentInfo = async (url: string, newPayment: string) => {
  try {
    const setPayment = await serverService.post(
      url,
      requestConfig({ paymentMethodId: newPayment })
    );

    return setPayment.data;
  } catch (e) {
    //
  }
};

const getInfoAboutUser = async (url: string) => {
  try {
    const getInfo = await serverService.get(url);

    return getInfo.data;
  } catch (e) {
    //
  }
};

const getUserInfo = async (url: string): Promise<UserDetails | undefined> => {
  try {
    const userInfo: { data: UserDetails } = await serverService.get(url);

    return userInfo.data;
  } catch (e) {
    //
  }
};

const getInfo = async (url: string) => {
  try {
    const info = await serverService.get(url);

    return info.data;
  } catch (e) {
    //
  }
};

const getHelpCrunchInfo = async (url: string) => {
  try {
    const helpCrunchInfo = await serverService.get(url);

    return helpCrunchInfo.data;
  } catch (e) {
    //
  }
};

const resetAttempts = async (url: string, learnerId: number) => {
  try {
    const responseResetAttempts = await serverService.post(
      url,
      requestConfig({ learnerId: learnerId })
    );

    return responseResetAttempts.data;
  } catch (e) {
    //
  }
};

const getPartnerInfo = async (url: string) => {
  try {
    const partnerInfo = await serverService.get(url);

    return partnerInfo.data;
  } catch (e) {
    //
  }
};

const resetProgress = async (
  url: string,
  learnerId: number,
  courseId: number
) => {
  try {
    const responseResetProgress = await serverService.post(
      url,
      requestConfig({ learnerId: learnerId, courseId: courseId })
    );

    return responseResetProgress;
  } catch (e) {
    //
  }
};

const getListOfCustomers = async (url: string) => {
  try {
    const customersList = await serverService.get(url);

    return customersList.data;
  } catch (e) {
    //
  }
};

const addAttachFileQuestion = async (url: string, file: any) => {
  try {
    const accessToken = localStorageService.getAccessToken();

    const newAttachFile: any = await serverService.post(url, {
      data: file,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
      },
    });

    return newAttachFile.data;
  } catch (e) {
    //
  }
};

const addAttachFileVariant = async (url: string, file: any) => {
  try {
    const accessToken = localStorageService.getAccessToken();

    const newAttachFile: any = await serverService.post(url, {
      data: file,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
      },
    });

    return newAttachFile.data;
  } catch (e) {
    //
  }
};

const deleteAttachFile = async (url: string) => {
  try {
    await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const getDataTeamMembers = async (
  url: string
): Promise<TeamMember[] | undefined> => {
  try {
    const dataTeam: { data: TeamMember[] } = await serverService.get(url);

    return dataTeam.data;
  } catch (e) {
    //
  }
};

const deleteTeamMember = async (url: string) => {
  try {
    await serverService.deleteMethod(url);
  } catch (e) {
    //
  }
};

const getFiltersMember = async function <Type>(
  url: string
): Promise<Type | undefined> {
  try {
    const filtersData: { data: Type } = await serverService.get(url);

    return filtersData.data;
  } catch (e) {
    //
  }
};

const resendVerification = async (url: string, email: string) => {
  try {
    await serverService.post(url, requestConfig({ email: email }));
  } catch (e) {
    //
  }
};

const verifyEmail = async (url: string, token: string) => {
  try {
    const res = await serverService.post(url, requestConfig({ token: token }));

    return res.data;
  } catch (e) {
    throw e;
  }
};

const setAiAssistantSetting = async (url: string, submitted: boolean) => {
  try {
    const res = await serverService.post(
      url,
      requestConfig({ submitted: submitted })
    );

    return res;
  } catch (e) {
    throw e;
  }
};

const setPipedriveIntegration = async (url: string, code: string) => {
  try {
    const res = await serverService.post(url, requestConfig({ code: code }));

    return res.data;
  } catch (e) {
    throw e;
  }
};

const getLearnersByPipedrive = async (url: string) => {
  try {
    const res = await serverService.get(url);

    return res.data;
  } catch (e) {
    throw e;
  }
};

const submitLearnersForPipedrive = async (
  url: string,
  submittedLearners: Array<number>
) => {
  try {
    const res = await serverService.post(
      url,
      requestConfig({ submittedLearners: submittedLearners })
    );

    return res.data;
  } catch (e) {
    throw e;
  }
};

const disconnectPipedrive = async (url: string) => {
  try {
    const res = await serverService.deleteMethod(url);

    return res.data;
  } catch (e) {
    throw e;
  }
};

const getMetricsForIntegration = async (url: string) => {
  try {
    const res = await serverService.get(url);

    return res.data;
  } catch (e) {
    throw e;
  }
};

const submitMetricsForPipedrive = async (
  url: string,
  updatedMetrics: Array<number>
) => {
  try {
    const res = await serverService.put(
      url,
      requestConfig({ updatedMetrics: updatedMetrics })
    );

    return res.data;
  } catch (e) {
    throw e;
  }
};

const getMetricsForAnalytics = async (url: string) => {
  try {
    const res = await serverService.get(url);

    return res.data;
  } catch (e) {
    throw e;
  }
};

const reviewCourse = async (
  url: string,
  learnerId: number,
  courseId: number
) => {
  try {
    const res = await serverService.post(
      url,
      requestConfig({ learnerId: learnerId, courseId: courseId })
    );

    return res.data;
  } catch (e) {
    throw e;
  }
};

const getPerformanceReports = async (url: string) => {
  try {
    const res: { data: PerformanceReportResponse } = await serverService.get(
      url
    );

    return res.data;
  } catch (e) {
    throw e;
  }
};

const getPerformanceReportDetails = async (url: string) => {
  try {
    const res: { data: PerformanceDetails } = await serverService.get(url);

    return res.data;
  } catch (e) {
    throw e;
  }
};

const setPerformanceReportStatus = async (url: string) => {
  try {
    return await serverService.put(url);
  } catch (e) {
    throw e;
  }
};

const setPerformanceOverview = async (
  url: string,
  day: string,
  zoneId: string
) => {
  try {
    await serverService.post(url, requestConfig({ day: day, zoneId: zoneId }));
  } catch (e) {
    throw e;
  }
};

export default {
  getAllCourses,
  createNewCourse,
  deleteCourse,
  renameCourse,
  setBrandingColor,
  setBrandingLogo,
  getBrandingColor,
  getBrandingLogo,
  getOnboaardeesList,
  deleteLearners,
  getIntegrationsBots,
  setTelegramIntegrations,
  setSlackIntegrations,
  getAllReports,
  setCourseGoal,
  getCourseById,
  addModuleByCourseId,
  updateModuleById,
  deleteModuleByModuleId,
  deleteBrandingLogo,
  updateModuleGoal,
  setBackgroundCourse,
  getFilterLearnerList,
  updateTextContent,
  deleteContentById,
  addTextBlock,
  addMediaBlock,
  createNewQuiz,
  updateQuestionTitle,
  createNewTask,
  updateTask,
  updateQuizTestLimit,
  addNewQuizQuestion,
  deleteQuizQuestionById,
  updateQuestionVariant,
  addQuizVariant,
  deleteQuizVariant,
  changeQuestionsType,
  deleteQuizById,
  getAnalytics,
  getOverview,
  getFilteredList,
  getAnalyticsListByFilter,
  getProgress,
  getFiltersLearner,
  getFilteredReports,
  getFilterReportsList,
  getDetailsQuiz,
  getDetailsTask,
  getCsvLearners,
  deleteTaskById,
  updateSequenceModule,
  sendEmailInvitations,
  sendPhoneInvitations,
  updateVariantSequence,
  updateQuestionSequence,
  updateContentSequence,
  setStatusReport,
  getCustomInfo,
  addDelaySettingsToModule,
  getTemplates,
  setUseTemplate,
  renameLearnerFirstName,
  renameLearnerLastName,
  getPaymentInfo,
  setPay,
  setSeats,
  getSeats,
  setPaymentInfo,
  getInfoAboutUser,
  getUserInfo,
  getHelpCrunchInfo,
  resetAttempts,
  resetProgress,
  getPartnerInfo,
  getListOfCustomers,
  addAttachFileQuestion,
  addAttachFileVariant,
  deleteAttachFile,
  getDataTeamMembers,
  deleteTeamMember,
  getFiltersMember,
  addPdf,
  resendVerification,
  verifyEmail,
  setPipedriveIntegration,
  getLearnersByPipedrive,
  submitLearnersForPipedrive,
  disconnectPipedrive,
  getMetricsForIntegration,
  submitMetricsForPipedrive,
  getMetricsForAnalytics,
  setAiAssistantSetting,
  reviewCourse,
  getPerformanceReports,
  getPerformanceReportDetails,
  setPerformanceOverview,
  setPerformanceReportStatus,
  getInfo,
  getPublicInviteLink,
  sendEmailInvitationsTeam,
  getInviteLinkForUser,
};

import React from 'react';

import styles from './CardComponent.module.scss';
import visaImg from '../../../../../../../../../../assets/images/icon/visa-icon.svg';
import mastercardImg from '../../../../../../../../../../assets/images/icon/mastercard-icon.svg';
import {ReactComponent as ChangeCardImg} from '../../../../../../../../../../assets/images/icon/change-card-details.svg';
import Image from '../../../../../../../../../components/Image/Image';
import Svg from '../../../../../../../../../components/SvgComponent/SvgComponent';
import {createStructuredSelector} from 'reselect';
import {makeSelectPaymentInfo} from '../../../../../../../../../state/selectors/subscription';
import {connect} from 'react-redux';

const mapStateToProps = createStructuredSelector({
    paymentInfo: makeSelectPaymentInfo(),
});

const CardComponent = (props:any) => {
    const {paymentInfo, onClickToChangeCard} = props;

    return (
        <>
            <div className={styles.SubtitleSubscription}>Payment Method</div>
            <div className={styles.WrapperForCardDetails}>
                <div className={styles.ContainerCardDetails}>
                    <span className={styles.NumberCard}>
                        **** **** **** {paymentInfo?.last4}
                    </span>
                    <div className={styles.WrapperForIconCard}>
                        <Image
                            className={styles.CardImg}
                            src={paymentInfo?.paymentBrand === 'visa' ? visaImg : mastercardImg}
                        />
                    </div>
                </div>
                <Svg
                    className={styles.ChangeCardImg}
                    onClick={onClickToChangeCard}
                >
                    <ChangeCardImg />
                </Svg>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(CardComponent);
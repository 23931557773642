import React, {useCallback} from 'react';
import styles from '../../MetricsItemComponent.module.scss';
import {DeletePopup} from '../../../../../../../../../../../../../../../components/DeletePopup/DeletePopup';
import learnersService
    from '../../../../../../../../../../../../../../../services/server/learnersService/learnersService';
import {toast} from 'react-toastify';

type ReviewCourseComponentProps = {
    courseId: number
    courseName: string
    learnerId: number
    learnerName: string
    setIsShowReviewPopUp: Function
}

const ReviewCourseComponent = ({courseId, learnerId,
    setIsShowReviewPopUp, courseName, learnerName} : ReviewCourseComponentProps) => {

    const reviewCourseButtonClick = useCallback(() => {
        setIsShowReviewPopUp(false);

        learnersService.reviewCourse(learnerId, courseId).then(() => {
            toast(`Your invitation is sent!`);
        }).catch(() => {
            toast(`Oops, something went wrong. Try again!`);
        });
    }, [courseId, learnerId]);

    const hideDeletePopup = () => setIsShowReviewPopUp(false);

    return (
        <DeletePopup
            classNameBackground={styles.Wrapper}
            onHide={hideDeletePopup}
            onDelete={reviewCourseButtonClick}
            textData={{
                title: '',
                header: {
                    general: '',
                    specific: '',
                    note:'',
                },
                deleteText: 'Yes, send it',
                courseName: courseName,
                learnerName: learnerName,
            }}
        />
    );
};

export default ReviewCourseComponent;

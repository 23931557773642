import React from 'react';
import styles from '../../../../../LearnersComponent/components/DeleteLearnerPopup/DeleteLearnerPopup.module.scss';
import {DeletePopup} from '../../../../../../../../../../../components/DeletePopup/DeletePopup';
import learnersService from '../../../../../../../../../../../services/server/learnersService/learnersService';
import {dispatch} from '../../../../../../../../../../../state/store';
import {
    setFilterNameLearnerList,
    setLearnersList,
    setSortedLearnerList, setSortedLearnerName
} from '../../../../../../../../../../../state/ducks/admin';
import teamService from '../../../../../../../../../../../services/server/teamService/teamService';
import {
    setFiltersName,
    setListOfTeamMembers, setSortedFiltersName,
    setSortedListOfTeamMembers
} from '../../../../../../../../../../../state/ducks/team';

const DeleteMemberPopup = (props:any) => {
    const {hideDeletePopup, memberName, memberId, setFilterValueName} = props;

    const deleteCourseButtonClick = async () => {
        await teamService.deleteMember(memberId);

        const teamMembersNewList:any = await teamService.getDataTeamMembers();
        dispatch(setListOfTeamMembers(teamMembersNewList));
        dispatch(setSortedListOfTeamMembers(teamMembersNewList));

        const listOfNamesMembers:any = await teamService.getFiltersMemberName();
        dispatch(setFiltersName(listOfNamesMembers.teamNames));
        dispatch(setSortedFiltersName(listOfNamesMembers.teamNames));

        setFilterValueName({name:'', id: -1});
        hideDeletePopup();
    };

    return (
        <DeletePopup
            classNameBackground={styles.Wrapper}
            onHide={hideDeletePopup}
            onDelete={deleteCourseButtonClick}
            textData={{
                title:'Delete this member?',
                header: {
                    general: 'You are about to delete',
                    specific: memberName,
                    note:'It\'ll be gone forever and we won\'t be able to recover it.'
                }
            }}
        />
    );
};

export default DeleteMemberPopup;

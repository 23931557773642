import { useEffect } from 'react';

import appService from './appService';

const App = (props:any) => {
    const {children} = props;

    useEffect( () => {
        appService.appInit()
            .catch((error) => {
                console.log(error)
            });
    }, []);

    return children;
}

export default App;

export enum OverviewType {
    VALUE = '#',
    PERCENTAGE= '%'
}

export interface OverviewData {
    current: number
    previous: number
    coefficient: number
    type: string
    sign: OverviewType
}
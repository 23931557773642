import classNames from 'classnames';
import React from 'react';

import styles from './Input.module.scss';

const Input = (props:any) => {
    const {type, maxLength, minLength, onChange, placeholder, className, isMultiple, value,
    reference, onKeyPress, onInput, isDisabledInput, autoFocus, checked, onBlur, pattern, readOnly, onFocus, style} = props;
    const inputClassName = classNames(styles.Input, className);

    return (
        <input
            readOnly={readOnly}
            pattern={pattern}
            autoFocus={autoFocus}
            disabled={isDisabledInput}
            ref={reference}
            type={type}
            className={inputClassName}
            onChange={onChange}
            onKeyUp={onKeyPress}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            multiple={isMultiple}
            value={value}
            onInput={onInput}
            checked={checked}
            onBlur={onBlur}
            onFocus={onFocus}
            style={style}
        />
    );
};

export default Input;
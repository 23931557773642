import {ReportsStorage} from '../interfaces/reportsInterface';

export const SET_IS_EMPTY_REPORTS_DATA = 'app/reports/SET_IS_EMPTY_REPORTS_DATA';
export const SET_REPORTS_LIST = 'app/reports/SET_REPORTS_LIST';
export const SET_FILTER_TYPE = 'app/reports/SET_FILTER_TYPE';
export const SET_FILTER_LEARNERS = 'app/reports/SET_FILTER_LEARNER';
export const SET_SORTED_LEARNERS_LIST = 'app/reports/SET_SORTED_LEARNERS_LIST';
export const SET_FILTER_MODULE = 'app/reports/SET_FILTER_MODULE';
export const SET_FILTER_COURSE = 'app/reports/SET_FILTER_COURSE';
export const SET_FILTER_STATUS = 'app/reports/SET_FILTER_STATUS';
export const SET_TOTAL_REPORT_PAGES = 'app/reports/SET_TOTAL_REPORT_PAGES';
export const SET_EMPTY_REPORTS_LIST = 'app/reports/SET_EMPTY_REPORTS_LIST';
export const SET_QUIZ_DETAILS = 'app/reports/SET_QUIZ_DETAILS';
export const SET_TASK_DETAILS = 'app/reports/SET_TASK_DETAILS';
export const SET_FILTERED_QUIZ_DETAILS = 'app/reports/SET_FILTERED_QUIZ_DETAILS';

const initialState:ReportsStorage = {
    isEmptyReportsData: true,
    reportsList: [],
    filterTypeList: [],
    filterLearnersList: [],
    sortedLearnersList: [],
    filterCourseList: [],
    filterModuleList : [],
    filterStatusList: [],
    totalReportPages: 0,
    quizDetails: [],
    filteredQuizDetails: [],
    taskDetails: []
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_IS_EMPTY_REPORTS_DATA: {
            return {...state, isEmptyReportsData: action.payload};
        }
        case SET_FILTER_TYPE: {
            return {...state, filterTypeList: action.payload};
        }
        case SET_FILTER_LEARNERS: {
            return {...state, filterLearnersList: action.payload};
        }
        case SET_REPORTS_LIST: {
            return {...state, reportsList: action.payload};
        }
        case SET_EMPTY_REPORTS_LIST: {
            return {...state, reportsList: initialState.reportsList};
        }
        case SET_SORTED_LEARNERS_LIST: {
            return {...state, sortedLearnersList: action.payload}
        }
        case SET_FILTER_MODULE: {
            return {...state, filterModuleList: action.payload};
        }
        case SET_FILTER_COURSE: {
            return {...state, filterCourseList: action.payload};
        }
        case SET_FILTER_STATUS: {
            return {...state, filterStatusList: action.payload}
        }
        case SET_TOTAL_REPORT_PAGES: {
            return {...state, totalReportPages: action.payload}
        }
        case SET_QUIZ_DETAILS: {
            return {...state, quizDetails: action.payload}
        }
        case SET_TASK_DETAILS: {
            return {...state, taskDetails: action.payload}
        }
        case SET_FILTERED_QUIZ_DETAILS: {
            return {...state, filteredQuizDetails: action.payload}
        }
        default: return state;
    }
}

export const setIsEmptyReportsData = (value:Boolean) => ({type: SET_IS_EMPTY_REPORTS_DATA, payload: value})

export const setReportsList = (value:Array<any>|[]) => ({type: SET_REPORTS_LIST, payload: value});

export const setFilterTypeList = (value:Array<any>|[]) => ({type: SET_FILTER_TYPE, payload: value});

export const setFilterLearnerList = (value:Array<any>|[]) => ({type: SET_FILTER_LEARNERS, payload: value});

export const setSortedLearnersList = (value:Array<any>|[]) => ({type: SET_SORTED_LEARNERS_LIST, payload: value});

export const setFilterModuleList = (value:Array<any>|[]) => ({type: SET_FILTER_MODULE, payload: value});

export const setFilterCourseList = (value:Array<any>|[]) => ({type: SET_FILTER_COURSE, payload:value});

export const setFilterStatusList = (value:Array<any>|[]) => ({type: SET_FILTER_STATUS, payload:value});

export const setTotalReportPages = (value:number|null) => ({type: SET_TOTAL_REPORT_PAGES, payload:value});

export const setEmptyReportsList = () => ({type: SET_EMPTY_REPORTS_LIST});

export const setQuizDetailsList = (value:Array<any>|[]) => ({type: SET_QUIZ_DETAILS, payload:value});

export const setTaskDetailsList = (value:Array<any>|[]) => ({type: SET_TASK_DETAILS, payload:value});

export const setFilteredQuizDetailsList = (value:Array<any>|[]) => ({type: SET_FILTERED_QUIZ_DETAILS, payload:value});


export const ReportStatus = {
    UNCHECKED:  {
        Value: 0,
        Name:"UNCHECKED"
    },
    CHECKED: {
        Value: 1,
        Name:"CHECKED"
    },
};
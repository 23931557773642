import React from "react";
import classNames from "classnames";

import styles from "./ListLearners.module.scss";
import ListAnalytics from "./components/ListAnalytics/ListAnalytics";
import ListProgress from "./components/ListProgress/ListProgress";

const ListLearners = (props: any) => {
  const { filterType, filterCourse, renderedAnalytics, renderedProgress } =
    props;

  const classNameWrapper =
    filterType.type === "Quiz result"
      ? styles.OnboardeeHeaderRank
      : filterType.type === "Course progress"
      ? styles.OnboardeeHeaderStatus
      : "";

  const textForSubtitle =
    filterType.type === "Quiz result"
      ? "Quiz result"
      : filterType.type === "Performance"
      ? "Performance"
      : filterType.type === "Course progress"
      ? "Training progress"
      : "Learning time";

  return (
    <div className={styles.WrapperHeaderAndList}>
      <div className={classNames(styles.OnboardeeHeader, classNameWrapper)}>
        <span className={styles.SubTitle}>User</span>
        <span className={styles.SubTitle}>{textForSubtitle}</span>
        {filterType.type === "Quiz result" && (
          <span className={styles.SubTitle}>Rank</span>
        )}
        {filterType.type === "Course progress" && (
          <span className={styles.SubTitle}>Status</span>
        )}
      </div>
      <div className={styles.OnboardeeList}>
        {filterType.type === "Course progress" ? (
          <ListProgress
            filterCourse={filterCourse}
            renderedProgress={renderedProgress}
          />
        ) : (
          <ListAnalytics
            filterType={filterType}
            renderedAnalytics={renderedAnalytics}
          />
        )}
      </div>
    </div>
  );
};

export default ListLearners;

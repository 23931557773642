
export const metricsList = [
    {
        id: 1,
        name: '%, Won rate',
        description: 'Team comparison'
    },
    {
        id: 2,
        name: '%, Conversions',
        description: 'Team comparison'
    },
    {
        id: 3,
        name: 'Sales Cycle',
        description: 'Team comparison'
    },
    {
        id: 4,
        name: '%, Loss rate',
        description: 'Team comparison'
    },
    {
        id: 5,
        name: '#, Calls',
        description: 'Personal comparison'
    },
    {
        id: 6,
        name: '#, Meetings',
        description: 'Personal comparison'
    },
    {
        id: 7,
        name: '#, Emails sent',
        description: 'Personal comparison'
    },
    {
        id: 8,
        name: '#, Emails recvd.',
        description: 'Personal comparison'
    },
    {
        id: 9,
        name: '# New deals',
        description: 'Team comparison'
    },
    {
        id: 10,
        name: '#, Activities compl.',
        description: 'Personal comparison'
    },
    {
        id: 11,
        name: '#, Notes added',
        description: 'Personal comparison'
    },
]
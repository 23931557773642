import React from 'react';
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames';

import styles from './CustomersItem.module.scss';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectCurrentPageDta} from '../../../../../../../../../../../state/selectors/home';
import dateModify from '../../../../../../../../../../../services/utils/dateModify/dateModify';

const mapStateToProps = createStructuredSelector({
    currentPageData: makeSelectCurrentPageDta()
});

const CustomersItem = (props:any) => {
    const {currentPageData} = props;

    return (
        <>
            {currentPageData?.map((item:any) => {
                const companyName = item.companyName;
                const email = item.email;
                const date = item.date;
                const source = item.urlParams;
                const learners = item.learnersNumber;
                const billing = item.billing;

                const classNameBilling = billing === 'free' ? styles.Free : billing === 'paid' ? styles.Paid :
                    billing === 'failure' ? styles.Failure : ''

                return (
                    <div key={companyName} className={styles.ContainerCustomersItem}>
                        <span className={styles.CompanyName}>{companyName}</span>
                        <span className={styles.Email}>{email}</span>
                        <span className={styles.SignUpToTime}>{dateModify.setModifyDate(date)}</span>
                        <span data-tip={source} className={styles.Source}>{source}</span>
                        <span className={styles.Learners}>{learners}</span>
                        <span className={classNames(styles.Billing, classNameBilling)}>{billing}</span>
                        <ReactTooltip
                            effect='solid'
                            place='bottom'
                        />
                    </div>
                )
            })}
        </>
    );
};

export default connect(mapStateToProps)(CustomersItem);
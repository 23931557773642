import React from 'react';

import TabItemComponent from '../../../AdminAccountPage/components/TabItemComponent/TabItemComponent';
import {availableTabListPartners} from './menuTabListPartners';

const MenuListPartners = (props:any) => {
    const {activeTab, onTabButtonClick} = props;

    return (
        <>
            {availableTabListPartners.map((item) => {
                const id = item.id;

                return (
                    <TabItemComponent
                        onTabButtonClick={onTabButtonClick}
                        item={item}
                        key={id}
                        activeTab={activeTab}
                    />
                )
            })}
        </>
    );
};

export default MenuListPartners;
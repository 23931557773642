import React, {useState} from 'react';

import styles from './ConfigureReports.module.scss';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import {ReactComponent as GearBoxImg} from '../../../../../../../../../../../../assets/images/icon/gearbox-icon.svg';
import Svg from '../../../../../../../../../../../components/SvgComponent/SvgComponent';
import DatePopUpComponent from './components/DatePopUpComponent/DatePopUpComponent';

interface ConfigureReportsProps {
    setControlTrigger: Function
}

const ConfigureReports = ({setControlTrigger}: ConfigureReportsProps) => {
    const [isVisibleDateComponent, setIsVisibleDateComponent] = useState(false);

    const handleConfigureClick = () => {
        setIsVisibleDateComponent(true);
    };

    const handleClosePopUp = () => {
        setIsVisibleDateComponent(false);
    };

    return (
        <>
            <div className={styles.Container}>
                <div>Choose day to get team report with metrics overview, performance gaps and team overview to set instant training actions.</div>
                <ButtonColor className={styles.Configure} onClick={handleConfigureClick}>
                    <Svg className={styles.Image}>
                        <GearBoxImg />
                    </Svg>
                    <span className={styles.TextBtn}>Configure reports</span>
                </ButtonColor>
            </div>
            {isVisibleDateComponent &&
                <DatePopUpComponent
                    handleClosePopUp={handleClosePopUp}
                    setControlTrigger={setControlTrigger}
                />
            }
        </>
    );
};

export default ConfigureReports;

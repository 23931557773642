import React, {useEffect, useState} from 'react';

import styles from './CrmAnalytics.module.scss';
import pipedriveLogo from '../../../../../../../../../../assets/images/logo/pipedrive.svg';
import Calendar from './components/Calendar/Calendar';
import FilterComponent from '../../../../../../../../../components/FilterComponent/FilterComponent';
import Svg from '../../../../../../../../../components/SvgComponent/SvgComponent';
import Image from '../../../../../../../../../components/Image/Image';
import {ReactComponent as ArrowLeftComponent} from '../../../../../../../../../../assets/images/icon/arrow-left-dashboard.svg';
import MetricsComponent from './components/MetricsComponent/MetricsComponent';
import calendar from '../../../../../../../../../services/date/calendar';
import date from '../../../../../../../../../services/date/date';
import learnersService from '../../../../../../../../../services/server/learnersService/learnersService';
import filterByCalendarAndPeriod from './components/filterByCalendarAndPeriod';
import {optionsForPeriod} from './components/optionsForPeriod';
import Loader from 'react-loader-spinner';
import {ToastContainer} from 'react-toastify';

type CrmAnalyticsProps = {
    learnerName: string
    learnerId: number
    learnerPipedriveId: number
    setIsVisibleDashboardComponent: Function
}

const CrmAnalytics:React.FC<CrmAnalyticsProps> = ({learnerName, setIsVisibleDashboardComponent, learnerId, learnerPipedriveId}) => {
    const [isShowCalendar, setIsShowCalendar] = useState(false);

    const [period, setPeriod] = useState({id: 1, name: 'This week'});
    const [firstDate, setFirstDate] = useState<string>(date.formattedDate(calendar.firstDayThisWeek()));
    const [lastDate, setLastDate] = useState<string>(date.formattedDate(calendar.lastDayThisWeek()));
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        if (!!dateRange.length) {
            getDataMetricsFromPeriod();
        }
    }, [dateRange]);

    useEffect(() => {
        filterCrmAnalytics();
    }, [firstDate, lastDate]);

    const getMetricsByDate = (begging:string, end:string) => {
        setLoader(false);
        learnersService.getMetricsForAnalytics(begging, end, learnerId, learnerPipedriveId)
            .then(() => {
                setLoader(true);
            }).catch((e) => {
            console.log(e);
        });
    };

    const getDataMetricsFromPeriod = () => {
        getMetricsByDate(dateRange[0], dateRange[1]);
    };

    const getDataMetrics = () => {
        getMetricsByDate(firstDate, lastDate);
    };

    const onSelectPeriodClick = (periodValue:string, id:number) => {
        setPeriod({id: id, name: periodValue});
    };

    const onSelectFirstDate = (firstDateValue:string) => {
        setFirstDate(firstDateValue);
    };

    const onSelectLastDate = (lastDateValue:string) => {
        setLastDate(lastDateValue);
    };

    const onBackButtonClick = () => {
        setIsVisibleDashboardComponent(false);
    };

    const filterCrmAnalytics = () => filterByCalendarAndPeriod.filterByCalendarAndPeriod(firstDate, lastDate, setPeriod);

    return (
        <div className={styles.CrmContainer}>
            <div className={styles.WrapperBtnName}>
                <div className={styles.BackImgWrapper} onClick={onBackButtonClick}>
                    <Svg className={styles.BackImg}>
                        <ArrowLeftComponent/>
                    </Svg>
                </div>
                <span>{learnerName}</span>
            </div>
            <div className={styles.TitlePerformance}>Performance</div>
            <div className={styles.WrapperFilters}>
                <FilterComponent
                    listOptions={optionsForPeriod}
                    filterValue={period}
                    classNameDropDown={styles.DropDownContainer}
                    classNameDropDownMenu={styles.DropDownMenuContainer}
                    DropDownMenuContainerNotSelected={styles.DropDownMenuContainerNotSelected}
                    classNameContainer={styles.FilterContainerItem}
                    classNameTitle={styles.Title}
                    isShowCalendar={isShowCalendar}
                    onOptionFilterClick={onSelectPeriodClick}
                />
                <Calendar
                    isShowCalendar={isShowCalendar}
                    setIsShowCalendar={setIsShowCalendar}
                    onSelectFirstDate={onSelectFirstDate}
                    onSelectLastDate={onSelectLastDate}
                    periodId={period.id}
                    getDataMetrics={getDataMetrics}
                    setDateRange={setDateRange}
                />
                <div className={styles.WrapperLogo}>
                    <div className={styles.Red}>.</div>
                    <div className={styles.PipedriveLogo}>
                        <Image
                            src={pipedriveLogo}
                            className={styles.ImagePipedrive}
                        />
                    </div>
                </div>
            </div>
            {loader ?
                <MetricsComponent
                    learnerId={learnerId}
                    learnerName={learnerName}
                /> :
                <div className={styles.WrapperForLoader}>
                        <Loader
                        type="TailSpin"
                        color="#F85A40"
                        height={50}
                        width={50}
                        />
                    <div className={styles.TextLoader}>Calculating your data...</div>
                </div>
            }
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                className={styles.ToastContainer}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
            />
        </div>
    );
};

export default CrmAnalytics;

const formattedDate = (date:any) => {
    const dayData = `0${date.getDate()}`;
    const monthData = `0${date.getMonth() + 1}`;

    return `${date.getFullYear()}-${(date.getMonth() + 1) > 9 ?
        date.getMonth() + 1 : monthData}-${date.getDate() > 9 ?
        date.getDate() : dayData}`;
};

export default {
    formattedDate
}
import {useEffect} from 'react';
import localStorageService from '../services/storage/localStorageService';
import appRouterService from '../services/route/appRouterService';


export const usePresentSession = () => {

    useEffect(() => {
        const _rt = localStorageService.getRefreshToken();
        const isRtPresent = _rt !== null && _rt !== 'undefined';

        if (isRtPresent) {
            appRouterService.forwardToAdminAccountPage();
        }

    }, []);
};
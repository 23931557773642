import config from '../../../config/config';
import stringUtils from '../../utils/stringUtilsService';
import adminService from '../adminService/adminService';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {getPerformanceReportsPath, setPerformanceOverviewPath, getPerformanceReportDetailsPath, setPerformanceReportStatusPath} = externalLinks;

const getPerformanceReports = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getPerformanceReportsPath);
    const reportsList = await adminService.getPerformanceReports(formattedUrl);

    return reportsList.performanceReports;
};

const setPerformanceOverview = async (day: string, zoneId: string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setPerformanceOverviewPath);

    await adminService.setPerformanceOverview(formattedUrl, day, zoneId);
};

const getReportsDetails = async (reportId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getPerformanceReportDetailsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, reportId)

    return await adminService.getPerformanceReportDetails(updatedUrl);
}

const setReportsStatus = async (reportId:number, status:boolean) => {
    const url = stringUtils.formatString(
        stringUtils.formatString(defaultApiLink, setPerformanceReportStatusPath),
        reportId,
        status
    );

    return await adminService.setPerformanceReportStatus(url);
}


export default {
    getPerformanceReports,
    getReportsDetails,
    setPerformanceOverview,
    setReportsStatus
}
import { useEffect, useState } from "react";
import styles from "./InviteLearnersPopup.module.scss";
import PopupFullPage from "../../../../../../../components/Popup/PopupFullPage/PopupFullPage";
import PublicLinkComponent from "./components/PublicLinkComponent/PublicLinkComponent";
import PersonalInviteComponent from "./components/PersonalInviteComponent/PersonalInviteComponent";
import { createStructuredSelector } from "reselect";
import {
  makeSelectIsLegacyUser,
  makeSelectPaymentInfo,
  makeSelectSeatsInfo,
} from "../../../../../../../state/selectors/subscription";
import { connect } from "react-redux";
import Restricted from "../../../../../../../components/Restricted/Restricted";
import { Role } from "../../../../../../../types/UserDetails";
import { makeSelectUserDetails } from "../../../../../../../state/selectors/userDetails";
import FinishedInvitation from "../../../../../../ConsumerAccountPage/AdminAccountPage/components/ContentComponent/components/TeamComponent/components/AddTeamMemberPopup/components/FinishedInvitation/FinishedInvitation";
import { dispatch } from "../../../../../../../state/store";
import { setIsVisibleSubscriptionPopup } from "../../../../../../../state/ducks/global";
import invitationService from "../../../../../../../services/server/invitationService/invitationService";

const mapStateToProps = createStructuredSelector({
  seatsInfo: makeSelectSeatsInfo(),
  isLegacyUser: makeSelectIsLegacyUser(),
  paymentInfo: makeSelectPaymentInfo(),
  userDetails: makeSelectUserDetails(),
});

const InviteLearnersPopup = (props: any) => {
  const {
    hideInviteLearnersPopup,
    course,
    seatsInfo,
    isLegacyUser,
    paymentInfo,
    userDetails,
    users,
  } = props;

  const [isCheckedShareSlider, setIsCheckedShareSlider] = useState(true);
  const [isCheckedEmailSlider, setIsCheckedEmailSlider] = useState(false);
  const [isInvitationFinished, setIsInvitationFinished] = useState(false);
  const [invitationCount, setInvitationCount] = useState(0);

  const onShareSliderChange = () => {
    setIsCheckedEmailSlider(false);
    setIsCheckedShareSlider(!isCheckedShareSlider);
  };

  useEffect(() => {
    if (users) {
      invitationService.getInviteLinkForUser();
    } else {
      invitationService.getPublicInviteLink(course?.id);
    }
  }, []);

  const onPersonalInviteSliderChange = () => {
    setIsCheckedShareSlider(false);
    setIsCheckedEmailSlider(!isCheckedEmailSlider);
  };

  const onFinishInvitation = (numberOfInvitations: number) => {
    setInvitationCount(numberOfInvitations);
    setIsInvitationFinished(true);
  };

  const onClickUpgrade = () => {
    dispatch(setIsVisibleSubscriptionPopup(true));
    hideInviteLearnersPopup();
  };

  return (
    <PopupFullPage
      closePopup={hideInviteLearnersPopup}
      classNameMainContainer={styles.PopupContainer}
      isSupportOutsidePopupClick
    >
      <div className={styles.MainContainer}>
        {!isInvitationFinished && (
          <>
            <span className={styles.TitleText}>
              {users
                ? `Invite Users to ${userDetails.companyName}`
                : "Invite Users to training"}
            </span>
            <PublicLinkComponent
              onShareSliderChange={onShareSliderChange}
              isCheckedSlider={isCheckedShareSlider}
              users={users}
            />
            <PersonalInviteComponent
              onPersonalInviteSliderChange={onPersonalInviteSliderChange}
              onFinishInvitation={onFinishInvitation}
              isCheckedSlider={isCheckedEmailSlider}
              courseId={!users && course.id}
              users={users}
            />
          </>
        )}
        {isInvitationFinished && (
          <FinishedInvitation
            hideInviteLearnersPopup={hideInviteLearnersPopup}
            invitationCount={invitationCount}
          />
        )}
        {!isLegacyUser && (
          <Restricted allowedTo={[Role.CREATOR]} userRoles={userDetails?.roles}>
            {seatsInfo?.availableSeatsNumber >= 3 ? (
              <div className={styles.Seats}>
                {seatsInfo?.availableSeatsNumber} available seats left
              </div>
            ) : seatsInfo?.availableSeatsNumber <= 2 ? (
              <div className={styles.Seats}>
                {seatsInfo?.availableSeatsNumber} available seats left. New
                seats will be added to the next payment
              </div>
            ) : (
              ""
            )}
            {!paymentInfo.las4 && (
              <div className={styles.Seats} style={{ marginTop: "15px" }}>
                <span className={styles.Upgrade} onClick={onClickUpgrade}>
                  Upgrade
                </span>
              </div>
            )}
          </Restricted>
        )}
      </div>
    </PopupFullPage>
  );
};

export default connect(mapStateToProps)(InviteLearnersPopup);

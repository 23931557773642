import React from 'react';
import classNames from 'classnames';

import MetricsItemOverview from './components/MetricsItemOverview/MetricsItemOverview'
import styles from './OverviewComponent.module.scss';
import {
    OverviewData,
} from "../../../../../../../../../../../../../../../../types/performanceReportsType/PerformanceOverview";
import {EmptyPerformanceDataComponent} from "../EmptyPerformanceDataComponent/EmptyPerformanceDataComponent";

interface OverviewType {
    overviewData: Record<string, OverviewData> | undefined
    reportPeriod: string
}

const OverviewComponent = ({overviewData, reportPeriod}: OverviewType) => {
    return (
        <div>
            <div className={styles.Title}>Overview</div>
            <div className={styles.WrapperOverview}>
                <div className={classNames(styles.SubWrapperOverview,  styles.DividerSubWrapper)}>
                    {overviewData ?
                        Object.keys(overviewData).map(metricName => {
                            const metricPayload = overviewData[metricName];

                            return (
                                <MetricsItemOverview
                                    key={metricName}
                                    metricName={metricName}
                                    metricPayload={metricPayload}
                                    reportPeriod={reportPeriod}
                                />
                            );
                        }) :
                        <EmptyPerformanceDataComponent waringMessage={'No overview data available'} />
                    }
                </div>
            </div>
        </div>
    );
};

export default OverviewComponent;

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import styles from './SlackIntegrationComponent.module.scss';
import informationImg from '../../../../../../../../../../assets/images/icon/information-icon.svg';
import topDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-top-black.svg';
import bottomDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-bottom-black.svg';
import slackImg from '../../../../../../../../../../assets/images/icon/slack-icon.svg';
import completeImg from '../../../../../../../../../../assets/images/icon/complete-integrations-icon.svg';
import Input from '../../../../../../../../../components/Input/Input';
import ButtonColor from '../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import Image from '../../../../../../../../../components/Image/Image';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectUserUrlSlack} from '../../../../../../../../../state/selectors/integrations';
import integrationsService from '../../../../../../../../../services/server/integrationsService/integrationsService';
import {dispatch} from "../../../../../../../../../state/store";
import {setSlackUrl} from "../../../../../../../../../state/ducks/integrations";

const mapStateToProps = createStructuredSelector({
    slackUrl: makeSelectUserUrlSlack()
});

const SlackIntegrationComponent = (props:any) => {
    const {slackUrl, classNameContainer, classNameHeader, classNameStatusContainer,
        classNameHeaderContainer, classNameImage, classNameCompleteImage,
        classNameWrapperInputContainer, classNameInput, classnameInputError, classNameInputLabelContainer,
        classNameConnectButton, сlassNameDisabledButton, classNameIntagration, classNameInvalid, classNameValid
    } = props;

    const isIntegrationSet = !!slackUrl;

    const [isVisible, setIsVisible] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(isIntegrationSet ? slackUrl : '');
    const [isWrongToken, setIsWrongToken] = useState<boolean>(false);
    const [isRightToken, setIsRightToken] = useState<boolean>(false);

    const resetIntegrationToken = () => {
        if (isWrongToken) {
            setIsWrongToken(false);
        }

        if(isRightToken) {
            setIsRightToken(false);
        }
    };

    const onHeaderButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const onConnectButtonClick = async () => {
        try {
            !!currentUrl && await integrationsService.setSlackIntegrations(currentUrl);
            dispatch(setSlackUrl(currentUrl));
            setIsRightToken(true);
            setIsWrongToken(false);
        } catch (error) {
            setIsWrongToken(true);
            setIsRightToken(false);
        }
    };

    const onInputUrlChange = (value:any) => {
        setCurrentUrl(value.target.value);
        resetIntegrationToken();
    };

    return (
        <div className={classNameContainer}>
            <div className={classNameHeaderContainer} onClick={onHeaderButtonClick}>
                <div className={classNameHeader} onClick={onHeaderButtonClick}>
                    <Image
                        src={slackImg}
                        className={classNameImage}
                    />
                    <span>Slack</span>
                </div>
                <div className={classNameStatusContainer}>
                    {slackUrl && (
                        <Image src={completeImg} className={classNameCompleteImage} />
                    )}
                    <Image src={isVisible ? topDropDownImg : bottomDropDownImg}
                           className={classNames(styles.DropDownIcon, isVisible && styles.isActiveDropDownIcon)}
                    />
                </div>
            </div>
            {isVisible && (
                <div className={classNameWrapperInputContainer}>
                    <div className={styles.InputContainer}>
                        <Input
                            className={isWrongToken ? classNames(classNameInput, classnameInputError) : classNameInput}
                            placeholder='Type your integration URL here'
                            onChange={onInputUrlChange}
                            value={currentUrl}
                        />
                        <ButtonColor
                            className={currentUrl.length ?
                                classNameConnectButton : classNames(сlassNameDisabledButton, classNameConnectButton)}
                            onClick={onConnectButtonClick}
                        >
                            {slackUrl ? 'Reconnect' : 'Connect'}
                        </ButtonColor>
                    </div>
                    {isWrongToken && <div className={classNameInvalid}>Url invalid!</div>}
                    {isRightToken && <div className={classNames(classNameInvalid, classNameValid)}>Connected successfully!</div>}
                    <div className={classNameInputLabelContainer}>
                        <div className={styles.InputDescriptionText}>
                            {!slackUrl && <Image src={informationImg}  className={styles.Icon}/>}
                                <div className={classNameIntagration}>
                                Click
                                <a href='https://slack.com/oauth/v2/authorize?client_id=1442835146978.1443052158035&scope=chat:write,commands,im:history&user_scope='
                                   target='_blank'
                                   rel='noopener'
                                   className={styles.Slack}>Add to Slack </a>
                                to connect Slack
                                </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps)(SlackIntegrationComponent);
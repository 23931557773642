import { useState, useEffect } from "react";
import classNames from "classnames";

import styles from "./CourseAnalytics.module.scss";
import TypeFilterComponent from "./components/TypeFilterComponent/TypeFilterComponent";
import CourseFilterComponent from "./components/CourseFilterComponent/CourseFilterComponent";
import ModuleFilterComponent from "./components/ModuleFilterComponent/ModuleFilterComponent";
import LearnerFilterComponent from "./components/LearnerFilterComponent/LearnerFilterComponent";
import ButtonColor from "../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import ListLearners from "./components/ListLearners/ListLearners";
import { connect } from "react-redux";
import { dispatch } from "../../../../../../../../../state/store";
import { createStructuredSelector } from "reselect";
import { setAnalyticsList } from "../../../../../../../../../state/ducks/analytics";
import {
  makeSelectAnalyticsList,
  makeSelectAnalyticsListProgress,
  makeSelectSortedAnalyticsList,
} from "../../../../../../../../../state/selectors/analytics";
import analyticsService from "../../../../../../../../../services/server/analyticsService/analyticsService";

const mapStateToProps = createStructuredSelector({
  analyticsListProgress: makeSelectAnalyticsListProgress(),
  analyticsList: makeSelectAnalyticsList(),
  sortedAnalyticsList: makeSelectSortedAnalyticsList(),
  sortedAnalyticsListProgress: makeSelectAnalyticsListProgress(),
});

const CourseAnalytics = (props: any) => {
  const {
    sortedAnalyticsList,
    sortedAnalyticsListProgress,
    analyticsListProgress,
    analyticsList,
  } = props;

  const [filterType, setFilterType] = useState({
    type: "Learning time",
    id: -1,
  });
  const [filterCourse, setFilterCourse] = useState({
    course: "All training",
    id: -1,
  });
  const [filterModule, setFilterModule] = useState({
    module: "All modules",
    id: -1,
  });
  const [filterLearners, setFilterLearners] = useState({ learner: "", id: -1 });
  const [renderedAnalytics, setRenderedAnalytics] = useState([]);
  const [renderedProgress, setRenderedProgress] = useState([]);

  useEffect(() => {
    const filteredAnalyticsList = analyticsList?.filter((item: any) => {
      if (filterType.type === "Performance") {
        return item.hasOwnProperty("performance");
      }
      if (filterType.type === "Quiz result") {
        return item.hasOwnProperty("quizResult");
      }
      if (filterType.type === "Learning time") {
        return item.hasOwnProperty("learningTime");
      }
    });

    setRenderedAnalytics(filteredAnalyticsList);
  }, [filterType, analyticsList]);

  useEffect(() => {
    setRenderedProgress(analyticsListProgress);
  }, [analyticsListProgress]);

  useEffect(() => {
    filteredCourse(filterCourse.id);
    filteredProgressCourse(filterCourse.id);
  }, [filterCourse]);

  useEffect(() => {
    filteredLearners(filterLearners.id);
    filteredProgressLearners(filterLearners.id);
  }, [filterLearners]);

  const onClickType = (type: any, id: number) => {
    setFilterType({ type: type, id: id });
  };

  const onClickCourse = async (course: any, id: number) => {
    setFilterCourse({ course: course, id: id });
  };

  const onClickModule = async (module: any, id: number) => {
    setFilterModule({ module: module, id: id });
    await filteredModules(filterCourse.id, id);
  };

  const onClickLearners = async (learner: any, id: number) => {
    setFilterLearners({ learner: learner, id: id });
  };

  const filteredLearners = async (id: number) => {
    const updatedAnalyticsList = sortedAnalyticsList.filter(
      (analyticsItem: any) => {
        if (analyticsItem.learner.id === Number(id) && Number(id) !== -1) {
          return analyticsItem;
        }
        if (Number(id) === -1) {
          return analyticsItem;
        }
      }
    );

    dispatch(setAnalyticsList(updatedAnalyticsList));
  };

  const filteredProgressLearners = async (id: number) => {
    if (Number(id) === -1) {
      await analyticsService.getProgress(filterCourse.id);
    } else {
      const updatedProgressAnalytics = sortedAnalyticsListProgress.filter(
        (progressItem: any) => {
          if (progressItem.learner.id === Number(id) && Number(id) !== -1) {
            return progressItem;
          }
        }
      );

      setRenderedProgress(updatedProgressAnalytics);
    }
  };

  const filteredCourse = async (id: number) => {
    await analyticsService.getFilteredCourses(id);
    setFilterLearners({ learner: "", id: -1 });
  };

  const filteredProgressCourse = async (id: number) => {
    await analyticsService.getProgress(id);
    setFilterLearners({ learner: "", id: -1 });
  };

  const filteredModules = async (courseId: number, moduleId: number) => {
    await analyticsService.getFilteredCoursesAndModules(courseId, moduleId);
    setFilterLearners({ learner: "", id: -1 });
  };

  const setModuleFilter = (filterName: string, filterId: number) => {
    setFilterModule({ module: filterName, id: filterId });
  };

  const setTypeFilter = (typeName: string, typeId: number) => {
    setFilterType({ type: typeName, id: typeId });
  };

  const onResetFiltersClick = () => {
    setFilterType({ type: "Learning time", id: -1 });
    setFilterCourse({ course: "All training", id: -1 });
    setFilterModule({ module: "All modules", id: -1 });
    setFilterLearners({ learner: "", id: -1 });
  };

  return (
    <div>
      <div className={styles.TitleCourses}>Training Analytics</div>
      <div className={styles.FilterWrapper}>
        <TypeFilterComponent
          classNameWrapperComponent={styles.FilterComponentWrapper}
          classNameDropDownContainer={styles.DropDownContainer}
          classNameOpenDropDown={styles.isOpenDropDown}
          classNameDropDownMenu={styles.DropDownMenuContainer}
          classNameDropDownOverflow={styles.DropDownOverflow}
          classNameTitle={styles.TitleFilter}
          classNameOptionsWrapper={styles.OptionsWrapper}
          onClickType={onClickType}
          filterType={filterType}
          filterCourse={filterCourse}
        />
        <CourseFilterComponent
          classNameWrapperComponent={styles.FilterComponentWrapper}
          classNameDropDownContainer={styles.DropDownContainer}
          classNameOpenDropDown={styles.isOpenDropDown}
          classNameTitle={styles.TitleFilter}
          classNameDropDownMenu={styles.DropDownMenuContainer}
          classNameDropDownOverflow={styles.DropDownOverflow}
          classNameOptionsWrapper={styles.OptionsWrapper}
          onClickCourse={onClickCourse}
          filterCourse={filterCourse}
          filterType={filterType}
          setFilterModule={setModuleFilter}
          setTypeFilter={setTypeFilter}
        />
        <ModuleFilterComponent
          classNameWrapperComponent={styles.FilterComponentWrapper}
          classNameDropDownContainer={classNames(
            styles.DropDownContainer,
            styles.DropDownContainerModule
          )}
          classNameOpenDropDown={styles.isOpenDropDown}
          classNameTitle={styles.TitleFilter}
          onClickModule={onClickModule}
          classNameDropDownMenu={classNames(
            styles.DropDownMenuContainer,
            styles.DropDownMenuContainerPeriodModule
          )}
          classNameDropDownOverflow={styles.DropDownOverflow}
          classNameOptionsWrapper={styles.OptionsWrapper}
          filterModule={filterModule}
          filterCourse={filterCourse}
          filterType={filterType}
        />
        <LearnerFilterComponent
          classNameWrapperComponent={styles.FilterComponentWrapper}
          classNameDropDownContainer={styles.DropDownContainer}
          classNameOpenDropDown={styles.isOpenDropDown}
          classNameTitle={styles.TitleFilter}
          classNameDropDownMenu={classNames(
            styles.DropDownMenuContainer,
            styles.DropDownMenuContainerLearner
          )}
          classNameDropDownOverflow={styles.DropDownOverflow}
          classNameOptionsWrapper={styles.OptionsWrapper}
          onClickLearners={onClickLearners}
          filterLearners={filterLearners}
          filterModule={filterModule}
          filterCourse={filterCourse}
          filterType={filterType}
        />
        <ButtonColor
          className={styles.ResetFilters}
          onClick={onResetFiltersClick}
        >
          Reset filters
        </ButtonColor>
      </div>
      <div>
        {renderedAnalytics.length ||
        (renderedProgress.length && filterType.type === "Course progress") ? (
          <ListLearners
            filterType={filterType}
            filterCourse={filterCourse}
            renderedAnalytics={renderedAnalytics}
            renderedProgress={renderedProgress}
          />
        ) : (
          <div className={styles.NoData}>No data found</div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(CourseAnalytics);

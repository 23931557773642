import React from 'react';
import styles from './ModalContainer.module.scss';
import Svg from '../../../../../../../../../../../components/SvgComponent/SvgComponent';
import {ReactComponent as DeleteImage} from '../../../../../../../../../../../../assets/images/icon/delete-course-icon.svg';

const ModalContainer = (props:any) => {
    const {modalRef, openDeletePopup} = props;



    return (
        <div className={styles.Modal} ref={modalRef}>
            <div className={styles.Delete} onClick={openDeletePopup}>
                <Svg className={styles.DeleteImage}>
                    <DeleteImage/>
                </Svg>
                Delete
            </div>
        </div>
    );
};

export default ModalContainer;

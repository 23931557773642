import React from 'react';
import Image from '../Image/Image';
import defaultLogo from '../../../assets/images/logo/stand-with-ua.svg';

interface Logo {
    className?: string;
    onClick?: VoidFunction;
}

const Logo = ({className, onClick}: Logo) => {
    return (
        <Image src={defaultLogo} className={className} onClick={onClick}/>
    );
};

export default Logo;

import classNames from 'classnames';
import React from 'react';

import styles from './Line.module.scss';

const Line = (props:any) => {
    const {className} = props;
    const classNameLine = classNames(styles.Line, className);
    
    return (
        <div className={classNameLine} />
    );
};

export default Line;
import {TeamStorage} from '../interfaces/teamIterface';
import {TeamMember} from '../../types/TeamMember';
import {Filter} from '../../types/filter/Filter';

export const SET_IS_EMPTY_TEAM_MEMBERS_DATA = 'app/team/SET_IS_EMPTY_TEAM_MEMBERS_DATA';
export const SET_LIST_OF_TEAM_MEMBERS = 'app/team/SET_LIST_OF_TEAM_MEMBERS';
export const SET_SORTED_LIST_OF_TEAM_MEMBERS = 'app/team/SET_SORTED_LIST_OF_TEAM_MEMBERS';
export const SET_FILTERS_TEAM_NAME = 'app/team/SET_FILTERS_TEAM_NAME';
export const SET_SORTED_FILTERS_TEAM_NAME = 'app/team/SET_SORTED_FILTERS_TEAM_NAME';
export const SET_FILTERS_TEAM_EMAIL = 'app/team/SET_FILTERS_TEAM_EMAIL';

const initialState:TeamStorage = {
    isEmptyTeamMembersData: true,
    listOfTeamMembers: [],
    sortedListOfMembers: [],
    filtersName: [],
    sortedFiltersName: [],
    filtersEmail: []
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_IS_EMPTY_TEAM_MEMBERS_DATA: {
            return {...state, isEmptyTeamMembersData: action.payload};
        }
        case SET_LIST_OF_TEAM_MEMBERS: {
            return {...state, listOfTeamMembers: action.payload};
        }
        case SET_SORTED_LIST_OF_TEAM_MEMBERS: {
            return {...state, sortedListOfMembers: action.payload};
        }
        case SET_FILTERS_TEAM_NAME: {
            return {...state, filtersName: action.payload}
        }
        case SET_SORTED_FILTERS_TEAM_NAME: {
            return {...state, sortedFiltersName: action.payload}
        }
        case SET_FILTERS_TEAM_EMAIL: {
            return {...state, filtersEmail: action.payload}
        }
        default: return state;
    }
}

export const setIsEmptyTeamMemberData = (value:boolean) => ({type: SET_IS_EMPTY_TEAM_MEMBERS_DATA, payload: value});

export const setListOfTeamMembers = (value:TeamMember[]) => ({type: SET_LIST_OF_TEAM_MEMBERS, payload: value});

export const setSortedListOfTeamMembers = (value:TeamMember[]) => ({type: SET_SORTED_LIST_OF_TEAM_MEMBERS, payload: value});

export const setFiltersName = (value:Filter[]) => ({type: SET_FILTERS_TEAM_NAME, payload: value});

export const setSortedFiltersName = (value:Filter[]) => ({type: SET_SORTED_FILTERS_TEAM_NAME, payload: value});

export const setFiltersEmail = (value:Filter[]) => ({type: SET_FILTERS_TEAM_EMAIL, payload: value});

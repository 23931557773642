import React from 'react';

import styles from './SubscriptionPopup.module.scss';
import YourPlanFreeDetailsComponent
    from '../../ConsumerAccountPage/AdminAccountPage/components/ContentComponent/components/SubscriptionComponent/components/CurrentPlanFree/YourPlanFreeDetailsComponent/YourPlanFreeDetailsComponent';
import UpgradeToPro
    from '../../ConsumerAccountPage/AdminAccountPage/components/ContentComponent/components/SubscriptionComponent/components/CurrentPlanFree/UpgradeToPro/UpgradeToPro';
import GetExtrasProComponent
    from '../../ConsumerAccountPage/AdminAccountPage/components/ContentComponent/components/SubscriptionComponent/components/CurrentPlanFree/GetExtrasProComponent/GetExtrasProComponent';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsVisibleSubscriptionPopup} from '../../../state/selectors/global';
import {connect} from 'react-redux';
import {dispatch} from '../../../state/store';
import {setIsVisibleSubscriptionPopup} from '../../../state/ducks/global';
import {makeSelectSeatsInfo} from '../../../state/selectors/subscription';

const mapStateToProps = createStructuredSelector({
    isVisibleSubscriptionPopup: makeSelectIsVisibleSubscriptionPopup(),
    seatsInfo: makeSelectSeatsInfo()
});

const SubscriptionPopup = (props:any) => {
    const {isVisibleSubscriptionPopup, seatsInfo} = props;

    const onClosePopup = () => {
        dispatch(setIsVisibleSubscriptionPopup(false));
    };

    return (
        isVisibleSubscriptionPopup && (
            <PopupFullPage
                classNameMainContainer={styles.MainContainer}
                closePopup={onClosePopup}
                isSupportOutsidePopupClick
                classNameClose={styles.CloseButton}
            >
                <div className={styles.WrapperCurrentPlanFree}>
                    <div className={styles.ContainerForFreeAndPro}>
                        <YourPlanFreeDetailsComponent
                            freeSeatsNumber={seatsInfo?.freeSeatsNumber}
                        />
                        <UpgradeToPro />
                    </div>
                    <GetExtrasProComponent />
                </div>
            </PopupFullPage>
        )
    );
};

export default connect(mapStateToProps)(SubscriptionPopup);

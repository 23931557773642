import React, {useState, useRef, useEffect} from 'react';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';

import styles from './TypeFilterComponent.module.scss';
import topDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg';
import bottomDropDownImg from '../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg';
import Image from '../../../../../../../../../components/Image/Image';
import OptionsComponent
    from '../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectFiltersType} from '../../../../../../../../../state/selectors/reports';
import reportsService from '../../../../../../../../../services/server/reportsService/reportsService';
import {useOutsideListener} from "../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
    filterTypeList: makeSelectFiltersType(),
});

const TypeFilterComponent = (props:any) => {
    const {classNameContainer, classNameDropDown, classNameDropDownMenu,
        classNameDropDownOverflow , filterTypeList, onTypeFilterClick, typeFilter} = props;

    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [dropDownValue, setDropDownValue] = useState(typeFilter.name);
    const [loader, setLoader] = useState(false);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectButtonRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setDropDownValue(typeFilter.name);
    },[typeFilter]);

    const openDropDownList = async () => {
        setIsOpenDropDown(!isOpenDropDown);
        await reportsService.getFilterReportsType();
        setLoader(true);
    };

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    const onTypeFilterButtonClick = (filterTypeName:string, filterTypeId:number) => {
        setDropDownValue(filterTypeName);
        onTypeFilterClick(filterTypeName, filterTypeId);
        closeDropDownList();
    };

    return (
        <div className={classNameContainer}>
            <span className={styles.TitleType}>Type</span>
            <div className={classNames(classNameDropDown, isOpenDropDown && styles.ActiveDropDown)}
                 onClick={openDropDownList} ref={selectButtonRef}>
                 <span className={styles.DropDownText}>{isOpenDropDown ? 'Select' : dropDownValue}</span>
                <Image src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
                   className={classNames(styles.DropDownIcon, isOpenDropDown && styles.isActiveDropDownIcon)}
                />
            </div>
            {isOpenDropDown && (
                <div className={classNameDropDownMenu} ref={dropDownRef}>
                    <div className={classNameDropDownOverflow}>
                        {loader ?
                            filterTypeList?.map((item:any) => {
                            const filterTypeName = item.name;
                            const filterTypeId = item.id;
                            const isActiveOptions = item.id === typeFilter.id;
                            const classNameType = filterTypeName === 'Task report' ? styles.TaskReport :
                                filterTypeName === 'Passed quiz' ? styles.PassedQuiz :
                                    filterTypeName === 'Quiz report' ? styles.QuizReport :
                                        filterTypeName === 'Failed quiz' ? styles.FailedQuiz :
                                            filterTypeName === 'Essay report' ? styles.TaskReport  : ''

                            return (
                                <OptionsComponent
                                    className={classNames(classNameType, styles.OptionsText)}
                                    classNameWraperOptions={styles.OptionsWrapper}
                                    isActiveOptions={isActiveOptions}
                                    reference={optionsRef}
                                    key={filterTypeId}
                                    optionText={filterTypeName}
                                    onClick={() => onTypeFilterButtonClick(filterTypeName, filterTypeId)}
                                />
                            )
                        }): <div className={styles.WrapperForLoader}>
                                <Loader
                                    type="TailSpin"
                                    color="#F85A40"
                                    height={25}
                                    width={25}
                                />
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps)(TypeFilterComponent);
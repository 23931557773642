import React, {useRef, useState} from 'react';
import classNames from 'classnames';

import styles from './MetricsItemComponent.module.scss'
import ProgressBar from '../ProgressBar/ProgressBar';
import AverageProgressBar from '../AverageProgressBar/AverageProgressBar';
import Svg from '../../../../../../../../../../../../../components/SvgComponent/SvgComponent';
import ActionComponent from '../ActionComponent/ActionComponent';
import {ReactComponent as ArrowBottom} from '../../../../../../../../../../../../../../assets/images/icon/arrow-bottom.svg';
import {ReactComponent as ArrowTop} from '../../../../../../../../../../../../../../assets/images/icon/arrow-top.svg';
import {MetricsListForDashboard} from '../../../../../../../../../../../../../types/performanceType/performanceType';
import TeamResultsComponent from './components/TeamResultsComponent/TeamResultsComponent';
import HeaderMetricsComponent from './components/HeaderMetricsComponent/HeaderMetricsComponent';
import ReviewCourseComponent from './components/ReviewCourseComponent/ReviewCourseComponent';
import {PERSONAL} from "../../../../../../../../../../../../../types/performanceReportsType/PerformanceMetricType";
import {useOutsideListener} from "../../../../../../../../../../../../../hooks/useOutsideListener";
import {OverviewType} from "../../../../../../../../../../../../../types/performanceReportsType/PerformanceOverview";

type MetricsItemComponentProps = {
    metricsListForDashboard: Array<MetricsListForDashboard>
    learnerName: string
    learnerId: number
};

const MetricsItemComponent:React.FC<MetricsItemComponentProps> = ({metricsListForDashboard,
    learnerName, learnerId}) => {
    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [metricId, setMetricId] = useState<string>('');
    const [isShowReviewPopUp, setIsShowReviewPopUp] = useState(false);
    const [courseName, setCourseName] = useState('');
    const [courseId, setCourseId] = useState(0);

    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectButtonRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const onDropDownButtonClick = (metricId:string) => {
        setIsOpenDropDown(!isOpenDropDown);
        setMetricId(metricId)
    };

    const onClickReview = (name:string, id:number) => {
        setIsOpenDropDown(false);
        setCourseName(name);
        setCourseId(id);
        setIsShowReviewPopUp(true);
    };

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);


    return (
        <div className={styles.Container}>
            <HeaderMetricsComponent />
            <div className={styles.SubContainer}>
                {metricsListForDashboard.map((item) => {
                    const metricKeyWord = item.metricKeyWord;
                    const metricName = item.metricName;
                    const averageResult = item.averageResult;
                    const worstResult = item.worstResult;
                    const personalResult = item.personalResult;
                    const bestResult = item.bestResult;
                    const comparisonResult = item.comparisonResult;
                    const metricComparisonType = item.metricComparisonType;
                    const betterPerformers = item.betterPerformers;
                    const isPercentage = item.metricSign === OverviewType.PERCENTAGE;

                    const isPersonalComparison = metricComparisonType === PERSONAL;
                    const isResultAbsoluteBest = !isPersonalComparison ? bestResult === personalResult && bestResult !== worstResult
                        : isPersonalComparison ? personalResult > comparisonResult
                        : false;
                    const isResultAbsoluteWorst = !isPersonalComparison ? personalResult === worstResult && bestResult !== worstResult
                        : isPersonalComparison ? personalResult < comparisonResult
                        : false;

                    const actionText = isResultAbsoluteBest ? 'Make it better' :
                        isResultAbsoluteWorst ? 'Need help' : 'Improve';
                    const actionClassNameImg = isResultAbsoluteBest ?
                        styles.MakeItImg : isResultAbsoluteWorst ? styles.NeedHelpImg : styles.ImproveImg;
                    const actionClassNameText = isResultAbsoluteBest ?
                        styles.ActionTextMake : isResultAbsoluteWorst ? styles.ActionTextNeedHelp : styles.ActionTextImprove;

                    return (
                        <div className={styles.WrapperMetric} key={metricKeyWord}>
                            <div className={styles.Name}>{metricName}</div>
                            <div className={styles.ProgressData}>
                                <div>
                                    <ProgressBar
                                        mainColor='#F85A40'
                                        personalResult={personalResult}
                                        bestValue={bestResult}
                                        comparisonResult={comparisonResult}
                                        worstResult={worstResult}
                                        betterPerformers={betterPerformers}
                                        isPercentage={isPercentage}
                                    />
                                    <AverageProgressBar
                                        bestValue={bestResult}
                                        averageValue={averageResult}
                                        backgroundColor={isPersonalComparison ? '#FFBAAE' :'#8C8C8C'}
                                        comparisonResult={comparisonResult}
                                        personalResult={personalResult}
                                        isPercentage={isPercentage}
                                    />
                                </div>
                            </div>
                            <div>
                                {!isPersonalComparison &&
                                    <TeamResultsComponent
                                        isPercentage={isPercentage}
                                        bestResult={bestResult}
                                        worstResult={worstResult}
                                    />
                                }
                            </div>
                            <div className={styles.ActionWrapper}>
                                <div
                                    className={styles.Action}
                                    onClick={() => onDropDownButtonClick(metricKeyWord)}
                                    ref={selectButtonRef}
                                >
                                    <div className={actionClassNameText}>{actionText}</div>
                                    <Svg className={classNames(actionClassNameImg, (isOpenDropDown && metricKeyWord === metricId) && styles.TopArrow)}>
                                        {(isOpenDropDown && metricKeyWord === metricId) ? <ArrowTop/> : <ArrowBottom/>}
                                    </Svg>
                                </div>
                                {(isOpenDropDown && metricKeyWord === metricId) && (
                                    <div ref={dropDownRef}>
                                        <ActionComponent
                                            learnerId={learnerId}
                                            learnerName={learnerName}
                                            setIsShowReviewPopUp={setIsShowReviewPopUp}
                                            onClickReview={onClickReview}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            {isShowReviewPopUp &&
                <div ref={modalRef}>
                    <ReviewCourseComponent
                        courseId={courseId}
                        learnerId={learnerId}
                        courseName={courseName}
                        learnerName={learnerName}
                        setIsShowReviewPopUp={setIsShowReviewPopUp}
                    />
                </div>
            }
        </div>
    );
};

export default MetricsItemComponent;

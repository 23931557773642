import React, {useEffect, useState} from 'react';

import styles from './QuizPopupComponent.module.scss';
import closePopupImg from '../../../../../../../../assets/images/icon/close-popup-icon.svg';
import settingsImg from '../../../../../../../../assets/images/icon/settings-quiz-icon.svg';
import QuestionsListComponent from './components/QuestionsListComponent/QuestionsListComponent';
import QuestionComponent from './components/QuestionComponent/QuestionComponent';
import QuizSettingsPopup from './components/QuizSettingsPopup/QuizSettingsPopup';
import AddNewQuestionComponent from './components/AddNewQuestionComponent/AddNewQuestionComponent';
import PopupFullPage from '../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import Image from '../../../../../../../components/Image/Image';
import DropDownTypesQuizComponent from './components/DropDownTypesQuizComponent/DropDownTypesQuizComponent';
import ButtonText from '../../../../../../../components/Button/components/ButtonText/ButtonText';
import {dispatch} from '../../../../../../../state/store';
import {connect} from 'react-redux';
import {setDraftModuleQuiz, setDraftQuizQuestion} from '../../../../../../../state/ducks/courseConstructor';
import {createStructuredSelector} from 'reselect';
import {
    makeSelectDraftModuleQuiz,
    makeSelectDraftQuizQuestion
} from '../../../../../../../state/selectors/courseConstructor';
import {typesQuestionList} from './typesQuestionList';
import draftModuleService from "../../draftModuleService";
import moduleService from '../../../../../../../services/server/moduleService/moduleService';

const mapStateToProps = createStructuredSelector({
    draftModuleQuiz: makeSelectDraftModuleQuiz(),
    draftQuizQuestion: makeSelectDraftQuizQuestion()
});

const QuizPopupComponent = (props:any) => {
    const {hideQuizPopup, draftModuleQuiz, draftQuizQuestion} = props;

    const [isVisibleDownList, setIsVisibleDownList] = useState(false);
    const [isVisibleSettingsPopup, setIsVisibleSettingsPopup] = useState(false);
    const [activeTypesQuestion, setActiveTypesQuestion] = useState(typesQuestionList[0]);

    useEffect(() => {
        const isEssayDraftQuizQuestion = draftQuizQuestion?.essayQuestion;
        
        setUpActiveTypesQuestion(isEssayDraftQuizQuestion);
    }, [draftQuizQuestion]);
    
    const onTypeQuizClick = async (questionTypeId:number, draftQuestionId:number) => {
        const isEssayTypeQuestion = questionTypeId === 1;
        
        const updatedQuestion = await moduleService.changeQuestionsType(draftQuestionId, isEssayTypeQuestion,
            !isEssayTypeQuestion);
        
        const updatedQuestionsList = draftModuleQuiz.questions.map((question:any) => {
            if(question.id === draftQuestionId) {
                return updatedQuestion;
            }
            
            return question;
        });

        const updatedQuiz = {...draftModuleQuiz, questions: updatedQuestionsList};

        dispatch(setDraftQuizQuestion(updatedQuestion));
        dispatch(setDraftModuleQuiz(updatedQuiz));
        draftModuleService.updateModuleQuiz(updatedQuiz);
    };
    
    const onSettingsButtonClick = () => {
        setIsVisibleSettingsPopup(true);
    };
    
    const hideSettingsPopup = () => {
        setIsVisibleSettingsPopup(false);
    };

    const createNewQuestionButtonClick = async (typeQuestionId:number) => {
        const newQuestion = await moduleService.addNewQuizQuestion(draftModuleQuiz.id, typeQuestionId);
        const updatedQuestionsList = draftModuleQuiz.questions.concat(newQuestion);
        const updatedQuiz = {...draftModuleQuiz, questions: updatedQuestionsList};

        dispatch(setDraftModuleQuiz(updatedQuiz));
        draftModuleService.updateModuleQuiz(updatedQuiz);

        setNewActiveQuestion(newQuestion);
    };
    
    const deleteQuestionButtonClick = async (questionId:number) => {
        if (draftModuleQuiz.questions.length > 1) {
            const updatedQuestionsList = draftModuleQuiz.questions.filter((question:any) => question.id !== questionId);
            const updatedQuiz = {...draftModuleQuiz, questions: updatedQuestionsList};

            dispatch(setDraftModuleQuiz(updatedQuiz));
            await moduleService.deleteQuizQuestionById(questionId);

            draftModuleService.updateModuleQuiz(updatedQuiz);

            if(questionId === draftQuizQuestion.id) {
                dispatch(setDraftQuizQuestion(updatedQuestionsList[0]));
                setUpActiveTypesQuestion(updatedQuestionsList[0]?.essayChoice);
            }
        }
    };

    const setNewActiveQuestion = (newActiveQuestion:any) => {
        const isEssayQuestion = newActiveQuestion?.essayQuestion;

        setUpActiveTypesQuestion(isEssayQuestion);
        dispatch(setDraftQuizQuestion(newActiveQuestion));
    };

    const onQuestionItemClick = (questionId:number) => {
        const newActiveQuestion = draftModuleQuiz.questions.find((question:any) => question.id === questionId);

        setNewActiveQuestion(newActiveQuestion);
    };
    
    const setUpActiveTypesQuestion = (isEssayQuestion:boolean) => {
        if (isEssayQuestion) {
            setActiveTypesQuestion(typesQuestionList[1]);
        } else {
            setActiveTypesQuestion(typesQuestionList[0]);
        }
    };
    
    return (
        <>
            <PopupFullPage
                closePopup={hideQuizPopup}
                classNameMainContainer={styles.MainPopupContainer}
                isVisibleCloseButton={false}
                isSupportOutsidePopupClick
            >
                <div className={styles.MainContentComponent}>
                    <div className={styles.QuizList}>
                        <div className={styles.QuizContainer}>
                            <QuestionsListComponent
                                draftModuleQuiz={draftModuleQuiz}
                                activeQuestion={draftQuizQuestion}
                                onQuestionItemClick={onQuestionItemClick}
                                deleteQuestionButtonClick={deleteQuestionButtonClick}
                            />
                            <div className={styles.AddNewQuestions}>
                                <AddNewQuestionComponent onItemClick={createNewQuestionButtonClick} />
                            </div>
                            <ButtonText className={styles.SettingsButton} onClick={onSettingsButtonClick}>
                                <Image src={settingsImg} className={styles.ButtonImg} />
                                <span className={styles.SettingsText}>Settings</span>
                            </ButtonText>
                        </div>
                    </div>
                    <div className={styles.MainBlock}>
                        <div className={styles.CloseContainer}>
                            <Image src={closePopupImg} className={styles.CloseButton} onClick={hideQuizPopup} />
                        </div>
                        <span className={styles.TitleText}>Сreate a Quiz</span>
                        <DropDownTypesQuizComponent
                            activeTypesQuestion={activeTypesQuestion}
                            isVisibleDownList={isVisibleDownList}
                            setIsVisibleDownList={setIsVisibleDownList}
                            onTypeQuizClick={onTypeQuizClick}
                            draftQuizQuestion={draftQuizQuestion}
                        />
                        <QuestionComponent />
                    </div>
                </div>
            </PopupFullPage>
            {isVisibleSettingsPopup && (
                <QuizSettingsPopup
                    hideSettingsPopup={hideSettingsPopup}
                />
            )}
        </>
    );
};

export default connect(mapStateToProps)(QuizPopupComponent);
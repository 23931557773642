import React, {useEffect, useState} from 'react';

import styles from './QuizSettingsPopup.module.scss';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import TimeLimitComponent from './components/TimeLimitComponent/TimeLimitComponent';
import PassingGradeComponent from './components/PassingGradeComponent/PassingGradeComponent';
import AttemptsComponent from './components/AttemptsComponent/AttemptsComponent';
import {dispatch} from '../../../../../../../../../state/store';
import {connect} from 'react-redux';
import {setDraftModuleQuiz} from '../../../../../../../../../state/ducks/courseConstructor';
import {makeSelectDraftModuleQuiz} from '../../../../../../../../../state/selectors/courseConstructor';
import {createStructuredSelector} from 'reselect';
import validationService from '../../../../../../../../../services/server/validationService/validationService';
import moduleService from '../../../../../../../../../services/server/moduleService/moduleService';
import draftModuleService from "../../../../draftModuleService";

const mapStateToProps = createStructuredSelector({
    draftModuleQuiz: makeSelectDraftModuleQuiz()
});

const QuizSettingsPopup = (props:any) => {
    const {hideSettingsPopup, draftModuleQuiz} = props;

    const [isActiveTimeLimit, setIsActiveTimeLimit] = useState(!!draftModuleQuiz.timer);
    const [isActivePassingGrade, setIsActivePassingGrade] = useState(!!draftModuleQuiz.passingGrade);
    const [isActiveAttempts, setIsActiveAttempts] = useState(!!draftModuleQuiz.attempts);
    const [timeLimit, setTimeLimit] = useState(draftModuleQuiz.timer);
    const [passingGrade, setPassingGrade] = useState(draftModuleQuiz.passingGrade);
    const [attempts, setAttempts] = useState(draftModuleQuiz.attempts);

    useEffect(() => {
        const updatedDraftModuleQuiz = {...draftModuleQuiz, timer: timeLimit, passingGrade: passingGrade,
            attempts: attempts};
        
        dispatch(setDraftModuleQuiz(updatedDraftModuleQuiz));
        draftModuleService.updateModuleQuiz(updatedDraftModuleQuiz);
    }, [timeLimit, passingGrade, attempts]);
    
    const changeActiveTimeLimit = async () => {
        setIsActiveTimeLimit(!isActiveTimeLimit);
        
        if(!isActiveTimeLimit === false) {
            setTimeLimit(null);
            await moduleService.updateQuizSettings(draftModuleQuiz.id, 'timer', null);
        } else {
            setTimeLimit(null);
            await moduleService.updateQuizSettings(draftModuleQuiz.id, 'timer', 5);
        }
    };
    
    const changeActivePassingGrade = async () => {
        setIsActivePassingGrade(!isActivePassingGrade);
        
        if(!isActivePassingGrade === false) {
            setIsActiveAttempts(false);
            setPassingGrade(null);
            setAttempts(null);
            await moduleService.updateQuizSettings(draftModuleQuiz.id, 'passing_grade', null);
            await moduleService.updateQuizSettings(draftModuleQuiz.id, 'attempts', null);
        } else {
            setPassingGrade(80);
            await moduleService.updateQuizSettings(draftModuleQuiz.id, 'passing_grade', 80);
        }
    };
    
    const changeActiveAttempts = async () => {
        setIsActiveAttempts(!isActiveAttempts);
    
        if(!isActiveAttempts === false) {
            setAttempts(null);
            await moduleService.updateQuizSettings(draftModuleQuiz.id, 'attempts', null);
        } else {
            setAttempts(null);
            await moduleService.updateQuizSettings(draftModuleQuiz.id, 'attempts', 3);
        }
    };
    
    const timeLimitChange = async (value:any) => {
        const updatedValue = validationService.roundInputValue(value.currentTarget.value, 180);
        setTimeLimit(updatedValue);
        await moduleService.updateQuizSettings(draftModuleQuiz.id, 'timer', updatedValue || null);
    };
    
    const passingGradeChange = async (value:any) => {
        const updatedValue = validationService.roundInputValue(value.currentTarget.value, 100);
        setPassingGrade(updatedValue);
        await moduleService.updateQuizSettings(draftModuleQuiz.id, 'passing_grade', updatedValue || null);
    };
    
    const attemptsChange = async (value:any) => {
        const updatedValue = validationService.roundInputValue(value.currentTarget.value, 100);
        setAttempts(updatedValue);
        await moduleService.updateQuizSettings(draftModuleQuiz.id, 'attempts', updatedValue || null);
    };
    
    return (
        <PopupFullPage
            closePopup={hideSettingsPopup}
            classNameMainContainer={styles.SettingsPopupContainer}
            isSupportOutsidePopupClick
        >
            <div className={styles.MainContainer}>
                <span className={styles.PopupTitle}>Settings</span>
                <TimeLimitComponent
                    timeLimit={timeLimit}
                    timeLimitChange={timeLimitChange}
                    classNameSliderTitleText={styles.SliderTitleText}
                    classNameInputField={`${styles.InputField} ${!isActiveTimeLimit && styles.DisableInputField}`}
                    isActiveTimeLimit={isActiveTimeLimit}
                    changeActiveTimeLimit={changeActiveTimeLimit}
                />
                <PassingGradeComponent
                    passingGrade={passingGrade}
                    passingGradeChange={passingGradeChange}
                    classNameSliderTitleText={styles.SliderTitleText}
                    classNameInputField={`${styles.InputField} ${!isActivePassingGrade && styles.DisableInputField}`}
                    isActivePassingGrade={isActivePassingGrade}
                    changeActivePassingGrade={changeActivePassingGrade}
                />
                <AttemptsComponent
                    attemptsChange={attemptsChange}
                    attempts={attempts}
                    classNameSliderTitleText={styles.SliderTitleText}
                    isActivePassingGrade={isActivePassingGrade}
                    classNameInputField={`${styles.InputField} ${!isActiveAttempts && styles.DisableInputField}`}
                    isActiveAttempts={isActiveAttempts}
                    changeActiveAttempts={changeActiveAttempts}
                />
            </div>
        </PopupFullPage>
    );
};

export default connect(mapStateToProps)(QuizSettingsPopup);
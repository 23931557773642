import classNames from "classnames";
import React, { useState } from "react";

import styles from "./CourseNameComponent.module.scss";
import introducedStepImg from "../../../../../../../../../../../../assets/images/icon/create-course-introduced-step-icon.svg";
import newStepImg from "../../../../../../../../../../../../assets/images/icon/create-course-new-step-icon.svg";
import Image from "../../../../../../../../../../../components/Image/Image";
import Input from "../../../../../../../../../../../components/Input/Input";
import ButtonColor from "../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import validationService from "../../../../../../../../../../../services/server/validationService/validationService";

const CourseNameComponent = (props: any) => {
    const {courseName, isNameIntroduced, onNameChange, onNextStepButtonClick, stepIconClassName,
        classNameLabelText} = props;

    const [count, setCount] = useState(courseName.length || 0);
    const [isNameError, setIsNameError] = useState(false);
    const classNameCharacterCount = classNames(
        styles.CharactersCount,
        isNameError && styles.ErrorCharactersCount,
        isNameIntroduced && styles.IntroducedName
    );
    const classNameInputContainer = classNames(styles.InputContainer, isNameIntroduced && styles.IntroducedContainer);

    const onKeyPressNameCourse = (event:any) => {
        if (event.code === "Enter") {
            onNextStepClick()
        }
    };

    const onInputFieldChange = (event: React.FormEvent<HTMLInputElement>) => {
        const isValidCourseName = validationService.isValidName(event.currentTarget.value);
        setCount(event.currentTarget.value.length);
        onNameChange(event.currentTarget.value);
        setIsNameError(!isValidCourseName);
    };
  
    const onNextStepClick = () => {
        const isValidCourseName = validationService.isValidName(courseName);
    
        onNextStepButtonClick(isValidCourseName);
        setIsNameError(!isValidCourseName);
    };
  
    return (
        <div className={styles.NameContainer}>
            <span className={styles.StepText}>1/3</span>
            <div className={styles.ImageContainer}>
                <Image
                  src={isNameIntroduced ? introducedStepImg : newStepImg}
                  className={stepIconClassName}
                />
                <div className={styles.Line} />
            </div>
            <div className={classNameInputContainer}>
                <span className={classNameLabelText}>Give it a name</span>
                <Input
                    autoFocus
                    type="text"
                    maxLength={60}
                    className={`${styles.InputField} ${isNameError && styles.ErrorInput}`}
                    onChange={onInputFieldChange}
                    value={courseName}
                    onKeyPress={onKeyPressNameCourse}
                    isDisabledInput={isNameIntroduced}
                />
                <div className={classNameCharacterCount}>{count}/60</div>
                {!isNameIntroduced && (
                    <ButtonColor
                      className={styles.NextStepButton}
                      onClick={onNextStepClick}
                    >
                      Next step
                    </ButtonColor>
                )}
            </div>
        </div>
    );
};

export default CourseNameComponent;

import classNames from 'classnames';
import React from 'react';

import Button from '../../../Button/Button';
import styles from './ButtonText.module.scss';

const ButtonText = (props:any) => {
    const {className, disabled} = props;
    const btnClassName = classNames(className, styles.Button);

    return (
        <Button 
            className={btnClassName}
            disabled={disabled}
            {...props}
        />
    );
};

export default ButtonText;
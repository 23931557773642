import classNames from 'classnames';
import React from 'react';

import styles from './Button.module.scss';

const Button = (props:any) => {
    const {className, onClick, children, style, reference, disabled} = props;
    const btnClassName = classNames(className, styles.Button);

    return (
        <button
            ref={reference}
            disabled={disabled}
            className={btnClassName}
            onClick={onClick}
            style={style}
        >
            {children}
        </button>
    );
};

export default Button;
import urlJoin from 'url-join';

const join = (...params:Array<string>) => urlJoin(params);

const getUrlParams = (paramNames:string[]) : (string | null)[] => {
    const urlParams = (new URL(document.location.href)).searchParams;
    return paramNames.map((param:string) => urlParams.get(param));
}

export default {
    join,
    getUrlParams
}
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import styles from './ProcessingChangePopup.module.scss';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import Loader from '../../../../../../../../../components/Loader/Loader';
import Image from '../../../../../../../../../components/Image/Image';
import ButtonColor from '../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import checkImg from '../../../../../../../../../../assets/images/icon/check-mark-white.svg';
import crossImg from '../../../../../../../../../../assets/images/icon/cross-icon.svg';
import subscriptionService from '../../../../../../../../../services/server/subscriptionService/subscriptionService';
import {dispatch} from '../../../../../../../../../state/store';
import {connect} from 'react-redux';
import {setPaymentInfo} from '../../../../../../../../../state/ducks/subscription';
import {createStructuredSelector} from 'reselect';
import {makeSelectResponseChangeCard} from '../../../../../../../../../state/selectors/subscription';
import ButtonText from '../../../../../../../../../components/Button/components/ButtonText/ButtonText';

const mapStateToProps = createStructuredSelector({
    responseChangeCard: makeSelectResponseChangeCard()
});

const ProcessingChangePopup = (props:any) => {
    const {setIsVisibleProcessingChangeCard, processingChangeCard, setIsVisibleChangeCardNumberPopup,
        responseChangeCard, errorChangeCard, setErrorChangeCard} = props;

    const [errorDetails, setErrorDetails] = useState(errorChangeCard || '');

    useEffect(() => {
        setErrorDetails(errorChangeCard);
    },[errorChangeCard]);

    useEffect(() => {
        if(!responseChangeCard?.error) {
            setVisiblePopups();
        }
    }, [responseChangeCard]);

    const hideProcessingPopup = () => {
        setIsVisibleProcessingChangeCard(false);
    };

    const onClickCardChangePopup = () => {
        setErrorChangeCard('');
        setIsVisibleProcessingChangeCard(false);
        setIsVisibleChangeCardNumberPopup(true);
    };

    const setVisiblePopups = async () => {
        const newPaymentInfo = await subscriptionService.getPaymentInfo();
        dispatch(setPaymentInfo(newPaymentInfo));
    };

    return (
        <PopupFullPage
            classNameMainContainer={classNames(styles.MainPopupContainer, processingChangeCard && styles.WrapperProcessing)}
            isSupportOutsidePopupClick
            closePopup={hideProcessingPopup}
            classNameClose={styles.CloseImg}
        >

            {processingChangeCard ?
                <div>
                    <Loader
                        classNameMainContainer={styles.MainContainer}
                    />
                </div> :
                responseChangeCard.error || errorDetails ?
                    <>
                        <div className={styles.ContainerError}>
                            <div className={styles.TitleError}>Card change failed</div>
                            <div className={classNames(styles.ImgSuccessful, styles.ImgError)}>
                                <Image src={crossImg} className={styles.ErrorImg}/>
                            </div>
                        </div>
                        {errorDetails && <div className={styles.ErrorMessage}>{errorDetails?.message}</div>}
                        <ButtonColor
                            onClick={onClickCardChangePopup}
                            className={styles.BtnTryAgain}
                        >
                            Try again
                        </ButtonColor>
                        <ButtonText className={styles.SkipBtn} onClick={hideProcessingPopup}>
                            Skip
                        </ButtonText>
                    </>
                    :
                    <>
                        <div className={styles.ContainerSuccessful}>
                            <div className={styles.TitleSuccessful}>Card change successful</div>
                            <div className={styles.ImgSuccessful}>
                                <Image  src={checkImg}/>
                            </div>
                        </div>
                        <ButtonColor className={styles.BtnContinue} onClick={hideProcessingPopup}>
                            Continue
                        </ButtonColor>
                    </>
            }
        </PopupFullPage>
    );
};

export default connect(mapStateToProps)(ProcessingChangePopup);
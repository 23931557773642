import React from 'react';

import styles from './CustomBrandingComponent.module.scss';
import NewLogoComponent from './components/NewLogoComponent/NewLogoComponent';
import NewBrandColorComponent from './components/NewBrandColorComponent/NewBrandColorComponent';
import {dispatch} from '../../../../../../../state/store';
import {setBrandColor} from '../../../../../../../state/ducks/customBranding';
import customBrandingService from '../../../../../../../services/server/customBrandigService/customBrandingService';

const CustomBrandingComponent = () => {
    let currentColor:string;

    const setColor = (color:string) => {
        currentColor = color;
    };

    const resetBrandingColor = async () => {
        await dispatch(setBrandColor(currentColor));
        await customBrandingService.setBrandingColor(currentColor);
    };

    return (
        <div className={styles.CustomBrandContainer}>
            <NewLogoComponent />
            <NewBrandColorComponent setColor={setColor} resetBrandingColor={resetBrandingColor} />
        </div>
    );
};

export default CustomBrandingComponent;
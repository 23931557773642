import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import {withCookies} from 'react-cookie';

import styles from './AdminAccountPage.module.scss';
import signOutImg from '../../../../assets/images/icon/sign-out-icon.svg';
import smileImg from '../../../../assets/images/icon/smile-icon.svg';
import ContentComponent from './components/ContentComponent/ContentComponent';
import MenuListComponent from './components/MenuListComponent/MenuListComponent';
import Image from '../../../components/Image/Image';
import ButtonText from '../../../components/Button/components/ButtonText/ButtonText';
import {availableTabList, TabListIndex} from './components/MenuListComponent/menuTabList';
import { availableSettingsList } from './components/SettingsListComponent/settingsTabList';
import appRouterService from '../../../services/route/appRouterService';
import localStorageService from '../../../services/storage/localStorageService';
import {dispatch} from '../../../state/store';
import {
  setIsVisibleCourseList,
  setIsVisibleCreateCourseComponent,
  setIsVisibleCreateTemplates
} from '../../../state/ducks/admin';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsLegacyUser} from '../../../state/selectors/subscription';
import {connect} from 'react-redux';
import {setActiveTab, setIsVisibleSubscriptionPopup} from '../../../state/ducks/global';
import ButtonColor from '../../../components/Button/components/ButtonColor/ButtonColor';
import Restricted from '../../../components/Restricted/Restricted';
import {Role} from '../../../types/UserDetails';
import {makeSelectUserDetails} from '../../../state/selectors/userDetails';
import {AdminMenuTab, SettingsMenuTab} from "../../../types/tab/Tab";
import {makeSelectActiveTab} from '../../../state/selectors/global';
import gtmService from "../../../services/dataTrigger/gtmService";
import CompanyDetails from "../../SignUpPage/components/CompanyDetails/CompanyDetails";
import Logo from '../../../components/Logo/Logo';

const mapStateToProps = () => createStructuredSelector({
  isLegacyUser: makeSelectIsLegacyUser(),
  userDetails: makeSelectUserDetails(),
  activeTab: makeSelectActiveTab()
});

const AdminAccountPage = (props:any) => {
  const {isLegacyUser, userDetails, activeTab, cookies} = props;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });
  const [isIntroNeeded, setIsIntroNeeded] = useState(userDetails.isIntroNeeded);

  useEffect(() => {

    setIsIntroNeeded(userDetails.isIntroNeeded)
  }, [userDetails]);


  useEffect(() => {
    if(cookies.cookies['activeTab']) {
      dispatch(setActiveTab(JSON.parse((cookies.cookies['activeTab']))));
    }
  }, []);

  const changeTab = (activeTabId: TabListIndex) => {
    const activeTab : AdminMenuTab | SettingsMenuTab | undefined =
        availableTabList.find((item) => item.id === activeTabId) ||
        availableSettingsList.find((item) => item.id === activeTabId);
        cookies.set('activeTab', activeTab);
    if (!!activeTab) {
      dispatch(setActiveTab(activeTab));
      gtmService.addTabClickEvent(activeTab.name);

      resetCourseView();
    }
  }

  // const onTabButtonClick = (activeTabId: number) => {
  //   const isUpgradePopUpVisible = activeTabId === TabListIndex.Team && !isSubscribed && !isLegacyUser;
  //
  //   if (isUpgradePopUpVisible) {
  //     dispatch(setIsVisibleSubscriptionPopup(true));
  //   } else {
  //     changeTab(activeTabId);
  //   }
  // };

  const onSignOutClick = () => {
    localStorageService.clearAccessToken();
    localStorageService.clearRefreshToken();
    cookies.remove('template-plash');
    appRouterService.forwardToAdminLoginPage();
  };

  const onLogoClick = () => {
    resetCourseView();
    window.location.reload();
  };

  const resetCourseView = () => {
    dispatch(setIsVisibleCourseList(true));
    dispatch(setIsVisibleCreateTemplates(false));
    dispatch(setIsVisibleCreateCourseComponent(false));
  };

  const onUpgradeButton = (event:React.ChangeEvent) => {
    if (activeTab.id !== TabListIndex.Subscription) {
      dispatch(setIsVisibleSubscriptionPopup(true));
    }
  };

  return (
    <>
      {!isIntroNeeded &&
        <div className={styles.LearnerAccountPage}>
          {!isTabletOrMobile &&
          <>
            <div className={styles.MenuContainer}>
              <div className={styles.LogoContainer} onClick={onLogoClick}>
                <Logo className={styles.LogoImg}/>
              </div>
              <MenuListComponent
                  activeTab={activeTab}
                  onTabButtonClick={changeTab}
              />
              <div className={styles.SignOutContainer}>
                <div
                    className={styles.SignOutButtonContainer}
                    onClick={onSignOutClick}
                >
                  <Image src={signOutImg} className={styles.SignOutIcon}/>
                  <ButtonText className={styles.SignOutButton}>Sign out</ButtonText>
                </div>
              </div>
              {!isLegacyUser &&
              <Restricted
                  userRoles={userDetails?.roles}
                  allowedTo={[Role.CREATOR]}
              >
                <ButtonColor className={styles.UpgradeButton} onClick={onUpgradeButton}>
                  Upgrade
                </ButtonColor>
              </Restricted>
              }
            </div>
            <div className={styles.MainContainer}>
              <ContentComponent activeTab={activeTab} />
            </div>
          </>
          }
          {isTabletOrMobile &&
          <div className={styles.NotAdaptiveWrapper}>
            <Logo className={styles.LogoImage} />
            <div className={styles.NotAdaptive}>
        <span className={styles.TitleNotAdaptive}>
          Please switch to full screen mode or use Gradual with desktop for better experience
        </span>
              <Image src={smileImg} className={styles.SmileImg}/>
            </div>
          </div>
          }
        </div>
      }
      {isIntroNeeded &&
        <CompanyDetails
            onSignOutClick={onSignOutClick}
            setIsIntroNeeded={setIsIntroNeeded}
            email={userDetails.email}
        />
      }
    </>
  );
};

export default withCookies(connect(mapStateToProps)(AdminAccountPage));

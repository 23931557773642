import React, {useState, useEffect} from 'react';
import {withCookies} from 'react-cookie';

import styles from './DraftModuleComponent.module.scss';
import inviteImg from '../../../../../../assets/images/icon/invite-learners.svg';
import ContentListComponent from './componentns/ContentListComponent/ContentListComponent';
import ButtonColor from '../../../../../components/Button/components/ButtonColor/ButtonColor';
import InviteLearnersPopup from './componentns/InviteLearnersPopup/InviteLearnersPopup';
import DelayModuleComponent
    from './componentns/AddContentComponent/components/DelayModuleComponent/DelayModuleComponent';
import RecordedVideoPreviewComponent from './componentns/RecordedVideoPreviewComponent/RecordedVideoPreviewComponent';
import AddContentComponent from './componentns/AddContentComponent/AddContentComponent';
import Image from '../../../../../components/Image/Image';
import AddYouTubeVideoPopup from './componentns/AddYouTubeVideoPopup/AddYouTubeVideoPopup';
import TaskPopupComponent from './componentns/TaskPopupComponent/TaskPopupComponent';
import QuizPopupComponent from './componentns/QuizPopupComponent/QuizPopupComponent';
import {dispatch} from '../../../../../state/store';
import {
    setDraftCourse,
    setDraftModule
} from '../../../../../state/ducks/courseConstructor';
import courseService from '../../../../../services/server/courseService/courseService';
import useScreenRecorder from "../../../../../hooks/useScreenRecorder";
import TextArea from '../../../../../components/TextArea/TextArea';

const DraftModuleComponent = (props:any) => {
    const {draftModule, draftCourse, cookies} = props;

    const [isVisibleYouTubePopup, setIsVisibleYouTubePopup] = useState(false);
    const [isVisibleTaskPopup, setIsVisibleTaskPopup] = useState(false);
    const [isVisibleQuizPopup, setIsVisibleQuizPopup] = useState(false);
    const [isVisibleInviteLearnersPopup, setIsVisibleInviteLearnersPopup] = useState(false);
    const [isVisibleDelayPopup, setIsVisibleDelayPopup] = useState(false);
    const [isVisibleRecordedVideoPreview, setIsVisibleRecordedVideoPreview] = useState(false);

    const {isRecording, mediaUrl, startRecording, stopRecording, flushMediaUrl} = useScreenRecorder();

    useEffect(() => {
        if(cookies.cookies['template-plash'] === undefined) {
            cookies.set('template-plash', true);
        } else if (cookies.cookies['template-plash'] === 'true') {
            cookies.set('template-plash', false);
        }
    },[]);

    useEffect(() => {
        if (mediaUrl) {
            openRecordedVideoPreview();
        }
    }, [mediaUrl]);

    const moduleGoalChange = async (event: React.FormEvent<HTMLInputElement>) => {
        const newDraftModule = {...draftModule, details: event.currentTarget.value};
        const newModulesList = draftCourse.modules.map((module:any) => {
            if (module.id === draftModule.id) {
                module.details = event.currentTarget.value;
            }
            return module;
        });
        dispatch(setDraftModule(newDraftModule));
        dispatch(setDraftCourse({...draftCourse, modules: newModulesList}));

        await courseService.updateModuleGoal(draftModule.id, event.currentTarget.value);
    };

    const setRecorder = () => {
        if (!isRecording){
            startRecording().catch((err) => {
                console.error(err);
            });
        } else {
            stopRecording();
        }
    };

    const hideRecordedVideoPreview = () => setIsVisibleRecordedVideoPreview(false);

    const openRecordedVideoPreview = () => setIsVisibleRecordedVideoPreview(true);

    const hideYouTubePopup = () => setIsVisibleYouTubePopup(false);

    const openYouTubePopup = () => setIsVisibleYouTubePopup(true);

    const openQuizPopup = () => setIsVisibleQuizPopup(true);

    const openDelayPopup = () => setIsVisibleDelayPopup(true);

    const openTaskPopup = () => setIsVisibleTaskPopup(true);

    const hideTaskPopup = () => setIsVisibleTaskPopup(false);

    const hideQuizPopup = () => setIsVisibleQuizPopup(false);

    const hideInviteLearnersPopup = () => setIsVisibleInviteLearnersPopup(false);

    const hideDelayPopup = () => setIsVisibleDelayPopup(false);

    return (
        <div>
            <div className={styles.MainContentComponent}>
                <div className={styles.ButtonContainer}>
                    <ButtonColor className={styles.InviteButton} onClick={() => setIsVisibleInviteLearnersPopup(true)}>
                        <Image src={inviteImg} className={styles.InviteImage} />
                        <span className={styles.InviteText}>Invite</span>
                    </ButtonColor>
                </div>
                <div className={styles.WrapperGoalField}>
                    <TextArea
                        className={styles.GoalField}
                        placeholder='What is the goal of this module?'
                        onChange={moduleGoalChange}
                        value={draftModule.details}
                    />
                </div>
                <ContentListComponent
                    openQuizPopup={openQuizPopup}
                    openTaskPopup={openTaskPopup}
                />
                <AddContentComponent
                    openYouTubePopup={openYouTubePopup}
                    openTaskPopup={openTaskPopup}
                    openQuizPopup={openQuizPopup}
                    openDelayPopup={openDelayPopup}
                    setRecorder={setRecorder}
                    isRecording={isRecording}
                />
                {isVisibleYouTubePopup && (
                    <AddYouTubeVideoPopup
                        hideYouTubePopup={hideYouTubePopup}
                        draftMOdule={draftModule}
                    />
                )}
                {isVisibleTaskPopup && (
                    <TaskPopupComponent
                        task={draftModule.task}
                        draftModule={draftModule}
                        hideTaskPopup={hideTaskPopup}
                    />
                )}
                {isVisibleQuizPopup && (
                    <QuizPopupComponent
                        hideQuizPopup={hideQuizPopup}
                    />
                )}
                {isVisibleInviteLearnersPopup && (
                    <InviteLearnersPopup
                        course={draftCourse}
                        hideInviteLearnersPopup={hideInviteLearnersPopup}
                    />
                )}
                {isVisibleDelayPopup && (
                    <DelayModuleComponent
                        moduleId={draftModule.id}
                        hideDelayPopup={hideDelayPopup} />
                )}
                {isVisibleRecordedVideoPreview && (
                    <RecordedVideoPreviewComponent
                        hideRecordedVideoPreview={hideRecordedVideoPreview}
                        flushMediaUrl={flushMediaUrl}
                        draftModule={draftModule}
                        mediaUrl={mediaUrl}
                    />
                )}
            </div>
            {(draftCourse.parentCourseId !== null && draftCourse.parentCourseId === 886141 && cookies.cookies['template-plash'] !== 'false') &&
                <div className={styles.FooterTemplate}>
                This template was created in partnership with <a target='_blank'
                className={styles.LinkTemplate}
                href='https://reply.io?utm_source=partners&utm_medium=product&utm_campaign=Gradual'>Reply.io</a>
                , sales engagement platform that helps to scale multichannel outreach.
                </div>
            }
    </div>
    );
};

export default withCookies(DraftModuleComponent);
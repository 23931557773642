const formatString = (string:string, ...values:any) => {
    for (let i = 0; i < values.length; i++) {
        string = string.replace(`{${i}}`, values[i]);
    }

    return string;
};

export default {
    formatString,
}
import classNames from 'classnames';
import React from 'react';

import Button from '../../../Button/Button';
import styles from './ButtonColor.module.scss';

const ButtonColor = ({isFilled=true, className, disabled, children, onClick, ...props}:
     {isFilled?:boolean, className:string, disabled?:boolean, children:any, onClick: Function}) => {
    const btnType = isFilled ? styles.ButtonFilled : styles.ButtonEmpty;
    const btnClassName = classNames(className, styles.Button, btnType);

    return (
        <Button
            className={btnClassName}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {children}
        </Button>
    );
};

export default ButtonColor;
import ReactDOM from 'react-dom';
import {useEffect} from 'react';

const Modal = (props:any) => {
    const {children} = props;
    const divElement:HTMLElement = document.createElement('div');
    const rootElement = document.getElementById('root') as HTMLFormElement;

    useEffect(() => {
        rootElement.appendChild(divElement);
    });

    return ReactDOM.createPortal(
        children,
        divElement,
    );
}

export default Modal;
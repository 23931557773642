import classNames from 'classnames';
import React, {useState, useEffect, useRef} from 'react';

import styles from './PopupFullPage.module.scss';
import Image from '../../Image/Image';
import closePopupImg from '../../../../assets/images/icon/close-popup-icon.svg';

const PopupFullPage = (props:any) => {
    const {classNameBackground, classNameMainContainer, children, closePopup,
        isSupportOutsidePopupClick = false, isVisibleCloseButton = true, classNameClose,
        closeContainer
    } = props;

    const classNamePseudoContainer = classNames(styles.PseudoMainContainer, classNameBackground);
    const classNameContainer = classNames(styles.MainContentContainer, classNameMainContainer);
    const classNameCloseImg = classNames(styles.CloseButton, classNameClose);
    const classNameCloseContainer = classNames(styles.CloseContainer, closeContainer);
    const [isVisiblePopup, setIsVisiblePopup] = useState(true);

    const popupRef = useRef<HTMLDivElement>(null);

    const useOutsideAlerter = (popupRef:any) => {

        useEffect(() => {
            function handleClickOutside(event:any) {
                if (popupRef.current && !popupRef.current.contains(event.target) && isSupportOutsidePopupClick) {
                    setIsVisiblePopup(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [popupRef]);
    };

    useOutsideAlerter(popupRef);

    const outsidePopupClick = () => {
        if(isSupportOutsidePopupClick) {
            closePopup(isVisiblePopup)
        }
    };

    return (
        <div className={styles.MainContainer}>
            <div className={classNamePseudoContainer} onClick={outsidePopupClick} />
            <div ref={popupRef} className={classNameContainer}>
                {isVisibleCloseButton && (
                    <div className={classNameCloseContainer}>
                        <Image src={closePopupImg} className={classNameCloseImg} onClick={closePopup} />
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};

export default PopupFullPage;
import React from 'react';

import styles from './ModalColor.module.scss';
import {ReactComponent as DeleteImg} from '../../../../../../../../../../../../../../assets/images/icon/delete-course-icon.svg';
import {ReactComponent as RenameImg} from '../../../../../../../../../../../../../../assets/images/icon/rename-course-icon.svg';
import Svg from '../../../../../../../../../../../../../components/SvgComponent/SvgComponent';
import Button from '../../../../../../../../../../../../../components/Button/Button';
import backgroundList from '../../../../backgroundList';
import textData from '../../../../../../../../../../../../../consts/textData/textData.json';

const ModalColor = (props:any) => {
    const {openRenamePopup, modalRef, openDeletePopup, backgroundCourseChange} = props;

    const backgroundCourseClick = (background:string) => {
        backgroundCourseChange(background);
    };

    return (
        <div className={styles.ModalContainer} ref={modalRef}>
            <div className={styles.TitleModalContainer}>{textData.TEXT_FOR_MODAL_COLOR_CHOOSE}</div>
            <div className={styles.ModalColorChoose}>
                {backgroundList.map((item, index) => {
                    const id = item.id;
                    
                    return (
                        <Button
                        key={id}
                        className={styles.ButtonChoose}
                        style={{background: item.color}}
                        onClick={() => backgroundCourseClick(item.color)}>
                        </Button>
                    )
                })}
                {/*<Button className={styles.ButtonChoose}>*/}
                {/*    <Image*/}
                {/*        className={styles.IconPicture}*/}
                {/*        src={pictureImg}*/}
                {/*    />*/}
                {/*</Button>*/}
            </div>
            <div className={styles.ButtonContainer}>
                <div className={styles.Button} onClick={openRenamePopup}>
                    <Svg className={styles.ButtonImg}>
                        <RenameImg/>
                    </Svg>
                    {textData.COURSE_CELL_DOTS_MODAL_RENAME_BUTTON_TEXT}
                </div>
                <div className={styles.Button} onClick={openDeletePopup}>
                    <Svg className={styles.ButtonImg}>
                        <DeleteImg/>
                    </Svg>
                    {textData.COURSE_CELL_DOTS_MODAL_DELETE_BUTTON_TEXT}
                </div>
            </div>
        </div>
    )
};

export default ModalColor;
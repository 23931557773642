import { AdminStorage } from "../interfaces/adminInterface";
import { CourseMetaData } from "../../types/CourseMetaData";

export const SET_IS_EMPTY_LEARNERS_DATA =
  "app/admin/SET_IS_EMPTY_LEARNERS_DATA";
export const SET_COURSE_LIST = "app/admin/SET_COURSE_LIST";
export const SET_LEARNERS_LIST = "app/admin/SET_LEARNERS_LIST";
export const SET_SORTED_LEARNER = "app/admin/SET_SORTED_LEARNER";
export const SET_FILTER_COURSE_LEARNER = "app/admin/SET_FILTER_COURSE_LEARNER";
export const SET_FILTER_NAME_LEARNER = "app/admin/SET_FILTER_NAME_LEARNER";
export const SET_SORTED_LEARNER_NAME = "app/admin/SET_SORTED_LEARNER_NAME";
export const SET_TEMPLATES_LIST = "app/admin/SET_TEMPLATES_LIST";
export const SET_IS_VISIBLE_COURSE_LIST =
  "app/admin/SET_IS_VISIBLE_COURSE_LIST";
export const SET_IS_VISIBLE_CREATE_TEMPLATES =
  "app/admin/SET_IS_VISIBLE_CREATE_TEPMLATES";
export const SET_IS_VISIBLE_CREATE_COURSE_COMPONENT =
  "app/admin/SET_IS_VISIBLE_CREATE_COURSE_COMPONENT";
export const SET_IS_EMPTY_COURSES_DATA = "app/admin/SET_IS_EMPTY_COURSES_DATA";
export const SET_PUBLIC_INVITE_LINK = "app/admin/SET_PUBLIC_INVITE_LINK";
export const SET_PUBLIC_USER_INVITE_LINK =
  "app/admin/SET_PUBLIC_USER_INVITE_LINK";

const initialState: AdminStorage = {
  isEmptyLearnersData: true,
  currentCourseList: [],
  learnersList: [],
  sortedLearnerList: [],
  filterLearnerCourse: [],
  filterLearnerName: [],
  sortedLearnerName: [],
  templatesList: [],
  isVisibleCourseList: true,
  isVisibleCreateTemplates: false,
  isVisibleCreateCourseComponent: false,
  isEmptyCoursesData: true,
  publicInviteLink: "",
  publicUserInviteLink: "",
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_IS_EMPTY_LEARNERS_DATA: {
      return { ...state, isEmptyLearnersData: action.payload };
    }
    case SET_COURSE_LIST: {
      return { ...state, currentCourseList: action.payload };
    }
    case SET_LEARNERS_LIST: {
      return { ...state, learnersList: action.payload };
    }
    case SET_SORTED_LEARNER: {
      return { ...state, sortedLearnerList: action.payload };
    }
    case SET_FILTER_COURSE_LEARNER: {
      return { ...state, filterLearnerCourse: action.payload };
    }
    case SET_FILTER_NAME_LEARNER: {
      return { ...state, filterLearnerName: action.payload };
    }
    case SET_SORTED_LEARNER_NAME: {
      return { ...state, sortedLearnerName: action.payload };
    }
    case SET_TEMPLATES_LIST: {
      return { ...state, templatesList: action.payload };
    }
    case SET_IS_VISIBLE_COURSE_LIST: {
      return { ...state, isVisibleCourseList: action.payload };
    }
    case SET_IS_VISIBLE_CREATE_TEMPLATES: {
      return { ...state, isVisibleCreateTemplates: action.payload };
    }
    case SET_IS_VISIBLE_CREATE_COURSE_COMPONENT: {
      return { ...state, isVisibleCreateCourseComponent: action.payload };
    }
    case SET_IS_EMPTY_COURSES_DATA: {
      return { ...state, isEmptyCoursesData: action.payload };
    }
    case SET_PUBLIC_INVITE_LINK: {
      return { ...state, publicInviteLink: action.payload };
    }
    case SET_PUBLIC_USER_INVITE_LINK: {
      return { ...state, publicUserInviteLink: action.payload };
    }
    default:
      return state;
  }
}

export const setIsEmptyLearnersData = (value: Boolean) => ({
  type: SET_IS_EMPTY_LEARNERS_DATA,
  payload: value,
});

export const setCurrentCourseList = (value: CourseMetaData[] | undefined) => ({
  type: SET_COURSE_LIST,
  payload: value,
});

export const setLearnersList = (value: Array<any> | []) => ({
  type: SET_LEARNERS_LIST,
  payload: value,
});

export const setSortedLearnerList = (value: Array<any> | []) => ({
  type: SET_SORTED_LEARNER,
  payload: value,
});

export const setFilterCourseLearnerList = (value: Array<any> | []) => ({
  type: SET_FILTER_COURSE_LEARNER,
  payload: value,
});

export const setFilterNameLearnerList = (value: Array<any> | []) => ({
  type: SET_FILTER_NAME_LEARNER,
  payload: value,
});

export const setSortedLearnerName = (value: Array<any> | []) => ({
  type: SET_SORTED_LEARNER_NAME,
  payload: value,
});

export const setTemplatesList = (value: Array<any> | []) => ({
  type: SET_TEMPLATES_LIST,
  payload: value,
});

export const setIsVisibleCourseList = (value: Boolean) => ({
  type: SET_IS_VISIBLE_COURSE_LIST,
  payload: value,
});

export const setIsVisibleCreateTemplates = (value: Boolean) => ({
  type: SET_IS_VISIBLE_CREATE_TEMPLATES,
  payload: value,
});

export const setIsVisibleCreateCourseComponent = (value: Boolean) => ({
  type: SET_IS_VISIBLE_CREATE_COURSE_COMPONENT,
  payload: value,
});

export const setIsEmptyCoursesData = (value: Boolean) => ({
  type: SET_IS_EMPTY_COURSES_DATA,
  payload: value,
});

export const setPublicInviteLink = (value: string) => ({
  type: SET_PUBLIC_INVITE_LINK,
  payload: value,
});

export const setPublicUserInviteLink = (value: string) => ({
  type: SET_PUBLIC_USER_INVITE_LINK,
  payload: value,
});

const months = ['Jan', 'Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

const WEEK = {
    MILS: 7 * 24 * 60 * 60 * 1000,
    SEC: 7 * 24 * 60 * 60
}


const setModifyDate = (utcIssueTime:number) => {
    const creationDate = new Date(utcIssueTime);
    const month = months[creationDate.getMonth()];
    const day = creationDate.getDate();
    const hour = creationDate.getHours();
    const amPmSuffix = (hour <= 23 && hour >= 12) ? "PM" : "AM";
    const formattedHours  = hour%12 === 0 ? 12 : hour%12;
    const minutes = (creationDate.getMinutes() < 10 ? '0' : '') + creationDate.getMinutes();
    return `${day} ${month} ${formattedHours}:${minutes} ${amPmSuffix}`;
}

const setNewPayDate = (utcIssueTime:number) => {
    const creationDate = new Date(utcIssueTime * 1000);
    const month = months[creationDate.getMonth()];
    const day = creationDate.getDate();
    const year = creationDate.getFullYear();
    return `${day} ${month} ${year}`;
}

const setPerformanceReportDate = (utcIssueTime:number) : string => {
    const creationDate = new Date(utcIssueTime),
        prevCreationDate = new Date (utcIssueTime - WEEK.MILS);

    const curDay = creationDate.getDate(),
        curMonth = months[creationDate.getMonth()],
        prevDay = prevCreationDate.getDate(),
        prevMonth = months[prevCreationDate.getMonth()];

    if (prevMonth != curMonth) {
        return `${prevDay} ${prevMonth} - ${curDay} ${curMonth}`;
    } else {
        return `${prevDay} - ${curDay} ${curMonth}`;
    }
}

export default  {
    setModifyDate,
    setNewPayDate,
    setPerformanceReportDate
};
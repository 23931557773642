import courseService from "../courseService/courseService";
import { dispatch } from "../../../state/store";
import {
  setCurrentCourseList,
  setFilterCourseLearnerList,
  setFilterNameLearnerList,
  setIsEmptyCoursesData,
  setIsEmptyLearnersData,
  setLearnersList,
  setSortedLearnerList,
  setSortedLearnerName,
} from "../../../state/ducks/admin";
import reportsService from "../reportsService/reportsService";
import {
  setFilterCourseList,
  setFilterLearnerList,
  setFilterStatusList,
  setFilterTypeList,
  setIsEmptyReportsData,
  setReportsList,
  setSortedLearnersList,
  setTotalReportPages,
} from "../../../state/ducks/reports";
import analyticsService from "../analyticsService/analyticsService";
import {
  setAnalyticsList,
  setFilterCourseListAnalytics,
  setFilterLearnersListAnalytics,
  setFilterTypeListAnalytics,
  setIsEmptyAnalyticsData,
  setOverviewList,
  setSortedLearnersListAnalytics,
  sortedAnalyticsList,
} from "../../../state/ducks/analytics";
import learnersService from "../learnersService/learnersService";
import integrationsService from "../integrationsService/integrationsService";
import { setSlackUrl, setTelegramId } from "../../../state/ducks/integrations";
import customBrandingService from "../customBrandigService/customBrandingService";
import {
  setBrandColor,
  setBrandLogo,
  setIsPhoneInvitation,
} from "../../../state/ducks/customBranding";
import subscriptionService from "../subscriptionService/subscriptionService";
import {
  setIsLegacyUser,
  setPaymentInfo,
  setSeatsInfo,
} from "../../../state/ducks/subscription";
import { setIsAppLoader } from "../../../state/ducks/global";
import userInfoService from "../userInfoService/userInfoService";
import { UserDetails } from "../../../types/UserDetails";
import teamService from "../teamService/teamService";
import {
  setFiltersEmail,
  setFiltersName,
  setIsEmptyTeamMemberData,
  setListOfTeamMembers,
  setSortedFiltersName,
  setSortedListOfTeamMembers,
} from "../../../state/ducks/team";
import { TeamMember } from "../../../types/TeamMember";
import { TeamMemNameFilter } from "../../../types/filter/teamMember/TeamMemNameFilter";
import { TeamMemEmailFilter } from "../../../types/filter/teamMember/TeamMemEmailFilter";
import { CourseMetaData } from "../../../types/CourseMetaData";

const coursesLoading = async () => {
  const allCourses: CourseMetaData[] | undefined =
    await courseService.getAllCourses();
  dispatch(setCurrentCourseList(allCourses));
  dispatch(setIsEmptyCoursesData(!allCourses?.length));

  await courseService.getTemplates();
};

const reportsLoading = async () => {
  const reportsList: any = await reportsService.getAllReports();
  dispatch(
    setIsEmptyReportsData(
      !reportsList.notifications.length && reportsList.finalPage
    )
  );
  dispatch(setReportsList(reportsList.notifications));
  dispatch(setTotalReportPages(reportsList.totalPages));

  const reportsLearner: any = await reportsService.getFilterReportsLearner();
  dispatch(setFilterLearnerList(reportsLearner.learnerFilters));
  dispatch(setSortedLearnersList(reportsLearner.learnerFilters));

  const reportsType: any = await reportsService.getFilterReportsType();
  dispatch(setFilterTypeList(reportsType.reportTypeFilters));

  const reportsCourse: any = await reportsService.getFilterReportsCourse();
  dispatch(setFilterCourseList(reportsCourse.courseFilters));

  const reportsStatus: any = await reportsService.getFilterReportsStatus();
  await dispatch(setFilterStatusList(reportsStatus.reportStatusFilters));
};

const analyticsLoading = async () => {
  const overview: any = await analyticsService.getOverview();
  dispatch(setOverviewList(overview));

  const analytics: any = await analyticsService.getAnalytics();
  dispatch(setIsEmptyAnalyticsData(!analytics.learnerAnalyticsList.length));
  dispatch(setAnalyticsList(analytics.learnerAnalyticsList));
  dispatch(sortedAnalyticsList(analytics.learnerAnalyticsList));

  const filterAnalyticsType: any = await analyticsService.getFilteredListType();
  dispatch(setFilterTypeListAnalytics(filterAnalyticsType.typeFilters));

  // const filterAnalyticsPeriod:any = await analyticsService.getFilteredListPeriod();
  // dispatch(setFilterPeriodListAnalytics(filterAnalyticsPeriod.periodFilters));

  const filterAnalyticsCourse: any =
    await analyticsService.getFilteredListCourse();
  dispatch(setFilterCourseListAnalytics(filterAnalyticsCourse.courseFilters));

  const filterAnalyticsLearners: any =
    await analyticsService.getFilteredListLearners();
  dispatch(
    setFilterLearnersListAnalytics(filterAnalyticsLearners.learnerFilters)
  );
  dispatch(
    setSortedLearnersListAnalytics(filterAnalyticsLearners.learnerFilters)
  );
};

const learnersLoading = async () => {
  const listOfNamesLearners: any =
    await learnersService.getFiltersLearnerName();
  dispatch(setFilterNameLearnerList(listOfNamesLearners.learnerFilters));
  dispatch(setSortedLearnerName(listOfNamesLearners.learnerFilters));

  const listOfCourses: any = await learnersService.getFiltersLearnerCourse();
  dispatch(setFilterCourseLearnerList(listOfCourses.courseFilters));

  const learnersList: any = await learnersService.getFilterLearners();
  dispatch(setIsEmptyLearnersData(!learnersList.learners.length));
  dispatch(setLearnersList(learnersList.learners.reverse()));
  dispatch(setSortedLearnerList(learnersList.learners));
};

const integrationLoading = async () => {
  const userBotsId: any = await integrationsService.getIntegrationsBots();
  dispatch(setTelegramId(userBotsId.telegram));
  dispatch(setSlackUrl(userBotsId.slack));
};

const customBrandingLoading = async () => {
  const brandColor: string | undefined =
    await customBrandingService.getBrandingColor();
  dispatch(setBrandColor(brandColor));

  const brandLogo: string | undefined =
    await customBrandingService.getBrandingLogo();
  dispatch(setBrandLogo(brandLogo));

  const isPhoneInvitation: any = await customBrandingService.getCustomInfo();
  dispatch(setIsPhoneInvitation(isPhoneInvitation.isPhoneInvitation));
};

const subscriptionDataLoading = async () => {
  dispatch(setIsAppLoader(true));

  const paymentInfo = await subscriptionService.getPaymentInfo();
  dispatch(setPaymentInfo(paymentInfo));

  const seatsInfo = await subscriptionService.getSeats();
  dispatch(setSeatsInfo(seatsInfo));

  const getInfoUser = await subscriptionService.getInfoAboutUser();
  dispatch(setIsLegacyUser(getInfoUser));
};

const userDetailsLoading = async (): Promise<UserDetails | undefined> => {
  return await userInfoService.getUserInfo();
};

const infoLoading = async () => {
  return await userInfoService.getInfo();
};

const teamDataLoading = async () => {
  const dataTeam: TeamMember[] | undefined =
    await teamService.getDataTeamMembers();
  if (dataTeam) {
    dispatch(setListOfTeamMembers(dataTeam));
    dispatch(setSortedListOfTeamMembers(dataTeam));
    dispatch(setIsEmptyTeamMemberData(!dataTeam.length));
  }

  const filtersTeamName: TeamMemNameFilter | undefined =
    await teamService.getFiltersMemberName();
  if (filtersTeamName) {
    dispatch(setFiltersName(filtersTeamName.teamNames));
    dispatch(setSortedFiltersName(filtersTeamName.teamNames));
  }

  const filtersTeamEmail: TeamMemEmailFilter | undefined =
    await teamService.getFiltersMemberEmail();
  if (filtersTeamEmail) {
    dispatch(setFiltersEmail(filtersTeamEmail.teamEmails));
  }
};

export default {
  coursesLoading,
  reportsLoading,
  analyticsLoading,
  learnersLoading,
  userDetailsLoading,
  integrationLoading,
  customBrandingLoading,
  subscriptionDataLoading,
  teamDataLoading,
  infoLoading,
};

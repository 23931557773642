import React, {useCallback, useEffect, useMemo, useState} from 'react';

import ReportsHeader from './components/ReportsHeader/ReportsHeader';
import FiltersPerformanceReports from './components/FiltersPerformanceReports/FiltersPerformanceReports';

import styles from './ReportsDetailsComponent.module.scss';
import {PerformanceReport} from '../../../../../../../../../types/performanceReportsType/performanceReports';
import dateModify from '../../../../../../../../../services/utils/dateModify/dateModify';
import ConfigureReports from './components/ConfigureReports/ConfigureReports';
import {UserDetails} from "../../../../../../../../../types/UserDetails";
import {
    EmptyPerformanceDataComponent
} from "./components/ReportsHeader/components/components/ReportDetails/components/EmptyPerformanceDataComponent/EmptyPerformanceDataComponent";

type ReportsDetailsComponentType = {
    performanceReports: PerformanceReport[]
    userDetails: UserDetails
    setControlTrigger: Function
    setReportStatus: Function
};

const ReportsDetailsComponent = ({performanceReports, userDetails, setControlTrigger, setReportStatus}: ReportsDetailsComponentType) => {
    const [date, setDate] = useState({name: 'All dates', id: -1});
    const [status, setStatus] = useState({name: 'All reports', id: -1});
    const [filteredPerformanceReports, setFilteredPerformanceReports] = useState<PerformanceReport[]>(performanceReports);
    const isVisibleConfigureDate = useMemo(() => userDetails.isPerformanceReportConfigured, [userDetails]);

    const onClickDate = (data: string, id: number) => {
        setDate({name: data, id: id});
    };

    const onClickStatus = (status: string, id: number) => {
        setStatus({name: status, id: id});
    };

    const handleResetBtnClick = () => {
        setDate({name: 'All dates', id: -1});
        setStatus({name: 'All reports', id: -1});
    };

    const filterReports = useCallback(() => {
        const isSelectDate = date.id !== -1;
        const isSelectStatus = status.id !== -1;
        const filteredByDateStatus = isSelectDate && isSelectStatus;

        const filteredReportsList = performanceReports.filter((reportItem: PerformanceReport) => {
            const reportDate = dateModify.setModifyDate(reportItem.timeStamp);
            const reportStatus = reportItem.checked ? 'Checked' : 'New';

            if (filteredByDateStatus) {
                return reportDate.includes(date.name) && reportStatus === status.name;
            }

            if (isSelectDate) {
                return reportDate.includes(date.name);
            }

            if (isSelectStatus) {
                return reportStatus === status.name;
            }

            return true;
        });

        setFilteredPerformanceReports(filteredReportsList);
    }, [filteredPerformanceReports, setFilteredPerformanceReports, date, status, performanceReports]);

    useEffect(filterReports,[date, status]);

    return (
        <div>
            <div className={styles.Title}>Reports</div>
            {(isVisibleConfigureDate && !!performanceReports.length) &&
                <>
                    <FiltersPerformanceReports
                        date={date}
                        onClickDate={onClickDate}
                        status={status}
                        onClickStatus={onClickStatus}
                        handleResetBtnClick={handleResetBtnClick}
                        performanceReports={performanceReports}
                    />
                    {!!filteredPerformanceReports.length ?
                        <ReportsHeader
                            performanceReports={filteredPerformanceReports}
                            setReportStatus={setReportStatus}
                        /> :
                        <EmptyPerformanceDataComponent waringMessage={'No matching data'} />
                    }
                </>
            }
            {(isVisibleConfigureDate && !performanceReports.length) && <div className={styles.EmptyReportTitle}>No reports have been generated yet</div>}
            {!isVisibleConfigureDate && <ConfigureReports setControlTrigger={setControlTrigger}/>}
        </div>
    );
};

export default ReportsDetailsComponent;

import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import styles from "./ModuleFilterComponent.module.scss";
import Image from "../../../../../../../../../components/Image/Image";
import topDropDownImg from "../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg";
import bottomDropDownImg from "../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg";
import OptionsComponent from "../../../../../../../../../components/DropDownList/component/OptionsComponent/OptionsComponent";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect/lib/index";
import { makeSelectFilterModule } from "../../../../../../../../../state/selectors/reports";
import reportsService from "../../../../../../../../../services/server/reportsService/reportsService";
import { useOutsideListener } from "../../../../../../../../../hooks/useOutsideListener";

const mapStateToProps = createStructuredSelector({
  filterModuleList: makeSelectFilterModule(),
});

const ModuleFilterComponent = (props: any) => {
  const {
    classNameContainer,
    classNameDropDown,
    classNameDropDownMenu,
    onModuleClick,
    filterModuleList,
    moduleFilter,
    courseFilter,
    classNameDropDownOverflow,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(moduleFilter.name);
  const [loader, setLoader] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDropDownValue(moduleFilter.name);
  }, [moduleFilter]);

  const closeDropDownList = () => setIsOpenDropDown(false);
  useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

  const openDropDownList = async () => {
    setIsOpenDropDown(
      courseFilter.name === "All training" ? false : !isOpenDropDown
    );
    await reportsService.getFilterReportsModule(courseFilter.id);
    setLoader(true);
  };

  const onModuleButtonClick = (moduleName: string, moduleId: number) => {
    setDropDownValue(moduleName);
    onModuleClick(moduleName, moduleId);
    closeDropDownList();
  };

  return (
    <div className={classNameContainer}>
      <span className={styles.TitleModule}>Module</span>
      <div
        className={
          isOpenDropDown
            ? classNames(styles.ActiveDropDown, classNameDropDown)
            : courseFilter.name === "All training"
            ? classNames(styles.DisabledContainer, classNameDropDown)
            : classNameDropDown
        }
        onClick={openDropDownList}
        ref={selectButtonRef}
      >
        <span className={styles.TextSelect}>
          {isOpenDropDown ? "Select" : dropDownValue}
        </span>
        <Image
          src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
          className={classNames(
            styles.DropDownIcon,
            isOpenDropDown && styles.isActiveDropDownIcon
          )}
        />
      </div>
      {isOpenDropDown && (
        <div className={classNameDropDownMenu} ref={dropDownRef}>
          <div className={classNameDropDownOverflow}>
            {loader ? (
              filterModuleList?.map((item: any) => {
                const moduleName = item.name;
                const moduleId = item.id;
                const isActiveOptions = item.id === moduleFilter.id;

                return (
                  <OptionsComponent
                    className={styles.OptionsText}
                    classNameShowText={moduleName?.length > 19 && true}
                    classNameWraperOptions={styles.OptionsWrapper}
                    isActiveOptions={isActiveOptions}
                    reference={optionsRef}
                    key={moduleId}
                    optionText={moduleName}
                    onClick={() => onModuleButtonClick(moduleName, moduleId)}
                  />
                );
              })
            ) : (
              <div className={styles.WrapperForLoader}>
                <Loader
                  type="TailSpin"
                  color="#F85A40"
                  height={25}
                  width={25}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ModuleFilterComponent);

import React from 'react';

import styles from './PassingGradeComponent.module.scss';
import Input from '../../../../../../../../../../../components/Input/Input';
import Slider from '../../../../../../../../../../../components/Slider/Slider';

const PassingGradeComponent = (props:any) => {
    const {isActivePassingGrade, changeActivePassingGrade, classNameSliderTitleText,
        classNameInputField, passingGrade, passingGradeChange} = props;
    const inputFieldValue = passingGrade === null ? '' : passingGrade;
    
    return (
        <div className={styles.MainContainer}>
            <div className={styles.ChangeContainer}>
                <div className={styles.SliderContainer}>
                    <Slider isChecked={isActivePassingGrade} onChange={changeActivePassingGrade} />
                    <span className={classNameSliderTitleText}>Passing Grade</span>
                </div>
                <span className={styles.DescriptionText}>Enable Passing Grade</span>
            </div>
            <div className={styles.InputContainer}>
                <Input
                    value={inputFieldValue}
                    onChange={passingGradeChange}
                    className={classNameInputField}
                    isDisabledInput={!isActivePassingGrade}
                    placeholder='80'
                    type='number'
                />
                <span className={styles.InputDescription}>%</span>
            </div>
        </div>
    );
};

export default PassingGradeComponent;
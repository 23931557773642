import {AnalyticsStorage} from '../interfaces/analyticsInterface';

export const SET_IS_EMPTY_ANALYTICS_DATA = 'app/admin/SET_IS_EMPTY_ANALYTICS_DATA';
export const SET_ANALYTICS_LIST = 'app/admin/SET_ANALYTICS_LIST';
export const SET_SORTED_ANALYTICS_LIST = 'app/admin/SET_SORTED_ANALYTICS_LIST';
export const SET_ANALYTICS_LIST_PROGRESS = 'app/admin/SET_ANALYTICS_LIST_PROGRESS';
export const SET_SORTED_ANALYTICS_LIST_PROGRESS = 'app/admin/SET_SORTED_ANALYTICS_LIST_PROGRESS';
export const SET_OVERVIEW_LIST = 'app/admin/SET_OVERVIEW_LIST';
export const SET_FILTER_TYPE_LIST = 'app/admin/SET_FILTER_TYPE_LIST';
export const SET_FILTER_COURSE_LIST = 'app/admin/SET_FILTER_COURSE_LIST';
export const SET_FILTER_MODULE_LIST = 'app/admin/SET_FILTER_MODULE_LIST';
export const SET_FILTER_PERIOD_LIST = 'app/admin/SET_FILTER_PERIOD_LIST';
export const SET_FILTER_LEARNERS_LIST = 'app/admin/SET_FILTER_LEARNERS_LIST';
export const SET_SORTED_LEARNERS_LIST = 'app/admin/SET_SORTED_LEARNERS_LIST';

const initialState:AnalyticsStorage = {
    isEmptyAnalyticsData:true,
    analyticsList:[],
    sortedAnalyticsList:[],
    analyticsListProgress: [],
    sortedAnalyticsListProgress: [],
    overviewList:[],
    filterTypeList:[],
    filterCourseList:[],
    filterModuleList:[],
    filterPeriodList:[],
    filterLearnersList:[],
    sortedLearnersList: []
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_IS_EMPTY_ANALYTICS_DATA: {
            return {...state, isEmptyAnalyticsData:action.payload};
        }
        case SET_ANALYTICS_LIST: {
            return {...state, analyticsList: action.payload};
        }
        case SET_SORTED_ANALYTICS_LIST: {
            return {...state, sortedAnalyticsList: action.payload};
        }
        case SET_ANALYTICS_LIST_PROGRESS: {
            return {...state, analyticsListProgress: action.payload};
        }
        case SET_SORTED_ANALYTICS_LIST_PROGRESS: {
            return {...state, sortedAnalyticsListProgress: action.payload};
        }
        case SET_OVERVIEW_LIST: {
            return {...state, overviewList: action.payload};
        }
        case SET_FILTER_TYPE_LIST: {
            return {...state, filterTypeList: action.payload};
        }
        case SET_FILTER_COURSE_LIST: {
            return {...state, filterCourseList: action.payload};
        }
        case SET_FILTER_MODULE_LIST: {
            return {...state, filterModuleList: action.payload};
        }
        case SET_FILTER_PERIOD_LIST: {
            return {...state, filterPeriodList: action.payload};
        }
        case SET_FILTER_LEARNERS_LIST: {
            return {...state, filterLearnersList: action.payload};
        }
        case SET_SORTED_LEARNERS_LIST: {
            return {...state, sortedLearnersList: action.payload};
        }
        default: return state;
    }
}
export const setIsEmptyAnalyticsData = (value:boolean) => ({type: SET_IS_EMPTY_ANALYTICS_DATA, payload: value});

export const setAnalyticsList = (value:Array<any>) => ({type: SET_ANALYTICS_LIST, payload: value});

export const sortedAnalyticsList = (value:Array<any>) => ({type: SET_SORTED_ANALYTICS_LIST, payload: value});

export const setAnalyticsListProgress = (value:Array<any>) => ({type: SET_ANALYTICS_LIST_PROGRESS, payload: value});

export const setSortedAnalyticsListProgress = (value:Array<any>) => ({type: SET_SORTED_ANALYTICS_LIST_PROGRESS, payload: value});

export const setOverviewList = (value:Array<any>) => ({type: SET_OVERVIEW_LIST, payload: value});

export const setFilterTypeListAnalytics = (value:Array<any>) => ({type: SET_FILTER_TYPE_LIST, payload: value});

export const setFilterCourseListAnalytics = (value:Array<any>) => ({type: SET_FILTER_COURSE_LIST, payload: value});

export const setFilterModuleListAnalytics = (value:Array<any>) => ({type: SET_FILTER_MODULE_LIST, payload: value});

export const setFilterPeriodListAnalytics = (value:Array<any>) => ({type: SET_FILTER_PERIOD_LIST, payload: value});

export const setFilterLearnersListAnalytics = (value:Array<any>) => ({type: SET_FILTER_LEARNERS_LIST, payload: value});

export const setSortedLearnersListAnalytics = (value:Array<any>) => ({type: SET_SORTED_LEARNERS_LIST, payload: value});
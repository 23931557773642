import {dispatch, getState} from '../../../../state/store';
import {
    setDraftCourse,
    setDraftModule
} from '../../../../state/ducks/courseConstructor';
import courseService from "../../../../services/server/courseService/courseService";
import {loading} from '../../../../components/Loader/AppLoader/loading';

const getDraftCourseFromState = () => getState().courseConstructor.draftCourse;

export type Course = any;
const loadCourseById = async (courseId:number) : Promise<Course> => {
    const getCourseById = async () : Promise<Course> => await courseService.getCourseById(courseId);

    const newDraftCourse:Course = await loading<Course>(getCourseById);
    dispatch(setDraftCourse(newDraftCourse));

    return newDraftCourse;
};

const updateCourse = async () => {
    const draftCourse = getDraftCourseFromState();
    const updatedDraftCourse = await courseService.getCourseById(draftCourse.id);

    dispatch(setDraftCourse(updatedDraftCourse));
    updateLastActiveModule();
};

const updateLastActiveModule = () => {
    const draftCourse = getDraftCourseFromState();
    dispatch(setDraftModule(draftCourse.modules[draftCourse.modules.length - 1]));
};

const updateCourseModuleList = (updatedModule: any) => {
    const draftCourse = getDraftCourseFromState();
    const updatedModuleList = draftCourse.modules.map((module:any) => {
        if (module.id === updatedModule.id) {
            return  updatedModule;
        }
        return module;
    });

    dispatch(setDraftCourse({...draftCourse, modules:updatedModuleList}));
};

const deleteModuleFromCourse = (moduleId:number) => {
    const draftCourse = getDraftCourseFromState();
    const updatedModuleList = draftCourse.modules.filter((module:any) => module.id !== moduleId);

    dispatch(setDraftCourse({...draftCourse, modules: updatedModuleList}));
};

export {
    updateCourse,
    loadCourseById,
    updateCourseModuleList,
    deleteModuleFromCourse,
    getDraftCourseFromState
}
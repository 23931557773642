import React from 'react';

import styles from './AuthErrorModal.module.scss';
const REGISTERED_MESSAGE = 'This user is already registered!';
const USER_NOT_FOUND_MESSAGE = 'User with this email is not found!';

const AuthErrorModal = (props:any) => {
    const {errorMessage, onClick} = props;

    const isClickToLogin = errorMessage === REGISTERED_MESSAGE;
    const isClickToRegister = errorMessage ===  USER_NOT_FOUND_MESSAGE;

    const isVisibleRedirectLink = isClickToLogin || isClickToRegister;

    return (
        <div className={styles.MainContainer}>
            <span>{errorMessage + ' '}</span>
            {isVisibleRedirectLink &&
                <span
                    className={styles.LinkText}
                    onClick={onClick}
                >
                    {isClickToRegister && 'Click to register.'}
                    {isClickToLogin && 'Click to login.'}
                </span>
            }
        </div>
    );
};

export default AuthErrorModal;
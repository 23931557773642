import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./ResetPasswordPage.module.scss";
import Image from "../../components/Image/Image";
import backgroundImg from "../../../assets/images/icon/background-login.png";
import InputAuthField from "../../components/Input/InputAuthField/InputAuthField";
import AuthErrorModal from "../../components/Error/AuthErrorComponent/AuthErrorComponent";
import ButtonColor from "../../components/Button/components/ButtonColor/ButtonColor";
import appRouterService from "../../services/route/appRouterService";
import adminAuthService from "../../services/server/adminService/adminAuthService";
import { BAD_REQUEST } from "../../consts/httpStatus/httpStatus";
import { USER_NOT_FOUND } from "../../consts/error/errorTypesMessage";
import smileImg from "../../../assets/images/icon/smile-icon.svg";
import SuccessContainer from "./components/SuccessContainer/SuccessContainer";
import Logo from "../../components/Logo/Logo";

const ResetPasswordPage = (props: any) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1279px)" });
  const verifyToken = props.match.params.verifyToken || null;

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isErrorOccurred, setIsErrorOccurred] = useState<boolean>(false);
  const [isVisibleSuccessText, setIsVisibleSuccessText] =
    useState<boolean>(false);

  const textFieldForConfirmPasswordRef = useRef<HTMLInputElement>(null)!;

  const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.currentTarget.value);
  };

  const onConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.currentTarget.value);
  };

  const onEnterKeyPressNewPassword = (event: React.KeyboardEvent) => {
    if (event.code === "Enter") {
      textFieldForConfirmPasswordRef!.current!.focus();
    }
  };

  const onEnterKeyPressConfirmPassword = (event: React.KeyboardEvent) => {
    if (event.code === "Enter") {
      onResetPasswordButtonClick();
    }
  };

  const onResetPasswordButtonClick = () => {
    const isEmptyPassword = !newPassword.length;
    const isValidConfirmPassword =
      newPassword === confirmPassword && !isEmptyPassword;

    if (isValidConfirmPassword) {
      adminAuthService
        .changePassword(verifyToken, newPassword)
        .then((res) => {
          setIsVisibleSuccessText(true);
        })
        .catch((error) => {
          const { data, status } = error;

          setIsErrorOccurred(true);

          if (status === BAD_REQUEST && data.includes(USER_NOT_FOUND)) {
            setErrorMessage("User with this email is not found!");
          } else {
            setErrorMessage("Opps... Something went wrong");
          }
        });
    } else {
      setIsErrorOccurred(true);
      setErrorMessage(
        isEmptyPassword ? "Password cannot be empty" : "Passwords don’t match"
      );
    }
  };

  const onSignUpButtonClick = () => {
    appRouterService.forwardToAdminSignUpPage();
  };

  return (
    <div className={styles.MainContainer}>
      {!isTabletOrMobile && (
        <div>
          <Image src={backgroundImg} className={styles.BackgroundImage} />
          <div className={styles.SignInContainer}>
            <div className={styles.TitleContainer}>
              <span className={styles.TitleText}>
                Welcome back to <span>Gradual</span>
              </span>
            </div>
            {!isVisibleSuccessText && (
              <div className={styles.InputContainer}>
                <div className={styles.Subtitle}>Get a new password</div>
                <InputAuthField
                  labelText="New password"
                  classNameInput={styles.InputField}
                  classNameLabel={styles.LabelInputText}
                  value={newPassword}
                  placeholderInput="**********"
                  onChange={onNewPasswordChange}
                  onKeyPress={onEnterKeyPressNewPassword}
                  isPasswordViewButton
                />
                <InputAuthField
                  labelText="Confirm new password"
                  classNameInput={styles.InputField}
                  classNameLabel={styles.LabelInputText}
                  value={confirmPassword}
                  placeholderInput="**********"
                  onChange={onConfirmPasswordChange}
                  onKeyPress={onEnterKeyPressConfirmPassword}
                  reference={textFieldForConfirmPasswordRef}
                  isPasswordViewButton
                />
                {isErrorOccurred && (
                  <AuthErrorModal
                    errorMessage={errorMessage}
                    onClick={onSignUpButtonClick}
                  />
                )}
                <ButtonColor
                  className={styles.LoginButton}
                  onClick={onResetPasswordButtonClick}
                >
                  Continue
                </ButtonColor>
              </div>
            )}
            {isVisibleSuccessText && <SuccessContainer />}
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <div className={styles.NotAdaptiveWrapper}>
          <Logo className={styles.LogoImage} />
          <div className={styles.NotAdaptive}>
            <span className={styles.TitleNotAdaptive}>
              Please switch to full screen mode or use Gradual with desktop for
              better experience
            </span>
            <Image src={smileImg} className={styles.SmileImg} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordPage;

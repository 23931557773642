import React, { useEffect, useRef, useState, MouseEvent, useMemo } from "react";
import ReactTooltip from "react-tooltip";

import styles from "./LearnersItem.module.scss";
import dotsImg from "../../../../../../../../../../assets/images/icon/three-dots-black.svg";
import resetIcon from "../../../../../../../../../../assets/images/icon/reset-icon.svg";
import Image from "../../../../../../../../../components/Image/Image";
import ResetPopup from "../ResetPopup/ResetPopup";
import RenameLearnerPopup from "../RenameLearnerPopup/RenameLearnerPopup";
import DeleteLearnerPopup from "../DeleteLearnerPopup/DeleteLearnerPopup";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectLearnersList } from "../../../../../../../../../state/selectors/admin";
import dateModify from "../../../../../../../../../services/utils/dateModify/dateModify";
import classNames from "classnames";
import ModalComponent from "./ModalComponent/ModalComponent";
import PaginationComponent from "../../../../../../../../../components/PaginationComponent/PaginationComponent";

const mapStateToProps = createStructuredSelector({
  learnersList: makeSelectLearnersList(),
});

const LearnersItem = (props: any) => {
  const {
    learnersList,
    filterName,
    filterCourse,
    setFilterName,
    setIsVisibleDashboardComponent,
    onSetLearnerForDashboard,
    setCurrentPageForDashboard,
    currentPageForDashboard,
  } = props;

  const [_courseName, setCourseName] = useState("");
  const [_courseId, setCourseId] = useState(0);
  const [learnerName, setLearnerName] = useState("");
  const [learnerId, setLearnerId] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    currentPageForDashboard ? currentPageForDashboard : 0
  );
  const [isVisibleDotsModal, setIsVisibleDotsModal] = useState(false);
  const [isVisibleRenamePopup, setIsVisibleRenamePopup] = useState(false);
  const [isVisibleDeletePopup, setIsVisibleDeletePopup] = useState(false);
  const [isVisibleResetPopup, setIsVisibleResetPopup] = useState(false);

  const learnerPerPage = useMemo(() => 5, []);
  const [currentPageData, setCurrentPageData] = useState<any[]>([]);

  const modalRef = useRef<HTMLDivElement>(null);
  const useOutsideClickParse = (popupRef: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setIsVisibleDotsModal(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [popupRef]);
  };

  useOutsideClickParse(modalRef);

  useEffect(() => {
    !currentPageForDashboard && setCurrentPage(0);
  }, [filterName, filterCourse]);

  const onDotsClick = (
    event: MouseEvent<HTMLDivElement>,
    learnerId: number,
    learnerName: string
  ) => {
    event.stopPropagation();
    setIsVisibleDotsModal(!isVisibleDotsModal);
    setLearnerId(learnerId);
    setLearnerName(learnerName);
  };

  const openRenamePopup = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsVisibleRenamePopup(true);
    setIsVisibleDotsModal(false);
  };

  const openDeletePopup = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsVisibleDeletePopup(true);
    setIsVisibleDotsModal(false);
  };

  const hideRenamePopup = () => {
    setIsVisibleRenamePopup(false);
  };

  const hideDeletePopup = () => {
    setIsVisibleDeletePopup(false);
  };

  const resetProgress = async (
    learnerName: string,
    learnerId: number,
    courseName: string,
    courseId: number
  ) => {
    setLearnerName(learnerName);
    setLearnerId(learnerId);
    setCourseName(courseName);
    setCourseId(courseId);
    setIsVisibleResetPopup(true);
  };

  const hideResetPopup = () => {
    setIsVisibleResetPopup(false);
  };

  const openDashboardPopup = async (
    event: MouseEvent<HTMLDivElement>,
    learnerName: string,
    learnerId: number,
    pipedriveId: number
  ) => {
    event.stopPropagation();
    setLearnerName(learnerName);
    onSetLearnerForDashboard(learnerName, pipedriveId, learnerId);
    setIsVisibleDashboardComponent(true);
    setCurrentPageForDashboard(currentPage);
  };

  return (
    <>
      {currentPageData?.map((item: any) => {
        const email: string = item.email;
        const name: string = item.name;
        const id: number = item.id;
        const courses = item.courses;
        const lastActivity = item.lastSeen;
        const pipedriveId = item.pipedriveId;

        return (
          <div
            className={classNames(
              styles.ContentItem,
              pipedriveId && styles.ContentItemPipedrive
            )}
            key={id}
            onClick={(event) =>
              pipedriveId && openDashboardPopup(event, name, id, pipedriveId)
            }
            data-tip={`${pipedriveId ? "View dashboard" : ""}`}
          >
            <span>{name}</span>
            <span>{email}</span>
            <div className={styles.CourseList}>
              {courses?.map((item: any) => {
                const courseName = item.name;
                const courseId = item.id;

                return (
                  <div key={courseId} className={styles.WrapperForCourseName}>
                    <span>{courseName}</span>
                    <span
                      data-tip="Reset course progress"
                      data-for="user"
                      onClick={(event) =>
                        resetProgress(name, id, courseName, courseId)
                      }
                      className={styles.ImageResetWrapper}
                    >
                      <Image src={resetIcon} className={styles.ImageReset} />
                    </span>
                    <ReactTooltip
                      className={styles.Tooltip}
                      effect="solid"
                      place="bottom"
                      id="user"
                    />
                  </div>
                );
              })}
            </div>
            <span>
              {lastActivity
                ? dateModify.setModifyDate(lastActivity)
                : "Last seen recently"}
            </span>
            <div className={styles.ImageContainer}>
              <Image
                src={dotsImg}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                  onDotsClick(event, id, name)
                }
                className={styles.ImgDots}
              />
              {isVisibleDotsModal && learnerId === id && (
                <div className={styles.Modal} ref={modalRef}>
                  <ModalComponent
                    openDashboardPopup={openDashboardPopup}
                    openRenamePopup={openRenamePopup}
                    openDeletePopup={openDeletePopup}
                    name={name}
                    id={id}
                    pipedriveId={pipedriveId}
                  />
                </div>
              )}
            </div>
            <ReactTooltip effect="solid" className={styles.Tooltip} />
          </div>
        );
      })}
      {isVisibleRenamePopup && (
        <RenameLearnerPopup
          learnerId={learnerId}
          hideRenamePopup={hideRenamePopup}
        />
      )}
      {isVisibleDeletePopup && (
        <DeleteLearnerPopup
          setFilterName={setFilterName}
          learnerId={learnerId}
          learnerName={learnerName}
          hideDeletePopup={hideDeletePopup}
        />
      )}
      {isVisibleResetPopup && (
        <ResetPopup
          hideResetPopup={hideResetPopup}
          courseName={_courseName}
          courseId={_courseId}
          learnerName={learnerName}
          learnerId={learnerId}
        />
      )}
      <PaginationComponent
        list={learnersList}
        elementsPerPage={learnerPerPage}
        handleWorkingListUpdate={setCurrentPageData}
      />
    </>
  );
};

export default connect(mapStateToProps)(LearnersItem);

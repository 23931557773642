import React, {useState} from 'react';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import styles from './TypeQuizPopup.module.scss';
import searchImg from '../../../../../../../../../../assets/images/icon/search-magnifier.svg';
import closePopupImg from '../../../../../../../../../../assets/images/icon/close-popup-icon.svg';
import PopupFullPage from '../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';
import Image from '../../../../../../../../../components/Image/Image';
import Input from '../../../../../../../../../components/Input/Input';
import ButtonColor from '../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import FilterDetailsQuiz from '../FilterDetailsQuiz/FilterDetailsQuiz';
import ListQuestionsItemComponent from './ListQuestionsItemComponent/ListQuestionsItemComponent';
import {connect} from 'react-redux';
import {dispatch} from '../../../../../../../../../state/store';
import {filterDetailsList} from '../FilterDetailsQuiz/filterDetailsList';
import {createStructuredSelector} from 'reselect';
import {
    makeSelectFilteredQuizDetails,
    makeSelectQuizDetails,
    makeSelectReportsList
} from '../../../../../../../../../state/selectors/reports';
import {setFilteredQuizDetailsList} from '../../../../../../../../../state/ducks/reports';
import ButtonText from '../../../../../../../../../components/Button/components/ButtonText/ButtonText';
import reportsService from '../../../../../../../../../services/server/reportsService/reportsService';
import checkedImg from '../../../../../../../../../../assets/images/icon/check-mark-white.svg'
import {ReactComponent as BellImgComponent} from '../../../../../../../../../../assets/images/icon/bell-icon.svg';
import Svg from '../../../../../../../../../components/SvgComponent/SvgComponent';
import {ReportStatus} from '../../../../../../../../../types/ReportStatus';

const mapStateToProps = createStructuredSelector({
    quizDetails: makeSelectQuizDetails(),
    reportsList: makeSelectReportsList(),
    filteredQuizDetails: makeSelectFilteredQuizDetails(),
});

const TypeQuizPopup = (props:any) => {
    const {hideQuizPopup, quizDetails, loader, filteredQuizDetails, onCheckedReport, onUncheckedReport, statusReport} = props;

    const [inputValue, setInputValue] = useState('');
    const [type, setType] = useState(filterDetailsList[0]);
    // const [disabledBtnReset, setDisabledBtnReset] = useState(false);
    // const attempts = quizDetails?.learnerAttempts;

    const onTypeClick = (type:any) => {
        setType(type);

        const newFilteredQuizDetailsList = quizDetails?.questionReports?.filter((item:any) => {

            if(type.id === 0 && item?.answers) {
                return item;
            }
            if(type.id === 1 && !item.answers) {
                return item;
            }
            if(type.id === -1) {
                return item;
            }
        });

        dispatch(setFilteredQuizDetailsList({...quizDetails, questionReports: newFilteredQuizDetailsList}));
    };

    const onChangeInput = (event:any) => {
        const {value} = event.currentTarget;

        const newFilteredQuizDetailsList = quizDetails?.questionReports?.filter((item:any) => {
           if(item?.title?.toLowerCase().trim().includes(value.toLowerCase().trim())) {
               return item;
           };
        });
        dispatch(setFilteredQuizDetailsList({...quizDetails, questionReports: newFilteredQuizDetailsList}));

        setInputValue(value);
    };

    const onClearInput = () => {
        setInputValue('');

        dispatch(setFilteredQuizDetailsList({...quizDetails, questionReports: quizDetails?.questionReports}));
    };

    // const onResetAttempts = async () => {
    //     await reportsService.resetAttempts(quizDetails?.questionReports?.id).then(() => {
    //         toast(`Attempts successfully reset!`);
    //         setDisabledBtnReset(true);
    //     }).catch((e) => {
    //         console.log(e);
    //         toast(`Opps, something went wrong. Try again!`);
    //         setDisabledBtnReset(false);
    //     });
    // };

    return (
        <PopupFullPage
                closePopup={hideQuizPopup}
                className={styles.HiddenBackground}
                isSupportOutsidePopupClick
            >
                <div className={styles.MainPopupContainer}>
                    <div className={styles.CloseContainer}>
                        <Image src={closePopupImg} onClick={hideQuizPopup} className={styles.CloseButton}/>
                    </div>
                    <div className={styles.MainContainer}>
                        {loader ?
                            <>
                                <span className={styles.TitleText}>{quizDetails?.moduleName}</span>
                                <div className={styles.WrapperInput}>
                                    <Image
                                        src={inputValue.length ? closePopupImg : searchImg}
                                        className={inputValue.length ? styles.ImageClose : styles.Image}
                                        onClick={onClearInput}
                                    />
                                    <Input
                                        onChange={onChangeInput}
                                        value={inputValue}
                                        className={styles.InputField}
                                        placeholder='Search for a question'
                                        type='text'
                                    />
                                </div>
                                <div className={styles.WrapperQuestions}>
                                    <FilterDetailsQuiz
                                        type={type}
                                        onTypeClick={onTypeClick}
                                    />
                                    {filteredQuizDetails?.questionReports?.length ?
                                        <ListQuestionsItemComponent
                                            // attempts={attempts}
                                        />
                                        : <div className={styles.Nodata}>No data found</div>}

                                </div>
                                {/*{attempts < 1 ?*/}
                                {/*    <>*/}
                                {/*        <div className={styles.WrapperBtns}>*/}
                                {/*            <ButtonColor*/}
                                {/*                className={styles.Button}*/}
                                {/*                onClick={hideQuizPopup}*/}
                                {/*            >*/}
                                {/*                OK*/}
                                {/*            </ButtonColor>*/}
                                {/*            <ButtonText*/}
                                {/*                className={classNames(styles.BtnReset, disabledBtnReset && styles.DisabledBtn)}*/}
                                {/*                onClick={onResetAttempts}*/}
                                {/*                disabled={disabledBtnReset}*/}
                                {/*            >*/}
                                {/*                Reset attempts*/}
                                {/*            </ButtonText>*/}
                                {/*        </div>*/}
                                {/*        <div className={styles.TextAttempts}>That was the last attempt made</div>*/}
                                {/*    </> :*/}

                                {statusReport === ReportStatus.UNCHECKED.Value &&
                                    <ButtonColor
                                        className={classNames(styles.Button, styles.BtnOk)}
                                        onClick={onCheckedReport}
                                    >
                                        <Image src={checkedImg} className={styles.ImageChecked}/>
                                        <span>Mark as checked</span>
                                    </ButtonColor>
                                }
                                {statusReport === ReportStatus.CHECKED.Value &&
                                    <ButtonColor
                                        className={classNames(styles.Button, styles.BtnOk, styles.ButtonUnchecked)}
                                        onClick={onUncheckedReport}>
                                        <Svg className={styles.ImageChecked}>
                                            <BellImgComponent/>
                                        </Svg>
                                        <span>Mark as unchecked</span>
                                    </ButtonColor>
                                }
                                {/*}*/}
                            </>:
                            <Loader
                                type="TailSpin"
                                color="#F85A40"
                                height={25}
                                width={25}
                                timeout={2000}
                            />
                        }
                        {/*<ToastContainer*/}
                        {/*    position="bottom-left"*/}
                        {/*    autoClose={3000}*/}
                        {/*    className={styles.ToastContainer}*/}
                        {/*    hideProgressBar={false}*/}
                        {/*    newestOnTop={false}*/}
                        {/*    closeOnClick*/}
                        {/*    rtl={false}*/}
                        {/*    pauseOnFocusLoss*/}
                        {/*    draggable*/}
                        {/*    pauseOnHover*/}
                        {/*/>*/}
                    </div>
                </div>
            </PopupFullPage>
    );
}

export default connect(mapStateToProps)(TypeQuizPopup);
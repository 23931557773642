import React from 'react';

import styles from '../../PipedriveComponent.module.scss';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import Image from '../../../../../../../../../../../components/Image/Image';
import onImg from '../../../../../../../../../../../../assets/images/icon/on-icon.svg';

const ConnectComponent = () => {
    return (
        <>
            <div className={styles.InfoText}>
                <div>Connect Gradual and Pipedrive to track sales team performance and</div>
                <div>get instant training suggestions.</div>
            </div>
            <a
                className={styles.ConnectionBtn}
                href='https://oauth.pipedrive.com/oauth/authorize?client_id=a64acc63df60263a&redirect_uri=https://app.gradual.io/pipedrive/validate'
            >
                <Image src={onImg} className={styles.ImgBtn}/>
                <span>Connect</span>
            </a>
        </>
    );
};

export default ConnectComponent;

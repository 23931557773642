import React, {useEffect, useState} from 'react';

import styles from './ProcessingPopupComponent.module.scss';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import Loader from '../../../components/Loader/Loader';
import Image from '../../../components/Image/Image';
import checkImg from '../../../../assets/images/icon/check-mark-white.svg';
import mainGif from '../../../../assets/gifs/successfull.gif';
import crossImg from '../../../../assets/images/icon/cross-icon.svg'
import {createStructuredSelector} from 'reselect';
import {makeSelectResponsePay} from '../../../state/selectors/subscription';
import {connect} from 'react-redux';
import subscriptionService from '../../../services/server/subscriptionService/subscriptionService';
import {dispatch} from '../../../state/store';
import {
    setPaymentInfo,
    setIsVisibleCardAndNextPayment,
    setIsVisiblePriceAndSeats, setSeatsInfo
} from '../../../state/ducks/subscription';
import classNames from 'classnames';
import ButtonColor from '../../../components/Button/components/ButtonColor/ButtonColor';

const mapStateToProps = createStructuredSelector({
    responsePay: makeSelectResponsePay()
});

const ProcessingPopupComponent = (props: any) => {
    const {processing, setIsVisibleProcessingPopup, responsePay, setIsVisiblePaymentPopup, errorPayment,
        setErrorPayment} = props;

    const [error, setError] = useState(errorPayment);

    useEffect(() => {
        setError(errorPayment);
    }, [errorPayment]);

    useEffect(() => {
        if (!responsePay?.error) {
            setVisiblePopups();
        }
    }, [responsePay, error]);

    const hideProcessingPopup = () => {
        setIsVisibleProcessingPopup(false);
    };

    const onClickPaymentPopup = () => {
        setErrorPayment('');
        setIsVisibleProcessingPopup(false);
        setIsVisiblePaymentPopup(true);
    };

    const setVisiblePopups = async () => {
        if(!error) {
            const newPaymentInfo = await subscriptionService.getPaymentInfo();
            const newSeatsInfo = await subscriptionService.getSeats();
            dispatch(setSeatsInfo(newSeatsInfo));
            dispatch(setPaymentInfo(newPaymentInfo));
            dispatch(setIsVisibleCardAndNextPayment(false));
            dispatch(setIsVisiblePriceAndSeats(false));
        } else {
            dispatch(setIsVisibleCardAndNextPayment(true));
            dispatch(setIsVisiblePriceAndSeats(true));
        }
    };

    return (
        <PopupFullPage
            classNameMainContainer={classNames(styles.MainPopupContainer, processing && styles.WrapperProcessing)}
            isSupportOutsidePopupClick
            closePopup={hideProcessingPopup}
            classNameClose={styles.CloseImg}
        >
            {processing ?
                <div>
                    <div className={styles.TitleProcessing}>Processing</div>
                    <Loader
                        classNameMainContainer={styles.MainContainer}
                    />
                </div>
                :
                responsePay.error || errorPayment ?
                    <>
                        <div className={styles.ContainerError}>
                            <div className={styles.TitleError}>Payment failed</div>
                            <div className={classNames(styles.ImgSuccessful, styles.ImgError)}>
                                <Image src={crossImg} className={styles.ErrorImg}/>
                            </div>
                        </div>
                        <div className={styles.TextError}>
                            <div>Your payment was not successfuly</div>
                            <div>processed. Reason:</div>
                        </div>
                        <div className={classNames(styles.TextError, styles.TextErrorDetails)}>{responsePay?.error?.message || error?.message}</div>
                        <ButtonColor
                            onClick={onClickPaymentPopup}
                            className={styles.BtnTryAgain}
                        >
                            Try again
                        </ButtonColor>
                    </> :
                    <>
                        <div className={styles.ContainerSuccessful}>
                            <div className={styles.TitleSuccessful}>Payment successful</div>
                            <div className={styles.ImgSuccessful}>
                                <Image  src={checkImg}/>
                            </div>
                        </div>
                        <Image
                            className={styles.GifImg}
                            src={mainGif}
                        />
                        <div className={styles.TextSuccessful}>
                            <div>Welcome to Big Boss Club! </div>
                            <div>Enjoy scaling your team with Gradual</div>
                        </div>
                        <ButtonColor className={styles.BtnContinue} onClick={hideProcessingPopup}>
                            Continue
                        </ButtonColor>
                    </>
            }
        </PopupFullPage>
    );
};

export default connect(mapStateToProps)(ProcessingPopupComponent);
import React from "react";

import {DeletePopup} from "../../../../../../../components/DeletePopup/DeletePopup";
import {dispatch} from "../../../../../../../state/store";
import {setDraftModule} from "../../../../../../../state/ducks/courseConstructor";
import {updateCourseModuleList} from "../../../draftCourseService";
import moduleService from "../../../../../../../services/server/moduleService/moduleService";

const DeleteQuizComponent = (props:any) => {
    const {draftModule, finishDeleteQuiz} = props;

    const deleteQuiz = async () => {
        await moduleService.deleteQuizById(draftModule?.quiz.id);

        const updatedModule = {...draftModule, quiz: null};
        dispatch(setDraftModule(updatedModule));
        updateCourseModuleList(updatedModule);

        finishDeleteQuiz();
    };

    return (
        <DeletePopup
            onDelete={deleteQuiz}
            onHide={finishDeleteQuiz}
            textData={{
                title:'Are you sure?',
                header:{
                    general:'You are about to remove the quiz!',
                    specific: '',
                    note:'It\'ll be gone forever and we won\'t be able to recover it.'
                }
            }}
        />
    );
};

export default DeleteQuizComponent;
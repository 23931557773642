import React from 'react';

import styles from './MenuComponent.module.scss';
import {ReactComponent as ArrowImg} from '../../../../../../../../../../../../../../../../assets/images/icon/arrow-right-menu.svg';
import Svg from '../../../../../../../../../../../../../../../components/SvgComponent/SvgComponent';
import {pipelineType} from '../../../../../../../../../../../../../../../types/integrationType/dataForPipedrive';
import classNames from 'classnames';

type MenuComponent = {
    isShowStagesConversions:boolean
    pipelines:Array<pipelineType>
    pipelineId: number|undefined
    onClickShowSubMenu:Function
};

const MenuComponent:React.FC<MenuComponent> = ({onClickShowSubMenu, isShowStagesConversions, pipelines, pipelineId}) => {
    return (
        <div className={styles.ContainerMenu}>
            <div className={styles.TitleMenu}>Select pipeline</div>
            <div className={styles.SubMenu}>
                {pipelines.map((item) => {
                    const id = item.id;
                    const name = item.name;
                    const stageConversions = item.stageConversions;

                    return (
                        <div
                            className={classNames(styles.WrapperTextImgMenu, (isShowStagesConversions && pipelineId === id) && styles.ActivePipeline)}
                            key={name}
                            onClick={() => onClickShowSubMenu(id, stageConversions)}
                        >
                            <div className={styles.PipelinesItem}>{name}</div>
                            <Svg className={styles.ImageMenu}>
                                <ArrowImg/>
                            </Svg>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default MenuComponent;

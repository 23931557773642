import classNames from 'classnames';
import React from 'react';

import styles from './Video.module.scss';

const Video = (props:any) => {
    const {src, classNameVideoContainer, classNameVideoElement, isIframe} = props;
    const classNameContainer = classNames(styles.VideoContainer, classNameVideoContainer);
    const classNameVideo = classNames(styles.Video, classNameVideoElement);

    return (
        <div className={classNameContainer}>
            {
                isIframe ? (<iframe className={classNameVideo} src={src} allowFullScreen frameBorder="0" />) :
                    (<video className={classNameVideo}
                            controls>
                        <source src={src}
                                type={"video/mp4"}/>
                        Sorry this browser does not support a video play!
                    </video>)
            }
        </div>
    );
};

export default Video;
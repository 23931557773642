import React from 'react';

import styles from './TabItemComponent.module.scss';
import Image from '../../../../../components/Image/Image';
import ReactTooltip from 'react-tooltip';
import {TabListNames} from '../MenuListComponent/menuTabList';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsLegacyUser, makeSelectPaymentInfo} from '../../../../../state/selectors/subscription';
import {connect} from 'react-redux';

const mapStateToProps = createStructuredSelector({
    isLegacyUser: makeSelectIsLegacyUser(),
    paymentInfo: makeSelectPaymentInfo()
});

const TabItemComponent = (props:any) => {
    const {onTabButtonClick, item, activeTab, isLegacyUser, paymentInfo} = props;
    const {name, activeIcon, icon, id} = item;

    const isSelectTab = activeTab.id === id;
    const isSubscribed = paymentInfo?.last4;
    const isVisibleProHint = name === TabListNames.Team && !isLegacyUser && !isSubscribed;

    return (
        <div
            className={`${styles.TabButton} ${isSelectTab && styles.SelectTabButton}`}
            onClick={() => onTabButtonClick(id)}
        >
            <Image
                src={isSelectTab ? activeIcon : icon}
                className={styles.TabIcon}
            />
            {name}

            {false &&
                <>
                    <a
                        data-for='global'
                        data-tip='<div>Invite team to collaborate on training and track </div><div>learning progress together. <span>Upgrade to PRO</span></div>'
                        className={styles.WrapperPro}
                    >
                        PRO
                    </a>
                    <ReactTooltip
                    id='global'
                    delayHide={500}
                    className={styles.Tooltip}
                    place='bottom'
                    html={true}
                    multiline={true}
                    effect='solid'
                    />
                </>
            }
        </div>
    );
};

export default connect(mapStateToProps)(TabItemComponent);
import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.subscription;

const makeSelectPaymentInfo = () => createSelector(selectGlobal, (globalState:any) => globalState.paymentInfo);

const makeSelectResponsePay = () => createSelector(selectGlobal, (globalState:any) => globalState.responsePay);

const makeSelectIsVisibleCardAndNextPayment = () => createSelector(selectGlobal, (globalState:any) => globalState.isVisibleCardAndNextPayment);

const makeSelectIsVisiblePriceAndSeats = () => createSelector(selectGlobal, (globalState:any) => globalState.isVisiblePriceAndSeats);

const makeSelectSeatsInfo = () => createSelector(selectGlobal, (globalState:any) => globalState.seatsInfo);

const makeSelectResponseChangeCard = () => createSelector(selectGlobal, (globalState:any) => globalState.responseChangeCard);

const makeSelectIsLegacyUser = () => createSelector(selectGlobal, (globalState:any) => globalState.isLegacyUser);

export {
    makeSelectPaymentInfo,
    makeSelectResponsePay,
    makeSelectIsVisibleCardAndNextPayment,
    makeSelectIsVisiblePriceAndSeats,
    makeSelectSeatsInfo,
    makeSelectResponseChangeCard,
    makeSelectIsLegacyUser
};

import React from "react";

import styles from "./CustomersComponent.module.scss";
import CustomersItem from "./components/CustomersItem/CustomersItem";

const CustomersComponent = () => {
  return (
    <>
      <div className={styles.TitleCustomers}>Customers</div>
      <div className={styles.ContainerConsumers}>
        <div className={styles.HeaderCostumers}>
          <span>Company</span>
          <span>Email</span>
          <span>Sign up time</span>
          <span>Source</span>
          <span>Users</span>
          <span>Billing</span>
        </div>
        <CustomersItem />
      </div>
    </>
  );
};

export default CustomersComponent;

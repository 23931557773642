import adminService from '../adminService/adminService';
import stringUtils from '../../utils/stringUtilsService';
import config from '../../../config/config';
import {dispatch} from '../../../state/store';
import {setMetricsForDashboard} from '../../../state/ducks/performance';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {deleteLearnerPath, learnersListPath, learnerFilterPath, getCvsPath, setRenameFirstNamePath,
    setRenameLastNamePath, resetProgressPath, getMetricsForAnalyticsPath, reviewCoursePath} = externalLinks;

const getFilterLearners = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, learnersListPath);

    return await adminService.getFilterLearnerList(formattedUrl);
}

const deleteLearners = async (learnerId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteLearnerPath);
    const updateUrl = stringUtils.formatString(formattedUrl, learnerId)

    await adminService.deleteLearners(updateUrl);
}

const getFiltersLearnerName = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, learnerFilterPath);
    const updatedUrl = stringUtils.formatString(formattedUrl,'name');

    return await adminService.getFiltersLearner(updatedUrl);
}

const getFiltersLearnerCourse = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, learnerFilterPath);
    const updatedUrl = stringUtils.formatString(formattedUrl,'course');

    return await adminService.getFiltersLearner(updatedUrl);
}

const getCsvLearners = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getCvsPath);

    return await adminService.getCsvLearners(formattedUrl);
}

const renameLearnerFirstName = async (id:number, firstName:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setRenameFirstNamePath);

    return await adminService.renameLearnerFirstName(formattedUrl, id, firstName);
}

const renameLearnerLastName = async (id:number, lastName:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setRenameLastNamePath);

    return await adminService.renameLearnerLastName(formattedUrl, id, lastName);
};

const resetProgress = async (learnerId:number, courseId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, resetProgressPath);

    return await adminService.resetProgress(formattedUrl, learnerId, courseId);
};

const getMetricsForAnalytics = async (startDate: string, endDate: string, learnerId: number, userId: number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getMetricsForAnalyticsPath);

    const updatedUrl = stringUtils.formatString(formattedUrl, startDate, endDate, learnerId, userId);

    const metricsList = await adminService.getMetricsForAnalytics(updatedUrl);

    dispatch(setMetricsForDashboard(metricsList));
};

const reviewCourse = async (learnerId: number, courseId: number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, reviewCoursePath);

    return await adminService.reviewCourse(formattedUrl, learnerId, courseId);
};

export default {
    deleteLearners,
    getFilterLearners,
    getFiltersLearnerName,
    getFiltersLearnerCourse,
    getCsvLearners,
    renameLearnerFirstName,
    renameLearnerLastName,
    resetProgress,
    getMetricsForAnalytics,
    reviewCourse
}
import date from '../../../../../../../../../../services/date/date';
import calendar from '../../../../../../../../../../services/date/calendar';

const filterByCalendarAndPeriod = (firstDate:string, lastDate:string, setPeriod:Function) => {
    const selectedThisWeek = firstDate === date.formattedDate(calendar.firstDayThisWeek()) &&
        lastDate === date.formattedDate(calendar.lastDayThisWeek());
    const selectedLastWeek = firstDate === date.formattedDate(calendar.lastMondayPreviousWeek()) &&
        lastDate === date.formattedDate(calendar.lastSundayPreviousWeek());
    const selectedThisMonth = firstDate === date.formattedDate(calendar.thisMonthFirstDay()) &&
        lastDate === date.formattedDate(calendar.thisMonthLastDay());
    const selectedLastMonth = firstDate === date.formattedDate(calendar.previousMonthFirstDay()) &&
        lastDate === date.formattedDate(calendar.previousMonthLastDay());
    const selectedThisYear = firstDate === date.formattedDate(calendar.thisYearFirstDay()) &&
        lastDate === date.formattedDate(calendar.thisYearLastDay());
    const selectedLastYear = firstDate === date.formattedDate(calendar.previousYearFirstDay()) &&
        lastDate === date.formattedDate(calendar.previousYearLastDay());

    selectedThisWeek ? setPeriod({id: 1, name: 'This week'}) :
        selectedLastWeek ? setPeriod( {id: 2, name: 'Last week'}) :
            selectedThisMonth ? setPeriod({id: 3, name: 'This month'}) :
                selectedLastMonth ? setPeriod({id: 4, name: 'Last month'}) :
                    selectedThisYear ? setPeriod({id: 5, name: 'This year'}) :
                        selectedLastYear ? setPeriod({id: 6, name: 'Last year'}) :
                            setPeriod( {id: 7, name: 'Custom select'});
}

export default {
    filterByCalendarAndPeriod
}

import classNames from 'classnames';
import React, {useRef, useState} from 'react';

import styles from './MultipleChoiceQuestionComponent.module.scss';
import plusImg from '../../../../../../../../../../../../assets/images/icon/plus-add-tomato.svg';
import Image from '../../../../../../../../../../../components/Image/Image';
import ButtonText from '../../../../../../../../../../../components/Button/components/ButtonText/ButtonText';
import VariantsListComponent from './components/VariantsListComponent/VariantsListComponent';
import {dispatch} from '../../../../../../../../../../../state/store';
import {setDraftQuizQuestion} from '../../../../../../../../../../../state/ducks/courseConstructor';
import draftModuleQuizService from '../../../../draftModuleQuizService';
import moduleService from '../../../../../../../../../../../services/server/moduleService/moduleService';
import ImageFullContainer from './components/ImageFullContainer/ImageFullContainer';
import AttachImageComponent from './components/AttachImageComponent/AttachImageComponent';
import TextArea from '../../../../../../../../../../../components/TextArea/TextArea';

const MultipleChoiceQuestionComponent = (props:any) => {
    const {onQuestionTitleChange, addImageToQuestion, activeQuestion, draftModuleQuiz, onImageDelete} = props;

    const [isVisibleImgFull, setIsVisibleImgFull] = useState(false);
    const variantsListRef = useRef<HTMLDivElement>(null);
    const classNameDragDrop = classNames(styles.ActionIcon, styles.DragDropImage);
    const originalImage = activeQuestion?.attachFile?.link;

    const titleChange = (event: React.FormEvent<HTMLInputElement>) => {
        onQuestionTitleChange(event.currentTarget.value, activeQuestion.id);

        dispatch(setDraftQuizQuestion({...activeQuestion, multipleChoiceQuestion: {...activeQuestion.multipleChoiceQuestion, title: event.currentTarget.value}}));
    };

    const updateDraftModuleQuiz = (updatedMultipleQuestion:any) => {
        const updatedDraftQuestion = {...activeQuestion, multipleChoiceQuestion: updatedMultipleQuestion};
        draftModuleQuizService.updateDraftModuleQuizQuestion(draftModuleQuiz, updatedDraftQuestion);
    };

    const updateVariantList = (newVariantList:any[]) => {
        const updatedMultipleQuestion = {...activeQuestion.multipleChoiceQuestion, variants: newVariantList};
        dispatch(setDraftQuizQuestion({...activeQuestion, multipleChoiceQuestion: updatedMultipleQuestion}));

        updateDraftModuleQuiz(updatedMultipleQuestion);
    };

    const onVariantImageUpdate = (attachFile:any, id:number) => {
        const updatedVariantsList = activeQuestion.multipleChoiceQuestion.variants.filter((variant:any) => {
            if(variant.id === id) {
                variant.attachFile = attachFile;
            }

            return variant;
        });

        updateVariantList(updatedVariantsList);
    };

    const variantTitleQuestionChange = async (title:string, variantId:number, isSelected:boolean) => {
        const updatedVariantsList = activeQuestion.multipleChoiceQuestion.variants.filter((variant:any) => {
            if(variant.id === variantId) {
                variant.title = title;
            }

            return variant;
        });

        updateVariantList(updatedVariantsList);
        await moduleService.updateQuestionVariant(variantId, title, isSelected);
    };

    const variantCheckedQuestionChange = async (title:string, isSelected:boolean, variantId:number) => {
        const updatedVariantsList = activeQuestion.multipleChoiceQuestion.variants.filter((variant:any) => {
            if(variant.id === variantId) {
                variant.isSelected = !variant.isSelected;
            }

            return variant;
        });

        const updatedMultipleQuestion = {...activeQuestion.multipleChoiceQuestion, variants: updatedVariantsList};
        dispatch(setDraftQuizQuestion({...activeQuestion, multipleChoiceQuestion: updatedMultipleQuestion}));

        updateDraftModuleQuiz(updatedMultipleQuestion);
        await moduleService.updateQuestionVariant(variantId, title, !isSelected);
    };

    const moveLastVariantIntoView = () => {
        if (variantsListRef.current) {
            variantsListRef.current.scrollTo({
                top: variantsListRef.current.scrollHeight,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    const onAddVariantButtonClick = async () => {
        const newVariant = await moduleService.addQuizVariant(activeQuestion.id);
        const updatedVariantList = activeQuestion.multipleChoiceQuestion.variants.concat(newVariant);

        const updatedMultipleQuestion = {...activeQuestion.multipleChoiceQuestion, variants: updatedVariantList};
        dispatch(setDraftQuizQuestion({...activeQuestion, multipleChoiceQuestion: updatedMultipleQuestion}));

        updateDraftModuleQuiz(updatedMultipleQuestion);
        moveLastVariantIntoView();
    };

    const onDeleteVariantButtonClick = async (variantId:number, isLastVariant:boolean) => {
        if(!isLastVariant) {
            await moduleService.deleteQuizVariant(variantId);

            const updatedVariantList = activeQuestion.multipleChoiceQuestion.variants.filter((variant:any) => variant.id !== variantId);
            const updatedMultipleQuestion = {...activeQuestion.multipleChoiceQuestion,
                variants: updatedVariantList};
            dispatch(setDraftQuizQuestion({...activeQuestion, multipleChoiceQuestion: updatedMultipleQuestion}));

            updateDraftModuleQuiz(updatedMultipleQuestion);
        }
    };

    return (
        <>
            <div className={styles.MultipleChoiceContainer}>
                <span className={styles.CorrectText}>Correct</span>
                <div className={styles.WrapperImg}>
                    <div className={styles.WrapperTitleQuestionInput}>
                        <TextArea
                            placeholder='Type a question'
                            onChange={titleChange}
                            className={styles.TitleQuestionInput}
                            value={activeQuestion.multipleChoiceQuestion.title || ''}
                        />
                    </div>
                    <AttachImageComponent
                        originalImage={originalImage}
                        setIsVisibleImgFull={setIsVisibleImgFull}
                        id={activeQuestion.multipleChoiceQuestion.id}
                        addImage={addImageToQuestion}
                        deleteImage={onImageDelete}
                    />
                </div>
                <VariantsListComponent
                    draftModuleQuiz={draftModuleQuiz}
                    variantsListRef={variantsListRef}
                    classNameDragDrop={classNameDragDrop}
                    activeQuestion={activeQuestion}
                    variantTitleQuestionChange={variantTitleQuestionChange}
                    variantCheckedQuestionChange={variantCheckedQuestionChange}
                    onDeleteVariantButtonClick={onDeleteVariantButtonClick}
                    onVariantImageUpdate={onVariantImageUpdate}
                    classNameActiveIcon={styles.ActionIcon}
                />
                <ButtonText className={styles.AddVariantButton} onClick={onAddVariantButtonClick}>
                    <Image src={plusImg} className={styles.ActionIcon} />
                    <span className={styles.AddVariantText}>Add an option</span>
                </ButtonText>
            </div>
            {isVisibleImgFull &&
                <ImageFullContainer
                    imageSrc={originalImage}
                    setIsVisibleImgFull={setIsVisibleImgFull}
                />
            }
        </>
    );
};

export default MultipleChoiceQuestionComponent;
import classNames from 'classnames';
import React, {useRef, useEffect, MutableRefObject} from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput, {PhoneInputProps} from 'react-phone-input-2';

import styles from './CompanyNumberComponent.module.scss';
import validationService from '../../../../../../../../services/server/validationService/validationService';

type CompanyNumberComponent = {
    phone:string;
    setPhone: Function;
};

const CompanyNumberComponent:React.FC<CompanyNumberComponent> = ({phone, setPhone}) => {
    const currentCountryCode:any = localStorage.getItem('currentCountryCode');
    // const inputClassName = classNames(styles.PhoneInput, isInputError && styles.InputError);
    const phoneInputRef = useRef(null);


    const onPhoneFieldChange = (phone:string) => {
        setPhone(phone);
    }

    return (
        <div className={styles.PhoneInputMainContainer}>
            <label className={styles.Label}>Company contact number</label>
            <div>
                <PhoneInput
                    inputClass={styles.PhoneInput}
                    buttonClass={styles.ButtonDropDown}
                    onChange={(phone) => onPhoneFieldChange(phone)}
                    country={currentCountryCode}
                    value={phone}
                    dropdownClass={styles.DropDown}
                    placeholder='Phone number'
                />
            </div>
            <div className={styles.Description}>Provide your contact number and we’ll set up account for free.</div>
        </div>
    );
};

export default CompanyNumberComponent;
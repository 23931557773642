import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.userDetails;

const makeSelectUserDetails = () => createSelector(selectGlobal, (globalState:any) => globalState);

const makeSelectUserRoles = () => createSelector(selectGlobal, (globalState:any) => globalState.roles);

const makeSelectUserId = () => createSelector(selectGlobal, (globalState:any) => globalState.id);

export {
  makeSelectUserDetails,
  makeSelectUserRoles,
  makeSelectUserId
};
import React, {useState} from 'react';

import styles from './WelcomeComponent.module.scss';
import plusImg from '../../../../../../../../../../../../assets/images/icon/plus-white-icon.svg';
import ButtonColor from '../../../../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import Image from '../../../../../../../../../../../components/Image/Image';
import CreateNewCourseComponent from '../../../CreateNewCourseComponent/CreateNewCourseComponent';
import {dispatch} from '../../../../../../../../../../../state/store';
import {setDraftCourse} from '../../../../../../../../../../../state/ducks/courseConstructor';

const WelcomeComponent = (props:any) => {
    const {onClick} = props;
    const [isVisibleWelcomeComponent, setIsVisibleWelcomeComponent] = useState(true);
    const [isVisibleCreateCourseComponent, setIsVisibleCreateCourseComponent] = useState(false);

    const hideCreateNewCourseComponent = () => {
        setIsVisibleCreateCourseComponent(false);
        setIsVisibleWelcomeComponent(true);
    };

    return (
        <>
        {isVisibleWelcomeComponent &&
            <div className={styles.WelcomeContainer}>
                <span className={styles.TitleText}>Welcome to </span>
                <span className={styles.Name}>Gradual</span>
                <span className={styles.DescriptionText}>
                    <div>create your first interactive learning path for</div>
                    <div>the team (newcomer onboarding, tools overview, etc)</div>
                </span>
                <ButtonColor className={styles.Button} onClick={onClick}>
                    <div className={styles.ButtonContainer}>
                        <Image src={plusImg} className={styles.PlusImage}/>
                        <span>Create my first course</span>
                    </div>
                </ButtonColor>
            </div>
        }
        {isVisibleCreateCourseComponent &&
            <CreateNewCourseComponent hideCreateNewCourseComponent={hideCreateNewCourseComponent} />
        }
        </>
    );
};

export default WelcomeComponent;
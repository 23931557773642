import React, { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./LearnerInviteContainer.module.scss";
import Input from "../../../../../../../../../../../components/Input/Input";
import LearnerInviteList from "./components/LearnerInviteList/LearnerInviteList";
import invitationConstructionService from "../../invitationConstructionService";

const LearnerInviteContainer = (props: any) => {
  const {
    invitationList,
    setInvitationList,
    isPhoneInvitation,
    isCorrect,
    setIsCorrect,
    setIsPressedEnter,
    memberInvitation,
  } = props;

  const [inputValue, setInputValue] = useState("");
  const placeholderInput =
    isPhoneInvitation && !memberInvitation
      ? "Add users by phone +380"
      : !isPhoneInvitation && !memberInvitation
      ? "Add users by email"
      : "Add members by email";

  useEffect(() => {
    !invitationList.length && setIsPressedEnter(false);
  }, [invitationList]);

  const setInputCorrect = () => {
    setIsCorrect(true);
  };

  const setInputWrong = () => {
    setIsCorrect(false);
  };

  const editInvitation = (
    initialInvitationValue: string,
    updatedInvitationValue: string
  ) => {
    const updatedList = [...invitationList];
    const invitationIndex = updatedList.findIndex(
      (invitation: string) => initialInvitationValue === invitation
    );

    updatedList.splice(invitationIndex, 1, updatedInvitationValue);
    setInvitationList(updatedList);
  };

  const removeInvitation = (invitationToRemove: string) => {
    const updatedList = [...invitationList];
    const invitationIndex = updatedList.findIndex(
      (invitation: string) => invitationToRemove === invitation
    );

    updatedList.splice(invitationIndex, 1);
    setInvitationList(updatedList);
  };

  const onKeyUpChange = (event: any) => {
    const invitationInput = event.currentTarget;
    const newInputValue = invitationInput.value;

    if (newInputValue === "") {
      setInputCorrect();
      if (invitationList.length) {
        setIsPressedEnter(true);
      } else {
        setIsPressedEnter(false);
      }
    } else {
      const keyPressed = event.key;
      if (keyPressed === "Enter") {
        const editedInput =
          invitationConstructionService.removeSeparators(newInputValue);
        const attemptedInvitationList =
          invitationConstructionService.turnIntoList(editedInput);
        const correctInvitationList: string[] = [],
          incorrectInvitationList: string[] = [];

        attemptedInvitationList.forEach((invitation: string) => {
          if (
            invitationConstructionService.isInvitationValid(
              isPhoneInvitation,
              invitation
            ) &&
            !invitationConstructionService.isInvitationInList(
              invitationList,
              invitation
            )
          ) {
            correctInvitationList.push(invitation);
          } else if (invitation !== "") {
            incorrectInvitationList.push(invitation);
          }
        });

        if (correctInvitationList.length) {
          const updatedInvitationList = [
            ...invitationList,
            ...correctInvitationList,
          ];
          setInvitationList(updatedInvitationList);
        }
        if (correctInvitationList.length && !incorrectInvitationList.length) {
          setInputValue("");
          invitationInput.blur();
          setInputCorrect();
          setIsPressedEnter(true);
        }

        if (incorrectInvitationList.length) {
          setInputValue(incorrectInvitationList.join(" "));
          setInputWrong();
          setIsPressedEnter(false);
        }
      }
    }
  };

  const onInputBlur = () => {
    setInputCorrect();
  };

  const onInputFocus = () => {
    if (
      !invitationConstructionService.isInvitationValid(
        isPhoneInvitation,
        inputValue
      ) &&
      inputValue !== ""
    ) {
      setInputWrong();
    }
  };

  const onInputChange = (event: any) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);
  };

  const onInvitationUpdate = (
    initialInvitationValue: string,
    updatedInvitationValue: string
  ) => {
    if (
      invitationConstructionService.isInvitationValid(
        isPhoneInvitation,
        updatedInvitationValue
      )
    ) {
      if (
        !invitationConstructionService.isInvitationInList(
          invitationList,
          updatedInvitationValue
        )
      ) {
        editInvitation(initialInvitationValue, updatedInvitationValue);
      }
    } else {
      removeInvitation(initialInvitationValue);
      setInputValue(updatedInvitationValue);
      setInputWrong();
    }
  };

  const onInvitationDelete = (invitationToRemove: string) => {
    removeInvitation(invitationToRemove);
  };

  return (
    <div>
      <div
        className={
          (isCorrect && styles.LearnerInviteContainer) ||
          classNames(
            styles.LearnerInviteContainer,
            styles.InvalidInviteInputField
          )
        }
      >
        <LearnerInviteList
          onInvitationDelete={onInvitationDelete}
          onInvitationUpdate={onInvitationUpdate}
          invitationList={invitationList}
        />
        <Input
          className={styles.InviteInputField}
          value={inputValue}
          onChange={onInputChange}
          onKeyPress={onKeyUpChange}
          placeholder={placeholderInput}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
      </div>
      <div>
        {!isCorrect && isPhoneInvitation && (
          <div
            style={{ color: "#F85A40", marginTop: "12px" }}
            className={styles.ErrorText}
          >
            Invalid phone
          </div>
        )}
        {!isCorrect && !isPhoneInvitation && (
          <div
            style={{ color: "#F85A40", marginTop: "12px" }}
            className={styles.ErrorText}
          >
            Invalid email
          </div>
        )}
      </div>
    </div>
  );
};

export default LearnerInviteContainer;

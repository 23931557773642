import userInfoService from "../server/userInfoService/userInfoService";
import {getState} from "../../state/store";

const fetchUserData = async () => await userInfoService.getUserInfo();
const getUserInfoFromState = () => getState().userDetails;

type DataLayerEvent = Record<string, string>;

interface CompanyPortfolio {
    companyName: string
    teamSize:string
    role: string
    phoneNumber: string
}

const pushEventToDataLayer = (event: DataLayerEvent) => {
    //@ts-ignore
    window.dataLayer = window.dataLayer || [];
    //@ts-ignore
    window.dataLayer.push(event);
};

const addNewCoursePress = async (isTemplate:boolean)  => {
    const userId = await fetchUserData();
    if (userId) {
        pushEventToDataLayer({
            "event": "create_new_course",
            "user_id": userId.toString(),
            "course_type": isTemplate ? "template" : "regular"
        });
    }
};

const addTabClickEvent = async (pageTitle: string) => {
    const userId = await fetchUserData();
    if (userId) {
        pushEventToDataLayer({
            "event": "virtualPageview",
            "user_id": userId.toString(),
            "pageUrl": "https://app.gradual.io/account#" + pageTitle.toLowerCase(),
            "pageTitle": pageTitle,
        });
    }
};

const userDetailsFilter = (pushObj: {}) => {
    const userDetails = getUserInfoFromState();
    if (!!userDetails.id) {
        pushEventToDataLayer({...pushObj, "user_id": userDetails.id, "user_type": userDetails.roles.join("; ")});
    } else {
        fetchUserData()
            .then(details => {
                if (details) {
                    pushEventToDataLayer({...pushObj, "user_id": details.id.toString(), "user_type": userDetails.roles.join("; ")});
                }
            })
            .catch(console.error);
    }
};

const addSignUpToGtm = (email:string) => userDetailsFilter({
    "event": "sign_up",
    "registration_source": "admin",
    "email": email
});

const addSignIn = (email:string) => userDetailsFilter({
    "event": "login",
    "email": email
}) ;

const addCompanyPortfolio = ({companyName, teamSize, role, phoneNumber}: CompanyPortfolio) =>
    userDetailsFilter({
        "event": "company_data_filled",
        "phone_number": phoneNumber,
        "company": companyName,
        "team_size": teamSize,
        "role": role
    });

const addUsageGoals = (goalList: string[]) => userDetailsFilter({
    "event": "goals_selected",
    "goals": goalList.join("; ")
});

export default {
    addNewCoursePress,
    addTabClickEvent,
    addSignUpToGtm,
    addCompanyPortfolio,
    addUsageGoals,
    addSignIn
};
import {createSelector} from 'reselect';

const selectGlobal = (state:any) => state.courseConstructor;

const makeSelectDraftCourse = () => createSelector(selectGlobal, (state:any) => state.draftCourse);

const makeSelectDraftModule = () => createSelector(selectGlobal, (state:any) => state.draftModule);

const makeSelectDraftModuleQuiz = () => createSelector(selectGlobal, (state:any) => state.draftModuleQuiz);

const makeSelectDraftQuizQuestion = () => createSelector(selectGlobal, (state:any) => state.draftQuizQuestion);

const makeSelectFilteredDraftModuleQuiz = () => createSelector(selectGlobal, (state:any) => state.filteredDraftModuleQuiz);

export {
    makeSelectDraftCourse,
    makeSelectDraftModule,
    makeSelectDraftModuleQuiz,
    makeSelectDraftQuizQuestion,
    makeSelectFilteredDraftModuleQuiz
};

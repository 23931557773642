import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import styles from './VariantsListComponent.module.scss';
import {ReactComponent as DragAndDropImg} from '../../../../../../../../../../../../../../assets/images/icon/drag-drop-quiz-questions-icon.svg';
import {ReactComponent as DeleteImg} from '../../../../../../../../../../../../../../assets/images/icon/delete-content-icon.svg';
import Input from '../../../../../../../../../../../../../components/Input/Input';
import Svg from '../../../../../../../../../../../../../components/SvgComponent/SvgComponent';
import draftModuleQuizService from "../../../../../../draftModuleQuizService";
import ImageFullContainer from '../ImageFullContainer/ImageFullContainer';
import AttachImageComponent from '../AttachImageComponent/AttachImageComponent';
import moduleService from '../../../../../../../../../../../../../services/server/moduleService/moduleService';

const VariantsListComponent = (props:any) => {
    const {variantsListRef, activeQuestion, classNameDragDrop, variantTitleQuestionChange, draftModuleQuiz, variantCheckedQuestionChange,
        classNameActiveIcon, onDeleteVariantButtonClick, onVariantImageUpdate} = props;

    const [isVisibleImgFull, setIsVisibleImgFull] = useState<boolean>(false);
    const [previewLink, setPreviewLink] = useState<string>('');

    const isLastVariant = activeQuestion.multipleChoiceQuestion.variants.length < 2;
    const classNameActiveButton = classNames(classNameActiveIcon, isLastVariant && styles.UnavailableIcon);
    const [variantsList, setVariantsList] = useState([]);
    const _dragEl = document.getElementById('draggable')!;

    useEffect(() => {
        const sortedVariantsList = activeQuestion?.multipleChoiceQuestion?.variants.sort((a:any, b:any) => a.sequenceId > b.sequenceId ? 1 : -1);
        setVariantsList(sortedVariantsList);
    }, [activeQuestion]);

    const addImageToVariant = async (file:File, id:number) => {
        const newFiles:any = new FormData();
        newFiles.append('image', file);
        newFiles.append('variantId', id);

        const attachFile:any = await moduleService.addAttachFileVariant(newFiles);
        onVariantImageUpdate(attachFile, id);
    };

    const deleteImageFromVariant = (imageId:number) => {
        moduleService.deleteAttachFileQuiz(imageId);
    };

    const onDeleteImageFromVariant = (imageId:number, variantId:number) => {
        deleteImageFromVariant(imageId);
        onVariantImageUpdate(null, variantId);
    }

    const showVariantImagePreview = (url:string) => {
        setPreviewLink(url);
        setIsVisibleImgFull(true);
    };

    const createNewPortal = (draggable:any, element:any) => {
        if(draggable) {
           return createPortal(element, _dragEl);
        }
        return element;
    };

    const updateVariantListSequence = async (updatedVariants:any[], previousVariants:any[]) => {
        const mutatedVariantListBySequence = draftModuleQuizService.updateVariantsListSequence(updatedVariants, previousVariants);
        const updatedQuestion = draftModuleQuizService.updateDraftQuizQuestionVariantList(activeQuestion, mutatedVariantListBySequence);
        draftModuleQuizService.updateDraftModuleQuizQuestion(draftModuleQuiz, updatedQuestion);
    };

    const onDragEnd = async (result:any) => {
        const {destination, source, reason} = result;

        if(!destination || reason === 'CANCEL') {
            return;
        }

        if(destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const variants = [...variantsList];
        const droppedVariants = variantsList[source.index];

        variants.splice(source.index, 1);
        variants.splice(destination.index, 0, droppedVariants);

        updateVariantListSequence(variants, variantsList);
    };

    return (
        <>
            <div className={styles.VariantsListContainer} ref={variantsListRef}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='id2'>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {variantsList.map((item:any, index:number) => {
                                    const id = item.id;
                                    const isSelected = item.isSelected;
                                    const attachFile = item.attachFile;
                                    const variantTitle = item.title;
                                    const originalImage = attachFile?.link;

                                    const variantTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
                                        variantTitleQuestionChange(event.currentTarget.value, id, isSelected);
                                    };

                                    return (
                                        <Draggable key={index} draggableId={index + ''} index={index}>
                                            {(provided:any,snapshot:any) => (
                                                createNewPortal(snapshot.isDragging, (
                                                    <div ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                    >
                                                        <div className={styles.VariantItemContainer} key={id}>
                                                            <Svg className={classNameDragDrop}>
                                                                <DragAndDropImg
                                                                    style={{fill: snapshot.isDragging && '#959595'}}
                                                                />
                                                            </Svg>
                                                            <Input
                                                                placeholder='Type an option'
                                                                value={variantTitle || ''}
                                                                className={styles.VariantInput}
                                                                style={{border: snapshot.isDragging && '1px solid #cbc3c1',
                                                                    fontFamily: snapshot.isDragging && 'Lato-Medium'
                                                                }}
                                                                onChange={variantTitleChange}
                                                            />
                                                            <AttachImageComponent
                                                                id={id}
                                                                isVariantImg
                                                                setIsVisibleImgFull={() => {
                                                                    showVariantImagePreview(originalImage);
                                                                }}
                                                                originalImage={originalImage}
                                                                addImage={addImageToVariant}
                                                                deleteImage={() => {
                                                                    onDeleteImageFromVariant(attachFile.id, id);
                                                                }}
                                                            />
                                                            <div className={styles.CheckBoxContainer}>
                                                                <label className={styles.LabelInput} >
                                                                    <input
                                                                        type='checkbox'
                                                                        checked={isSelected}
                                                                        onChange={() => variantCheckedQuestionChange(variantTitle, isSelected, id)}
                                                                        style={{border: snapshot.isDragging && !isSelected ? '1px solid #cbc3c1' :
                                                                                snapshot.isDragging && isSelected ? '1px solid #f85a40' : '',
                                                                                backgroundColor: snapshot.isDragging && isSelected ? '#f85a40' : ''
                                                                        }}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <Svg className={classNameActiveButton}
                                                                 onClick={() => onDeleteVariantButtonClick(id, isLastVariant)}
                                                                 style={{fill: snapshot.isDragging && '#8c8c8c'}}>
                                                                <DeleteImg/>
                                                            </Svg>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            {isVisibleImgFull &&
                <ImageFullContainer
                    imageSrc={previewLink}
                    setIsVisibleImgFull={setIsVisibleImgFull}
                />
            }
        </>
    );
};

export default VariantsListComponent;
import React from 'react';

import styles from './GetExtrasProComponent.module.scss'
import Image from '../../../../../../../../../../components/Image/Image';
import penImg from '../../../../../../../../../../../assets/images/icon/pen-icon.svg';
import flaskImg from '../../../../../../../../../../../assets/images/icon/flask-icon.svg'
import brightnessImg from '../../../../../../../../../../../assets/images/icon/brightness-icon.svg';
import settingsImg from '../../../../../../../../../../../assets/images/icon/settings-icon.svg';

const GetExtrasProComponent = () => {
    return (
        <div>
            <div className={styles.Title}>Get extras with PRO</div>
            <div className={styles.WrapperExtras}>
                <div className={styles.WrapperExtrasItem}>
                    <Image src={penImg} />
                    <div className={styles.TextItem}>Training content writing</div>
                </div>
                <div className={styles.WrapperExtrasItem}>
                    <Image src={flaskImg} />
                    <div className={styles.TextItem}>Dedicated training setup expert</div>
                </div>
                <div className={styles.WrapperExtrasItem}>
                    <Image src={brightnessImg} />
                    <div className={styles.TextItem}>Custom solution</div>
                </div>
                <div className={styles.WrapperExtrasItem}>
                    <Image src={settingsImg} />
                    <div className={styles.TextItem}>Existing content transfer</div>
                </div>
            </div>
        </div>
    );
};

export default GetExtrasProComponent;

export const secondBenefitsList = [
    {
        id: 5,
        name: 'Mobile-ready',
    },
    {
        id: 6,
        name: 'Integrations',
    },
    {
        id: 7,
        name: 'Custom branding',
    },
    {
        id: 8,
        name: 'Online support'
    },
]

export default secondBenefitsList;
import React, { useMemo, useState } from "react";

import styles from "./TeamItemComponent.module.scss";
import TeamItem from "./components/TeamItem/TeamItem";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeSelectListOfTeamMembers } from "../../../../../../../../../state/selectors/team";
import { TeamMember } from "../../../../../../../../../types/TeamMember";
import PaginationComponent from "../../../../../../../../../components/PaginationComponent/PaginationComponent";

const mapStateToProps = createStructuredSelector({
  listOfTeamMembers: makeSelectListOfTeamMembers(),
});

const TeamItemComponent = (props: any) => {
  const { listOfTeamMembers, setFilterValueName } = props;
  const perPage = useMemo(() => 4, []);
  const [currentPageDataTeam, setCurrentPageDataTeam] = useState<TeamMember[]>(
    []
  );

  return (
    <div>
      <div className={styles.WrapperSubtitle}>
        <span>Name</span>
        <span>Email</span>
        <span>Training names</span>
        <span>Created seats</span>
      </div>
      <div>
        {currentPageDataTeam.map((teamMember: TeamMember) => {
          const id = teamMember.id;

          return (
            <TeamItem
              key={id}
              teamMember={teamMember}
              setFilterValueName={setFilterValueName}
            />
          );
        })}
      </div>
      <PaginationComponent
        list={listOfTeamMembers}
        elementsPerPage={perPage}
        handleWorkingListUpdate={setCurrentPageDataTeam}
      />
    </div>
  );
};

export default connect(mapStateToProps)(TeamItemComponent);

import React from 'react';
import {connect} from 'react-redux';

import styles from './NoInternetConnectionPopup.module.scss';
import PopupFullPage from '../PopupFullPage';
import Modal from '../../../../containers/ServiceContainers/Modal/Modal';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsNetworkOnline} from '../../../../state/selectors/global';

const mapStateToProps = createStructuredSelector({
    isNetworkOnline: makeSelectIsNetworkOnline(),
});

const NoInternetConnectionPopup = (props:any) => {
    const {isNetworkOnline, children} = props;
    const isNotNetworkConnection = isNetworkOnline === false;

    return (
        isNotNetworkConnection ? (
            <Modal>
                <PopupFullPage>
                    <div className={styles.LogoContainer}>
                        <span>No internet connection!</span>
                        <span>Restart and try again</span>
                    </div>
                </PopupFullPage>
            </Modal>
        ) : (
            children || null
        )
    );
}

export default connect(mapStateToProps)(NoInternetConnectionPopup);

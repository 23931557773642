import React from 'react';

import styles from './ImageFullContainer.module.scss';
import Image from '../../../../../../../../../../../../../components/Image/Image';
import PopupFullPage from '../../../../../../../../../../../../../components/Popup/PopupFullPage/PopupFullPage';

const ImageFullContainer = (props:any) => {
    const {imageSrc, setIsVisibleImgFull} = props;

    const onClosePopupImage = () => {
        setIsVisibleImgFull(false);
    };

    return (
        <PopupFullPage
            isVisibleCloseButton={false}
            isSupportOutsidePopupClick
            classNameMainContainer={styles.MainContainer}
            closePopup={onClosePopupImage}
        >
            <Image src={imageSrc} className={styles.Image}/>
        </PopupFullPage>
    );
};

export default ImageFullContainer;

import adminService from '../adminService/adminService';
import stringUtils from '../../utils/stringUtilsService';
import config from '../../../config/config';
import {dispatch} from '../../../state/store';
import {setTemplatesList} from '../../../state/ducks/admin';
import gtmService from "../../dataTrigger/gtmService";
import {CourseMetaData} from '../../../types/CourseMetaData';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {coursePath, getAllCoursesPath, setCourseGoalPath, getCourseByIdPath, addModuleByCourseIdPath,
        updateModuleByIdPath, deleteModuleByIdPath, setModuleGoalPath, setBackgroundCoursePath,
    setSequenceModulePath, getTemplatesPath, setUseTemplatePath} = externalLinks;

const createNewCourse = async (name:string, background:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, coursePath);

    gtmService.addNewCoursePress(false);

    return await adminService.createNewCourse(formattedUrl, name, background);
};

const getAllCourses = async () : Promise<CourseMetaData[] | undefined> => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getAllCoursesPath);

    return await adminService.getAllCourses(formattedUrl);
};

const deleteCourse = async (courseId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, coursePath);

    return await adminService.deleteCourse(formattedUrl, courseId);
};

const renameCourse = async (courseId:number, courseName:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, coursePath);

    return await adminService.renameCourse(formattedUrl, courseId, courseName);
};

const getCourseById = async (courseId:number|null) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getCourseByIdPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, courseId);

    return await adminService.getCourseById(updatedUrl);
};

const setCourseGoal = async (courseId:number, newGoal:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setCourseGoalPath);

    return await adminService.setCourseGoal(formattedUrl, courseId, newGoal);
};

const addModuleByCourseId = async (courseId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, addModuleByCourseIdPath);

    return await adminService.addModuleByCourseId(formattedUrl, courseId);
};

const deleteModuleByModuleId = async (moduleId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, deleteModuleByIdPath);

    return await adminService.deleteModuleByModuleId(formattedUrl, moduleId);
};

const updateModuleById = async (moduleId:number, newCourseName:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, updateModuleByIdPath);

    return await adminService.updateModuleById(formattedUrl, moduleId, newCourseName);
};

const updateModuleGoal = async (moduleId:number, newGoal:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setModuleGoalPath);

    await adminService.updateModuleGoal(formattedUrl, moduleId, newGoal);
};

const setBackgroundCourse = async (courseId:number, background:string) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setBackgroundCoursePath);

    await adminService.setBackgroundCourse(formattedUrl, courseId, background);
};

const updateSequenceModule = async (moduleSequences:any[]) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setSequenceModulePath);

    await adminService.updateSequenceModule(formattedUrl, moduleSequences);
};

const getTemplates = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getTemplatesPath);

    const templatesList = await adminService.getTemplates(formattedUrl);

    dispatch(setTemplatesList(templatesList));
};

const setUseTemplate = async (courseId: number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, setUseTemplatePath);

    gtmService.addNewCoursePress(true);

    return await adminService.setUseTemplate(formattedUrl, courseId);

}

export default {
    createNewCourse,
    getAllCourses,
    deleteCourse,
    renameCourse,
    setCourseGoal,
    getCourseById,
    addModuleByCourseId,
    updateModuleById,
    deleteModuleByModuleId,
    updateModuleGoal,
    setBackgroundCourse,
    updateSequenceModule,
    getTemplates,
    setUseTemplate
}
import {PerformanceStorage} from '../interfaces/performanceInterface';
import {MetricsListForDashboard} from '../../types/performanceType/performanceType';

export const SET_METRICS_FOR_DASHBOARD = 'app/performance/SET_METRICS_FOR_DASHBOARD';

const initialState:PerformanceStorage = {
    metricsListForDashboard: [],
};

export default function reducer(state = initialState, action:any) {
    switch (action.type) {
        case SET_METRICS_FOR_DASHBOARD: {
            return {...state, metricsListForDashboard: action.payload};
        }
        default: return state;
    }
};

export const setMetricsForDashboard = (value:Array<MetricsListForDashboard>) => ({type: SET_METRICS_FOR_DASHBOARD, payload: value});

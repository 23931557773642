import React, {MutableRefObject, useState} from 'react';

import styles from './CompanyNameComponent.module.scss';
import InputAuthField from '../../../../../../../../components/Input/InputAuthField/InputAuthField';
import validationService from '../../../../../../../../services/server/validationService/validationService';

type CompanyNameComponentProps = {
    setName:Function;
    name: string;
}

const CompanyNameComponent:React.FC<CompanyNameComponentProps> = ({setName, name}) => {
    const [isInputError, setIsInputError] = useState<boolean>(false);

    const onNameChange = (event: React.FormEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
    };

    const onKeyPressName = (event: { target: HTMLInputElement}) => {
        const isValidName = validationService.isValidName(event.target.value);

        isValidName ? setIsInputError(false) : setIsInputError(true);
    }

    return (
       <InputAuthField
            value={name}
            labelText='Company name'
            classNameLabel={styles.Label}
            classNameInput={styles.Input}
            type='text'
            onChange={onNameChange}
            onKeyPress={onKeyPressName}
            placeholderInput='Company name'
            isInputError={isInputError}
       />
    );
};

export default CompanyNameComponent;

import {dispatch} from '../../../state/store';
import {setIsAppLoader} from '../../../state/ducks/global';

type AsyncFunc<T> = () => Promise<T>;

export const loading = async function<T>(func: AsyncFunc<T>) : Promise<T> {

    dispatch(setIsAppLoader(true));
    const res:T  = await func();
    dispatch(setIsAppLoader(false));

    return res;
};
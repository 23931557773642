import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import styles from './ReportsItem.module.scss';
import checkedImg from '../../../../../../../../../../../../assets/images/icon/checked-mark-green-icon.svg';
import newImg from '../../../../../../../../../../../../assets/images/icon/new-mark-tomato-icon.svg';
import passedImg from '../../../../../../../../../../../../assets/images/icon/passed-icon-green-icon.svg';
import failedImg from '../../../../../../../../../../../../assets/images/icon/failed-icon.svg';
import quizReportImg from '../../../../../../../../../../../../assets/images/icon/quiz-report-yellow-icon.svg';
import taskImg from '../../../../../../../../../../../../assets/images/icon/task-report-icon.svg';
import Image from '../../../../../../../../../../../components/Image/Image';
import TypeDetailsQuizPopup from '../../../TypeQuizPopup/TypeQuizPopup';
import TypeDetailsTaskPopup from '../../../TypeDetailsTaskPopup/TypeDetailsTaskPopup';
import {createStructuredSelector} from 'reselect';
import {setReportsList} from '../../../../../../../../../../../state/ducks/reports';
import {makeSelectReportsList} from '../../../../../../../../../../../state/selectors/reports'
import {dispatch} from '../../../../../../../../../../../state/store';
import {connect} from 'react-redux';
import reportsService from '../../../../../../../../../../../services/server/reportsService/reportsService';
import dateModify from '../../../../../../../../../../../services/utils/dateModify/dateModify';
import {ReportStatus} from '../../../../../../../../../../../types/ReportStatus';

const mapStateToProps = createStructuredSelector({
    reportsList: makeSelectReportsList(),
});

const ReportsItem = (props:any) => {
    const {reportsList} = props;

    const [isVisibleQuizPopup, setIsVisibleQuizPopup] = useState(false);
    const [isVisibleTaskPopup, setIsVisibleTaskPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [statusReport, setStatusReport] = useState(ReportStatus.UNCHECKED.Value);
    const [idReportOpen, setIdReportOpen] = useState(0);

    const hideQuizPopup = async () => {
        setIsVisibleQuizPopup(false);
        setLoader(false);
    };

    const hideTaskPopup = () => {
        setIsVisibleTaskPopup(false);
        setLoader(false);
    };

    const updateStatusChecked = (reportId:number) => {
        const updatedReportsList = reportsList?.filter((reportItem:any) => {
            if(reportItem.id === reportId && reportItem.status !== ReportStatus.CHECKED.Value){
                reportItem.status = ReportStatus.CHECKED.Value;
            };
            return reportItem;
        });

        dispatch(setReportsList(updatedReportsList));
    }

    const updateStatusUnchecked = (reportId:number) => {
        const updatedReportsList = reportsList?.filter((reportItem:any) => {
            if(reportItem.id === reportId && reportItem.status !== ReportStatus.UNCHECKED.Value){
                reportItem.status = ReportStatus.UNCHECKED.Value;
            };
            return reportItem;
        });

        dispatch(setReportsList(updatedReportsList));
    }

    const onDetailsClickQuiz = async (reportId:any, status:number) => {
        setIdReportOpen(reportId);
        setIsVisibleQuizPopup(true);
        setStatusReport(status);
        await reportsService.getDetailsQuiz(reportId);
        setLoader(true);
    };

    const onDetailsClickTask = async (reportId:any, status:number) => {
        setIdReportOpen(reportId);
        setIsVisibleTaskPopup(true);
        setStatusReport(status);
        await reportsService.getDetailsTask(reportId);
        setLoader(true);
    };

    const onCheckedReport = async () => {
        setStatusReport(ReportStatus.CHECKED.Value);
        reportsService.setStatusReport(idReportOpen, ReportStatus.CHECKED.Name);
        updateStatusChecked(idReportOpen);
    };

    const onUncheckedReport = async () => {
        setStatusReport(ReportStatus.UNCHECKED.Value);
        reportsService.setStatusReport(idReportOpen, ReportStatus.UNCHECKED.Name);
        updateStatusUnchecked(idReportOpen);
    };

    return (
        <div className={styles.ReportsListContainer}>
            {reportsList.map((item:any) => {
                const reportId = item.id;
                const type = item.type;
                const learnerName = item.learner.name;
                const courseName = item.course.name;
                const moduleName = item.module.name;
                const date = item.timeOfIssue;
                const status = item.status;
                const result = item.quizReport?.quizResult;
                const resultPassed = item?.quizReport?.isPassed === true;
                const resultFailed = item?.quizReport?.isPassed === false;
                const resultQuizReport = item?.quizReport?.isPassed === null;

                const classNamesResults = classNames(styles.ResultContainer, resultPassed ? styles.ReportsItemPassed : resultFailed ?
                    styles.ReportsItemFailed : resultQuizReport ? styles.ReportsItemQuizReport :
                        styles.ReportsTask);
                const textResults = resultPassed ? `Passed Quiz ${result !== null ? result : ''}%` :
                    resultFailed ? `Failed Quiz ${result !== null ? result : ''}%` : resultQuizReport ? `Quiz report ${result !== null ? `${result}%` : ''}` : '';

                const iconResults = resultPassed ? passedImg : resultFailed ?
                    failedImg : resultQuizReport ? quizReportImg : taskImg;

                return (
                    <div data-tip='View report'
                         className={styles.ReportsItem}
                         key={reportId}
                         onClick={() => type === 'QUIZ' ? onDetailsClickQuiz(reportId, status) :
                             onDetailsClickTask(reportId, status)}>
                        <span className={styles.ReportsItemOnboardee}>{learnerName}</span>
                        <div className={classNamesResults}>
                            <Image className={styles.Image} src={iconResults}/>
                            <span className={styles.ResultsText}>
                                {type === 'QUIZ' ? textResults : 'Task report'}
                            </span>
                        </div>
                        <span className={styles.CourseText}
                              data-tip={courseName?.length > 20 ? courseName : ''}>{courseName}</span>
                        <span className={styles.ModuleText}
                              data-tip={moduleName?.length > 20 ? moduleName : ''}>{moduleName}</span>
                        <span className={styles.DateText}>{dateModify.setModifyDate(date)}</span>
                        <div className={styles.StatusContainer}>
                            <Image className={styles.Image} src={status === 1 ? checkedImg : newImg}/>
                            <span className={styles.StatusText}>
                                {status === ReportStatus.CHECKED.Value ? 'Checked' : 'New'}
                            </span>
                        </div>
                        <ReactTooltip
                            effect='solid'
                            className={styles.Tooltip}
                        />
                    </div>
                );
            })}
            {isVisibleQuizPopup && (
                <TypeDetailsQuizPopup
                    hideQuizPopup={hideQuizPopup}
                    loader={loader}
                    onCheckedReport={onCheckedReport}
                    onUncheckedReport={onUncheckedReport}
                    statusReport={statusReport}
                />
            )}
            {isVisibleTaskPopup && (
                <TypeDetailsTaskPopup
                    hideTaskPopup={hideTaskPopup}
                    loader={loader}
                    onCheckedReport={onCheckedReport}
                    onUncheckedReport={onUncheckedReport}
                    statusReport={statusReport}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(ReportsItem);
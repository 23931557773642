import React from 'react';

import styles from './QuestionComponent.module.scss';
import MultipleChoiceQuestionComponent
    from './components/MultipleChoiceQuestionComponent/MultipleChoiceQuestionComponent';
import EssayQuestionComponent from './components/EssayQuestionComponent/EssayQuestionComponent';
import {dispatch} from '../../../../../../../../../state/store';
import {connect} from 'react-redux';
import {setDraftModuleQuiz, setDraftQuizQuestion} from '../../../../../../../../../state/ducks/courseConstructor';
import {
    makeSelectDraftModuleQuiz,
    makeSelectDraftQuizQuestion
} from '../../../../../../../../../state/selectors/courseConstructor';
import {createStructuredSelector} from 'reselect';
import moduleService from '../../../../../../../../../services/server/moduleService/moduleService';

const mapStateToProps = createStructuredSelector({
    draftModuleQuiz: makeSelectDraftModuleQuiz(),
    draftQuizQuestion: makeSelectDraftQuizQuestion()
});

const QuestionComponent = (props:any) => {
    const {draftQuizQuestion, draftModuleQuiz} = props;

    const isMultipleChoiceQuestion = draftQuizQuestion?.multipleChoiceQuestion;
    const isEssayQuestion = draftQuizQuestion?.essayQuestion;

    const onImageToQuestionUpdate = (image:any, questionId:number) => {
        const updateQuestionsList = draftModuleQuiz.questions.filter((question:any) => {
            if(question.id === questionId) {
                question.attachFile = image;
                dispatch(setDraftQuizQuestion({...question}));
            }
            return question;
        });

        dispatch(setDraftModuleQuiz({...draftModuleQuiz, questions: [...updateQuestionsList]}));
    };

    const deleteImageFromQuestion = async (imageId:number) => {
        await moduleService.deleteAttachFileQuiz(imageId);
    };

    const onImageDeleteFromQuestion = async () => {
        deleteImageFromQuestion(draftQuizQuestion.attachFile.id)
            .then(() => {
                onImageToQuestionUpdate(null, draftQuizQuestion.id);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onQuestionTitleChange = async (newTitle:string, questionId:number) => {
        const updateQuestionsList = draftModuleQuiz.questions.filter((question:any) => {
            if(question.id === questionId) {
                if(isMultipleChoiceQuestion) {
                    question.multipleChoiceQuestion.title = newTitle;
                }
                if(isEssayQuestion) {
                    question.essayQuestion.title = newTitle;
                }
            }
            return question;
        });

        dispatch(setDraftModuleQuiz({...draftModuleQuiz, questions: updateQuestionsList}));
        await moduleService.updateQuestionTitle(questionId, newTitle);
    };

    const addImageToQuestion = async (file:File, id:number) => {
        const newFiles:any = new FormData();
        newFiles.append('image', file);
        newFiles.append('questionId', id);

        const attachFile:any = await moduleService.addAttachFileQuestion(newFiles);
        await onImageToQuestionUpdate(attachFile, id);
    };

    return (
        <div className={styles.QuizContainer}>
            {isMultipleChoiceQuestion && (
                <MultipleChoiceQuestionComponent
                    draftModuleQuiz={draftModuleQuiz}
                    onQuestionTitleChange={onQuestionTitleChange}
                    addImageToQuestion={addImageToQuestion}
                    onImageDelete={onImageDeleteFromQuestion}
                    activeQuestion={draftQuizQuestion}
                />
            )}
            {isEssayQuestion && (
                <EssayQuestionComponent
                    onQuestionTitleChange={onQuestionTitleChange}
                    addImageToQuestion={addImageToQuestion}
                    onImageDelete={onImageDeleteFromQuestion}
                    activeQuestion={draftQuizQuestion}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(QuestionComponent);
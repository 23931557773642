import React from 'react';

import styles from './SubMenuComponent.module.scss';
import Input from '../../../../../../../../../../../../../../../../../components/Input/Input';
import {dispatch} from '../../../../../../../../../../../../../../../../../state/store';
import {setMetricsListByPipedrive} from '../../../../../../../../../../../../../../../../../state/ducks/integrations';
import {
    pipelineType,
    stageConversionsType
} from '../../../../../../../../../../../../../../../../../types/integrationType/dataForPipedrive';
import {createStructuredSelector} from 'reselect';
import {makeSelectMetricsListByPipedrive} from '../../../../../../../../../../../../../../../../../state/selectors/integrations';
import {connect} from 'react-redux';
import Image from '../../../../../../../../../../../../../../../../../components/Image/Image';
import {ReactComponent as ArrowImg} from '../../../../../../../../../../../../../../../../../../assets/images/icon/arrow-left-dashboard.svg';
import Svg from '../../../../../../../../../../../../../../../../../components/SvgComponent/SvgComponent';

const mapStateToProps = () => createStructuredSelector({
    metricsListByPipedrive: makeSelectMetricsListByPipedrive()
});

type SubMenuComponentProps = {
    stageConversions: stageConversionsType[]
    metricsListByPipedrive: Array<any>
    pipelines:Array<pipelineType>
    pipelineId: number|undefined
    setIsShowPipeLineMenu:Function
    setIsShowStagesConversions:Function
};

const SubMenuComponent:React.FC<SubMenuComponentProps> = ({stageConversions, metricsListByPipedrive, pipelines, pipelineId, setIsShowPipeLineMenu, setIsShowStagesConversions}) => {

    const stagesSelectedChange = (stageId:number) => {
        const updatedStagesList = stageConversions.filter((stage:any) => {
            if(stage.id === stageId) {
                stage.isSelected = !stage.isSelected;
            }
            return stage;
        });

        const updatedPipelines = pipelines.filter((pipeline) => {
            if (pipeline.id === pipelineId) {
                pipeline = {...pipeline, stageConversions: updatedStagesList}
            }
            return pipeline;
        });

        const updatedMetricsList = metricsListByPipedrive.filter((metric) => {
           if (metric.metricType.keyWord === 'CONVERSION_RATES') {
               metric = {...metric, pipelines: updatedPipelines};
           }
            return metric;
        })

        dispatch(setMetricsListByPipedrive(updatedMetricsList));
    };

    const onClickGoBack = () => {
        setIsShowStagesConversions(false);
        setIsShowPipeLineMenu(true);
    };

    return (
        <div className={styles.ContainerSub}>
            <div className={styles.WrapperGoBack} onClick={onClickGoBack}>
                <Svg className={styles.ImageMenu}>
                    <ArrowImg/>
                </Svg>
                <span className={styles.TextGoBack}>Go back</span>
            </div>
            <div className={styles.TitleSub}>Select stages conversions</div>
            <div className={styles.SubMenu}>
                {stageConversions.map((item: any) => {
                    const from = item.from;
                    const to = item.to;
                    return (
                        <div key={item.id} className={styles.WrapperInputImageSub}>
                            <label className={styles.LabelSub}>
                                <Input
                                    className={styles.InputSub}
                                    type='checkbox'
                                    checked={item.isSelected}
                                    onChange={() => stagesSelectedChange(item.id)}
                                />
                                <div className={styles.StageItem}>{`${from.name} - ${to.name}`}</div>
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(SubMenuComponent);

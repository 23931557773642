import React from 'react';
import styles from './TeamResultsComponent.module.scss';
import Image from '../../../../../../../../../../../../../../../components/Image/Image';
import fireImg from '../../../../../../../../../../../../../../../../assets/images/icon/fire-icon.svg';
import heartImg from '../../../../../../../../../../../../../../../../assets/images/icon/heart-icon.svg';

type TeamResultsComponentType = {
    isPercentage: boolean
    bestResult: number
    worstResult: number
}

const TeamResultsComponent:React.FC<TeamResultsComponentType> = ({isPercentage, bestResult, worstResult}) => {
    const value = isPercentage ? '%' : '';
    const bestResMsg  = 'Team best: ' + bestResult + value;
    const worstResMsg = 'Team worst: ' + worstResult + value;

    return (
        <div className={styles.TeamResults} >
            <div className={styles.WrapperResults}>
                <Image src={fireImg}/>
                <span>{bestResMsg}</span>
            </div>
            <div className={styles.WrapperResults}>
                <Image src={heartImg}/>
                <span>{worstResMsg}</span>
            </div>
        </div>
    );
};

export default TeamResultsComponent;

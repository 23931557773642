import styles from "./GapsItem.module.scss";
import Svg
    from "../../../../../../../../../../../../../../../../../../../../../../components/SvgComponent/SvgComponent";
import {
    ReactComponent as ArrowTop
} from "../../../../../../../../../../../../../../../../../../../../../../../assets/images/icon/arrow-top.svg";
import {
    ReactComponent as ArrowBottom
} from "../../../../../../../../../../../../../../../../../../../../../../../assets/images/icon/arrow-bottom.svg";
import ActionComponent
    from "../../../../../../../../../../../../../../../../LearnersComponent/components/CrmAnalytics/components/MetricsComponent/components/ActionComponent/ActionComponent";
import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {
    useOutsideListener
} from "../../../../../../../../../../../../../../../../../../../../../../hooks/useOutsideListener";

interface SetActionProps {
    setIsOpenReview: Function
    courseMeta: MutableRefObject<{name: string, id: number}|null>
}

export const SetActionComponent = ({setIsOpenReview, courseMeta}: SetActionProps) => {

    const [isOpenDropDown, setIsOpenDropDown] = useState(false);

    const toggleDropDownClick = () => {
        setIsOpenDropDown(!isOpenDropDown);
    };

    const dropDownRef = useRef<HTMLDivElement>(null!);
    const selectButtonRef = useRef<HTMLDivElement>(null!);

    const closeDropDownList = () => setIsOpenDropDown(false);
    useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

    const onReviewClick = useCallback((courseName:string, courseId:number) => {
        setIsOpenReview(() => {
            courseMeta.current = {name: courseName, id:courseId};
            return true;
        });
    }, []);

    return (
        <div className={styles.WrapperImg} onClick={toggleDropDownClick}>
            <span>Set action</span>
            <Svg className={styles.ImgDropDown} ref={selectButtonRef}>
                {isOpenDropDown ? <ArrowTop/> : <ArrowBottom/>}
            </Svg>
            {isOpenDropDown &&
                <div ref={dropDownRef}>
                    <ActionComponent
                        Container={styles.ContainerAction}
                        onClickReview={onReviewClick}
                    />
                </div>
            }
        </div>
    );

}
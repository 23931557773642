import React, {useState} from 'react';

import styles from './SubscriptionComponent.module.scss';
import ChangeCardPopup from './components/ChangeCardPopup/ChangeCardPopup';
import ProcessingChangePopup from './components/ProcessingChangePopup/ProcessingChangePopup';
import {connect} from 'react-redux';
import {
    makeSelectIsVisibleCardAndNextPayment,
    makeSelectIsVisiblePriceAndSeats,
    makeSelectPaymentInfo
} from '../../../../../../../state/selectors/subscription';
import {createStructuredSelector} from 'reselect';
import CurrentPlanFree from './components/CurrentPlanFree/CurrentPlanFree';
import CurrentPlanDetails from './components/CurrentPlanDetails/CurrentPlanDetails';
import CardComponent from './components/CardComponent/CardComponent';
import {dispatch} from '../../../../../../../state/store';
import {setIsVisibleAddSeatsPopUp} from '../../../../../../../state/ducks/global';
import {makeSelectIsVisibleSubscriptionPopup} from '../../../../../../../state/selectors/global';
import dateModify from '../../../../../../../services/utils/dateModify/dateModify';

const mapStateToProps = createStructuredSelector({
    paymentInfo: makeSelectPaymentInfo(),
    isVisibleCardAndNextPayment: makeSelectIsVisibleCardAndNextPayment(),
    isVisiblePriceAndSeats: makeSelectIsVisiblePriceAndSeats(),
    isVisibleSubscriptionPopup: makeSelectIsVisibleSubscriptionPopup()
});

const SubscriptionComponent = (props:any) => {
    const {paymentInfo, isVisibleCardAndNextPayment, isVisiblePriceAndSeats} = props;

    const [isVisibleChangeCardNumberPopup, setIsVisibleChangeCardNumberPopup] = useState(false);
    const [processingChangeCard, setProcessingChangeCard] = useState(false);
    const [isVisibleProcessingChangeCard, setIsVisibleProcessingChangeCard] = useState(false);
    const [errorChangeCard, setErrorChangeCard] = useState();

    const price:number = paymentInfo?.priceNumber/100;

    const onErrorChangeCard = (detailsError:any) => {
        setIsVisibleChangeCardNumberPopup(false);
        setIsVisibleProcessingChangeCard(true);
        setErrorChangeCard(detailsError);
    };

    const onClickToChangeCard = () => {
        setIsVisibleChangeCardNumberPopup(true);
    };

    const onClickToChangeSeats = () => {
        dispatch(setIsVisibleAddSeatsPopUp(true));
    };

    const onChangeCardProgress = () => {
        setIsVisibleProcessingChangeCard(true);
    };

    return (
        <>
            <div className={styles.WrapperSubscription}>
                <div className={styles.TitleSubscription}>Subscription</div>
                {(!isVisiblePriceAndSeats || paymentInfo?.last4 === null) &&
                    <CurrentPlanFree />
                }
                <div className={styles.WrapperDetailsSubscription}>
                    {(isVisiblePriceAndSeats || paymentInfo?.last4 !== null) &&
                        <div className={styles.ContainerSubscription}>
                            <div className={styles.SubtitleSubscription}>Current Plan</div>
                               <CurrentPlanDetails
                                   price={price}
                                   onClickToChangeSeats={onClickToChangeSeats}
                               />
                        </div>
                    }
                    {(isVisibleCardAndNextPayment || paymentInfo?.last4 !== null) &&
                        <div className={styles.ContainerCard}>
                            <CardComponent
                                onClickToChangeCard={onClickToChangeCard}
                            />
                        </div>
                    }
                    {(isVisibleCardAndNextPayment || paymentInfo?.last4 !== null) &&
                        <div className={styles.ContainerNextPayment}>
                            <div className={styles.SubtitleSubscription}>Next Payment</div>
                            <div className={styles.DataNextPayment}>{dateModify.setNewPayDate(paymentInfo?.nextPayment)}</div>
                        </div>
                    }
                </div>
            </div>
            {isVisibleChangeCardNumberPopup &&
                <ChangeCardPopup
                    setIsVisibleChangeCardNumberPopup={setIsVisibleChangeCardNumberPopup}
                    onChangeCardProgress={onChangeCardProgress}
                    setProcessingChangeCard={setProcessingChangeCard}
                    onErrorChangeCard={onErrorChangeCard}
                />
            }
            {isVisibleProcessingChangeCard &&
                <ProcessingChangePopup
                    setIsVisibleProcessingChangeCard={setIsVisibleProcessingChangeCard}
                    processingChangeCard={processingChangeCard}
                    setIsVisibleChangeCardNumberPopup={setIsVisibleChangeCardNumberPopup}
                    errorChangeCard={errorChangeCard}
                    setErrorChangeCard={setErrorChangeCard}
                />
            }
            </>
        )
}
export default connect(mapStateToProps)(SubscriptionComponent);


import React from 'react';

import styles from './UpgradeToPro.module.scss';
import checkMarkImg from '../../../../../../../../../../../assets/images/icon/check-mark-red.svg';
import Image from '../../../../../../../../../../components/Image/Image';
import companyAccountList from '../companyAccountList';
import streamlineListPro from '../streamlineListPro';
import getSupportList from '../getSupportList';
import upskillTeamList from '../upskillTeamList';

const UpgradeToPro = () => {
    return (
        <div className={styles.ContainerPro}>
            <div className={styles.ProPlanWrapper}>
                <div className={styles.PlanTitlePro}>Upgrade to:</div>
                <div className={styles.PlanPro}>PRO</div>
                <div className={styles.SeatsPro}>Unlimited seats, pay as your team grows</div>
            </div>
            <a href='https://calendly.com/gradual/20min?month=2021-11' target='_blank' className={styles.Link}>
                Talk to us
            </a>
        <div className={styles.ContainerDetailsPro}>
            <div>
                <div className={styles.WrapperDetailsPro}>
                    <div className={styles.SubtitlePro}>COMPANY ACCOUNT</div>
                    {companyAccountList.map((item, id:number) => {
                        return (
                            <div className={styles.Item} key={id}>
                                <Image src={checkMarkImg} className={styles.Image}/>
                                <div>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <div className={styles.SubtitlePro}>STREAMLINE TRAINING:</div>
                    {streamlineListPro.map((item, id:number) => {
                        return (
                            <div className={styles.Item} key={id}>
                                <Image src={checkMarkImg} className={styles.Image}/>
                                <div>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div>
                <div className={styles.WrapperDetailsPro}>
                    <div className={styles.SubtitlePro}>UPSKILL TEAM:</div>
                    {upskillTeamList.map((item,id:number) => {
                        return (
                            <div className={styles.Item} key={id}>
                                <Image src={checkMarkImg} className={styles.Image}/>
                                <div>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <div className={styles.SubtitlePro}>GET SUPPORT:</div>
                    {getSupportList.map((item, id:number) => {
                        return (
                            <div className={styles.Item} key={id}>
                                <Image src={checkMarkImg} className={styles.Image}/>
                                <div>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            </div>
        </div>
    );
};

export default UpgradeToPro;

export const secondNeedsList = [
    {
        id: 1,
        name: 'Partner base, team wiki',
        isChecked: false
    },
    {
        id: 2,
        name: 'Performance review',
        isChecked: false
    },
    {
        id: 3,
        name: 'Channel partner training',
        isChecked: false
    },
]

export default secondNeedsList;
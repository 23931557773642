import React, {useEffect, useState} from 'react';

import styles from './VerifyComponent.module.scss';
import Image from '../../components/Image/Image';
import backgroundImg from '../../../assets/images/icon/background-login.png';
import {useMediaQuery} from 'react-responsive';
import ButtonColor from '../../components/Button/components/ButtonColor/ButtonColor';
import verifyService from '../../services/server/verifyService/verifyService';
import urlUtils from '../../services/utils/urlUtils';
import {loading} from '../../components/Loader/AppLoader/loading';
import localStorageService from '../../services/storage/localStorageService';
import appRouterService from '../../services/route/appRouterService';
import rocketImg from '../../../assets/images/emoji/rocket_1f680.png';
import {ROOT} from "../../consts/routes/routePaths";

const VerifyComponent = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });
    const [token] : (string|null)[] = urlUtils.getUrlParams(['token']);

    const [verified, setVerified] = useState<boolean>(false);
    const [isUserAuthorized, setIsUserAuthorized] = useState<boolean>(false);

    useEffect(() => {
        loading(verifyEmail).then(() => {
            setVerified(true);
            const r_f = localStorageService.getRefreshToken();

            if (r_f !== 'undefined') {
                setIsUserAuthorized(true);
            }

        }).catch((e) => {
            console.log(e);
        })
    }, []);

    const verifyEmail = async () => {
        if (token) {
            await verifyService.verifyEmail(token);
        } else {
            throw 'Token invalid!'
        }
    };

    const onGetToAccountClick = () => {
        window.location.replace(ROOT);
    };

    const onSignInClick = () => {
        appRouterService.forwardToAdminLoginPage()
    };

    return (
        <div className={styles.MainContainer}>
            {!isTabletOrMobile && <Image src={backgroundImg} className={styles.BackgroundImage}/>}
            <div className={styles.SignInContainer}>
                <div className={styles.TitleContainer}>
                    <span className={styles.TitleText}>Welcome back to <span>Gradual</span></span>
                </div>
                    {verified &&
                        <>
                            <div className={styles.WrapperSubtitle}>
                                <div>Wonderful! Your email is verified, go on </div>
                                <div className={styles.Subtitle}>
                                    <span>with training</span>
                                    <Image src={rocketImg} className={styles.RocketImg}/>
                                </div>
                            </div>
                            {(isUserAuthorized && !isTabletOrMobile) &&
                                <ButtonColor className={styles.Button} onClick={onGetToAccountClick}>
                                    Get me to account
                                </ButtonColor>
                            }
                            {(!isUserAuthorized && !isTabletOrMobile) &&
                                <ButtonColor className={styles.Button} onClick={onSignInClick}>
                                    Login
                                </ButtonColor>
                            }
                        </>
                    }
                    {!verified &&
                        <div className={styles.WrapperSubtitle}>
                            <div>Ooops... your email is not verified, please </div>
                            <div>try again or resend verification email.</div>
                        </div>
                    }
            </div>
        </div>
    );
};

export default VerifyComponent;

import React, { useState } from "react";

import styles from "./PublicLinkComponent.module.scss";

import shareImg from "../../../../../../../../../../assets/images/icon/share-icon.svg";
import copyLinkImg from "../../../../../../../../../../assets/images/icon/copy-public-link-icon.svg";
import Image from "../../../../../../../../../components/Image/Image";
import Slider from "../../../../../../../../../components/Slider/Slider";
import Input from "../../../../../../../../../components/Input/Input";
import {
  makeSelectPublicInviteLink,
  makeSelectPublicUserInviteLink,
} from "../../../../../../../../../state/selectors/admin";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

const mapStateToProps = createStructuredSelector({
  publicInviteLink: makeSelectPublicInviteLink(),
  publicUserInviteLink: makeSelectPublicUserInviteLink(),
});

const PublicLinkComponent = (props: any) => {
  const {
    isCheckedSlider,
    onShareSliderChange,
    publicInviteLink,
    publicUserInviteLink,
    users,
  } = props;
  console.log(publicInviteLink);
  console.log(publicUserInviteLink);

  const [isCopied, setIsCopied] = useState(false);

  const callback = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    navigator.clipboard
      .writeText(users ? publicUserInviteLink.link : publicInviteLink.link)
      .catch((error) => {
        console.log("Could not write to clipboard!\n" + error);
        setIsCopied(false);
      });
  };

  return (
    <>
      <div className={styles.ShareContainer}>
        <div className={styles.ShareTextContainer}>
          <Image src={shareImg} className={styles.ShareImage} />
          <div className={styles.TextContainer}>
            <span>Link</span>
            <span>Anyone on the internet with this link can view</span>
          </div>
        </div>
        <Slider isChecked={isCheckedSlider} onChange={onShareSliderChange} />
      </div>
      {isCheckedSlider && (
        <div className={styles.InputContainer}>
          <Input
            readOnly={true}
            className={styles.PublicInputField}
            value={users ? publicUserInviteLink.link : publicInviteLink.link}
          />
          <div className={styles.CopyPublicLinkContainer} onClick={callback}>
            <Image src={copyLinkImg} className={styles.CopyImage} />
          </div>
          {isCopied && <div className={styles.WrapperForHint}>Copied!</div>}
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(PublicLinkComponent);

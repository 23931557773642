import React, {useRef} from 'react';
import styles from './InputFile.module.scss';

const InputFile = (props:any) => {
    const {className, children, onChange, accept} = props;
    const inputField = useRef<HTMLInputElement>(null);

    const onInputButtonClick = () => {
        if(inputField.current) {
            inputField.current.click();
        }
    };

    return (
        <>
            <input
                ref={inputField}
                type='file'
                multiple
                className={styles.HiddenInput}
                onChange={onChange}
                accept={accept}
            />
            <div className={className} onClick={onInputButtonClick}>
                {children}
            </div>
        </>
    );
};

export default InputFile;
import * as routePaths from '../../consts/routes/routePaths';
import routerService from './routerService';

const forwardToAdminLoginPage = () => routerService.forwardTo(routePaths.ADMIN_SIGN_IN);
const forwardToAdminSignUpPage = () => routerService.forwardTo(routePaths.ADMIN_SIGN_UP);
const forwardToAdminAccountPage = () => routerService.forwardTo(routePaths.ADMIN_ACCOUNT_PAGE);
const forwardToCreateNewCoursePage = () => routerService.forwardTo(routePaths.NEW_COURSE_PAGE);
const forwardToCourseConstructor = () => routerService.forwardTo(routePaths.COURSE_CONSTRUCTOR);
const forwardToAdminForgotPage = () => routerService.forwardTo(routePaths.FORGOT_PASSWORD);
const forwardToAdminResetPage = () => routerService.forwardTo(routePaths.RESET_PASSWORD);

export default {
    forwardToAdminLoginPage,
    forwardToAdminSignUpPage,
    forwardToAdminAccountPage,
    forwardToCreateNewCoursePage,
    forwardToCourseConstructor,
    forwardToAdminForgotPage,
    forwardToAdminResetPage
};

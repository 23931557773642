import React, {useState} from 'react';

import styles from './CompanyNeedsComponent.module.scss';
import Input from '../../../../../../../../components/Input/Input';
import classNames from 'classnames';
import ButtonColor from '../../../../../../../../components/Button/components/ButtonColor/ButtonColor';
import firstNeedsList from './firstNeedsList';
import secondNeedsList from './secondNeedsList';

type CompanyNeedsComponentProps = {
    onClickContinueButton: Function;
}

const CompanyNeedsComponent:React.FC<CompanyNeedsComponentProps> = ({onClickContinueButton}) => {
    const [firstListNeedsRegistration, setFirstListNeedsRegistration] = useState(firstNeedsList);
    const [secondListNeedsRegistration, setSecondListNeedsRegistration] = useState(secondNeedsList);

    const onChangeInputFirst = (id:number) => {
        const updateList = firstListNeedsRegistration.filter((item) => {
            if(item.id === id) {
                item.isChecked = !item.isChecked;
            }
            return item;
        })
        setFirstListNeedsRegistration(updateList);
    };

    const onChangeInputSecond = (id:number) => {
        const updateList = secondListNeedsRegistration.filter((item) => {
            if(item.id === id) {
                item.isChecked = !item.isChecked;
            }
            return item;
        })
        setSecondListNeedsRegistration(updateList);
    };

    return (
        <>
            <div className={styles.Container}>
                <div className={classNames(styles.WrapperItem, styles.WrapperItemFirstList)}>
                    {firstListNeedsRegistration.map((item, id) => {
                        return (
                            <label className={styles.Item} key={id}>
                                <Input
                                    className={styles.Input}
                                    type='checkbox'
                                    checked={item.isChecked}
                                    onChange={() => onChangeInputFirst(item.id)}
                                />
                                <span>{item.name}</span>
                            </label>
                        )
                    })}
                </div>
                <div className={styles.WrapperItem}>
                    {secondListNeedsRegistration.map((item, id) => {
                        return (
                            <label className={styles.Item} key={id}>
                                <Input
                                    className={styles.Input}
                                    type='checkbox'
                                    checked={item.isChecked}
                                    onChange={() => onChangeInputSecond(item.id)}
                                />
                                <span>{item.name}</span>
                            </label>
                        )
                    })}
                </div>
            </div>
            <ButtonColor className={styles.ContinueButtonNeeds} onClick={onClickContinueButton}>
                Continue
            </ButtonColor>
        </>

    );
};

export default CompanyNeedsComponent;

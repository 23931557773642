import { useState } from "react";
import ReactTooltip from "react-tooltip";

import emailImg from "../../../../../../../../../../assets/images/icon/email-invite-icon.svg";
import phoneImg from "../../../../../../../../../../assets/images/icon/phone-icon.svg";
import styles from "./PersonalInviteComponent.module.scss";
import Image from "../../../../../../../../../components/Image/Image";
import Slider from "../../../../../../../../../components/Slider/Slider";
import ButtonColor from "../../../../../../../../../components/Button/components/ButtonColor/ButtonColor";
import LearnerInviteContainer from "./components/LearnerInviteContainer/LearnerInviteContainer";
import { createStructuredSelector } from "reselect";
import { makeSelectIsPhoneInvitation } from "../../../../../../../../../state/selectors/customBranding";
import { connect } from "react-redux";
import invitationService from "../../../../../../../../../services/server/invitationService/invitationService";
import classNames from "classnames";

const mapStateToProps = createStructuredSelector({
  isPhoneInvitation: makeSelectIsPhoneInvitation(),
});

const PersonalInviteComponent = (props: any) => {
  const {
    onPersonalInviteSliderChange,
    onFinishInvitation,
    isCheckedSlider,
    courseId,
    isPhoneInvitation,
    users,
  } = props;
  const [invitationList, setInvitationList] = useState<string[]>([]);
  const [isCorrect, setIsCorrect] = useState(true);
  const [isPressedEnter, setIsPressedEnter] = useState(false);

  const sendInvitations = async () => {
    if (users) {
      await invitationService.invitationForUserByEmail(invitationList);
    } else {
      if (isPhoneInvitation) {
        await invitationService.sendPhoneInvitationToCourse(
          courseId,
          invitationList
        );
      } else {
        await invitationService.sendEmailInvitationToCourse(
          courseId,
          invitationList
        );
      }
    }
  };

  const onInviteButtonClick = () => {
    if (invitationList.length) {
      sendInvitations().then(() => {
        onFinishInvitation(invitationList.length);
      });
    } else {
      setIsCorrect(false);
      setTimeout(() => {
        setIsCorrect(true);
      }, 1500);
    }
  };

  return (
    <>
      <div className={styles.ShareContainer}>
        <div className={styles.ShareTextContainer}>
          {!isPhoneInvitation && (
            <Image src={emailImg} className={styles.ShareImage} />
          )}
          {isPhoneInvitation && (
            <Image src={phoneImg} className={styles.ShareImage} />
          )}
          <div className={styles.TextContainer}>
            <span>Share</span>
            {!isPhoneInvitation && <span>Share with people by email</span>}
            {isPhoneInvitation && <span>Share with people by phone</span>}
          </div>
        </div>
        <Slider
          isChecked={isCheckedSlider}
          onChange={onPersonalInviteSliderChange}
        />
      </div>
      {isCheckedSlider && (
        <div className={styles.InputContainer}>
          <LearnerInviteContainer
            isPhoneInvitation={isPhoneInvitation}
            invitationList={invitationList}
            setInvitationList={setInvitationList}
            isCorrect={isCorrect}
            setIsCorrect={setIsCorrect}
            setIsPressedEnter={setIsPressedEnter}
          />
          <div data-tip={!isPressedEnter ? "Press Enter to add user!" : ""}>
            <ButtonColor
              className={classNames(
                styles.InviteButtonClick,
                !isPressedEnter && styles.DisabledBtn
              )}
              onClick={onInviteButtonClick}
              disabled={!isPressedEnter && !invitationList.length}
            >
              Invite
            </ButtonColor>
            <ReactTooltip effect="solid" className={styles.Tooltip} />
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(PersonalInviteComponent);

import config from '../../../config/config';
import stringUtils from '../../utils/stringUtilsService';
import adminService from '../adminService/adminService';
import {dispatch} from '../../../state/store';
import {
    setFilterModuleListAnalytics,
    setAnalyticsList,
    setAnalyticsListProgress, setSortedAnalyticsListProgress, sortedAnalyticsList
} from '../../../state/ducks/analytics';

const {data} = config;
const {defaultApiLink, externalLinks} = data;
const {getAnalyticsPath, getOverviewPath, getFilteredListAnalyticsPath, getFilteredListPath, getProgressPath} = externalLinks;

const getAnalytics = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getAnalyticsPath);

    return await adminService.getAnalytics(formattedUrl);
};

const getFilteredCourses = async (courseId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilteredListPath);
    let updateUrl = stringUtils.formatString(formattedUrl, courseId);

    if(Number(courseId) !== -1) {
        const filteredCourses = await adminService.getAnalyticsListByFilter(updateUrl);

        dispatch(setAnalyticsList(filteredCourses.learnerAnalyticsList));
        dispatch(sortedAnalyticsList(filteredCourses.learnerAnalyticsList));

    } else if (Number(courseId) === -1) {
        const allAnalyticsList = await getAnalytics();

        dispatch(setAnalyticsList(allAnalyticsList.learnerAnalyticsList));
        dispatch(sortedAnalyticsList(allAnalyticsList.learnerAnalyticsList));
    }
};

const getFilteredCoursesAndModules = async (courseId:number, moduleId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilteredListPath);
    let updateUrl = stringUtils.formatString(formattedUrl, courseId);

    if(Number(moduleId) !== -1) {
        updateUrl = updateUrl.concat(`&moduleId=${moduleId}`);
        const filteredModules = await adminService.getAnalyticsListByFilter(updateUrl);

        dispatch(setAnalyticsList(filteredModules.learnerAnalyticsList));
        dispatch(sortedAnalyticsList(filteredModules.learnerAnalyticsList));

    } else {
         await getFilteredCourses(courseId);
    }
};

const getOverview = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getOverviewPath);

    return await adminService.getOverview(formattedUrl);
};

const getProgress = async (courseId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getProgressPath);
    const updateUrl = stringUtils.formatString(formattedUrl, courseId);

    const progressCourse = await adminService.getProgress(updateUrl);

    dispatch(setAnalyticsListProgress(progressCourse.learnerProgressList));
    dispatch(setSortedAnalyticsListProgress(progressCourse.learnerProgressList));
};

const getFilteredListType = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilteredListAnalyticsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'type');

    return await adminService.getFilteredList(updatedUrl);
};

const getFilteredListPeriod = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilteredListAnalyticsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'period');

    return await adminService.getFilteredList(updatedUrl);
};

const getFilteredListCourse = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilteredListAnalyticsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'course');

    return await adminService.getFilteredList(updatedUrl);
};

const getFilteredListModule = async (courseId:number) => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilteredListAnalyticsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'module');
    const finalUrl = updatedUrl.concat(`&courseId=${courseId}`)

    const modulesList = await adminService.getFilteredList(finalUrl);
    const sortedModulesList = modulesList.moduleFilters.sort((a:any, b:any) => a?.id < b?.id ? -1 : 1);

    dispatch(setFilterModuleListAnalytics(sortedModulesList));
};

const getFilteredListLearners = async () => {
    const formattedUrl = stringUtils.formatString(defaultApiLink, getFilteredListAnalyticsPath);
    const updatedUrl = stringUtils.formatString(formattedUrl, 'learner');

    return await adminService.getFilteredList(updatedUrl);
};

export default {
    getAnalytics,
    getOverview,
    getProgress,
    getFilteredCourses,
    getFilteredCoursesAndModules,
    getFilteredListType,
    getFilteredListPeriod,
    getFilteredListCourse,
    getFilteredListModule,
    getFilteredListLearners
}

import React, {useRef, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import styles from './SignUpPage.module.scss';
import backgroundImg from '../../../assets/images/icon/background-new.png';
import Image from '../../components/Image/Image';
import InformationComponent from './components/InformationComponent/InformationComponent';
import Line from '../../components/Line/Line';
import Input from '../../components/Input/Input';
import InputAuthField from '../../components/Input/InputAuthField/InputAuthField';
import ButtonColor from '../../components/Button/components/ButtonColor/ButtonColor';
import ButtonText from '../../components/Button/components/ButtonText/ButtonText';
import {USER_ALREADY_REGISTERED} from '../../consts/error/errorTypesMessage';
import {BAD_REQUEST} from '../../consts/httpStatus/httpStatus';
import appRouterService from '../../services/route/appRouterService';
import validationService from '../../services/server/validationService/validationService';
import adminAuthService from '../../services/server/adminService/adminAuthService';
import AuthErrorModal from '../../components/Error/AuthErrorComponent/AuthErrorComponent';
import {usePresentSession} from '../../hooks/usePresentSession';
import gtmService from "../../services/dataTrigger/gtmService";
import Logo from '../../components/Logo/Logo';
import localStorageService from "../../services/storage/localStorageService";
import {ROOT} from "../../consts/routes/routePaths";

const SignUpPage = () => {
    usePresentSession();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isErrorOccurred, setIsErrorOccurred] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isValidInputField, setIsValidInputField] = useState({
        isValidEmail: false,
        isValidPassword: false,
        isValidCompanyName: false
    });

    const textFieldForEmailRef = useRef<HTMLInputElement>(null)!;
    const textFieldForPasswordRef = useRef<HTMLInputElement>(null)!;

    const modifiedEmail = email.toLowerCase();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });

    const stringUrl = document.location.href;
    const params = (new URL(stringUrl)).searchParams;
    const partnerId = params.get("partnerId");

    const signUpUser = () => {
        const zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
        adminAuthService.signUpUser(email, password, stringUrl, partnerId, zoneId)
            .then(onSignUp)
            .catch((error: any) => {
                const {status, data} = error;
                if (status === BAD_REQUEST && data.includes(USER_ALREADY_REGISTERED)) {
                    setIsErrorOccurred(true);
                    setErrorMessage('This user is already registered!');
                }
        });
    };

    const onEnterKeyPressEmail = (event: any) => {
        const isValidEmail = validationService.isValidEmail(event.target.value);

        if (event.code === 'Enter' && isValidEmail) {
            textFieldForPasswordRef!.current!.focus();
        } else {
            setIsValidInputField({...isValidInputField, isValidEmail: !isValidEmail});
        }
    };

    const onEnterKeyPressPassword = (event: any) => {
        const isValidPassword = validationService.isValidPassword(event.target.value);

        if(event.code === 'Enter' && isValidPassword && isTermsChecked) {
            onSignUpButtonClick()
        } else {
            setIsValidInputField({...isValidInputField, isValidPassword: !isValidPassword});
        }
    };

    const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };

    const onPasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
    };

    const onCheckClick = (event:any) => {
        setIsTermsChecked(event.target.checked);
    };

    const onSignUpButtonClick = () => {
        const isValidEmail = validationService.isValidEmail(email);
        const isValidPassword = validationService.isValidPassword(password);

        if (isTermsChecked && isValidEmail && isValidPassword) {
            signUpUser();
        } else {
            setIsErrorOccurred(true);
            setErrorMessage('You need to accept Privacy Policy in order to proceed!');
        }
    };

    const onSignInButtonClick = () => {
        appRouterService.forwardToAdminLoginPage();
    };

    const onSignUp = () => adminAuthService.loginUser(modifiedEmail, password)
        .then(() => {
            gtmService.addSignUpToGtm(modifiedEmail);
            window.location.replace(ROOT);
        })
        .catch(console.error);

    return (
        <>
            <div className={isTabletOrMobile ? styles.MainContainerMobile : styles.MainContainerDesktop}>
                {isTabletOrMobile && <Logo className={styles.LogoImage}/>}
                <>
                    {!isTabletOrMobile && <Image src={backgroundImg} className={styles.BackgroundImage}/>}
                    <div
                        className={classNames(styles.ContentContainer, isTabletOrMobile && styles.ContentContainerMobile)}>
                        {!isTabletOrMobile && <InformationComponent/>}
                        <div className={isTabletOrMobile ? styles.SignUpContainerMobile : styles.SignUpContainer}>
                            {isTabletOrMobile &&
                            <div className={styles.TitleContainerWelcome}>
                                <span className={styles.TitleText}>Welcome to <span>Gradual</span></span>
                            </div>
                            }
                            <div className={styles.InputContainer}>
                                <div className={styles.TitleContainer}>
                                    <Line className={styles.Line}/>
                                    <span>Sign up with email</span>
                                    <Line className={styles.Line}/>
                                </div>
                                <InputAuthField
                                    classNameInput={styles.InputField}
                                    classNameLabel={styles.LabelInputText}
                                    labelText='Email'
                                    value={email}
                                    type='text'
                                    reference={textFieldForEmailRef}
                                    onKeyPress={onEnterKeyPressEmail}
                                    placeholderInput='myemail@company.com'
                                    onChange={onEmailChange}
                                    isInputError={isValidInputField.isValidEmail}
                                />
                                <InputAuthField
                                    classNameInput={styles.InputField}
                                    classNameLabel={styles.LabelInputText}
                                    labelText='Password'
                                    value={password}
                                    reference={textFieldForPasswordRef}
                                    placeholderInput='**********'
                                    onChange={onPasswordChange}
                                    onKeyPress={onEnterKeyPressPassword}
                                    isInputError={isValidInputField.isValidPassword}
                                    isPasswordViewButton
                                />
                                <label className={styles.PrivacyPolicyContainer}>
                                    <Input
                                        type='checkbox'
                                        className={styles.CheckBox}
                                        onChange={onCheckClick}
                                    />
                                    <span>By signing up, I agree to the <a href='https://gradual.io/terms-of-service'
                                                                           target='_blank'>Terms of Use</a> and <a
                                        href='https://gradual.io/privacy-policy'
                                        target='_blank'>Privacy Policy.</a></span>
                                </label>
                                {isErrorOccurred &&
                                <AuthErrorModal
                                    errorMessage={errorMessage}
                                    onClick={onSignInButtonClick}
                                />
                                }
                                <ButtonColor
                                    className={classNames(styles.SignUpButton, (!email.length && !password.length) && styles.DisabledSignUpButton)}
                                    onClick={onSignUpButtonClick}
                                >
                                    Let's get it!
                                </ButtonColor>
                                <div className={styles.TitleContainer}>
                                    <Line className={styles.Line}/>
                                    <span>Have an account?</span>
                                    <Line className={styles.Line}/>
                                </div>
                                <ButtonText className={styles.SignInButton} onClick={onSignInButtonClick}>
                                    Sign in
                                </ButtonText>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

export default SignUpPage;

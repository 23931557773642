import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Image from "../../../../../../../../../../../../../../../../../../../../components/Image/Image";
import topDropDownImg from "../../../../../../../../../../../../../../../../../../../../../assets/images/icon/drop-down-top-icon.svg";
import bottomDropDownImg from "../../../../../../../../../../../../../../../../../../../../../assets/images/icon/drop-down-bottom-icon.svg";
import checkedStatusImg from "../../../../../../../../../../../../../../../../../../../../../assets/images/icon/checked-mark-green-icon.svg";
import newStatusImg from "../../../../../../../../../../../../../../../../../../../../../assets/images/icon/new-mark-tomato-icon.svg";
import Loader from "react-loader-spinner";
import learnersService from "../../../../../../../../../../../../../../../../../../../../services/server/learnersService/learnersService";

import styles from "./FilterMetricsComponent.module.scss";
import OptionsComponentCheckBox from "../../../../../../../../../../../../../../../../../../../../components/DropDownList/component/OptionsComponentCheckBox/OptionsComponentCheckBox";
import { useOutsideListener } from "../../../../../../../../../../../../../../../../../../../../hooks/useOutsideListener";

type filterValueType = {
  name: string;
  id: number;
};

type FilterMetricsComponentProps = {
  classNameContainer: string;
  classNameTitle?: string;
  textTitle: string;
  classNameDropDown: string;
  classNameDropDownMenu: string;
  listOptions: filterValueType[];
  sortedListOptions: any;
  setListOptions: Function;
};

const FilterMetricsComponent: React.FC<FilterMetricsComponentProps> = ({
  classNameContainer,
  classNameTitle,
  textTitle,
  classNameDropDown,
  classNameDropDownMenu,
  listOptions,
  sortedListOptions,
  setListOptions,
}) => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [loader, setLoader] = useState(false);

  const openDropDownList = async () => {
    setIsOpenDropDown(!isOpenDropDown);
    await learnersService.getFiltersLearnerCourse();
    setLoader(true);
  };

  const closeDropDownList = () => setIsOpenDropDown(false);
  useOutsideListener(dropDownRef, selectButtonRef, closeDropDownList);

  const onValueFilterButtonClick = (
    event: any,
    filterValue: string,
    id: number
  ) => {
    if (event.target.checked) {
      listOptions.push({ name: filterValue, id: id });
    } else {
      listOptions = listOptions.filter((option) => option.name != filterValue);
    }
    setListOptions([...listOptions]);
  };

  return (
    <div className={classNames(styles.FilterContainer, classNameContainer)}>
      <span className={classNames(styles.Title, classNameTitle)}>
        {textTitle}
      </span>
      <div
        className={
          isOpenDropDown
            ? classNames(
                classNameDropDown,
                styles.DropDownContainer,
                styles.ActiveDropDown
              )
            : classNames(classNameDropDown, styles.DropDownContainer)
        }
        onClick={openDropDownList}
        ref={selectButtonRef}
      >
        <span className={styles.DropDownValue}>
          {isOpenDropDown ? "Select" : "Click to choose"}
        </span>
        <Image
          src={isOpenDropDown ? topDropDownImg : bottomDropDownImg}
          className={classNames(
            styles.DropDownIcon,
            isOpenDropDown && styles.isActiveDropDownIcon
          )}
        />
      </div>
      {isOpenDropDown && (
        <div
          className={classNames(
            styles.DropDownMenuContainer,
            classNameDropDownMenu
          )}
          ref={dropDownRef}
        >
          <div className={styles.DropDownOverflow}>
            {loader ? (
              sortedListOptions?.map((item: any) => {
                const filterId = item.id;
                const filterName = item.name;

                const imgOptions =
                  filterName === "Checked"
                    ? checkedStatusImg
                    : filterName === "New"
                    ? newStatusImg
                    : "";
                const checked = listOptions
                  .map((option: any) => option.name)
                  .includes(filterName);

                return (
                  <OptionsComponentCheckBox
                    classNameWraperOptions={styles.OptionsWrapper}
                    checked={checked}
                    reference={optionsRef}
                    key={filterId}
                    optionText={filterName}
                    onClick={(event: any) =>
                      onValueFilterButtonClick(event, filterName, filterId)
                    }
                    classNameShowText={filterName?.length > 17 && true}
                    optionImg={imgOptions}
                    isCheckBoxOption
                  />
                );
              })
            ) : (
              <div className={styles.WrapperForLoader}>
                <Loader
                  type="TailSpin"
                  color="#F85A40"
                  height={25}
                  width={25}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterMetricsComponent;

import classNames from 'classnames';
import React, {useState} from 'react';

import styles from './InputAuthField.module.scss';
import Input from '../Input';
import Image from '../../Image/Image';
import visiblePasswordImg from '../../../../assets/images/icon/eye-visible-password.svg';
import hidePasswordImg from '../../../../assets/images/icon/eye-not-visible-password.svg';

const InputAuthField = (props:any) => {
    const {labelText, type, classNameInput, classNameLabel, isPasswordViewButton,
        onChange, onKeyPress, placeholderInput, maxLength, minLength, isInputError, children,
        reference, value, isDisabledInput = false} = props;
    const inputClassName = classNames(classNameInput, isInputError && styles.InputError,
        isPasswordViewButton && styles.PasswordInput);

    const [isVisiblePassword, setIsVisiblePassword] = useState(false);
    const [passwordFieldTypeText, setPasswordFieldTypeText] = useState(false);

    const showPasswordButtonClick = () => {
        setIsVisiblePassword(!isVisiblePassword);
        setPasswordFieldTypeText(!isVisiblePassword);
    };

    return (
        <div>
            <label className={classNameLabel}>{labelText}</label>
            <div className={styles.InputContainer}>
                <Input
                    type={type ? type : passwordFieldTypeText ? 'text' : 'password'}
                    className={inputClassName}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    placeholder={placeholderInput}
                    maxLength={maxLength}
                    minLength={minLength}
                    value={value}
                    reference={reference}
                    isDisabledInput={isDisabledInput}
                />
                {isPasswordViewButton && (
                    <div className={styles.EyeButtonContainer} onClick={showPasswordButtonClick}>
                        <Image
                            src={isVisiblePassword ? visiblePasswordImg : hidePasswordImg}
                            className={styles.ShowPassword}
                        />
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};

export default InputAuthField;

import React from 'react';

import styles from './HomeComponent.module.scss';
import wavingHandImg from '../../../../../../../../assets/images/emoji/waving-hand.png';
import CustomersComponent from './components/CustomersComponent/CustomersComponent';
import PaginationComponent from './components/PaginationComponent/PaginationComponent';
import Image from '../../../../../../../components/Image/Image';
import PartnerLink from './components/PartnerLink/PartnerLink';
import {createStructuredSelector} from 'reselect';
import {makeSelectCustomersList} from '../../../../../../../state/selectors/home';
import {connect} from 'react-redux';

const mapStateToProps = createStructuredSelector({
    customersList: makeSelectCustomersList()
});

const HomeComponent = (props:any) => {
    const {customersList} = props;

    return (
        <div className={styles.ContainerHome}>
            {customersList.length ?
                <>
                    <div className={styles.WrapperMainText}>
                        <div className={styles.Title}>Welcome back</div>
                        <Image
                            className={styles.TitleImg}
                            src={wavingHandImg}
                        />
                    </div>
                    <PartnerLink />
                    <CustomersComponent />
                    <PaginationComponent />
                </> :
                <div className={styles.PartnersNone}>It seems you don’t have customers right now.
                How about crushing new sales goals? 😏</div>
            }
        </div>
    );
};

export default connect(mapStateToProps)(HomeComponent);